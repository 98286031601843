<div class="row">
    <h2>{{ '::SonaroCRM' | abpLocalization }}</h2>
    <hr>
    <form 
        class="pt-4"
        *ngIf="!isLoading"
        (ngSubmit)="setSonaroCRMSettings()"
        [formGroup]="sonaroCRMProfileForm"
    >
        <div class="form-group mb-3">
            <label class="form-label" for="sonaro-crm-client-id">
            {{ '::SonaroCRMClientID' | abpLocalization }}
            </label>
            <input type="text" id="sonaro-crm-client-id" class="form-control" formControlName="clientID"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="sonaro-crm-client-secret">
                {{ '::SonaroCRMClientSecret' | abpLocalization }}
            </label>
            <input type="password" id="sonaro-crm-client-secret" class="form-control" formControlName="clientSecret"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="sonaro-crm-grant-type">
            {{ '::SonaroCRMGrantType' | abpLocalization }}
            </label>
            <input type="text" id="sonaro-crm-grant-type" class="form-control" formControlName="grantType"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="sonaro-crm-api-url">
            {{ '::SonaroCRMApiUrl' | abpLocalization }}
            </label>
            <input type="text" id="sonaro-crm-api-url" class="form-control" formControlName="apiUrl"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="sonaro-crm-auth-api-url">
            {{ '::SonaroCRMAuthApiUrl' | abpLocalization }}
            </label>
            <input type="text" id="sonaro-crm-auth-api-url" class="form-control" formControlName="authApiUrl"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="sonaro-crm-client-id">
            {{ '::SonaroCRMIsEnabled' | abpLocalization }}
            </label>
            <div class="form-check">
                <div class="form-check-inline">
                    <label class="form-check-label" for="true">{{ '::Enabled' | abpLocalization }}</label>
                    <input class="form-check-input" type="radio" [value]="true" id="true" formControlName="isEnabled"/>
                </div>
                <div class="mx-4 form-check-inline">
                    <label class="form-check-label" for="false">{{ '::Disabled' | abpLocalization }}</label>
                    <input class="form-check-input" type="radio" [value]="false" id="false" formControlName="isEnabled"/>
                </div>
            </div>

        </div>

        <abp-button
            (click)="setSonaroCRMSettings()"
        >
            <i class="fa fa-save"></i>
            {{ '::Save' | abpLocalization }}
        </abp-button>
    </form>
</div>