import { Component, Input, OnInit } from '@angular/core';
import { ClassDto } from '@proxy/classes';

@Component({
  selector: 'app-courses-photo-viewer',
  templateUrl: './courses-photo-viewer.component.html',
  styleUrls: ['./courses-photo-viewer.component.scss']
})
export class CoursesPhotoViewerComponent implements OnInit {
  @Input() class: ClassDto;
  @Input() fileStreamSource: string;
  isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void {}

}
