import type { CompanyProfileDto, SetCompanyProfileDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyProfileService {
  apiName = 'Default';
  

  getCompanyProfile = () =>
    this.restService.request<any, CompanyProfileDto>({
      method: 'GET',
      url: '/api/app/company-profile/company-profile',
    },
    { apiName: this.apiName });
  

  setCompanyInformationForTenant = (input: SetCompanyProfileDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/company-profile/set-company-information-for-tenant',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
