import { NameValue } from './../proxy/volo/abp/models';
import { EnovaOrganizationUnitLookupService } from './../proxy/organization-units/enova-organization-unit-lookup.service';
import { TicketKind, ticketKindOptions } from './../proxy/tickets/ticket-kind.enum';
import { ConfigStateService, ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { TicketDto, TicketEducationKind, ticketEducationKindOptions, TicketsService, TicketStatus, ticketStatusOptions } from '@proxy/tickets';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Constants } from '../shared/Constants/Constants';
import { TenantUserLookupDto } from '@proxy/enova-users-lookup';
import { BaseDocumentDto, DocumentStatus, TenantDocumentWithApprovalDataDto,  } from '@proxy/documents';
import { BaseCourseDto, TenantCourseWithApprovalDataDto } from '@proxy/courses';
import { TenantDocumentLookupService } from '@proxy/tenant-documents';
import { TenantCourseLookupService } from '@proxy/tenant-courses';
import { DocumentApprovalDto, DocumentApprovalSettingsService } from '@proxy/documents-approval';
import { LookupDto, LookupRequestDto } from '@proxy/shared';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnovaOrganizationUnitsService } from '@proxy/enova-organization-units';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  providers: [ListService, DatePipe,
    {
      provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    }
  ],
})
export class TicketComponent implements OnInit {
  task = { 
    items: [], 
    totalCount: 0 
  } as PagedResultDto<TicketDto>;

  selectedUsers = {
    items: [],
    totalCount: 0
  } as PagedResultDto<TenantUserLookupDto>;

  filteredOrganizationUnits = {
    items: [],
    totalCount: 0
  } as PagedResultDto<LookupDto<string>>

  isModalOpen = false;
  statusTypes =  ticketStatusOptions;
  ticketKinds = ticketKindOptions;
  ticketEducationKinds = ticketEducationKindOptions;
  filterForm: UntypedFormGroup;
  createUpdateTicketForm: UntypedFormGroup;
  tenantUsers$: Observable<TenantUserLookupDto[]>;
  selectedTicket = {} as TicketDto;
  DateTimeFormatConst = Constants.DATETIME;
  courseLookup: BaseCourseDto[] = [];
  tenantUsersLookup: TenantUserLookupDto[] = [];
  documentLookup: TenantDocumentWithApprovalDataDto[] = [];
  courseWithApprovalDataLookup: TenantCourseWithApprovalDataDto[] = [];
  isTicketEducationKindShown: boolean = false;
  isDocumentShown: boolean = null;
  isDocumentShownForApproval: boolean = null;
  isTicketDocumentApprovalSelected: boolean = false;
  isTicketEdit: boolean = false;
  selectFromUsers: boolean = false;
  selectFromOrganizationUnit: boolean = false;

  responsibleUserForApproval: DocumentApprovalDto;
  currentUserId: string;

  today: Date = new Date();
  weekFromToday: Date = new Date();
  
  focusForDocumentFilter$ = new Subject<string>();
  clickForDocumentFilter$ = new Subject<string>();
  focusForCourseFilter$ = new Subject<string>();
  clickForCourseFilter$ = new Subject<string>();
  focusForTicketDocument$ = new Subject<string>();
  clickForTicketDocument$ = new Subject<string>();
  focusForTicketDocumentWithApprovalData$ = new Subject<string>();
  clickForTicketDocumentWithApprovalData$ = new Subject<string>();
  focusForTicketCourseWithApprovalData$ = new Subject<string>();
  clickForTicketCourseWithApprovalData$ = new Subject<string>();
  focusForTicketCourse$ = new Subject<string>();
  clickForTicketCourse$ = new Subject<string>();
  focusForTicketTenant$ = new Subject<string>();
  clickForTicketTenant$ = new Subject<string>();
  organizationUnitId;

  documentFormatter = (document: TenantDocumentWithApprovalDataDto) => document.name;
  courseForApprovalFormatter = (course: TenantCourseWithApprovalDataDto) => course.name;
  courseFormatter = (course: BaseCourseDto) => course.name;
  tenantFormatter = (tenant: TenantUserLookupDto) => tenant.userName;

  disabled = false;
  limitSelection = false;
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  selectedItems = [];
  selectedItemsForCreate = [];

  constructor(
    private datePipe: DatePipe,
    public readonly list: ListService,
    public readonly orgnUnitlist: ListService,
    private formBuilder: UntypedFormBuilder,
    private taskService: TicketsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private tenantDocumentLookupService: TenantDocumentLookupService,
    private tenantCourseLookupService: TenantCourseLookupService,
    private confirmation: ConfirmationService,
    private documentApprovalSettingsService: DocumentApprovalSettingsService,
    private config: ConfigStateService,
    private enovaOrganizationUnitLookupService: EnovaOrganizationUnitLookupService,
    private enovaOrganizationUnitService: EnovaOrganizationUnitsService
  )
  {
    this.tenantUsers$ = taskService.getTenantUserLookup().pipe(map(r => r.items));
  }

  onSelectionFromValueChange(event: any) {
    this.selectedItemsForCreate = [];
    switch(event) {
      case "true":
        this.selectFromUsers = true;
        this.selectFromOrganizationUnit = false;
        break;
      case "false":
        this.selectFromUsers = false;
        this.selectFromOrganizationUnit = true;
        break;
      default: 
        this.selectFromUsers = false;
        this.selectFromOrganizationUnit = false;
        break;
    }
  }
  
  onOrganizationUnitSelect(item: any) {
    var data = this.filteredOrganizationUnits.items.find(x => x.displayName === item);
    this.selectedItems.push({name: data.displayName, id: data.id});
  }

  onOrganizationUnitCreateSelect(item: any) {
    var data = this.filteredOrganizationUnits.items.find(x => x.displayName === item);
    this.selectedItemsForCreate.push({name: data.displayName, id: data.id});
  }

  onOrganizationUnitDeSelect(item: any) {
    var data = this.selectedItems.find(x => x.displayName === item);
    var indexOfData = this.selectedItems.indexOf(data);
    this.selectedItems.splice(indexOfData, 1);
  }

  onOrganizationUnitCreateDeSelect(item: any) {
    var data = this.selectedItemsForCreate.find(x => x.displayName === item);
    var indexOfData = this.selectedItemsForCreate.indexOf(data);
    this.selectedItemsForCreate.splice(indexOfData, 1);
  }

  ngOnInit(): void {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;

    this.taskService.getTenantUserLookup().subscribe((response) => {
      this.tenantUsersLookup = response.items;
    });

    this.currentUserId = this.config.getOne("currentUser").id;

    this.documentApprovalSettingsService.getDocumentApprovalInformation(true).subscribe((response) => {
      this.responsibleUserForApproval = response;
    });

    this.tenantDocumentLookupService.getTenantDocumentWithApprovalDataLookup().subscribe((response) => {
      this.documentLookup = response.items;
    });
    
    this.tenantCourseLookupService.getTenantCourseWithApprovalDataLookup().subscribe((response) => {
      this.courseWithApprovalDataLookup = response.items;
    });

    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;

    this.tenantCourseLookupService.getTenantCourseLookup().subscribe((response) => {
      this.courseLookup = response.items;
    });

    this.taskService.getTenantUserLookup().subscribe((response) => {
      this.tenantUsersLookup = response.items;
    });

    const taskStreamCreator = (query) => this.taskService.getList(
      {
        ...query,
        status: this.filterForm.get("taskStatus").value,
        ticketKind: this.filterForm.get("taskKind").value,
        ticketEducationKind: this.filterForm.get("taskEducationKind").value,
        assignedUserId: this.filterForm.get("assignedUserId").value,
        tenantDocumentId: this.filterForm.get("document").value ? this.filterForm.get("document").value.id : null,
        tenantCourseId: this.filterForm.get("course").value ? this.filterForm.get("course").value.id : null,
        startDate: this.transformDate(this.filterForm.get("startDate").value, this.filterForm.get("startTime").value),
        endDate: this.transformDate(this.filterForm.get("endDate").value, this.filterForm.get("endTime").value),
        organizationUnitsIds: this.selectedItems ? this.selectedItems.map(item => item.id) : null
      }
    );

    this.list.hookToQuery(taskStreamCreator).subscribe((response) => {
      this.task = response;
    });

    this.getOrganizationUnits();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      defaultOpen: false,
      enableCheckAll: false,
      noDataAvailablePlaceholderText: this.localizationService.instant("::NoDataAvailable"),
      searchPlaceholderText: this.localizationService.instant("::Search")
    };

    this.buildFormForSearch();
  }

  organizationUnitFilterChange(event: any) {
    this.fetchOrganizationUnits({ filter: event } as LookupRequestDto);
  }

  getOrganizationUnits() {
    this.fetchOrganizationUnits({} as LookupRequestDto);
  }

  private fetchOrganizationUnits(requestDto: LookupRequestDto) {
    const orgUnitStreamCreator = (query) => {
      const updatedQuery = query.filter !== undefined ? { ...query } : { ...query, filter: requestDto.filter };
      return this.enovaOrganizationUnitLookupService.getOrganizationUnitLookup(updatedQuery);
    };
  
    this.orgnUnitlist.hookToQuery(orgUnitStreamCreator).subscribe((response) => {
      if (response && response.items && response.items.length > 0) {
        this.dropdownList = response.items.map(item => item.displayName);
      } else {
        this.dropdownList = [{
          item_id: 0,
          item_text: this.localizationService.instant("::NoDataAvailable"),
          isDisabled: true
        }];
      }
      
      if (response) {
        this.filteredOrganizationUnits = response;
      } else {
        this.filteredOrganizationUnits = {
          items: [],
          totalCount: 0
        };
      }
    });
  }

  searchTicketByDocument: OperatorFunction<string, readonly BaseDocumentDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForDocumentFilter$, this.clickForDocumentFilter$).pipe(
      map(term => (term === '' ? this.documentLookup
        : this.documentLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchTicketByCourse: OperatorFunction<string, readonly BaseCourseDto[]> = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

      return merge(debouncedText$, this.focusForCourseFilter$, this.clickForCourseFilter$).pipe(
        map(term => (term === '' ? this.courseLookup
          : this.courseLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
        )
      );
  };

  searchForNotApprovedDocumentsForTicket: OperatorFunction<string, readonly TenantDocumentWithApprovalDataDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketDocumentWithApprovalData$, this.clickForTicketDocumentWithApprovalData$).pipe(
      map(term => (term === '' ? this.documentLookup.filter(x => x.status == DocumentStatus.NotApproved)
        : this.documentLookup.filter(v => (v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) && v.status == DocumentStatus.NotApproved) )
      )
    );
  };

  searchForNotApproveCoursesForTicket: OperatorFunction<string, readonly TenantCourseWithApprovalDataDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketCourseWithApprovalData$, this.clickForTicketCourseWithApprovalData$).pipe(
      map(term => (term === '' ? this.courseWithApprovalDataLookup.filter(x => x.status == DocumentStatus.NotApproved)
        : this.courseWithApprovalDataLookup.filter(v => (v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) && v.status == DocumentStatus.NotApproved) )
      )
    );
  };

  searchDocumentForTicket: OperatorFunction<string, readonly BaseDocumentDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.focusForTicketDocument$, this.clickForTicketDocument$).pipe(
      map(term => (term === '' ? this.documentLookup
        : this.documentLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchCourseForTicket: OperatorFunction<string, readonly BaseCourseDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTicketCourse$, this.clickForTicketCourse$).pipe(
      map(term => (term === '' ? this.courseLookup
        : this.courseLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchTenantForTicket: OperatorFunction<string, readonly TenantUserLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  
    return merge(debouncedText$, this.focusForTicketTenant$, this.clickForTicketTenant$).pipe(
      map(term => (term === '' ? this.tenantUsersLookup
        : this.tenantUsersLookup.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  selectedDocumentItem() {
    this.list.get();
  }

  selectedCourseItem() {
    this.list.get();
  }

  getSelectedTaskKind(kind) {
    this.createUpdateTicketForm.controls["document"].setValue(null);
    this.createUpdateTicketForm.controls["course"].setValue(null);
    this.createUpdateTicketForm.controls["ticketEducationKind"].setValue(null);

    if (kind == TicketKind.Education) {
      this.isTicketEducationKindShown = true;
      this.isDocumentShown = null;
      this.isDocumentShownForApproval = null;
      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);

      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators([Validators.required]);
    } 
    else if (kind == TicketKind.Renewal) { 
      this.isTicketEducationKindShown = false;      
      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);

      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators(null);
    }
    else if (kind == TicketKind.DocumentApproval) {    
      if (this.responsibleUserForApproval == null) {
        this.createUpdateTicketForm.controls["ticketKind"].setValue(null);

        this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
        this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
        this.createUpdateTicketForm.controls["ticketKind"].updateValueAndValidity();

        this.toaster.warn(
          this.localizationService.instant("::TicketForApproval:Error:00001")
        );

        return;
      }

      this.isTicketEducationKindShown = true;
      this.isDocumentShown = null;
      this.isDocumentShownForApproval = null;

      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);
      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators([Validators.required]);
      this.createUpdateTicketForm.controls["taskStatus"].setValue(TicketStatus.New);
      this.createUpdateTicketForm.controls["taskStatus"].updateValueAndValidity();
    }
    else {
      this.isTicketEducationKindShown = false;
      this.isDocumentShown = null;
      this.isDocumentShownForApproval = null;

      this.createUpdateTicketForm.controls["document"].setValidators(null);
      this.createUpdateTicketForm.controls["course"].setValidators(null);
      this.createUpdateTicketForm.controls["ticketEducationKind"].setValidators(null);
    }

    this.isDocumentForApprovalSelectShown();

    this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
    this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
    this.createUpdateTicketForm.controls["ticketEducationKind"].updateValueAndValidity();
  }

  getSelectedTaskEducationKind(kind) {
    var ticketKind = this.createUpdateTicketForm.get('ticketKind').value;

    if (ticketKind == TicketKind.Education) {

      if (kind == TicketEducationKind.Course) {
        this.isDocumentShown = false;
        this.createUpdateTicketForm.controls["document"].setValue(null);
        this.createUpdateTicketForm.controls["document"].setValidators(null);
        this.createUpdateTicketForm.controls["course"].setValidators(Validators.required);
      }

      if (kind == TicketEducationKind.Document) {
        this.isDocumentShown = true;      
        this.createUpdateTicketForm.controls["course"].setValue(null);
        this.createUpdateTicketForm.controls["course"].setValidators(null);
        this.createUpdateTicketForm.controls["document"].setValidators(Validators.required);
      }
  
      if (kind === "") {
        this.isDocumentShown = null;
      }   
    }
    
    if (ticketKind == TicketKind.DocumentApproval) {
      if (kind == TicketEducationKind.Course) {
        this.isDocumentShownForApproval = false;
        this.createUpdateTicketForm.controls["course"].setValidators([Validators.required]);
        this.createUpdateTicketForm.controls["document"].setValue(null);
        this.createUpdateTicketForm.controls["document"].setValidators(null);
      }

      if (kind == TicketEducationKind.Document) {
        this.isDocumentShownForApproval = true;      
        this.createUpdateTicketForm.controls["document"].setValidators([Validators.required]);
        this.createUpdateTicketForm.controls["course"].setValue(null);
        this.createUpdateTicketForm.controls["course"].setValidators(null);
      }
  
      if (kind === "") {
        this.isDocumentShownForApproval = null;
      }   
    }

    this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
    this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
  }

  getListWhenDocumentInputEmpty(event: any){
    if(event == ''){
      this.filterForm.controls["document"].setValue(null);
      this.list.get();
    }
  }

  getListWhenCourseInputEmpty(event: any){
    if(event == ''){
      this.filterForm.controls["course"].setValue(null);
      this.list.get();
    }
  }

  isDocumentForApprovalSelectShown() {
    var value = this.createUpdateTicketForm.controls["ticketKind"].value;

    if (value == TicketKind.DocumentApproval) {
      this.createUpdateTicketForm.controls["taskStatus"].setValue(this.selectedTicket?.status != null ? this.selectedTicket.status : TicketStatus.New);
      this.createUpdateTicketForm.controls["assignedUserId"].setValue(this.responsibleUserForApproval?.selectedUser.id);

      this.selectedUsers.items = [{userName: this.responsibleUserForApproval?.selectedUser?.userName, id: this.responsibleUserForApproval?.selectedUser?.id}]
      this.selectedUsers.totalCount = 1;
      
      this.isTicketDocumentApprovalSelected = true;
      return true;
    }

    this.isTicketDocumentApprovalSelected = false;

    return false;
  }

  disableTicketStatusField(): boolean {
    if(!this.isTicketDocumentApprovalSelected){
      return false;
    }

    if(this.currentUserId == this.responsibleUserForApproval?.selectedUser.id){
      if( this.isTicketEdit && this.isTicketDocumentApprovalSelected ) 
      {
        return false;
      }
    }

    return true;
  }

  isDocumentSelectShown(){
    var value = this.createUpdateTicketForm.controls["ticketKind"].value;

    if(value == TicketKind.DocumentApproval || value == null){
      return false;
    }

    if(this.isTicketEducationKindShown == false) {
      return true;
    } else{
        if(this.isDocumentShown == true)
          return true;
        else 
          return false; 
    }
  }

  isCourseSelectShown() {
    var value = this.createUpdateTicketForm.controls["ticketKind"].value;

    if(value == TicketKind.DocumentApproval || value == null)
      return false;

    if(this.isTicketEducationKindShown == false) {
      return true;
    } else {
        if(this.isDocumentShown == false)
          return true;
        else 
          return false;
    }
  }

  isCourseApproval() {
    if(this.createUpdateTicketForm.controls["course"].hasValidator(Validators.required) && this.isDocumentShownForApproval == false)
      return true;
    return false;
  }

  isDocumentApproval() {
    if(this.createUpdateTicketForm.controls["document"].hasValidator(Validators.required)  && this.isDocumentShownForApproval == true)
      return true;
    return false;
  }

  courseHasRequiredValidator(){
    if(this.createUpdateTicketForm.controls["course"].hasValidator(Validators.required))
      return true;
    return false;
  }

  documentHasRequiredValidator(){
    if(this.createUpdateTicketForm.controls["document"].hasValidator(Validators.required))
      return true;
    return false;
  }

  buildFormForSearch() {
    this.filterForm = this.formBuilder.group({
      startDate: [null],
      startTime: [{ hour: 0, minute: 0}],
      endDate: [null],
      endTime: [{ hour: 0, minute: 0}],
      assignedUserId: [null],
      taskStatus: [null],
      taskKind: [null],
      taskEducationKind: [null],
      document: [null],
      course: [null],
      organizationUnitId: [null]
    });
  }

  isCurrentUserResponsible(): boolean {
    if(this.responsibleUserForApproval?.selectedUser.id == this.currentUserId) {
      return true;
    }

    return false;
  }

  buildFormForCreate() {
    this.selectedItemsForCreate = [];

    this.createUpdateTicketForm = this.formBuilder.group({
      name: [this.selectedTicket.name || this.localizationService.instant("::Ticket:Default:Name"), Validators.required],
      description: [this.selectedTicket.description || ''],
      assignedUserId: [this.selectedTicket.assignedUserId || null, Validators.required],
      taskStatus: [this.selectedTicket.status != null ? this.selectedTicket.status : TicketStatus.New, Validators.required],
      ticketKind: [this.selectedTicket.ticketKind != null ? this.selectedTicket.ticketKind : null, [Validators.required]],
      ticketEducationKind: [this.selectedTicket.ticketEducationKind != null ? this.selectedTicket.ticketEducationKind : null],
      course: [this.selectedTicket.course || null],
      document: [this.selectedTicket.document || null],
      startDate: [this.selectedTicket.startDateTime 
        ? new Date(this.selectedTicket.startDateTime) 
        : this.today, Validators.required
      ],
      startTime: [
        this.selectedTicket.startDateTime ? 
        { 
          hour: new Date(this.selectedTicket.startDateTime).getHours(),
          minute: new Date(this.selectedTicket.startDateTime).getMinutes()
        } :
        {
          hour: 12, 
          minute: 0
        }
      ],
      endDate: [this.selectedTicket.endDateTime 
        ? new Date(this.selectedTicket.endDateTime)
        : this.weekFromToday, Validators.required
      ],
      endTime: [
        this.selectedTicket.endDateTime ?
        { 
          hour: new Date(this.selectedTicket.endDateTime).getHours(), 
          minute: new Date(this.selectedTicket.endDateTime).getMinutes()
        } :
        {
          hour: 12,
          minute: 0
        }
      ],
      organizationUnitId: [null, []]
    });

    this.createUpdateTicketForm.get('ticketKind').valueChanges.subscribe((kind: TicketKind) => {
      this.getSelectedTaskKind(kind);
    });
  }

  getListWithFilters() {
    this.list.get()
  }

  selectUsersFromSelectedOrganizationUnit() {
    var data = this.selectedItemsForCreate.length > 0 ? this.selectedItemsForCreate.map(e => e.id) : null;

    if (!data) {
      this.toaster.warn(
        this.localizationService.instant("::Organization:Unit:Selection:IsEmpty")
      );

      return;
    }

    this.enovaOrganizationUnitService.getUsers(data).subscribe((result) => {
      this.addUsersToList(result.items);
    });
    
  }

  transformDate(date: any, time: any) {
    if(date)
      date.setHours(time.hour, time.minute, 0)
    
    return this.datePipe.transform(date, "yyyy-MM-dd HH:mm");
  }
  
  clearFilters() {
    this.filterForm.reset();
    this.selectedItems = [];
    this.list.get();
  }

  createTicket() {
    this.selectedTicket = {} as TicketDto;
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.isTicketEdit = false;
    this.isTicketEducationKindShown = false;
    this.selectFromUsers = false;
    this.selectFromOrganizationUnit = false;
    this.weekFromToday.setDate(this.today.getDate() + 7);
    this.buildFormForCreate();
    this.isDocumentForApprovalSelectShown();
    this.isModalOpen = true;
  }

  saveTicket() {
    if(this.createUpdateTicketForm.invalid){
      return;
    }

    if(this.selectedTicket.id){
      this.taskService.update(this.selectedTicket.id, this.formTicketForUpdateData()).subscribe({
        next: () => {
          this.isModalOpen = false;
          this.createUpdateTicketForm.reset();
          this.list.get();
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Ticket:Message:Edited:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:Edited:Error")
          );
        }
      })
    } else {
      this.taskService.create(this.formTicketData()).subscribe({
        next: () => {
          this.isModalOpen = false;
          this.list.get();
          this.createUpdateTicketForm.reset();
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Ticket:Message:Created:Success")
          )
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:Created:Error")
          )
        }
      })
    }
  }

  editTicket(ticketId: string) {
    this.isDocumentShown = null;
    this.isDocumentShownForApproval = null;
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.isTicketEdit = true;
    this.taskService.get(ticketId).subscribe((ticket) => {
      this.selectedTicket = ticket;

      this.isTicketEducationKindShown = ticket.ticketKind == TicketKind.Education ? true : false;

      if(this.isTicketEducationKindShown){
        this.isDocumentShown = ticket.document != null ? true : (ticket.course != null ? false : null);
      }

      if(ticket.ticketKind == TicketKind.DocumentApproval) {
        if(ticket.document != null) {
          this.isDocumentShownForApproval = true;
          this.selectedTicket.ticketEducationKind = TicketEducationKind.Document;
        }

        if(ticket.course != null) {
          this.isDocumentShownForApproval = false;
          this.selectedTicket.ticketEducationKind = TicketEducationKind.Course;
        }

        this.selectedUsers.items = [{userName: this.selectedTicket.assignedUserName, id: this.selectedTicket.assignedUserId}]
        this.selectedUsers.totalCount = 1;
        
        this.isTicketDocumentApprovalSelected = true;
        this.isTicketEducationKindShown = true;
      }

      this.buildFormForCreate();
      
      if(ticket.ticketEducationKind != null) {
        this.getSelectedTaskEducationKind(ticket.ticketEducationKind);
      }

      if(this.isDocumentShown == true){
        this.createUpdateTicketForm.controls["document"].addValidators(Validators.required);
        this.createUpdateTicketForm.controls["document"].updateValueAndValidity();
      }
      if(this.isDocumentShown == false){
        this.createUpdateTicketForm.controls["course"].addValidators(Validators.required);
        this.createUpdateTicketForm.controls["course"].updateValueAndValidity();
      }
      this.isModalOpen = true;
    })
  }

  isTicketForEdit(): boolean {
    if(this.selectedTicket.id != null){
      return true
    }

    return false;
  }

  deleteTicket(ticketId: string) {
    this.confirmation.warn("::AreYouSureToDeleteTicket", "::AreYouSure").subscribe((status) => {
      if(status == Confirmation.Status.confirm){
        this.taskService.delete(ticketId).subscribe({
          next: () => this.list.get(),
          complete: () => {
            this.toaster.success(
              this.localizationService.instant("::Ticket:Message:Deleted:Success")
            );
          },
          error: () => {
            this.toaster.error(
              this.localizationService.instant("::Ticket:Message:Deleted:Error")
            )
          }
        });
      }
    });
  }

  selectedNewTaskUsers(selectedUser: any) {
    if (selectedUser) {

      if(this.selectedUsers.totalCount > 0) {
        var isValuePresent = this.selectedUsers.items.find(x => x.userName == selectedUser.userName);
  
        if(isValuePresent) {
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:User:Error:00001")
          );
    
          return;
        }
      }

      this.selectedUsers.items.push({userName: selectedUser.userName, id: selectedUser.id});

      this.selectedUsers.items = [...this.selectedUsers.items]
      this.selectedUsers.totalCount++;

      this.checkIfSelectedUsers();
    }
  }

  addUsersToList(selectedUsers: TenantUserLookupDto[]) {
    if (selectedUsers) {
      if (this.selectedUsers.totalCount > 0) {
        selectedUsers.forEach(element => {
          var isValuePresent = this.selectedUsers.items.find(x => x.userName == element.userName);

          if (!isValuePresent) {
            this.selectedUsers.items.push({userName: element.userName, id: element.id});

            this.selectedUsers.items = [...this.selectedUsers.items]
            this.selectedUsers.totalCount++;
          }
        });
      } else {
        this.selectedUsers.items = selectedUsers;
        this.selectedUsers.totalCount = selectedUsers.length;
  
        this.selectedUsers.items = [...this.selectedUsers.items];
      }

      this.checkIfSelectedUsers();
    }
  }

  removeAssignedUser(eventValue: any) {

    this.selectedUsers.items.forEach((element, index) => {
      if(element.id == eventValue.id) {
        this.selectedUsers.items.splice(index, 1);
        this.selectedUsers.totalCount--;
        this.selectedUsers.items = [...this.selectedUsers.items];

        this.checkIfSelectedUsers();
      }
    });

  }

  checkIfSelectedUsers() {
    if(this.selectedUsers.totalCount == 0) {
      this.createUpdateTicketForm.controls["assignedUserId"].setValue(null);
      this.createUpdateTicketForm.controls["assignedUserId"].addValidators(Validators.required);
      this.createUpdateTicketForm.controls["assignedUserId"].updateValueAndValidity();
    } else {
      this.createUpdateTicketForm.controls["assignedUserId"].clearValidators();
      this.createUpdateTicketForm.controls["assignedUserId"].updateValueAndValidity();
    }
  }

  selectAllTenantUsers() { 
    this.taskService.getTenantUserLookup().subscribe((response) => {
      this.selectedUsers.items = response.items;
      this.selectedUsers.totalCount = response.items.length;

      this.checkIfSelectedUsers();
    })
  }

  formTicketData() {
    return {
      name: this.createUpdateTicketForm.get('name').value,
      description: this.createUpdateTicketForm.get('description').value,
      status: this.createUpdateTicketForm.get('taskStatus').value,
      ticketKind: this.createUpdateTicketForm.get('ticketKind').value,
      ticketEducationKind: this.createUpdateTicketForm.get('ticketEducationKind').value,
      assignedUserIdsList: this.selectedUsers.items.map(user => user.id),
      tenantCourseId: this.createUpdateTicketForm.get("course").value 
        ? this.createUpdateTicketForm.get("course").value.id : null,
      tenantDocumentId: this.createUpdateTicketForm.get("document").value 
        ? this.createUpdateTicketForm.get("document").value.id : null,
      startDateTime: this.datePipe.transform(this.getStartTime(), "yyyy-MM-ddTHH:mm:ss"),
      endDateTime: this.datePipe.transform(this.getEndTime(), "yyyy-MM-ddTHH:mm:ss")
    }
  }

  formTicketForUpdateData() {
    return {
      name: this.createUpdateTicketForm.get('name').value,
      description: this.createUpdateTicketForm.get('description').value,
      status: this.createUpdateTicketForm.get('taskStatus').value,
      ticketKind: this.createUpdateTicketForm.get('ticketKind').value,
      ticketEducationKind: this.createUpdateTicketForm.get('ticketEducationKind').value,
      assignedUserId: this.createUpdateTicketForm.get('assignedUserId').value,
      tenantCourseId: this.createUpdateTicketForm.get("course").value 
        ? this.createUpdateTicketForm.get("course").value.id : null,
      tenantDocumentId: this.createUpdateTicketForm.get("document").value 
        ? this.createUpdateTicketForm.get("document").value.id : null,
      startDateTime: this.datePipe.transform(this.getStartTime(), "yyyy-MM-ddTHH:mm:ss"),
      endDateTime: this.datePipe.transform(this.getEndTime(), "yyyy-MM-ddTHH:mm:ss")
    }
  }

  getStartTime() {
    let startDateTime = this.createUpdateTicketForm.get('startDate').value;
    startDateTime.setHours(this.createUpdateTicketForm.get('startTime').value.hour);
    startDateTime.setMinutes(this.createUpdateTicketForm.get('startTime').value.minute);
    return startDateTime
  }

  getEndTime() {
    let endDateTime = this.createUpdateTicketForm.get('endDate').value;
    endDateTime.setHours(this.createUpdateTicketForm.get('endTime').value.hour);
    endDateTime.setMinutes(this.createUpdateTicketForm.get('endTime').value.minute);
    return endDateTime
  }
}
