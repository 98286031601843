<ng-container>
    <div class="row">
        <h2>{{ '::DocumentApprovalResponsibleUser' | abpLocalization }}</h2>
        <hr>
        <form 
            *ngIf="!isLoading"
            [formGroup]="form"
            (ngSubmit)="setUserValueToSettings()"
        >
            <div class="row m-1">
                <div class="mb-3">
                    <label class="form-label" for="responsible-users">
                        {{ '::ResponsibleUser' | abpLocalization }}
                    </label>
                    <input
                        class="form-control" 
                        id="responsible-users"
                        type="search"
                        formControlName="selectedUser"
                        [editable]="false"
                        [resultFormatter]="tenantFormatter"
                        [inputFormatter]="tenantFormatter"
                        [ngbTypeahead]="searchForTenant"
                        (focus)="focusForTenant$.next($any($event).target.value)"
                        (click)="clickForTenant$.next($any($event).target.value)"
                        #instance="ngbTypeahead"
                    />
                </div>        
                <abp-button
                    (click)="setUserValueToSettings()"
                >
                    <i class="fa fa-save"></i>
                    {{ '::Save' | abpLocalization }}
                </abp-button>
            </div>
            
        </form>
    
    </div>

</ng-container>