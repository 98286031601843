import { BaseCourseDto } from './../proxy/courses/models';
import { BaseDocumentDto } from './../proxy/documents/models';
import { tenantActionTypeOptions } from './../proxy/tenant-actions/tenant-action-type.enum';
import { tenantActionEntityOptions } from './../proxy/tenant-actions/tenant-action-entity.enum';
import { TenantActionDto } from './../proxy/tenant-actions/models';
import { ListService, PagedResultDto } from "@abp/ng.core";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EnovaUserLookupService } from '@proxy/enova-user-lookup';
import { TenantUserLookupDto } from '@proxy/enova-users-lookup';
import { Constants } from '../shared/Constants/Constants';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TenantActionsService } from '@proxy/tenant-actions';
import { TenantDocumentLookupService } from '@proxy/tenant-documents';
import { TenantCourseLookupService } from '@proxy/tenant-courses';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from '@proxy/employees';
import { EnovaOrganizationUnitDto } from '@proxy/organization-units';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-tenant-actions',
    templateUrl: './tenant-actions.component.html',
    styleUrls: ['./tenant-actions.component.scss'],
    providers: [ListService, {
        provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    }]
})
export class TenantActionsComponent implements OnInit {
    tenantActions = { items: [], totalCount: 0} as PagedResultDto<TenantActionDto>;
    organizationUnitPositions$: Observable<EnovaOrganizationUnitDto[]>
    filterForm: UntypedFormGroup;
    actionEntityOptions = tenantActionEntityOptions;
    actionTypeOptions = tenantActionTypeOptions;
    focusForDocumentFilter$ = new Subject<string>();
    clickForDocumentFilter$ = new Subject<string>();
    focusForCourseFilter$ = new Subject<string>();
    clickForCourseFilter$ = new Subject<string>();

    tenantUsers: TenantUserLookupDto[] = [];

    documentLookup: BaseDocumentDto[] = [];
    courseLookup: BaseCourseDto[] = [];

    documentId: string;
    courseId: string;

    DateTimeFormatConst = Constants.DATETIME;

    documentFormatter = (document: BaseDocumentDto) => document.name;
    courseFormatter = (course: BaseCourseDto) => course.name;

    constructor(
        public readonly list: ListService, 
        private fb: UntypedFormBuilder,
        private tenantLookUpService: EnovaUserLookupService,
        private tenantActionsService: TenantActionsService,
        private tenantDocumentLookupService: TenantDocumentLookupService,
        private tenantCourseLookupService: TenantCourseLookupService,
        private employeesService: EmployeesService,
        private datePipe: DatePipe,
        private router: Router,
    ) { 
        this.organizationUnitPositions$ = this.employeesService.getPositionLookup().pipe(map(r => r.items));
    }

    ngOnInit(): void {

        const courseStreamCreator = (query) => this.tenantActionsService.getList(
            {
                ...query,
                type: this.filterForm.get('type').value,
                entity: this.filterForm.get('entity').value,
                userName: this.filterForm.get('userName').value,
                documentId: this.documentId ? this.documentId : null,
                courseId: this.courseId ? this.courseId : null,
                position: this.filterForm.get('position').value,
                nameAndSurname: this.filterForm.get('nameAndSurname').value,
                dateFrom: this.filterForm.get('dateFrom').value != null ? this.datePipe.transform(this.filterForm.get("dateFrom").value, "yyyy-MM-dd") : null,
                dateTo: this.filterForm.get('dateTo').value != null ? this.datePipe.transform(this.filterForm.get("dateTo").value, "yyyy-MM-dd") : null
            }
        );

        this.list.hookToQuery(courseStreamCreator).subscribe((response) => {
            this.tenantActions.items = response.items;
            this.tenantActions.totalCount = response.totalCount;
        });

        this.tenantLookUpService.getTenantUserLookup().subscribe((response) => {
            this.tenantUsers = response.items;
        })

        this.tenantDocumentLookupService.getTenantDocumentLookup().subscribe((response) => {
            this.documentLookup = response.items;
        })

        this.tenantCourseLookupService.getTenantCourseLookup().subscribe((response) => {
            this.courseLookup = response.items;
        })

        this.buildFormForSearch();
    }

    searchActionByDocument: OperatorFunction<string, readonly BaseDocumentDto[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    
        return merge(debouncedText$, this.focusForDocumentFilter$, this.clickForDocumentFilter$).pipe(
            map(term => (term === '' ? this.documentLookup
                : this.documentLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
            )
        );
    };

    searchActionByCourse: OperatorFunction<string, readonly BaseCourseDto[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    
        return merge(debouncedText$, this.focusForCourseFilter$, this.clickForCourseFilter$).pipe(
            map(term => (term === '' ? this.courseLookup
                : this.courseLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
            )
        );
    };

    buildFormForSearch(){
        this.filterForm = this.fb.group({
            type: [null],
            entity: [null],
            userName: [null],
            documentName: [null],
            courseName: [null],
            nameAndSurname: [null],
            position: [null],
            dateFrom: [null],
            dateTo: [null]
        })
    }

    selectedDocumentItem(item: any){
        this.documentId = item.item.id;
        this.list.get();
    }

    selectedCourseItem(item: any){
        this.courseId = item.item.id;
        this.list.get();
    }

    clearFilters(){
        this.buildFormForSearch();
        this.documentId = null;
        this.courseId = null;
        this.list.get();
    }

    getListWithFilters() {
        this.list.get();
    }

    getListWhenDocumentInputEmpty(event: any){
        if(event == ''){
            this.documentId = null;
            this.list.get();
        }
    }

    getListWhenCourseInputEmpty(event: any){
        if(event == ''){
            this.courseId = null;
            this.list.get();
        }
    }

    printActions(){
        this.router.navigate(["users-actions/report"], {queryParams: {
            type: this.filterForm.get('type').value,
            entity: this.filterForm.get('entity').value,
            userName: this.filterForm.get('userName').value,
            documentId: this.documentId ? this.documentId : null,
            courseId: this.courseId ? this.courseId : null,
            position: this.filterForm.get('position').value,
            nameAndSurname: this.filterForm.get('nameAndSurname').value,
            dateFrom: this.filterForm.get('dateFrom').value != null ? this.datePipe.transform(this.filterForm.get("dateFrom").value, "yyyy-MM-dd") : null,
            dateTo: this.filterForm.get('dateTo').value != null ? this.datePipe.transform(this.filterForm.get("dateTo").value, "yyyy-MM-dd") : null
        }});
    }

}