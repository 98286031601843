import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUESTIONNAIRE_QUESTION_ANSWERS_QUESTIONNAIRE_QUESTION_ANSWER_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/questionnaire-question-answers',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:QuestionnaireQuestionAnswers',
        layout: eLayoutType.application,
        order: 14,
        requiredPolicy: 'Enova.QuestionnaireQuestionAnswers',
      },
    ]);
  };
}
