import { Component, Input, OnInit } from '@angular/core';
import { ClassDto } from '@proxy/classes';

@Component({
  selector: 'app-courses-class-text',
  templateUrl: './courses-class-text.component.html',
  styleUrls: ['./courses-class-text.component.scss']
})
export class CoursesClassTextComponent implements OnInit {
  @Input() class: ClassDto;
  constructor() { }

  ngOnInit(): void {
  }

}
