import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { IncidentRoutingModule } from './incident-routing.module';
import { IncidentComponent } from './incident.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { IncidentAdminTabComponent } from './incident-admin-tab/incident-admin-tab.component';
import { INCIDENTS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { INCIDENTS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.provider';

@NgModule({
  declarations: [
    IncidentComponent,
    IncidentAdminTabComponent
  ],
  imports: [
    SharedModule,
    IncidentRoutingModule,
    NgbDatepickerModule,
    NgbTimepickerModule
  ]
})
export class IncidentModule { 
  static forRoot(): ModuleWithProviders<IncidentModule> {
    return {
      ngModule: IncidentModule,
      providers: [
        INCIDENTS_MANAGEMENT_FEATURES_PROVIDERS,
        INCIDENTS_MANAGEMENT_VISIBLE_PROVIDERS,
      ],
    }
  }
}
