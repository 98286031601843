import { UserMenu, UserMenuService } from "@abp/ng.theme.shared";
import { Observable, map } from "rxjs";

function createVisibleFn(){
    return () => {
        return true;
    }
}

export class MyUserMenuService extends UserMenuService{
    get items$(): Observable<UserMenu[]>{
        return super.items$.pipe(
            map((x: any) => {
                return x.map(y => {
                    if(y.visible){
                        return y;
                    }
                    return {
                        ...y,
                        visible: createVisibleFn(),
                    };
                });
            })
        );
    }
}