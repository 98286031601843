import type { DocumentDto, GetDocumentListDto, UploadDocumentsDto } from './models';
import type { TenantHostDocumentDto } from './tenant-host-document/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  apiName = 'Default';
  

  checkForTenantTagsByDocumentId = (documentId: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/documents/check-for-tenant-tags/${documentId}`,
    },
    { apiName: this.apiName });
  

  checkIfDocumentFilePDFOrHTMLByDocumentId = (documentId: string) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/documents/check-if-document-file-pDFOr-hTML/${documentId}`,
    },
    { apiName: this.apiName });
  

  copy = (tenantId: string, documentId: string) =>
    this.restService.request<any, DocumentDto>({
      method: 'POST',
      url: '/api/app/documents/copy',
      params: { tenantId, documentId },
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/documents/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, DocumentDto>({
      method: 'GET',
      url: `/api/app/documents/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetDocumentListDto) =>
    this.restService.request<any, PagedResultDto<TenantHostDocumentDto>>({
      method: 'GET',
      url: '/api/app/documents',
      params: { filter: input.filter, categoryId: input.categoryId, testId: input.testId, creationDateFrom: input.creationDateFrom, creationDateTo: input.creationDateTo, creatorId: input.creatorId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  uploadDocumentsForTenant = (input: UploadDocumentsDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/documents/upload-documents-for-tenant',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
