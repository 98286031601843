import type { FullIncidentDataDto, GetIncidentListByDatesDto, GetIncidentListDto, IncidentDto, IncidentUpdateDto, TenantAdminUserDto, TenantDashboardIncidentDto } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantIncidentsService {
  apiName = 'Default';
  

  changeStatusByIncidentIdAndInput = (incidentId: string, input: IncidentUpdateDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/tenant-incidents/change-status/${incidentId}`,
      body: input,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/tenant-incidents/${id}`,
    },
    { apiName: this.apiName });
  

  getFilteredList = (input: GetIncidentListByDatesDto) =>
    this.restService.request<any, TenantDashboardIncidentDto>({
      method: 'GET',
      url: '/api/app/tenant-incidents/filtered-list',
      params: { fromDate: input.fromDate, toDate: input.toDate },
    },
    { apiName: this.apiName });
  

  getIncident = (incidentId: string) =>
    this.restService.request<any, FullIncidentDataDto>({
      method: 'GET',
      url: `/api/app/tenant-incidents/incident/${incidentId}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetIncidentListDto) =>
    this.restService.request<any, PagedResultDto<IncidentDto>>({
      method: 'GET',
      url: '/api/app/tenant-incidents',
      params: { filter: input.filter, type: input.type, status: input.status, fromDate: input.fromDate, toDate: input.toDate, creationDateFrom: input.creationDateFrom, creationDateTo: input.creationDateTo, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getTenantAdminUser = () =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/tenant-incidents/tenant-admin-user',
    },
    { apiName: this.apiName });
  

  getTenantAdminUsersList = () =>
    this.restService.request<any, ListResultDto<TenantAdminUserDto>>({
      method: 'GET',
      url: '/api/app/tenant-incidents/tenant-admin-users-list',
    },
    { apiName: this.apiName });
  

  hardDeleteIncidentFile = (id: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/tenant-incidents/${id}/hard-delete-incident-file`,
    },
    { apiName: this.apiName });
  

  sendEmailToTenantAdmin = (incident: IncidentDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/tenant-incidents/send-email-to-tenant-admin',
      body: incident,
    },
    { apiName: this.apiName });
  

  setTenantAdminUser = (email: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/tenant-incidents/set-tenant-admin-user',
      params: { email },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
