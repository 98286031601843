<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::EditTest' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/"><i class="fa fa-home"></i> </a>
            </li>
            <li class="breadcrumb-item">
                <a routerLink="/tests">{{ '::Menu:Tests' | abpLocalization }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ '::EditTest' | abpLocalization }}
            </li>
        </ol>
    </div>

    <div class="col">
        <div id="AbpContentToolbar" class="row justify-content-end mx-0">
            <div class="col-auto"></div>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-body">
        <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="main-tab0"
                    type="button"
                    role="tab"
                    (click)="setActiveTab(0)"
                    [class.active]="isActiveTab === 0 ? true : false"
                >
                    {{ '::MainInformation' | abpLocalization }}
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="main-tab1"
                    type="button"
                    role="tab"
                    (click)="setActiveTab(1)"
                    [class.active]="isActiveTab === 1 ? true : false"
                >
                    {{ '::Questions' | abpLocalization }}
                </button>
            </li>
        </ul>
    
        <div class="tab-content" id="myTabContent0" [style]="isActiveTab === 0 ? '' : 'display: none;'">
            <div
                class="tab-pane fade"
                id="0"
                role="tabpanel"
                aria-labelledby="main-tab0"
                [class.active]="isActiveTab === 0 ? true : false"
                [class.show]="isActiveTab === 0 ? true : false"
            >
            <div class="row">
                <div class="col-xs-12 col-md-6">
                <form [formGroup]="form">
                    <div class="form-group mb-3">
                        <label class="form-label" for="test-name">{{ '::Name' | abpLocalization }}</label>
                        <span> * </span>
                        <input
                            type="text"
                            id="test-name"
                            class="form-control"
                            formControlName="Name"
                            autofocus
                        />
                    </div>
                    
                    <div class="form-group mb-3">
                        <label class="form-label" for="test-IdentificationNumber">
                            {{ '::IdentificationNumber' | abpLocalization }}
                        </label><span> * </span>
                        <input 
                            type="text" 
                            id="test-IdentificationNumber" 
                            class="form-control" 
                            formControlName="identificationNumber" 
                            autofocus />
                    </div>

                    <div class="form-group mb-3">
                        <label class="form-label" for="test-Desc">
                            {{'::input:Label:Description' | abpLocalization}}
                        </label>
                        <textarea
                            id="test-Desc"
                            class="form-control"
                            rows="5"
                            formControlName="Description"
                        ></textarea>
                    </div>
                </form>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-6">
                <button type="button" class="btn btn-secondary mx-2" (click)="navigateToTests()">
                    {{ '::Cancel' | abpLocalization }}
                </button>
                <button class="btn btn-primary" (click)="updateTest()" [disabled]="form.invalid">
                    <i class="fa fa-check mr-1"></i>
                    {{ '::Save' | abpLocalization }}
                </button>
                </div>
            </div>
            </div>
        </div>
    
        <div class="tab-content" id="myTabContent1" [style]="isActiveTab === 1 ? '' : 'display: none;'">
            <div
                class="tab-pane fade"
                id="1"
                role="tabpanel"
                aria-labelledby="main-tab1"
                [class.active]="isActiveTab === 1 ? true : false"
                [class.show]="isActiveTab === 1 ? true : false"
            >
                <div class="row">
                    <div class="col-8 col-sm-auto">
                        <h3 class="content-header-title">{{ '::QuestionList' | abpLocalization }}</h3>
                    </div>
        
                    <div class="col">
                        <div class="row justify-content-end mx-0">
                            <div class="col-auto">
                                <button
                                    (click)="createQuestion()"
                                    type="button"
                                    ng-reflect-ng-class="btn btn-sm btn-primary"
                                    class="btn btn-sm btn-primary ng-star-inserted"
                                >
                                <i class="me-1 fa fa-plus" ng-reflect-ng-class="fa fa-plus"></i>
                                <span> {{ '::NewQuestionButton' | abpLocalization }} </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <ngx-datatable [rows]="questions.items" [count]="questions.totalCount" [list]="list" default>
                    <ngx-datatable-column
                        [name]="'::Actions' | abpLocalization"
                        [maxWidth]="150"
                        [sortable]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div ngbDropdown container="body" class="d-inline-block">
                                <button
                                    class="btn btn-primary btn-sm dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    ngbDropdownToggle
                                >
                                    <i class="fa fa-cog mr-1"></i>{{ '::Actions' | abpLocalization }}
                                </button>
                                <div ngbDropdownMenu>
                                    <button ngbDropdownItem (click)="selectQuestionToEdit(row)">
                                        {{ '::Edit' | abpLocalization }}
                                    </button>
                
                                    <button ngbDropdownItem (click)="removeQuestion(row)">
                                        {{ '::Delete' | abpLocalization }}
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        [name]="'::QuestionText' | abpLocalization"
                        prop="questionText"
                    ></ngx-datatable-column>
                </ngx-datatable>

            </div>
        </div>
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ (isQuestionToEdit ? '::EditQuestion' : '::CreateNewQuestion') | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="questionForm">

            <ul class="nav nav-tabs mb-3" id="myTab1" role="tablist">
                <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="main-tab2"
                    type="button"
                    role="tab"

                    [class.active]="true"
                >
                    {{ '::AnswerText' | abpLocalization }}
                </button>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent2" [style]="true ? '' : 'display: none;'">
                <div
                    class="tab-pane fade"
                    id="0"
                    role="tabpanel"
                    aria-labelledby="main-tab0"
                    [class.active]="true"
                    [class.show]="true"
                >
                    <div class="row">
                        <div>
                            <div class="form-group mb-3">
                                <label class="form-label" for="question-text">{{ '::QuestionText' | abpLocalization }}</label>
                                <span> * </span>
                                <textarea
                                    type="text"
                                    id="question-text"
                                    class="form-control"
                                    formControlName="QuestionText"
                                    rows="2"
                                    autofocus
                                ></textarea>

                                <label class="form-label" for="answer-text">{{ '::Answer' | abpLocalization }}</label>
                                <div class="row">
                                    <div class="col-md-10">
                                        <textarea
                                            type="text"
                                            id="answer-text"
                                            class="form-control"
                                            [(ngModel)]="AnswerText"
                                            [ngModelOptions]="{standalone: true}"
                                            rows="1"
                                        ></textarea>
                                    </div>
                                    <div class="col-md-2">
                                        <button
                                            class="btn btn-success"
                                            aria-haspopup="true"
                                            (click)="addAnswer()"
                                            type="button"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>

                                <ngx-datatable 
                                    [rows]="questionAnswers.items" 
                                    [count]="questionAnswers.totalCount" 
                                    [limit]="10"
                                    [questionList]="questionList" 
                                    default>
                                    <ngx-datatable-column
                                        [name]="'::Actions' | abpLocalization"
                                        [maxWidth]="80"
                                        [sortable]="false"
                                    >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div container="body" class="d-inline-block">
                                            <button
                                                class="btn btn-danger btn-sm"
                                                aria-haspopup="true"
                                                (click)="removeAnswer(row)"
                                                type="button"
                                            >
                                                <i class="fa fa-trash"></i>
                                            </button>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column
                                        [name]="'::Answer' | abpLocalization"
                                        prop="answerText"
                                    ></ngx-datatable-column>
                                    <ngx-datatable-column
                                        [name]="'::IsCorrectAnswer' | abpLocalization"
                                        prop="isCorrect"
                                    >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <input 
                                                (change)="changeCorrectAnswer($event, row.answerText)"
                                                class="form-check-input" 
                                                type="checkbox" 
                                                [checked]="row.isCorrect"
                                                [disabled]="selectedCorrectAnswer && !row.isCorrect ? true : false"
                                            >
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-content" id="myTabContent3" [style]="false ? '' : 'display: none;'">
                <div
                    class="tab-pane fade"
                    id="0"
                    role="tabpanel"
                    aria-labelledby="main-tab0"
                    [class.active]="false"
                    [class.show]="false"
                >
                    <div class="row">
                        <div>
                            <div class="form-group mb-3">
                                <label class="form-label" for="question-text">{{ '::QuestionText' | abpLocalization }}</label
                                ><span> * </span>
                                <input
                                    type="text"
                                    id="question-text"
                                    class="form-control"
                                    formControlName="QuestionText"
                                    autofocus
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </ng-template>
    <ng-template #abpFooter>
        <button 
            type="button" 
            class="btn btn-secondary mx-2" 
            abpClose>
            {{ '::Cancel' | abpLocalization }}
        </button>
        <div *ngIf="!isQuestionToEdit">
            <button
                class="btn btn-primary"
                type="button"
                [disabled]="questionForm.invalid"
                (click)="addQuestion()"
            >
                {{ '::Save' | abpLocalization }}
            </button>
        </div>
        <div *ngIf="isQuestionToEdit">
            <button
                class="btn btn-primary"
                type="button"
                [disabled]="questionForm.invalid"
                (click)="updateSelectedQuestion()"
            >
                {{ '::Edit' | abpLocalization }}
            </button>
        </div>

    </ng-template>

</abp-modal>

