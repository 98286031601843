import { ModuleWithProviders, NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TicketRoutingModule } from './ticket-routing.module';
import { TicketComponent } from './ticket.component';
import { SharedModule } from '../shared/shared.module';
import { NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketsCalendarComponent } from './calendar/tickets-calendar.component';
import { TASKS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { TASKS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.providers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const Routes = [
  {
    path: '',
    component: TicketComponent
  },
  {
    path: 'calendar', 
    component: TicketsCalendarComponent 
  }
];

@NgModule({
  declarations: [
    TicketComponent,
    TicketsCalendarComponent
  ],
  imports: [    
    NgbModule,
    SharedModule,    
    FullCalendarModule,
    TicketRoutingModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    CommercialUiModule,
    FormsModule,
    NgMultiSelectDropDownModule
  ]
})
export class TicketModule {
  static forRoot(): ModuleWithProviders<TicketModule> {
    return {
      ngModule: TicketModule,
      providers: [
          TASKS_MANAGEMENT_FEATURES_PROVIDERS,
          TASKS_MANAGEMENT_VISIBLE_PROVIDERS,
      ],
    }
  }
}
