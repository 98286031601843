import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TestResultsRoutingModule } from './test-results-routing.module';
import { TestResultsComponent } from './test-results.component';
import { SharedModule } from '../shared/shared.module';
import { TEST_RESULTS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { TEST_RESULTS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.providers';


@NgModule({
  declarations: [
    TestResultsComponent
  ],
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    TestResultsRoutingModule
  ]
})
export class TestResultsModule {
  static forRoot(): ModuleWithProviders<TestResultsModule> {
    return {
        ngModule: TestResultsModule,
        providers: [
            TEST_RESULTS_MANAGEMENT_FEATURES_PROVIDERS,
            TEST_RESULTS_MANAGEMENT_VISIBLE_PROVIDERS,
        ],
    }
  }
}
