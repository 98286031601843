import { CourseCreateUpdateComponent } from './couses-create-update/courses-create-update.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursesComponent } from './courses.component';
import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { CoursesViewComponent } from './courses-view/courses-view.component';
import { TestAttemptComponent } from '../test-attempt/test-attempt.component';
import { CanDeactivateGuardService } from '../shared/CanDeactivateGuard/canDeactivate-guard.service';


const routes: Routes = [
  { path: '', component: CoursesComponent, canActivate: [AuthGuard, PermissionGuard]},
  { path: 'create', component: CourseCreateUpdateComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'edit/:id', component: CourseCreateUpdateComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'view/:id', component: CoursesViewComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'tests/attempt/:id', component: TestAttemptComponent, canActivate: [AuthGuard, PermissionGuard], canDeactivate: [CanDeactivateGuardService] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
  ]
})
export class CourseRoutingModule { }
