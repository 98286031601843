<div class="row">
    <h2>{{ '::ProductRenewalExpirationDay' | abpLocalization }}</h2>
    <hr>
    <form
        class="pt-4"
        *ngIf="!isLoading"
        (ngSubmit)="setSettings()"
        [formGroup]="form"
    >

        <div class="form-group mb-3">
            <label class="form-label" for="productRenewalExpirationDays">
            {{ '::ProductRenewalExpirationDay:label' | abpLocalization }}
            </label>
            <input 
                type="number" 
                id="productRenewalExpirationDays" 
                class="form-control" 
                formControlName="days"/>
        </div>

        <abp-button
            (click)="setSettings()"
            [disabled]="form.invalid"
        >
            <i class="fa fa-save"></i>
            {{ '::Save' | abpLocalization }}
        </abp-button>
    </form>
</div>