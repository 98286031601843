<ngx-datatable 
    [rows]="tags.items" 
    [count]="tags.totalCount" 
    [limit]="10"
    #width
    default>
    <ngx-datatable-column
        [name]="'::Tag' | abpLocalization"
        prop="generatorTag"
    ></ngx-datatable-column>
</ngx-datatable>
