import { ListService, PagedResultDto } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseCourseDto } from '@proxy/courses';
import { BaseDocumentDto } from '@proxy/documents';
import { TenantCourseLookupService } from '@proxy/tenant-courses';
import { TenantDocumentLookupService } from '@proxy/tenant-documents';
import { TenantUserDto, TenantUserLookupService } from '@proxy/tenants';
import { TestAttemptService, TestUserAttemtResultDto } from '@proxy/test-attempts';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.scss'],
  providers: [ListService]
})
export class TestResultsComponent implements OnInit {
  testResult = { items: [], totalCount: 0 } as PagedResultDto<TestUserAttemtResultDto>;
  tenantUsers$: Observable<TenantUserDto[]>;
  focusForDocumentFilter$ = new Subject<string>();
  clickForDocumentFilter$ = new Subject<string>();
  focusForCourseFilter$ = new Subject<string>();
  clickForCourseFilter$ = new Subject<string>();

  documentLookup: BaseDocumentDto[] = [];
  courseLookup: BaseCourseDto[] = [];

  documentId: string;
  courseId: string;

  filterForm: UntypedFormGroup;

  documentFormatter = (document: BaseDocumentDto) => document.name;
  courseFormatter = (course: BaseCourseDto) => course.name;

  constructor(
    public readonly list: ListService,
    private testAttemptService: TestAttemptService,
    private tenantUserLookupService: TenantUserLookupService,
    private tenantDocumentLookupService: TenantDocumentLookupService,
    private tenantCourseLookupService: TenantCourseLookupService,
    private fb: UntypedFormBuilder
    ) 
    { 
      this.tenantUsers$ = tenantUserLookupService.getTenantUserLookup().pipe(map(r => r.items));
    }

  ngOnInit(): void {
    const testResultsStreamCreator = (query) => this.testAttemptService.getList(
      {
        ...query,
        testAttemptUserId: this.filterForm.get('testAttemptUser').value,
        documentId: this.documentId ? this.documentId : null,
        courseId: this.courseId ? this.courseId : null,
      }
    );

    this.list.hookToQuery(testResultsStreamCreator).subscribe((response) => {
      this.testResult = response;
    })

    this.tenantDocumentLookupService.getTenantDocumentLookup().subscribe((response) => {
      this.documentLookup = response.items;
    })

    this.tenantCourseLookupService.getTenantCourseLookup().subscribe((response) => {
      this.courseLookup = response.items;
    })


    this.buildFormForSearch();
  }

  buildFormForSearch(){
    this.filterForm = this.fb.group({
      testAttemptUser: [null],
      documentName: [null],
      courseName: [null]
    });
  }

  searchAttemptByDocument: OperatorFunction<string, readonly BaseDocumentDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForDocumentFilter$, this.clickForDocumentFilter$).pipe(
        map(term => (term === '' ? this.documentLookup
            : this.documentLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
        )
    );
  };

  searchAttemptByCourse: OperatorFunction<string, readonly BaseCourseDto[]> = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

      return merge(debouncedText$, this.focusForCourseFilter$, this.clickForCourseFilter$).pipe(
          map(term => (term === '' ? this.courseLookup
              : this.courseLookup.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
          )
      );
  };

  selectedDocumentItem(item: any){
    this.documentId = item.item.id;
    this.list.get();
  }

  selectedCourseItem(item: any){
    this.courseId = item.item.id;
    this.list.get();
  }

  clearFilters(){
    this.buildFormForSearch();
    this.documentId = null;
    this.courseId = null;
    this.list.get();
  }

  getListWithFilters(){
    this.list.get()
  }

  getListWhenDocumentInputEmpty(event: any){
    if(event == ''){
      this.documentId = null;
      this.list.get();
    }
  }

  getListWhenCourseInputEmpty(event: any){
    if(event == ''){
      this.courseId = null;
      this.list.get();
    }
  }
}
