import { LocalizationService } from "@abp/ng.core";
import { ToasterService } from "@abp/ng.theme.shared";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DocumentApprovalSettingsService } from "@proxy/documents-approval";
import { EnovaUserLookupService } from "@proxy/enova-user-lookup";
import { TenantUserLookupDto } from "@proxy/enova-users-lookup";
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, map, merge } from "rxjs";

@Component({
    selector: 'app-document-approval-tab',
    templateUrl: './document-approval-tab.component.html',
    styleUrls: ['./document-approval-tab.component.scss']
})
export class DocumentApprovalTabComponent implements OnInit {

    isLoading: boolean = false;
    tenantUsersLookup: TenantUserLookupDto[] = [];
    currentSelectedUser: TenantUserLookupDto;

    form: UntypedFormGroup;

    focusForTenant$ = new Subject<string>();
    clickForTenant$ = new Subject<string>();

    tenantFormatter = (tenant: TenantUserLookupDto) => tenant.userName;

    constructor(
        private toaster: ToasterService,
        private localizationService: LocalizationService,
        private fb: UntypedFormBuilder,
        private userLookupService: EnovaUserLookupService,
        private documentApprovalService: DocumentApprovalSettingsService
    ){ }

    ngOnInit(): void {
        this.isLoading = true;
    
        // Get information from settings
        this.documentApprovalService.getDocumentApprovalInformation(true).subscribe((response) => {
            this.currentSelectedUser = response?.selectedUser;
        
            // Build the form after retrieving the data
            this.buildForm();
            this.isLoading = false;
        });
        
        // Get tenant users list
        this.userLookupService.getTenantUserLookup().subscribe((response) => {
            this.tenantUsersLookup = response.items;
        });
    }


    buildForm(){
        this.form = this.fb.group({
            selectedUser: [this.currentSelectedUser != null ? this.currentSelectedUser : null , Validators.required]
        });
    }

    searchForTenant: OperatorFunction<string, readonly TenantUserLookupDto[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    
        return merge(debouncedText$, this.focusForTenant$, this.clickForTenant$).pipe(
            map(term => (term === '' ? this.tenantUsersLookup
                : this.tenantUsersLookup.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
            )
        );
    };

    getFormData(){
        return {
            selectedUser: this.form.get("selectedUser").value
        };
    }

    setUserValueToSettings(){
        if (this.form.invalid)
            return;

        this.documentApprovalService.setDocumentApprovalInformation(this.getFormData()).subscribe({
            next: () => {
                this.currentSelectedUser = this.form.value
            },
            complete: () => {
                this.toaster.success(
                    this.localizationService.instant("::DocumentApproval:Message:SetUser:Success")
                );
            },
            error: () => {
                this.toaster.error(
                    this.localizationService.instant("::DocumentApproval:Message:SetUser:Error")
                );
            }  
        });
    }

}
