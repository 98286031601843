import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeesComponent } from './employee.component';
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EMPLOYEES_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { EMPLOYEES_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.provider';

@NgModule({
    declarations: [
        EmployeesComponent
    ],
    imports:[
        SharedModule,
        CommonModule,
        EmployeeRoutingModule,
        NgbDatepickerModule,
        NgbTimepickerModule
    ]
})
export class EmployeeModule { 
    static forRoot(): ModuleWithProviders<EmployeeModule> {
    return {
        ngModule: EmployeeModule,
        providers: [
            EMPLOYEES_MANAGEMENT_FEATURES_PROVIDERS,
            EMPLOYEES_MANAGEMENT_VISIBLE_PROVIDERS,
        ],
    }
    }
}