import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TenantAdminUserDto, TenantIncidentsService } from '@proxy/incidents';

@Component({
  selector: 'app-incident-admin-tab',
  templateUrl: './incident-admin-tab.component.html',
  styleUrls: ['./incident-admin-tab.component.scss']
})
export class IncidentAdminTabComponent implements OnInit {
  incidentTenantAdmins: TenantAdminUserDto[] = [];
  isLoading: boolean = false;
  selectedAdminEmail: string;
  adminForm: UntypedFormGroup;

  constructor(
    private incidentService: TenantIncidentsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private config: ConfigStateService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.incidentService.getTenantAdminUser().subscribe((response) => {
      this.selectedAdminEmail = response;
    })
    this.incidentService.getTenantAdminUsersList().subscribe((response) => {
      this.incidentTenantAdmins = response.items;
      this.buildForm();
      this.isLoading = false;
    });
  }

  setAdminValueToTenantSettings(){
    if(this.adminForm.invalid)
      return;

    this.incidentService.setTenantAdminUser(this.adminForm.get("adminEmail").value).subscribe({
      next: () => {
        this.selectedAdminEmail = this.adminForm.value
      },
      complete: () => {
        this.toaster.success(
          this.localizationService.instant("::Incident:Message:SetAdmin:Success")
        );
      },
      error: () => {
        this.localizationService.instant("::Incident:Message:SetAdmin:Error")
      }
    })
  }

  buildForm(){
    this.adminForm = this.fb.group({
      adminEmail: [this.selectedAdminEmail != null ? this.selectedAdminEmail : null , Validators.required]
    });
  }

  getIncidentControlFeature(): boolean {
    return this.config.getFeature("Incident.Control") == "true";
  }

}
