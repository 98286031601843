import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateTestQuestionDto, GetTestQuestionListDto, TestAttemptQuestionDto, TestQuestionDto, UpdateTestQuestionDto } from '../../test-questions/models';

@Injectable({
  providedIn: 'root',
})
export class TestQuestionService {
  apiName = 'Default';
  

  create = (input: CreateTestQuestionDto, testId: string) =>
    this.restService.request<any, TestQuestionDto>({
      method: 'POST',
      url: '/api/app/test-question',
      params: { testId },
      body: input,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/test-question/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, TestQuestionDto>({
      method: 'GET',
      url: `/api/app/test-question/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetTestQuestionListDto, testId: string) =>
    this.restService.request<any, PagedResultDto<TestQuestionDto>>({
      method: 'GET',
      url: '/api/app/test-question',
      params: { filter: input.filter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, testId },
    },
    { apiName: this.apiName });
  

  getTestAttemptQuestion = (id: string) =>
    this.restService.request<any, TestAttemptQuestionDto>({
      method: 'GET',
      url: `/api/app/test-question/${id}/test-attempt-question`,
    },
    { apiName: this.apiName });
  

  getTestAttemptQuestions = (testId: string) =>
    this.restService.request<any, TestAttemptQuestionDto[]>({
      method: 'GET',
      url: `/api/app/test-question/test-attempt-questions/${testId}`,
    },
    { apiName: this.apiName });
  

  update = (id: string, input: UpdateTestQuestionDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/test-question/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
