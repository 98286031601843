import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-tawk-to',
  templateUrl: './tawk-to.component.html',
  styleUrls: ['./tawk-to.component.scss'],
})
export class TawkToComponent implements OnInit {
  @Input() id: string;
  @Input() widgetId: string;
  script = this._renderer.createElement('script');

  constructor(private _renderer: Renderer2, @Inject(DOCUMENT) private _document) {}

  ngOnInit(): void {
    this.script.text = `var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/${this.id}/${this.widgetId}';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
      Tawk_API.customStyle = {
        visibility : {
          desktop : {
            position : 'br',
            xOffset : '37px',
            yOffset : '70px'
          },
          mobile : {
            position : 'br',
            xOffset : '34px',
            yOffset : '70px'
          },
          bubble : {
            rotate : '0deg',
             xOffset : -20,
             yOffset : 0
          }
        }
      };
      `;

      this._renderer.appendChild(this._document.body, this.script);
  }
}
