import type { CourseDto, CreateCourseDto, GetCourseListDto, UpdateCourseDto, UploadCoursesDto } from './models';
import type { TenantHostCourseDto } from './tenant-host-course/models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  apiName = 'Default';
  

  copy = (tenantId: string, courseId: string) =>
    this.restService.request<any, CourseDto>({
      method: 'POST',
      url: '/api/app/courses/copy',
      params: { tenantId, courseId },
    },
    { apiName: this.apiName });
  

  create = (input: CreateCourseDto) =>
    this.restService.request<any, TenantHostCourseDto>({
      method: 'POST',
      url: '/api/app/courses',
      body: input,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/courses/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, CourseDto>({
      method: 'GET',
      url: `/api/app/courses/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetCourseListDto) =>
    this.restService.request<any, PagedResultDto<TenantHostCourseDto>>({
      method: 'GET',
      url: '/api/app/courses',
      params: { filter: input.filter, categoryId: input.categoryId, testId: input.testId, availableUntil: input.availableUntil, creationDateFrom: input.creationDateFrom, creationDateTo: input.creationDateTo, creatorId: input.creatorId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  update = (id: string, input: UpdateCourseDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/courses/${id}`,
      body: input,
    },
    { apiName: this.apiName });
  

  uploadCoursesForTenant = (input: UploadCoursesDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/courses/upload-courses-for-tenant',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
