<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Employees' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <div class="col">
        <div id="AbpContentToolbar" class="text-right float-end">

            <button (click)="createImportEmployees()" type="button" class="btn btn-sm btn-primary" style="margin-right: 10px;">
                <i class="fa fa-cloud-upload"></i> 
                <span> {{ '::EmployeeImport' | abpLocalization}} </span>
            </button>

            <button (click)="createEmployee()" type="button" class="btn btn-sm btn-primary" id="new-employee">
                <i class="me-1 fa fa-plus"></i> 
                <span> {{ '::NewEmployee' | abpLocalization}} </span>
            </button> 

        </div>
    </div>
</div>

<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="">
        <abp-advanced-entity-filters-form>
            <form [formGroup]="filterForm">
                <div class="row form-group">
                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-position" class="form-label">
                            {{ '::SearchByPosition' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-position" class="form-select form-control" formControlName="OrganizationUnitPositionId" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="position.id" *ngFor="let position of organizationUnitPositions$ | async">{{ position.displayName }}</option>
                            </select>
                        </div>
                    </div>
                
                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-department" class="form-label">
                            {{ '::SearchByDepartment' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-department" class="form-select form-control" formControlName="OrganizationUnitDepartmentId" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="position.id" *ngFor="let position of organizationUnitDepartments$ | async">{{ position.displayName }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-dangerous-jobs" class="form-label">
                            {{ '::input:Label:IsDoingDangerousJobs' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-dangerous-jobs" class="form-select form-control" formControlName="IsDoingDangerousJobs" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option value=true>{{ '::Yes' | abpLocalization }}</option>
                                <option value=false>{{ '::No' | abpLocalization }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-safety-measures" class="form-label">
                            {{ '::input:Label:IsGettingSafetyMeasures' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-safety-measures" class="form-select form-control" formControlName="IsGettingSafetyMeasures" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option value=true>{{ '::Yes' | abpLocalization }}</option>
                                <option value=false>{{ '::No' | abpLocalization }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-dangerous-equipment" class="form-label">
                            {{ '::input:Label:IsWorkingWithDangerousEquipment' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-dangerous-equipment" class="form-select form-control" formControlName="IsWorkingWithDangerousEquipment" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option value=true>{{ '::Yes' | abpLocalization }}</option>
                                <option value=false>{{ '::No' | abpLocalization }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-health-check" class="form-label">
                            {{ '::input:Label:HasHealthChecked' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-health-check" class="form-select form-control" formControlName="HasHealthChecked" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option value=true>{{ '::Yes' | abpLocalization }}</option>
                                <option value=false>{{ '::No' | abpLocalization }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-work-safery-certificate" class="form-label">
                            {{ '::input:Label:HasWorkSafetyCertificate' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-work-safery-certificate" class="form-select form-control" formControlName="HasWorkSafetyCertificate" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option value=true>{{ '::Yes' | abpLocalization }}</option>
                                <option value=false>{{ '::No' | abpLocalization }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 mb-3">
                        <label for="employee-working-restrictions" class="form-label">
                            {{ '::input:Label:HasWorkingRestrictions' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-working-restrictions" class="form-select form-control" formControlName="HasWorkingRestrictions" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option value=true>{{ '::Yes' | abpLocalization }}</option>
                                <option value=false>{{ '::No' | abpLocalization }}</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-sm-auto align-self-start mt-3">
                    <div class="row">
                        <div class="col-6 col-sm-auto d-grid">
                            <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                                <span>{{ '::Clear' | abpLocalization }}</span>
                            </button>
                        </div>
                        <div class="col-6 col-sm-auto d-grid">
                            <button type="button" class="btn btn-primary" (click)="list.get()">
                                <span>{{ '::Refresh' | abpLocalization }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div class="card">
    <div class="card-body">
        <ngx-datatable 
            [rows]="employees.items" 
            [count]="employees.totalCount" 
            [list]="list" 
            default
        >
            <ngx-datatable-column
            [name]="'::Actions' | abpLocalization"
            [maxWidth]="150"
            [sortable]="false"
            *abpPermission="'Employees.Employees.Edit || Employees.Employees.Delete'"
            >
                <ng-template let-row="row" ngx-datatable-cell-template >
                    <div ngbDropdown container="body" class="d-inline-block">
                        <button
                            class="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            ngbDropdownToggle
                        >
                            <i class="fa fa-cog mr-1"></i>
                            {{ '::Actions' | abpLocalization }}
                        </button>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem *abpPermission="'Employees.Employees.Edit'"
                            (click)="selectEmployeeEdit(row)"
                            >
                                {{ '::Edit' | abpLocalization }}
                            </button>
                            <button ngbDropdownItem *abpPermission="'Employees.Employees.Delete'"
                            (click)="selectEmployeeDelete(row)"
                            >
                                {{ '::Delete' | abpLocalization }}
                            </button>
                        </div>
                    </div>
                </ng-template>

            </ngx-datatable-column>

            <ngx-datatable-column [name]="'::input:Label:Name' | abpLocalization" prop="identityUser.name"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::input:Label:IdentificationCode' | abpLocalization" prop="identificationCode"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::input:Label:Surname' | abpLocalization" prop="identityUser.surname"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::input:Label:UserName' | abpLocalization" prop="identityUser.userName"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::input:Label:Email' | abpLocalization" prop="identityUser.email"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::input:Label:Position' | abpLocalization" prop="organizationUnitPositionName"></ngx-datatable-column>

        </ngx-datatable>
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ (isEmployeeEdit ? '::EditEmployee' : '::NewEmployee') | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody> 
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="form-group mb-3">
                <label class="form-label" for="employee-UserName">{{ '::input:Label:UserName' | abpLocalization }}</label><span> * </span>
                <input type="text" id="employee-UserName" class="form-control" formControlName="UserName" autofocus />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="employee-name">{{ '::input:Label:Name' | abpLocalization }}</label><span> * </span>
                <input type="text" id="employee-name" class="form-control" formControlName="Name" />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="employee-surname">{{ '::input:Label:Surname' | abpLocalization }}</label><span> * </span>
                <input type="text" id="employee-surname" class="form-control" formControlName="Surname" />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="employee-identificationCode">{{ '::input:Label:IdentificationCode' | abpLocalization }}</label>
                <input type="text" id="employee-identificationCode" class="form-control" formControlName="IdentificationCode" />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="employee-email">{{ '::input:Label:Email' | abpLocalization }}</label><span> * </span>
                <input type="email" min="1" id="employee-email" class="form-control" formControlName="Email" />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="employee-department">{{ '::input:Label:Department' | abpLocalization }}</label>
                <select id="employee-department" class="form-select form-control" formControlName="OrganizationUnitDepartmentId">
                    <option [value]=""></option>
                    <option [ngValue]="department.id" *ngFor="let department of organizationUnitDepartments$ | async">{{ department.displayName }}</option>
                </select>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="employee-position-unit">{{ '::input:Label:Position' | abpLocalization }}</label>
                <select id="employee-position-unit" class="form-select form-control" formControlName="OrganizationUnitPositionId">
                    <option [value]=""></option>
                    <option [ngValue]="position.id" *ngFor="let position of organizationUnitPositions$ | async">{{ position.displayName }}</option>
                </select>
            </div>

            <div class="form-group mb-3">
                <input type="checkbox" id="employee-isDoingDangerousJobs" class="form-check-input" formControlName="IsDoingDangerousJobs" />&nbsp;
                <label class="form-label" for="employee-isDoingDangerousJobs">{{ '::input:Label:IsDoingDangerousJobs' | abpLocalization }}</label>
            </div>

            <div class="form-group mb-3">
                <input type="checkbox" id="employee-IsGettingSafetyMeasures" class="form-check-input" formControlName="IsGettingSafetyMeasures" />&nbsp;
                <label class="form-label" for="employee-IsGettingSafetyMeasures">{{ '::input:Label:IsGettingSafetyMeasures' | abpLocalization }}</label>
            </div>

            <div class="form-group mb-3">
                <label for="DateOfEmployment-time">{{ '::DateOfEmployment' | abpLocalization }} </label>
                <div class="row">
                    <div class="col">
                        <input 
                            id="DateOfEmployment-time"
                            #dateOfEmployment="ngbDatepicker"
                            class="form-control mt-2"
                            name="dateOfEmployment"
                            formControlName="DateOfEmployment"
                            ngbDatepicker
                            (click)="dateOfEmployment.toggle()"
                        >
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label for="DateOfUnemployment-time">{{ '::DateOfUnemployment' | abpLocalization }} </label>
                <div class="row">
                    <div class="col">
                        <input 
                            id="DateOfUnemployment-time"
                            #dateOfSelfEmployment="ngbDatepicker"
                            class="form-control mt-2"
                            name="dateOfUnemployment"
                            formControlName="DateOfUnemployment"
                            ngbDatepicker
                            (click)="dateOfSelfEmployment.toggle()"
                        >
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <input type="checkbox" id="employee-IsWorkingWithDangerousEquipment" class="form-check-input" formControlName="IsWorkingWithDangerousEquipment" />&nbsp;
                <label class="form-label" for="employee-IsWorkingWithDangerousEquipment">{{ '::input:Label:IsWorkingWithDangerousEquipment' | abpLocalization }}</label>
            </div>


            <div class="form-group mb-3">
                <input type="checkbox" id="employee-HasHealthChecked" class="form-check-input" formControlName="HasHealthChecked" />&nbsp;
                <label class="form-label" for="employee-HasHealthChecked">{{ '::input:Label:HasHealthChecked' | abpLocalization }}</label>
            </div>

            <div class="form-group mb-3" *ngIf="hasCheckedHealth()">
                <label for="HealthCheckedDate-time">{{ '::HealthCheckedDate' | abpLocalization }} </label>
                <div class="row">
                    <div class="col">
                        <input 
                            id="HealthCheckedDate-time"
                            #healthCheckedDate="ngbDatepicker"
                            class="form-control mt-2"
                            name="healthCheckedDate"
                            formControlName="HealthCheckedDate"
                            ngbDatepicker
                            (click)="healthCheckedDate.toggle()"
                            (change)="changeEmployeeHealthCheckStatus()"
                        >
                    </div>
                </div>
            </div>

            <div class="row mb-3" *ngIf="hasCheckedHealth()">
                <label class="form-label" for="employee-renewal-period">{{ '::EmployeeRenewalPeriod' | abpLocalization }}</label>
                <div class="col">
                    <input type="number" id="employee-renewalPeriodNumber" class="form-control" formControlName="RenewalPeriodNumber" (change)="selectRenewalPeriod(form)"/>
                </div>
                <div class="col">
                    <select id="renewalPeriod" class="form-select form-control" formControlName="RenewalPeriod" (change)="selectRenewalPeriod(form)">
                        <option value=""></option>
                        <option [ngValue]="renewalPeriod.value" *ngFor="let renewalPeriod of renewalPeriods">
                            {{ '::Enum:Employee:RenewalPeriod:' + renewalPeriod.value | abpLocalization }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group mb-3">
                <input type="checkbox" id="employee-HasWorkSafetyCertificate" class="form-check-input" formControlName="HasWorkSafetyCertificate" />&nbsp;
                <label class="form-label" for="employee-HasWorkSafetyCertificate">{{ '::input:Label:HasWorkSafetyCertificate' | abpLocalization }}</label>
            </div>

            <div class="form-group mb-3">
                <input type="checkbox" id="employee-HasWorkingRestrictions" class="form-check-input" formControlName="HasWorkingRestrictions" />&nbsp;
                <label class="form-label" for="employee-HasWorkingRestrictions">{{ '::input:Label:HasWorkingRestrictions' | abpLocalization }}</label>
            </div>

            <div class="form-group mb-3" *ngIf="!this.isEmployeeEdit">
                <label class="form-label" for="employee-password">{{ '::input:Label:Password' | abpLocalization }}</label><span> * </span>
                <input type="password" min="1" id="employee-password" class="form-control" formControlName="Password" />
            </div>

        </form>

    </ng-template>

    <ng-template #abpFooter>
        <button type="button" class="btn btn-secondary" abpClose>
            {{ '::Cancel' | abpLocalization }}
        </button>
        <button class="btn btn-primary" id="save-button" (click)="save()" [disabled]="form.invalid">
            <i class="fa fa-check mr-1"></i>
            {{ (isEmployeeEdit ? '::Edit' : '::Save') | abpLocalization }}
        </button>
    </ng-template>
</abp-modal>

<abp-modal [(visible)]="isModalImportOpen" [busy]="modalBusy" (disappear)="onImportModalDisappear()">

    <ng-template #abpHeader>
        <h3>{{ '::EmployeeImport' | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="importForm">

            <div class="form-group mb-3">
                <label class="form-label" for="import-file-example"> {{ '::FileExample' | abpLocalization }} </label>
                <div class="input-group mb-3">
                    <button 
                        id="import-file-example" 
                        class="btn btn-primary" 
                        type="button"
                        (click)="downloadExample()"
                        [disabled]="modalBusy"
                    >
                        {{ '::Download' | abpLocalization }}
                    </button>
                </div>
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="import-file"> {{ '::FileAttachment' | abpLocalization }} </label><span> * </span>
                <div class="input-group mb-3">
                    <input
                        #takeInput
                        class="form-control"
                        type="file"
                        formControlName="file"
                        id="import-file"
                        aria-describedby="import-file"
                        aria-label="{{ '::FileName' | abpLocalization }}"
                        (change)="onFileChanged($event)"
                        [accept]="allowedFileTypesFormattedString"
                        [abpDisabled]="modalBusy"
                    />
                </div>            
            </div>   
            
            <div class="card border-danger mb-3" *ngIf="importErrors.totalCount > 0">
                <div class="card-header">
                    <h3>{{ '::Import:Errors' | abpLocalization }}</h3>
                </div>
                <div class="card-body">
                    <ngx-datatable 
                    [rows]="importErrors.items" 
                    [count]="importErrors.totalCount"
                    [limit]="10"
                    [importErrorsList]="importErrorsList"
                    columnMode="force"
                    default
                >
                    <ngx-datatable-column
                    [maxWidth]="100"
                    [name]="'::LineNumber' | abpLocalization"
                    prop="lineNumber"
                    [sortable]="false"
                >
                </ngx-datatable-column>
                    <ngx-datatable-column
                        [name]="'::ErrorMessage' | abpLocalization"
                        prop="errorMessage"
                        [sortable]="false"
                    >
                    </ngx-datatable-column>
                </ngx-datatable>
                </div>
            </div>

            <div 
                class="card" 
                *ngIf="importSuccessResponse != undefined"
            >
                <div class="card-header">
                    <h3>{{ '::Import:Success' | abpLocalization }}</h3>
                </div>

                <div class="card-body">
                    <div>
                        <p>
                            {{ '::EmployeesUploadedFromExcel' | abpLocalization }}&nbsp;
                            {{importSuccessResponse.uploadedEmployeesFromExcel}}
                        </p>
                    </div>

                    <div>
                        <p>
                            {{ '::EmployeesImportedToSystem' | abpLocalization }}&nbsp;
                            {{importSuccessResponse.importedEmployees}}
                        </p>
                    </div>
                </div>
            </div>
            
        </form>
    </ng-template>

    <ng-template #abpFooter>
        <button 
            type="button" 
            class="btn btn-secondary" 
            (click)="onImportModalClose()"
            [disabled]="modalBusy"
        >
            {{ '::Close' | abpLocalization }}
        </button>
        <abp-button 
            *ngIf="importSuccessResponse == undefined"
            (click)="import()" 
            [disabled]="importForm.invalid"
        >
            {{ '::Import' | abpLocalization }}
        </abp-button>
    </ng-template>

</abp-modal>