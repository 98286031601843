import { APP_INITIALIZER, Injector } from '@angular/core';
import { combineLatest } from 'rxjs';
import { RoutesService } from '@abp/ng.core';
import { DEVICES_MANAGEMENT_ROUTE_VISIBILITY } from './features.token';
import { ProviderConsts } from 'src/app/shared/Constants/providers.constants';

export const DEVICES_MANAGEMENT_VISIBLE_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: devicesManagementVisibility,
        deps: [Injector],
        multi: true,
    },
];

export function devicesManagementVisibility(injector: Injector) {
    return () => {
        const isDevicesManagementFeatureEnable$ = injector.get(DEVICES_MANAGEMENT_ROUTE_VISIBILITY);
        const routes = injector.get(RoutesService);
        combineLatest([isDevicesManagementFeatureEnable$]).subscribe(
        ([isDevicesManagementFeatureEnable]) => {
            routes.patch(ProviderConsts.DEVICE_ROUTE_PATCH_NAME, {
            invisible: !isDevicesManagementFeatureEnable,
            });
        },
        );
    };
}
