import { mapEnumToOptions } from '@abp/ng.core';

export enum TenantActionType {
  View = 0,
  Download = 1,
  Complete = 2,
  Approve = 3,
}

export const tenantActionTypeOptions = mapEnumToOptions(TenantActionType);
