import { TestService } from './../proxy/tests/test.service';
import { TestDto } from './../proxy/tests/models';
import { ConfigStateService, CurrentUserDto, ListService, LocalizationService, PagedResultDto } from "@abp/ng.core";
import { Component, OnInit } from "@angular/core";
import { Constants } from '../shared/Constants/Constants';
import { Router } from '@angular/router';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserLookupDto } from '@proxy/enova-users-lookup';
import { EnovaUserLookupService } from '@proxy/enova-user-lookup';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, map, merge } from 'rxjs';

@Component({
    selector: 'app-test',
    templateUrl: './tests.component.html',
    styleUrls: ['./tests.component.scss'],
    providers: [ListService]
})
export class TestsComponent implements OnInit {
    tests = { items: [], totalCount: 0} as PagedResultDto<TestDto>;
    filterInput = '';
    isModalOpen = false;
    tenantId: string;
    isAdmin = false;
    hostUsers: UserLookupDto[] = [];
    
    DateTimeFormatConst = Constants.DATETIME;

    form: UntypedFormGroup;
    filterForm: UntypedFormGroup;

    focusForUserSearch$ = new Subject<string>();
    clickForUserSearch$ = new Subject<string>();
    
    formatter = (user: UserLookupDto) => user.userName;

    searchTestByUser: OperatorFunction<string, readonly UserLookupDto[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

        return merge(debouncedText$, this.focusForUserSearch$, this.clickForUserSearch$).pipe(
            map(term => (term === '' ? this.hostUsers
            : this.hostUsers.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
            )
        );
    };

    constructor(
        public readonly list: ListService, 
        private enovaUserLookupService: EnovaUserLookupService,
        private testAppService: TestService,
        private localizationService: LocalizationService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private confirmation: ConfirmationService,
        private config: ConfigStateService,
        private toaster: ToasterService) 
    { }

    ngOnInit(): void {
        const testStreamCreator = (query) => this.testAppService.getList({
            ...query,
            creatorId: this.filterForm.get('creatorId').value ? this.filterForm.get('creatorId').value.id : null,
        });

        this.buildFormForSearch();

        var currentUser = this.config.getOne("currentUser");
        if (currentUser.tenantId == null && currentUser.roles.includes("admin")) {
            this.enovaUserLookupService.getUserLookup().subscribe((response) => {
                this.hostUsers = response.items;
            })
            this.isAdmin = true;
        }

        this.list.hookToQuery(testStreamCreator).subscribe((response) => {
            this.tests.items = response.items;
            this.tests.totalCount = response.totalCount;
        });
    }

    getListWhenUserInputEmpty(event: any){
        if(event == ''){
            this.list.get();
        }
    }

    selectedUser(user: any){
        this.list.get();
    }

    createTest() {
        this.buildForm();
        this.isModalOpen = true;
    }

    buildForm() {
        this.form = this.fb.group({
            Name: ['', [Validators.required]],
            IdentificationNumber: ['', [Validators.required]],
            Description: ['', []]
        });
    }

    buildFormForSearch() {
        this.filterForm = this.fb.group({
            creatorId: [null],
        })
    }

    clearFilters(){
        this.buildFormForSearch();
        this.list.get();
    }

    openEditView(testId: string) {
        this.router.navigate(['tests/edit', testId]);
    }

    openTestAttemptView(testId: string){
        this.router.navigate(["test/attempt"], {queryParams: {
            id: testId
        }});
    }

    getTestAttemptFeature(): boolean {
        return this.config.getFeature("Test.Attempt") == "true";
    }
    
    getTestControlFeature(): boolean {
        return this.config.getFeature("Test.Control") == "true";
    }
    

    deleteTest(id: string) {
        this.confirmation.warn('::Test:Message:Delete:Confirmation', 'AbpAccount::AreYouSure')
            .subscribe((status) => {
                if (status === Confirmation.Status.confirm) {
                    this.testAppService.delete(id).subscribe(null, 
                        () => {
                            this.toaster.error(
                                this.localizationService.instant("::Test:Message:Delete:Error")
                            );
                        }, () => {
                            this.list.get();
                            this.toaster.success( 
                                this.localizationService.instant("::Test:Message:Delete:Success")
                            );
                        }
                    );
                }
            });
    }

    save() {
        if (this.form.invalid) {
            return;
        }
    
        this.testAppService.create(this.form.value).subscribe(
            (value) => {
                this.isModalOpen = false;
                this.form.reset();
                this.list.get();
                this.router.navigate(['tests/edit', value.id]);
            }, (error) => {
                this.toaster.error(
                    this.localizationService.instant("::Test:Message:Created:Error")
                );
            }, () => {
                this.toaster.success( 
                    this.localizationService.instant("::Test:Message:Created:Success")
                );
            }
        );
    }
}