<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Course' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <ng-container *ngIf="getCourseControlFeature()">
        
        <div class="col">

            <div *abpPermission="'Tickets.Tickets.Create || Courses.Courses.Create'">
                <div id="AbpContentToolbar" class="float-end" *ngIf="isMultiSelectDataSelected()">
                    <button
                        (click)="openCreateCourseMultiselectTaskModal()"
                        class="btn btn-sm btn-primary m-2"
                        type="button"
                        *ngIf="isCurrentTenantUserAdminOrDSSSpecialist()"
                    >
                        <span>{{ '::CreateTask' | abpLocalization }}</span>
                    </button>
                    <button
                        (click)="openUploadCoursesModal()"
                        class="btn btn-sm btn-primary m-2"
                        type="button"
                        *ngIf="isCurrentUserSuperAdmin()"
                    >
                        <i class="me-2 fa fa-upload" aria-hidden="true"></i>
                        <span>{{ '::UploadCoursesForTenant' | abpLocalization }}</span>
                    </button>
                </div>
            </div>

            <div id="AbpContentToolbar" class="text-right float-end">
                <button 
                    (click)="createCourse()" 
                    type="button"  
                    class="btn btn-sm btn-primary m-2"
                    *abpPermission="'Courses.Courses.Create || TenantCourses.Courses.Create'"
                    id="new-course"
                >
                    <i class="me-1 fa fa-plus"></i> 
                    <span> {{ '::NewCourse' | abpLocalization}} </span>
                </button>                    
            </div>

        </div>
    </ng-container>

</div>


<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="">
        <abp-advanced-entity-filters-form>
            <form [formGroup]="filterForm">
            <div class="row form-group">

                <div class="col-12 col-sm-4 mb-3">
                    <div class="data-tables-filter form-group">
                        <div class="input-group">
                            <input
                                class="form-control"
                                placeholder="{{ '::SearchByCategory' | abpLocalization }}"    
                                id="typeahead-focus"
                                type="search"
                                class="form-control"
                                [editable]="false"
                                (selectItem)="selectedItem($event)"
                                [resultFormatter]="formatter"
                                [inputFormatter]="formatter"
                                formControlName="category"
                                (input)="getListWhenCategoryInputEmpty($event.target.value)"
                                [ngbTypeahead]="searchCourseByCategory"
                                (focus)="focusForCategoryFilter$.next($any($event).target.value)"
                                (click)="clickForCategoryFilter$.next($any($event).target.value)"
                                #instance="ngbTypeahead"
                            />
                        </div>
                    </div>
                </div>
                
                <div class="col-12 col-sm-4 mb-3">
                    <div class="data-tables-filter form-group">
                        <div class="input-group">
                            <input
                                class="form-control"
                                placeholder="{{ '::SearchByTest' | abpLocalization }}"     
                                id="typeahead-focus"
                                type="search"
                                class="form-control"
                                [editable]="false"
                                (selectItem)="selectedTestItem($event)"
                                [resultFormatter]="formatter"
                                [inputFormatter]="formatter"
                                formControlName="test"
                                (input)="getListWhenTestInputEmpty($event.target.value)"
                                [ngbTypeahead]="searchCourseByTest"
                                (focus)="focusForTestFilter$.next($any($event).target.value)"
                                (click)="clickForTestFilter$.next($any($event).target.value)"
                                #instance="ngbTypeahead"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-4 mb-3" *ngIf="showAvailableUntilField()">
                    <div class="data-tables-filter form-group">
                        <div class="input-group">
                            <input 
                                id="available-date"
                                placeholder="{{ '::AvailableUntilDate' | abpLocalization }}"
                                #datepickerStart="ngbDatepicker"
                                class="form-control"
                                name="datepickerStart"
                                formControlName="availableUntil"
                                ngbDatepicker
                                (click)="datepickerStart.toggle()"
                            >                                
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-4 mb-3">
                    <div class="data-tables-filter form-group">
                      <div class="input-group">
                        <input 
                          id="creation-date-from"
                          placeholder="{{ '::CreationDateFrom' | abpLocalization }}"
                          #datepickerCreationTimeFrom="ngbDatepicker"
                          class="form-control"
                          name="datepickerCreationTimeFrom"
                          formControlName="creationTimeFrom"
                          ngbDatepicker
                          (click)="datepickerCreationTimeFrom.toggle()"
                        >                                
                      </div>
                    </div>
                  </div>
        
                  <div class="col-12 col-sm-4 mb-3">
                    <div class="data-tables-filter form-group">
                      <div class="input-group">
                        <input 
                          id="creation-date-to"
                          placeholder="{{ '::CreationDateTo' | abpLocalization }}"
                          #datepickerCreationTimeTo="ngbDatepicker"
                          class="form-control"
                          name="datepickerCreationTimeTo"
                          formControlName="creationTimeTo"
                          ngbDatepicker
                          (click)="datepickerCreationTimeTo.toggle()"
                        >                                
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-4 mb-3" *ngIf="isAdmin">
                    <div class="input-group">
                        <input 
                          class="form-control"
                          id="typeahead-focus-user"
                          type="search"
                          [editable]="false"
                          placeholder="{{ '::SearchByAuthor' | abpLocalization }}"     
                          (selectItem)="selectedUser($event)"
                          [resultFormatter]="userFormatter"
                          [inputFormatter]="userFormatter"
                          formControlName="creatorId"
                          (input)="getListWhenUserInputEmpty($event.target.value)"
                          [ngbTypeahead]="searchCourseByUser"
                          (focus)="focusForUserSearch$.next($any($event).target.value)"
                          (click)="clickForUserSearch$.next($any($event).target.value)"
                          #instance="ngbTypeahead"
                        />
                    </div>
                  </div>
            </div>
            <div class="col-12 col-sm-auto align-self-start mb-3">
                <div class="row">
                    <div class="col-6 col-sm-auto d-grid">
                        <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                            <span>{{ '::Clear' | abpLocalization }}</span>
                        </button>
                    </div>
                    <div class="col-6 col-sm-auto d-grid">
                        <button type="button" class="btn btn-primary" (click)="list.get()">
                            <span>{{ '::Refresh' | abpLocalization }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div *abpPermission="'Courses.Courses.ViewCourseSuggestions'">
    <app-course-banner
        *ngIf="tenantHasMissingCourses"
        [courses]="missingCourses"
        [ecommerceUrl]="ecommerceUrl"
    ></app-course-banner>
</div>


<div class="card">
    <div class="card-body">
        <ngx-datatable 
            [rows]="courses.items" 
            [count]="courses.totalCount" 
            [list]="list" 
            default
        >
            <ngx-datatable-column
                [name]="'::Actions' | abpLocalization"
                [maxWidth]="150"
                [sortable]="false"
            >
                <ng-template let-row="row" ngx-datatable-cell-template >
                    <div ngbDropdown container="body" class="d-inline-block">
                        <button
                            class="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            ngbDropdownToggle
                        >
                            <i class="fa fa-cog mr-1"></i>
                            {{ '::Actions' | abpLocalization }}
                        </button>
                        <div ngbDropdownMenu>

                            <ng-container *ngIf="getCourseViewFeature()">
                                <button 
                                    ngbDropdownItem
                                    *abpPermission="'Courses.Courses.View'"
                                    (click)="openCourseView(row.id, row.tenantCourseId)"
                                >
                                    {{ '::ViewCourse' | abpLocalization }}
                                </button>     
                                
                                <ng-container *ngIf="getTestAttemptFeature()">
                                    <div *abpPermission="'Tests.Tests.Attempt'">
                                        <button
                                            *ngIf="row.test"
                                            ngbDropdownItem
                                            (click)="attemptCourseTest(row.test.id, this.isTenantExist ? row.tenantCourseId : row.id)"
                                        >
                                            {{ '::TakeATest' | abpLocalization }}
                                        </button>
                                    </div>
                                </ng-container>
                                
                            </ng-container>

                            <ng-container *ngIf="ecommerceUrl">
                                <button
                                    *ngIf="row.productUrlPath"
                                    ngbDropdownItem
                                    (click)="goToProductPage(row.productUrlPath)"
                                >
                                    {{ '::CourseProductPageButton' | abpLocalization }}
                                </button>
                            </ng-container>

                            <ng-container *ngIf="getCourseControlFeature()">
                                <ng-container *ngIf="isShownForCopy(row.id)">
                                    <button
                                        ngbDropdownItem
                                        *abpPermission="'Courses.Courses.Copy'"
                                        (click)="copyCourse(row.id)"
                                    >
                                        {{ '::Copy' | abpLocalization }}
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="isShown(row.tenantId)">
                                    <button
                                        ngbDropdownItem
                                        *abpPermission="'Courses.Courses.Edit'"
                                        (click)="openEditView(row.id, row.tenantCourseId)"
                                    >
                                        {{ '::Edit' | abpLocalization }}
                                    </button>

                                    <button
                                        ngbDropdownItem
                                        *abpPermission="'Courses.Courses.Delete'"
                                        (click)="delete(row.id)"
                                    >
                                        {{ '::Delete' | abpLocalization }}
                                    </button>
                                </ng-container>
                            </ng-container>

                        </div>
                    </div>
                </ng-template>

            </ngx-datatable-column>

            <ngx-datatable-column
                [sortable]="false"
                [maxWidth]="60"
                *ngIf="isCurrentTenantUserAdminOrDSSSpecialist() || isCurrentUserSuperAdmin()"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="form-check form-check-inline">
                        <input 
                            *ngIf="isCurrentTenantUserAdminOrDSSSpecialist()"
                            class="form-check-input" 
                            type="checkbox" 
                            (change)="changeCourseSelection(row, $event.target.checked)"
                            [checked]="isCourseChecked(row.tenantCourseId)"
                        >
                        <input 
                            *ngIf="isCurrentUserSuperAdmin()"
                            class="form-check-input" 
                            type="checkbox" 
                            (change)="changeCourseSelection(row, $event.target.checked)"
                            [checked]="isCourseChecked(row.id)"
                        >
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'::Name' | abpLocalization" prop="name"></ngx-datatable-column>

            <ngx-datatable-column [name]="'::Category' | abpLocalization" prop="category.name" [sortable]="false">
            </ngx-datatable-column>     
            
            <ngx-datatable-column
                [name]="'::Test' | abpLocalization"
                prop="test.name"
            ></ngx-datatable-column>

            <ngx-datatable-column 
                [name]="'::Code' | abpLocalization" 
                prop="code" 
                [sortable]="false"
                [maxWidth]="150"
            ></ngx-datatable-column>

            <ngx-datatable-column
                *ngIf="isCurrentUserImpersonatedSuperAdmin()"
                [name]="'::IsUploaded' | abpLocalization"
                prop="isUpload"
                [maxWidth]="150" 
                [sortable]="false" 
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.isUpload ? ('::Yes' | abpLocalization) : '::No' | abpLocalization }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column 
                *ngIf="isAdmin" 
                [name]="'::Modifier' | abpLocalization" 
                prop="modifier"
                [sortable]="false"
            ></ngx-datatable-column>

            <ngx-datatable-column 
                *ngIf="isAdmin"
                [name]="'::ModificationTime' | abpLocalization" 
                prop="modificationTime"
                [maxWidth]="200"
                [sortable]="false"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.lastModificationTime | date: dateFormat }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column 
                *ngIf="isAdmin" 
                [name]="'::Creator' | abpLocalization" 
                prop="creator"
                [sortable]="false"    
            ></ngx-datatable-column>

            <ngx-datatable-column 
                [name]="'::CreationDate' | abpLocalization" 
                prop="creationTime"
                [maxWidth]="200"
                [sortable]="false"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.creationTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column 
                *ngIf="currentUserTenantId != null"
                [name]="'::ApprovalDate' | abpLocalization" 
                prop="approvalDate"
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.approvalDate | date: dateFormat }}
                </ng-template>
            </ngx-datatable-column>

        </ngx-datatable>        
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ '::NewCourse' | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody> 
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="form-group mb-3">
                <label class="form-label" for="course-name">{{ '::Name' | abpLocalization }}</label><span> * </span>
                <input type="text" id="course-name" class="form-control" formControlName="Name" autofocus />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="course-code">{{ '::Code' | abpLocalization }}</label><span> * </span>
                <input type="text" id="course-code" class="form-control" formControlName="Code" />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="course-length">{{ '::input:Label:Length' | abpLocalization }}</label><span> * </span>
                <input type="number" min="1" id="course-length" class="form-control" formControlName="Length" />
            </div>
            <div class="form-group mb-3">
                <label 
                    class="form-label" 
                    for="parent-category-name"
                >
                    {{ '::Category' | abpLocalization }}
                </label>
                <input
                    id="typeahead-focus"
                    type="text"
                    class="form-control"
                    formControlName="Category"
                    [editable]="false"
                    [ngbTypeahead]="searchForCategory"
                    [resultFormatter]="formatter"
                    [inputFormatter]="formatter"
                    (focus)="focusForCategorySearch$.next($any($event).target.value)"
                    (click)="clickForCategorySearch$.next($any($event).target.value)"
                    #instance="ngbTypeahead"
                />
            </div>
            <div class="form-group mb-3">
                <label for="test-name" class="form-label">
                    {{ '::Test' | abpLocalization }}
                </label>
                <input
                    id="typeahead-focus"
                    type="text"
                    class="form-control"
                    formControlName="Test"
                    [editable]="false"
                    [ngbTypeahead]="searchForTest"
                    [resultFormatter]="formatter"
                    [inputFormatter]="formatter"
                    (focus)="focusForTestSearch$.next($any($event).target.value)"
                    (click)="clickForTestSearch$.next($any($event).target.value)"
                    #instance="ngbTypeahead"
                />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="course-shortDesc">{{ '::input:Label:ShortDescription' | abpLocalization }}</label>
                <textarea  id="course-shortDesc" class="form-control" rows="2" formControlName="ShortDescription" >
                </textarea>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="course-fullDesc">{{ '::input:Label:FullDescription' | abpLocalization }}</label>
                <textarea id="course-fullDesc" class="form-control" rows="5" formControlName="FullDescription" ></textarea>
            </div>

            <div class="form-group mb-3" *ngIf="this.isGrantedPermissionForSettingProductPageUrl">
                <label class="form-label" for="course-page-link">{{ '::ProductPageUrl' | abpLocalization }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" *ngIf="ecommerceUrl">{{ ecommerceUrl }}</span>
                    <input type="text" id="course-page-link" class="form-control" formControlName="ProductUrlPath"/>
                </div>
            </div>

            <div class="row" *ngIf="!isTenantExist">
                <label class="form-label" for="course-renewal-period">{{ '::CourseRenewalPeriod' | abpLocalization }} <span> *</span></label>

                <div class="col">
                    <input type="number" id="course-name" class="form-control" formControlName="RenewalPeriodNumber"/>
                </div>
                <div class="col">
                    <select id="renewalPeriod" class="form-select form-control" formControlName="RenewalPeriod">
                        <option value=""></option>
                        <option [ngValue]="renewalPeriod.value" *ngFor="let renewalPeriod of renewalPeriods">
                            {{ '::Enum:Course:RenewalPeriod:' + renewalPeriod.value | abpLocalization }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group mb-3" *ngIf="showAvailableUntilField()">
                <label for="available-date">{{ '::AvailableUntilDate' | abpLocalization }} </label>
                <div class="row">
                    <div>
                        <input 
                            id="available-date"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            formControlName="AvailableUntil"
                            ngbDatepicker
                            (click)="datepickerStart.toggle()"
                        >
                    </div>
                </div>
            </div>

        </form>
    </ng-template>

    <ng-template #abpFooter>
        <button type="button" class="btn btn-secondary" abpClose>
            {{ '::Cancel' | abpLocalization }}
        </button>
        <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
            <i class="fa fa-check mr-1"></i>
            {{ '::Save' | abpLocalization }}
        </button>
    </ng-template>
</abp-modal>

<abp-modal [(visible)]="isCopyModalOpen" [busy]="isLoading">
    <ng-template #abpHeader>
        <h3>{{ '::CourseCopy' | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="copyForm" (ngSubmit)="saveCourseCopy()">
            <div class="form-group">
            <label for="course-tenant">{{ '::TenantForCourseCopy' | abpLocalization }}</label><span> *</span>
                <select id="course-tenant" class="form-select form-control" formControlName="tenantId">
                    <option value=""></option>
                    <option [ngValue]="tenant.id" *ngFor="let tenant of tenants$ | async">{{ tenant.name }}</option>
                </select>
            </div>
        </form>
    </ng-template>
    
    <ng-template #abpFooter>
        <button
            type="button"
            class="btn btn-secondary"
            abpClose
        >
            {{ '::Close' | abpLocalization }}
        </button>

        <abp-button 
            iconClass="fa fa-check" 
            (click)="saveCourseCopy()" 
            [disabled]="copyForm.invalid"
        >
            {{ '::Save' | abpLocalization }}
        </abp-button>
    </ng-template>
</abp-modal>

<abp-modal [(visible)]="isMultiSelectModalOpen">
    <ng-template #abpHeader>
        <h3>{{ '::NewTask' | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="multiSelectTaskForm" (ngSubmit)="saveMultiSelectTask()">
            <div class="form-group mb-3">
                <label for="task-name" class="form-label">{{ '::Name' | abpLocalization }} </label><span> *</span>
                <input type="text" id="task-name" class="form-control" formControlName="name">
            </div>

            <div class="form-group mb-3">
                <label for="task-description" class="form-label">{{ '::Description' | abpLocalization }}</label>
                <textarea id="task-description" class="form-control" cols="30" rows="4" formControlName="description"></textarea>
            </div>

            <div>
                <div class="form-group mb-3">
                    <label class="form-label" for="ticket-users-id">
                        {{ '::Users' | abpLocalization }}
                    </label><span> *</span>
            
                    <input
                        class="form-control" 
                        id="ticket-users-id"
                        type="search"
                        formControlName="assignedUserId"
                        [editable]="false"
                        [resultFormatter]="tenantFormatter"
                        [inputFormatter]="tenantFormatter"
                        [ngbTypeahead]="searchTenantForTicketUsers"
                        (focus)="focusForTicketTenantUser$.next($any($event).target.value)"
                        (click)="clickForTicketTenantUser$.next($any($event).target.value)"
                        (ngModelChange)="selectedNewTaskUsers($event)"
                        #instance="ngbTypeahead"
                        [abpDisabled]="isTicketDocumentApprovalSelected"
                    />
                </div>
            
                <button 
                    type="button" 
                    class="btn btn-primary"
                    (click)="selectAllTenantUsers()"
                    [disabled]="isTicketDocumentApprovalSelected"
                    >
                    {{ '::SelectAll' | abpLocalization }}
                </button>
            
                <ngx-datatable 
                    [rows]="selectedUsers.items" 
                    [count]="selectedUsers.totalCount" 
                    [limit]="5"
                    #width
                    default
                >
                    <ngx-datatable-column
                        [name]="'::Actions' | abpLocalization"
                        [maxWidth]="80"
                        [sortable]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div container="body" class="d-inline-block">
                                <button
                                    class="btn btn-danger btn-sm"
                                    aria-haspopup="true"
                                    (click)="removeAssignedUser(row)"
                                    type="button"
                                    [disabled]="isTicketDocumentApprovalSelected"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
            
                    <ngx-datatable-column
                        [name]="'::UserName' | abpLocalization"
                        prop="userName"
                    >
                    </ngx-datatable-column>
            
                </ngx-datatable>
            </div>

            <div class="form-group">
                <label for="task-start-time">{{ '::StartDateTime' | abpLocalization }} </label><span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input 
                            id="task-start-time"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            formControlName="startDate"
                            ngbDatepicker
                            (click)="datepickerStart.toggle()"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            formControlName="startTime"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="task-end-time">{{ '::EndDateTime' | abpLocalization }}</label> <span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input
                            id="task-end-time"
                            #datepickerEnd="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerEnd"
                            formControlName="endDate"
                            ngbDatepicker
                            (click)="datepickerEnd.toggle()"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            formControlName="endTime"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label for="task-kind" class="form-label">{{ '::TaskKind' | abpLocalization }}</label>
                <select id="task-kind" class="form-select form-control" formControlName="ticketKind">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="kind.value" *ngFor="let kind of ticketKinds">
                        {{ '::Enum:TaskKind:' + kind.value | abpLocalization }}
                    </option>
                </select>
            </div>

        </form>
    </ng-template>

    <ng-template #abpFooter>
        <button 
            type="button" 
            class="btn btn-secondary" 
            abpClose>
            {{ '::Close' | abpLocalization }}
        </button>
    
        <abp-button 
            iconClass="fa fa-check" 
            (click)="saveMultiSelectTask()" 
            [disabled]="multiSelectTaskForm.invalid"
        >
            {{
                '::Save' | abpLocalization 
            }}
        </abp-button>
    </ng-template>

</abp-modal>

<abp-modal [(visible)]="isUploadModalOpen">
    <ng-template #abpHeader>
        <h3>{{ '::CoursesUpload' | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="uploadForm" (ngSubmit)="uploadCourses()">
          <div class="form-group">
            <label for="courses-upload-tenant">{{ '::TenantForCoursesUpload' | abpLocalization }}</label><span> *</span>
            <select id="courses-upload-tenant" class="form-select form-control" formControlName="tenantId">
              <option value=""></option>
              <option [ngValue]="tenant.id" *ngFor="let tenant of tenants$ | async">{{ tenant.name }}</option>
            </select>
          </div>
        </form>
      </ng-template>
    
      <ng-template #abpFooter>
        <button 
          type="button" 
          class="btn btn-secondary" 
          abpClose>
          {{ '::Close' | abpLocalization }}
        </button>
    
        <abp-button 
          iconClass="fa fa-check" 
          (click)="uploadCourses()"
          [disabled]="uploadForm.invalid"
        >
          {{
            '::Upload' | abpLocalization 
          }}
        </abp-button>
      </ng-template>
</abp-modal>