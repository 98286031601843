<div class="row row-entry">
        <div class="col-12 col-sm-auto">
            <h1 class="content-header-title">{{ '::Menu:TicketsCalendar' | abpLocalization }}</h1>
        </div>

        <div class="col pl-lg-0">
            <abp-breadcrumb></abp-breadcrumb>
        </div>

    <full-calendar  
        [options]="calendarOptions"
        >
    </full-calendar>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ (isView ? '::ViewTask' : (selectedTicket.id ? '::EditTask' : '::NewTask')) | abpLocalization }}
        </h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="createUpdateTicketForm" (ngSubmit)="saveTicket()">
            <div class="form-group mb-3">
                <label for="task-name" class="form-label">{{ '::Name' | abpLocalization }}</label><span> *</span>
                <input type="text" id="task-name" class="form-control" formControlName="name" [readonly]="isView">
            </div>
            
            <div class="form-group mb-3">
                <label for="task-description" class="form-label">{{ '::Description' | abpLocalization }}</label>
                <textarea id="task-description" class="form-control" cols="30" rows="4" formControlName="description" [readonly]="isView"></textarea>
            </div>

            <div *ngIf="isTicketForEdit()">
                <div class="form-group mb-3">
                    <label for="task-user" class="form-label"> {{ '::AssignedTo' | abpLocalization }} </label> <span> *</span>
                    <select id="task-user" class="form-select form-control" formControlName="assignedUserId" [abpDisabled]="isTicketDocumentApprovalSelected">
                        <option value=""></option>
                        <option [ngValue]="user.id" *ngFor="let user of tenantUsers$ | async">{{ user.userName }}</option>
                    </select>
                </div>
            </div>

            <div *ngIf="!isTicketForEdit()">

                <div class="form-group mb-3">
                    <label for="select-from-switch">
                        {{ '::SelectUsersFrom' |  abpLocalization }}
                    </label>
                    <select 
                        id="select-from-switch" 
                        class="form-select form-control" 
                        (change)="onSelectionFromValueChange($event.target.value)"
                    >
                        <option value=""></option>
                        <option value="true" >
                            {{ '::Enum:SelectUsersFrom:Users' | abpLocalization }}
                        </option>
                        <option value="false" >
                            {{ '::Enum:SelectUsersFrom:OrganizationUnits' | abpLocalization }}
                        </option>
                    </select>

                </div>

                <div class="form-group mb-3" *ngIf="selectFromUsers">
                    <label class="form-label" for="ticket-users-id">
                        {{ '::Users' | abpLocalization }} 
                    </label> <span> *</span>

                    <input
                        class="form-control" 
                        id="ticket-users-id"
                        type="search"
                        formControlName="assignedUserId"
                        [editable]="false"
                        [resultFormatter]="tenantFormatter"
                        [inputFormatter]="tenantFormatter"
                        [ngbTypeahead]="searchTenantForTicket"
                        (focus)="focusForTicketTenant$.next($any($event).target.value)"
                        (click)="clickForTicketTenant$.next($any($event).target.value)"
                        (ngModelChange)="selectedNewTaskUsers($event)"
                        #instance="ngbTypeahead"
                        [abpDisabled]="disableTicketStatusField()"
                    />
                    <br/>
                    <button 
                        type="button" 
                        class="btn btn-primary"
                        (click)="selectAllTenantUsers()"
                        [disabled]="disableTicketStatusField()"
                    >
                        {{ '::SelectAll' | abpLocalization }}
                    </button>
                </div>

                <div class="form-group mb-3" *ngIf="selectFromOrganizationUnit">
                    <label class="form-label" for="organization-unit-create">
                        {{ '::OrganizationUnit' | abpLocalization}}
                    </label><span> *</span>

                    <ng-multiselect-dropdown
                        name="organization-unit"
                        id="ng-organization-unit-create"
                        [data]="dropdownList"
                        formControlName="organizationUnitId"
                        (onSelect)="onOrganizationUnitCreateSelect($event)"
                        (onDeSelect)="onOrganizationUnitCreateDeSelect($event)"
                        (onFilterChange)="organizationUnitFilterChange($event)"
                        [settings]="dropdownSettings">
                    </ng-multiselect-dropdown>

                    <br/>
                    <button 
                        type="button" 
                        class="btn btn-primary"
                        (click)="selectUsersFromSelectedOrganizationUnit()"
                    >
                        {{ '::Select' | abpLocalization }}
                    </button>
                </div>

                <ngx-datatable 
                    [rows]="selectedUsers.items" 
                    [count]="selectedUsers.totalCount" 
                    [limit]="5"
                    #width
                    default
                >

                    <ngx-datatable-column
                        [name]="'::Actions' | abpLocalization"
                        [maxWidth]="80"
                        [sortable]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div container="body" class="d-inline-block">
                            <button
                                class="btn btn-danger btn-sm"
                                aria-haspopup="true"
                                (click)="removeAssignedUser(row)"
                                type="button"
                                [disabled]="disableTicketStatusField()"
                            >
                                <i class="fa fa-trash"></i>
                            </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column
                        [name]="'::UserName' | abpLocalization"
                        prop="userName"
                    >
                    </ngx-datatable-column>

                </ngx-datatable>

            </div>

            <div class="form-group mb-3">
                <label for="task-status" class="form-label">{{ '::Status' | abpLocalization }}</label> <span> *</span>
                <select 
                    id="task-status" 
                    class="form-select form-control" 
                    formControlName="taskStatus" 
                    [abpDisabled]="isView || disableTicketStatusField()"
                >
                    <option value=""></option>
                    <option [ngValue]="status.value" *ngFor="let status of statusTypes">
                        {{ '::Enum:TaskStatus:' + status.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3">
                <label for="task-start-time">{{ '::StartDateTime' | abpLocalization }}</label><span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input 
                            id="task-start-time"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            formControlName="startDate"
                            ngbDatepicker
                            (click)="datepickerStart.toggle()"
                            [abpDisabled]="isView"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            [abpDisabled]="!this.createUpdateTicketForm.get('startDate').value"
                            formControlName="startTime"
                            [abpDisabled]="isView"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="task-end-time">{{ '::EndDateTime' | abpLocalization }}</label> <span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input
                            id="task-end-time"
                            #datepickerEnd="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerEnd"
                            formControlName="endDate"
                            ngbDatepicker
                            (click)="datepickerEnd.toggle()"
                            [abpDisabled]="isView"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            [abpDisabled]="!this.createUpdateTicketForm.get('endDate').value"
                            formControlName="endTime"
                            [abpDisabled]="isView"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label for="task-kind" class="form-label">{{ '::TaskKind' | abpLocalization }}</label><span> *</span>
                <select 
                    id="task-kind" 
                    class="form-select form-control" 
                    formControlName="ticketKind" 
                    [abpDisabled]="isView"
                    (change)="getSelectedTaskKind($event.target.value)"
                >
                    <option [value]="null"></option>
                    <option [value]="kind.value" *ngFor="let kind of ticketKinds">
                        {{ '::Enum:TaskKind:' + kind.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3" [hidden]="!isTicketEducationKindShown">
                <label for="task-kind" class="form-label">{{ '::TicketEducationKind' | abpLocalization }}</label><span> *</span>
                <select 
                    id="task-kind" 
                    class="form-select form-control" 
                    formControlName="ticketEducationKind" 
                    [abpDisabled]="isView"
                    (change)="getSelectedTaskEducationKind($event.target.value)"
                >
                    <option value=""></option>
                    <option [value]="educationKind.value" *ngFor="let educationKind of ticketEducationKinds">
                        {{ '::Enum:EducationKind:' + educationKind.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3" [hidden]="!isTicketDocumentApprovalSelected && isDocumentShownForApproval == null">
                <div [hidden]="!isDocumentApproval()">
                    <label for="task-document-approval" class="form-label"> 
                        {{ '::SelectDocumentForApproval' | abpLocalization }}
                    </label>
                    <span [hidden]="!documentHasRequiredValidator()"> *</span>
                    <div class="input-group">
                        <input
                            class="form-control" 
                            id="task-document-approval"
                            type="search"
                            class="form-control"
                            formControlName="document"
                            [editable]="false"
                            [resultFormatter]="documentFormatter"
                            [inputFormatter]="documentFormatter"
                            [ngbTypeahead]="searchForNotApprovedDocumentsForTicket"
                            (focus)="focusForTicketDocumentWithApprovalData$.next($any($event).target.value)"
                            (click)="clickForTicketDocumentWithApprovalData$.next($any($event).target.value)"
                            #instance="ngbTypeahead"
                        />
                    </div>
                </div>

                <div [hidden]="!isCourseApproval()">
                    <label for="task-course-approval" class="form-label"> 
                        {{ '::SelectCourseForApproval' | abpLocalization }}
                    </label>
                    <span [hidden]="!courseHasRequiredValidator()"> *</span>
                    <div class="input-group">
                        <input
                            class="form-control" 
                            id="task-course-approval"
                            type="search"
                            class="form-control"
                            formControlName="course"
                            [editable]="false"
                            [resultFormatter]="courseForApprovalFormatter"
                            [inputFormatter]="courseForApprovalFormatter"
                            [ngbTypeahead]="searchForNotApproveCoursesForTicket"
                            (focus)="focusForTicketCourseWithApprovalData$.next($any($event).target.value)"
                            (click)="clickForTicketCourseWithApprovalData$.next($any($event).target.value)"
                            #instance="ngbTypeahead"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group mb-3" [hidden]="!isCourseSelectShown()">
                <label for="task-course" class="form-label"> {{ '::SelectCourseForTask' | abpLocalization }} </label>
                <span *ngIf="courseHasRequiredValidator()"> *</span>
                <div class="input-group">
                    <input
                        class="form-control" 
                        id="typeahead-focus"
                        type="search"
                        class="form-control"
                        formControlName="course"
                        [editable]="false"
                        [resultFormatter]="courseFormatter"
                        [inputFormatter]="courseFormatter"
                        [ngbTypeahead]="searchCourseForTicket"
                        (focus)="focusForTicketCourse$.next($any($event).target.value)"
                        (click)="clickForTicketCourse$.next($any($event).target.value)"
                        #instance="ngbTypeahead"
                        [abpDisabled]="isView"
                    />
                </div>
            </div>

            <div class="form-group mb-3" [hidden]="!isDocumentSelectShown()">
                <label for="task-document" class="form-label"> {{ '::SelectDocumentForTask' | abpLocalization }}</label>
                <span *ngIf="documentHasRequiredValidator()"> *</span>
                <div class="input-group">
                    <input
                        class="form-control" 
                        id="typeahead-focus"
                        type="search"
                        class="form-control"
                        formControlName="document"
                        [editable]="false"
                        [resultFormatter]="documentFormatter"
                        [inputFormatter]="documentFormatter"
                        [ngbTypeahead]="searchDocumentForTicket"
                        (focus)="focusForTicketDocument$.next($any($event).target.value)"
                        (click)="clickForTicketDocument$.next($any($event).target.value)"
                        #instance="ngbTypeahead"
                        [abpDisabled]="isView"
                    />
                </div>
            </div>
        </form>
    </ng-template>
    
    <ng-template *abpPermission="'Tickets.Tickets.Edit || Tickets.Tickets.Delete || Tickets.Tickets.Create'" #abpFooter>
        <button type="button" class="btn btn-secondary" abpClose>
            {{ '::Close' | abpLocalization}}
        </button>

        <button 
            type="button" 
            (click)="deleteTicket(this.selectedTicket.id)"
            *ngIf="this.selectedTicket.id && deletePermission"
            class="btn btn-warning">
            {{ '::Delete' | abpLocalization }}
        </button>

        <button 
            class="btn btn-primary" 
            (click)="saveTicket()"
            *ngIf="!isView"
            [disabled]="createUpdateTicketForm.invalid"
        >
            {{ '::Save' | abpLocalization}}
        </button>
    </ng-template>
</abp-modal>