import { FormControl } from "@angular/forms";

export function requiredFileTypes(types: string[]) {
    return function (control: FormControl) {
        const file = control.value;
        if (file) {
            const extension: string[] = file.split('.');
            if (!types.includes(extension[extension.length - 1])) {
                return {
                    requiredFileTypes: true
                };
            }

            return null;
        }

        return null;
    }
}