
export abstract class Constants {
    static readonly DATETIME: string = "YYYY-MM-d HH:mm";
    static readonly DATE: string = "YYYY-MM-dd"
}

export abstract class FreeFieldName {
    static readonly Name: string = "Laisvas laukas";
}
export abstract class FreeFieldName1 {
    static readonly Name: string = "Laisvas laukas (1)";
}
export abstract class FreeFieldName2 {
    static readonly Name: string = "Laisvas laukas (2)";
}
export abstract class FreeFieldName3 {
    static readonly Name: string = "Laisvas laukas (3)";
}
export abstract class FreeFieldName4 {
    static readonly Name: string = "Laisvas laukas (4)";
}
export abstract class FreeFieldName5 {
    static readonly Name: string = "Laisvas laukas (5)";
}
export abstract class FreeFieldName6 {
    static readonly Name: string = "Laisvas laukas (6)";
}
export abstract class FreeFieldName7 {
    static readonly Name: string = "Laisvas laukas (7)";
}
export abstract class FreeFieldName8 {
    static readonly Name: string = "Laisvas laukas (8)";
}
export abstract class FreeFieldName9 {
    static readonly Name: string = "Laisvas laukas (9)";
}
export abstract class FreeFieldName10 {
    static readonly Name: string = "Laisvas laukas (10)";
}
export abstract class FreeFieldName11 {
    static readonly Name: string = "Laisvas laukas (11)";
}
export abstract class FreeFieldName12 {
    static readonly Name: string = "Laisvas laukas (12)";
}
export abstract class FreeFieldName13 {
    static readonly Name: string = "Laisvas laukas (13)";
}
export abstract class FreeFieldName14 {
    static readonly Name: string = "Laisvas laukas (14)";
}