import { TestAttemptEntity } from './../proxy/test-attempts/test-attempt-entity.enum';
import { DocumentDownloadService } from './../proxy/documents/document-download.service';
import { downloadBlob, ConfigStateService, ListService, LocalizationService, PagedResultDto, ListResultDto, PermissionService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseDocumentDto, DocumentDto, DocumentsService, DocumentStatus, documentStatusOptions, DocumentTagDto, renewalPeriodOptions, UploadDocumentsDto} from '@proxy/documents';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { allowedFileTypes } from 'src/environments/environment';
import { 
  Constants, 
  FreeFieldName, 
  FreeFieldName1, 
  FreeFieldName10, 
  FreeFieldName11, 
  FreeFieldName12, 
  FreeFieldName13, 
  FreeFieldName14, 
  FreeFieldName2, 
  FreeFieldName3, 
  FreeFieldName4, 
  FreeFieldName5, 
  FreeFieldName6, 
  FreeFieldName7, 
  FreeFieldName8, 
  FreeFieldName9 
} from '../shared/Constants/Constants';
import { BaseCategoryDto, CategoriesLookupService } from '@proxy/categories';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TenantLookupDto, TenantLookupService } from '@proxy/tenants';
import { CustomDocumentDto, CustomDocumentService } from './services/document.service';
import { requiredFileTypes } from '../shared/FileTypeValidation/file.type.validation';
import { GeneratorTagDto, GeneratorTagLookupService } from '@proxy/generator-tags';
import { TestLookupDto, TestLookupService } from '@proxy/tests';
import { Router } from '@angular/router';
import { TenantHostDocumentDto } from '@proxy/documents/tenant-host-document';
import { TenantDocumentService } from '@proxy/tenant-documents';
import { MissingDocumentDto } from '@proxy/documents/tenant-documents';
import { DatePipe } from '@angular/common';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { allowedFileSize } from '../shared/FileSizeValidation/file.size.validation';
import { DocumentApprovalDto, DocumentApprovalSettingsService } from '@proxy/documents-approval';
import { CreateMultiSelectTicketDto, TicketEducationKind, TicketKind, TicketStatus, TicketsService, ticketKindOptions, ticketStatusOptions } from '@proxy/tickets';
import { TenantUserLookupDto, UserLookupDto } from '@proxy/enova-users-lookup';
import { EnovaUserLookupService } from '@proxy/enova-user-lookup';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  providers: [ListService, DatePipe,
    {
      provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    }]
})
export class DocumentComponent implements OnInit{
  @ViewChild("takeInput", {static: false}) InputVar: ElementRef;

  tags = { items: [], totalCount: 0 } as PagedResultDto<DocumentTagDto>;
  missingDocuments = { items: [], totalCount: 0 } as PagedResultDto<MissingDocumentDto>;
  
  isAdmin = false;
  hostUsers: UserLookupDto[] = [];

  selectedDocuments: BaseDocumentDto[] = [];

  ticketKinds = ticketKindOptions;

  selectedUsers = {
    items: [],
    totalCount: 0
  } as PagedResultDto<TenantUserLookupDto>;

  tenantUsersLookup: TenantUserLookupDto[] = [];

  tenantHasMissingDocuments: boolean = false;
  isGrantedPermissionForSelectingTags: boolean;
  isGrantedPermissionForSettingProductPageUrl: boolean;
  focusForCategorySearch$ = new Subject<string>();
  clickForCategorySearch$ = new Subject<string>();
  focusForCategoryFilter$ = new Subject<string>();
  clickForCategoryFilter$ = new Subject<string>();
  isActiveTab: number = 0;
  focusForTestSearch$ = new Subject<string>();
  clickForTestSearch$ = new Subject<string>();
  focusForTestFilter$ = new Subject<string>();
  clickForTestFilter$ = new Subject<string>();
  focusForUserSearch$ = new Subject<string>();
  focusForTicketTenantUser$ = new Subject<string>();
  clickForTicketTenantUser$ = new Subject<string>();
  clickForUserSearch$ = new Subject<string>();
  testId: string;
  isView: boolean = false;
  categoryId: string;
  documentId: string;
  allCategories: BaseCategoryDto[] = [];
  allTests: TestLookupDto[] = [];
  availableTests: TestLookupDto[] = [];
  tenants$: Observable<TenantLookupDto[]>;
  generatorTags: ListResultDto<GeneratorTagDto>;
  tenantDocument = { items: [], totalCount: 0 } as PagedResultDto<TenantHostDocumentDto>;
  ecommerceUrl: string = "";
  form: UntypedFormGroup;
  copyForm: UntypedFormGroup;
  uploadForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  multiSelectTaskForm: UntypedFormGroup;
  selectedDocument: DocumentDto;
  fileName: string = "";
  currentUserTenantId: string;

  responsibleUserForApproval: DocumentApprovalDto;

  documentStatusTypes =  documentStatusOptions;
  isCurrentUserResponsibleForApproval: boolean = false;
  isTicketDocumentApprovalSelected: boolean = false;

  freeFieldSelected: boolean = false;
  freeFieldSelected1: boolean = false;
  freeFieldSelected2: boolean = false;
  freeFieldSelected3: boolean = false;
  freeFieldSelected4: boolean = false;
  freeFieldSelected5: boolean = false;
  freeFieldSelected6: boolean = false;
  freeFieldSelected7: boolean = false;
  freeFieldSelected8: boolean = false;
  freeFieldSelected9: boolean = false;
  freeFieldSelected10: boolean = false;
  freeFieldSelected11: boolean = false;
  freeFieldSelected12: boolean = false;
  freeFieldSelected13: boolean = false;
  freeFieldSelected14: boolean = false;
  renewalPeriods = renewalPeriodOptions;

  AVAILABLE_UPLOAD_SIZE: number = 500000000;

  isModalOpen = false;
  isCopyModalOpen = false;
  isMultiSelectModalOpen = false;
  isUploadModalOpen = false;

  isLoading = false;

  modalBusy = false;

  today: Date = new Date();
  weekFromToday: Date = new Date();

  activeDocumentService: TenantDocumentService | DocumentsService;

  formatter = (category: BaseCategoryDto) => category.name;
  tenantFormatter = (tenant: TenantUserLookupDto) => tenant.userName;
  userFormatter = (user: UserLookupDto) => user.userName;

  searchForCategory: OperatorFunction<string, readonly BaseCategoryDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForCategorySearch$, this.clickForCategorySearch$).pipe(
      map(term => (term === '' ? this.allCategories
        : this.allCategories.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchDocumentByCategory: OperatorFunction<string, readonly BaseCategoryDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForCategoryFilter$, this.clickForCategoryFilter$).pipe(
      map(term => (term === '' ? this.allCategories
        : this.allCategories.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchForTest: OperatorFunction<string, readonly TestLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTestSearch$, this.clickForTestSearch$).pipe(
      map(term => (term === '' ? this.allTests
        : this.allTests.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchDocumentByTest: OperatorFunction<string, readonly TestLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTestFilter$, this.clickForTestFilter$).pipe(
      map(term => (term === '' ? this.availableTests
        : this.availableTests.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchTenantForTicketUsers: OperatorFunction<string, readonly TenantUserLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  
    return merge(debouncedText$, this.focusForTicketTenantUser$, this.clickForTicketTenantUser$).pipe(
      map(term => (term === '' ? this.tenantUsersLookup
        : this.tenantUsersLookup.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchDocumentByUser: OperatorFunction<string, readonly UserLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForUserSearch$, this.clickForUserSearch$).pipe(
        map(term => (term === '' ? this.hostUsers
        : this.hostUsers.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
        )
    );
};

  constructor(
    public readonly list: ListService, 
    private readonly customDocumentService: CustomDocumentService,
    private enovaUserLookupService: EnovaUserLookupService,
    private categoryLookupService: CategoriesLookupService,
    private testLookupService: TestLookupService,
    private tenantLookupService: TenantLookupService,
    private confirmation: ConfirmationService,
    private documentService: DocumentsService,
    private tenantDocumentService: TenantDocumentService,
    private documentApprovalSettingsService: DocumentApprovalSettingsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private documentDownloadService: DocumentDownloadService,
    private generatorTagLookupService: GeneratorTagLookupService,
    private ticketsService: TicketsService,
    private permissionService: PermissionService,
    private config: ConfigStateService,
    private router: Router,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder
  ) 
  {
    this.tenants$ = this.tenantLookupService.getTenantLookup().pipe(map(r => r.items));
    this.isGrantedPermissionForSelectingTags = this.permissionService.getGrantedPolicy('E-Document.Documents.SelectTag');

    this.isGrantedPermissionForSettingProductPageUrl =
      this.permissionService.getGrantedPolicy('E-Document.Documents.AddProductPageUrl');
    
    if(this.isGrantedPermissionForSelectingTags){
      this.generatorTagLookupService.getGeneratorTagLookup().subscribe((response) => {
        this.generatorTags = response;
      })
    }

    this.ecommerceUrl = this.config.getSetting("Enova.DefaultEcommerceUrl");
  }

  get dateFormat() { return Constants.DATETIME }

  get allowedFileTypesFormattedString() {
    return allowedFileTypes.types.map(e => `.${e}`).join();;
  }

  ngOnInit(): void {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;

    const canCreate = this.permissionService.getGrantedPolicy('Tickets.Tickets.Create');

    if(canCreate){
      this.ticketsService.getTenantUserLookup().subscribe((response) => {
        this.tenantUsersLookup = response.items;
      });
    }

    this.selectedDocuments = [];
    this.buildFormForSearch();
    this.currentUserTenantId = this.config.getOne("currentUser").tenantId;
    var currentUser = this.config.getOne("currentUser");

    this.documentApprovalSettingsService.getDocumentApprovalInformation(true).subscribe((response) => {
      this.isCurrentUserResponsibleForApproval = response?.selectedUser?.id === currentUser.id;
      this.responsibleUserForApproval = response;
    });
    
    if(currentUser.tenantId == null){
      if(currentUser.roles.includes("admin")) {
        this.enovaUserLookupService.getUserLookup().subscribe((response) => {
          this.hostUsers = response.items;
        })
        this.isAdmin = true;
      }

      this.activeDocumentService = this.documentService;
      this.getDocumentListHandler();
    } else {

      this.activeDocumentService = this.tenantDocumentService;
      this.getDocumentListHandler();

      var canViewDocumentSuggestions = this.permissionService.getGrantedPolicy('E-Document.Documents.ViewProductSuggestions');
      if(canViewDocumentSuggestions){
        this.tenantDocumentService.getMissingDocumentsList().subscribe((response) => {
          this.missingDocuments.items = response.items;
          this.missingDocuments.totalCount = response.items.length;
          if(response.items.length > 0)
            this.tenantHasMissingDocuments = true;
        });
      }

    }
    
    this.categoryLookupService.getCategoryLookup().subscribe((response) => {
      this.allCategories = response.items;
    });

    this.testLookupService.getTestLookup().subscribe((response) => {
      this.allTests = response.items;
    });

    this.testLookupService.getAvailableTestLookup().subscribe((response) => {
      this.availableTests = response.items;
    });
  }

  buildFormForSearch() {
    this.filterForm = this.fb.group({
      category: [null],
      test: [null],
      availableUntil: [null],
      creationTimeFrom: [null],
      creationTimeTo: [null],
      documentStatus: [""],
      creatorId: [null]
    })
  }

  selectedUser(user: any){
    this.list.get();
  }

  getTruncatedDescription(description: string): string {
    const limit = 30;
    const ellipsis = '...';

    if (description == null || description.length <= limit) {
      return description;
    } else {
      return description.substring(0, limit) + ellipsis;
    }
  }

  clearFilters(){
    this.buildFormForSearch();
    this.list.get();
  }

  getDocumentListHandler() {
    const documentStreamCreator = (query) => 
      this.activeDocumentService.getList(
        {
          ...query, 
          categoryId: this.filterForm.get("category").value ? this.filterForm.get("category").value.id : null,
          testId: this.filterForm.get("test").value ? this.filterForm.get("test").value.id : null,
          availableUntil: this.filterForm.get("availableUntil").value ? this.datePipe.transform(this.filterForm.get("availableUntil").value, "yyyy-MM-dd") : null,
          creationDateFrom: this.filterForm.get("creationTimeFrom").value ? this.datePipe.transform(this.filterForm.get("creationTimeFrom").value, "yyyy-MM-dd") : null,
          creationDateTo: this.filterForm.get("creationTimeTo").value ? this.datePipe.transform(this.filterForm.get("creationTimeTo").value, "yyyy-MM-dd") : null,
          documentStatus: this.filterForm.get("documentStatus").value,
          creatorId: this.filterForm.get('creatorId').value ? this.filterForm.get('creatorId').value.id : null,
        }
      );
    
    this.list.hookToQuery(documentStreamCreator).subscribe((response) => {
      this.tenantDocument.items = response.items;
      this.tenantDocument.totalCount = response.totalCount;
    });
  }

  isFormShown(){
    if(this.isActiveTab === 0)
      return true;
    return false;
  }

  isTenantExist(){
    if(this.currentUserTenantId != null)
      return true;
    return false;
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.selectedDocument.name || '', Validators.required],
      code: [this.selectedDocument.code || '', Validators.required],
      description: [this.selectedDocument.description || ''],
      category: [this.selectedDocument.category || ''],
      file: [null, this.selectedDocument.fileDescriptorId ? 
        [requiredFileTypes(allowedFileTypes.types)] 
        : [Validators.required, requiredFileTypes(allowedFileTypes.types)]],
      fileSource: [null, [allowedFileSize(this.AVAILABLE_UPLOAD_SIZE)]],
      test: [this.selectedDocument.test || ''],
      productUrlPath: [this.selectedDocument.productUrlPath || ''],
      renewalPeriodNumber: [this.selectedDocument.renewalPeriodNumber || '', Validators.min(1)],
      availableUntil: [this.selectedDocument.availableUntil != null ? new Date(this.selectedDocument.availableUntil) : null],
      renewalPeriod: [this.selectedDocument.renewalPeriod || ''],

      status: [this.selectedDocument.status || DocumentStatus.NotApproved, Validators.required],
      approvalDate: [this.selectedDocument.approvalDate != null ? new Date(this.selectedDocument.approvalDate) : null ],

      freeField: [this.selectedDocument.freeField || ''],
      freeFieldFillingInstruction: [this.selectedDocument.freeFieldFillingInstruction || ''],
      freeField1: [this.selectedDocument.freeField1 || ''],
      freeFieldFillingInstruction1: [this.selectedDocument.freeFieldFillingInstruction1 || ''],
      freeField2: [this.selectedDocument.freeField2 || ''],
      freeFieldFillingInstruction2: [this.selectedDocument.freeFieldFillingInstruction2 || ''],
      freeField3: [this.selectedDocument.freeField3 || ''],
      freeFieldFillingInstruction3: [this.selectedDocument.freeFieldFillingInstruction3 || ''],
      freeField4: [this.selectedDocument.freeField4 || ''],
      freeFieldFillingInstruction4: [this.selectedDocument.freeFieldFillingInstruction4 || ''],
      freeField5: [this.selectedDocument.freeField5 || ''],
      freeFieldFillingInstruction5: [this.selectedDocument.freeFieldFillingInstruction5 || ''],
      freeField6: [this.selectedDocument.freeField6 || ''],
      freeFieldFillingInstruction6: [this.selectedDocument.freeFieldFillingInstruction6 || ''],
      freeField7: [this.selectedDocument.freeField7 || ''],
      freeFieldFillingInstruction7: [this.selectedDocument.freeFieldFillingInstruction7 || ''],
      freeField8: [this.selectedDocument.freeField8 || ''],
      freeFieldFillingInstruction8: [this.selectedDocument.freeFieldFillingInstruction8 || ''],
      freeField9: [this.selectedDocument.freeField9 || ''],
      freeFieldFillingInstruction9: [this.selectedDocument.freeFieldFillingInstruction9 || ''],
      freeField10: [this.selectedDocument.freeField10 || ''],
      freeFieldFillingInstruction10: [this.selectedDocument.freeFieldFillingInstruction10 || ''],
      freeField11: [this.selectedDocument.freeField11 || ''],
      freeFieldFillingInstruction11: [this.selectedDocument.freeFieldFillingInstruction11 || ''],
      freeField12: [this.selectedDocument.freeField12 || ''],
      freeFieldFillingInstruction12: [this.selectedDocument.freeFieldFillingInstruction12 || ''],
      freeField13: [this.selectedDocument.freeField13 || ''],
      freeFieldFillingInstruction13: [this.selectedDocument.freeFieldFillingInstruction13 || ''],
      freeField14: [this.selectedDocument.freeField14 || ''],
      freeFieldFillingInstruction14: [this.selectedDocument.freeFieldFillingInstruction14 || '']
    })
  }

  buildMultiselectFrom() {
    this.multiSelectTaskForm = this.fb.group({
      name: [this.localizationService.instant("::Ticket:Default:Name"), Validators.required],
      description: [''],
      assignedUserId: [null, Validators.required],
      taskStatus: [TicketStatus.New, Validators.required],
      ticketKind: [null],
      ticketEducationKind: [null],
      document: [null],
      startDate: [this.today, Validators.required],
      startTime: [
        {
          hour: 12, 
          minute: 0
        }
      ],
      endDate: [this.weekFromToday, Validators.required],
      endTime: [
        {
          hour: 12,
          minute: 0
        }
      ]
    });

    this.multiSelectTaskForm.get('ticketKind').valueChanges.subscribe((kind: TicketKind) => {
      if(kind == TicketKind.Education) {
        this.multiSelectTaskForm.controls["ticketEducationKind"].setValue(TicketEducationKind.Document);
        this.multiSelectTaskForm.controls["ticketEducationKind"].updateValueAndValidity();
      } else {
        this.multiSelectTaskForm.controls["ticketEducationKind"].setValue(null);
        this.multiSelectTaskForm.controls["ticketEducationKind"].updateValueAndValidity();
      }

      if(kind == TicketKind.DocumentApproval) {    
        if(this.responsibleUserForApproval == null) {
          this.multiSelectTaskForm.controls["ticketKind"].setValue(null);
          this.multiSelectTaskForm.controls["ticketKind"].updateValueAndValidity();
  
          this.toaster.warn(
            this.localizationService.instant("::TicketForApproval:Error:00001")
          );
  
          return;
        }
      }
  
      this.isDocumentForApprovalSelectShown(kind);
    });
  }

  getAvailableUntilDate() {
    let availableUntil = this.form.get('availableUntil').value;
    availableUntil.setHours(0);
    availableUntil.setMinutes(0);

    return availableUntil
  }

  buildCopyForm(){
    this.copyForm = this.fb.group({
      tenantId: [null, Validators.required]
    });
  }

  buildUploadForm(){
    this.uploadForm = this.fb.group({
      tenantId: [null, Validators.required]
    });
  }

  onTagSelected(eventValue: string){
    if(eventValue == ""){
      return;
    }

    var dublicatedTag = this.tags.items.find(item => 
      item.generatorTagId == eventValue
    );

    if(dublicatedTag){
      this.toaster.warn(
        this.localizationService.instant("::GeneratorTag:Warning:00001")
      );
      return;
    }

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName.Name){
      this.freeFieldSelected = true;
      this.addValidityForFreeField(0);
    }
    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName1.Name){
      this.freeFieldSelected1 = true;
      this.addValidityForFreeField(1);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName2.Name){
      this.freeFieldSelected2 = true;
      this.addValidityForFreeField(2)
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName3.Name){
      this.freeFieldSelected3 = true;
      this.addValidityForFreeField(3);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName4.Name){
      this.freeFieldSelected4 = true;
      this.addValidityForFreeField(4);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName5.Name){
      this.freeFieldSelected5 = true;
      this.addValidityForFreeField(5);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName6.Name){
      this.freeFieldSelected6 = true;
      this.addValidityForFreeField(6);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName7.Name){
      this.freeFieldSelected7 = true;
      this.addValidityForFreeField(7);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName8.Name){
      this.freeFieldSelected8 = true;
      this.addValidityForFreeField(8);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName9.Name){
      this.freeFieldSelected9 = true;
      this.addValidityForFreeField(9);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName10.Name){
      this.freeFieldSelected10 = true;
      this.addValidityForFreeField(10);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName11.Name){
      this.freeFieldSelected11 = true;
      this.addValidityForFreeField(11);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName12.Name){
      this.freeFieldSelected12 = true;
      this.addValidityForFreeField(12);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName13.Name){
      this.freeFieldSelected13 = true;
      this.addValidityForFreeField(13);
    } 

    if(this.generatorTags.items.find(x => x.id == eventValue).tag == FreeFieldName14.Name){
      this.freeFieldSelected14 = true;
      this.addValidityForFreeField(14);
    } 

    this.tags.items.push(
      {
        generatorTagId: this.generatorTags.items.find(x => x.id == eventValue).id,
        generatorTag: this.generatorTags.items.find(x => x.id == eventValue).tag
      } as DocumentTagDto
    );

    this.tags.items = [...this.tags.items];
    this.tags.totalCount++; 
  }

  removeTag(eventValue: any){
    this.tags.items.forEach((element, index) => {
      if(element.generatorTagId == eventValue.generatorTagId){
        if(element.generatorTag == FreeFieldName.Name)
          this.resetFreeFieldControls(0);

        if(element.generatorTag == FreeFieldName1.Name)
          this.resetFreeFieldControls(1);

        if(element.generatorTag == FreeFieldName2.Name)
          this.resetFreeFieldControls(2);

        if(element.generatorTag == FreeFieldName3.Name)
          this.resetFreeFieldControls(3);

        if(element.generatorTag == FreeFieldName4.Name)
          this.resetFreeFieldControls(4);

        if(element.generatorTag == FreeFieldName5.Name)
          this.resetFreeFieldControls(5);

        if(element.generatorTag == FreeFieldName6.Name)
          this.resetFreeFieldControls(6);

        if(element.generatorTag == FreeFieldName7.Name)
          this.resetFreeFieldControls(7);

        if(element.generatorTag == FreeFieldName8.Name)
          this.resetFreeFieldControls(8);
        
        if(element.generatorTag == FreeFieldName9.Name)
          this.resetFreeFieldControls(9);

        if(element.generatorTag == FreeFieldName10.Name)
          this.resetFreeFieldControls(10);

        if(element.generatorTag == FreeFieldName11.Name)
          this.resetFreeFieldControls(11);

        if(element.generatorTag == FreeFieldName12.Name)
          this.resetFreeFieldControls(12);

        if(element.generatorTag == FreeFieldName13.Name)
          this.resetFreeFieldControls(13);

        if(element.generatorTag == FreeFieldName14.Name)
          this.resetFreeFieldControls(14);

        this.tags.items.splice(index, 1);
        this.tags.totalCount--;
        this.tags.items = [...this.tags.items];
      }
    })
  }

  setActiveTab(index: number){
    this.isActiveTab = index;
  }

  selectedItem(item: any){
    this.categoryId = item.item.id;
    this.list.get();
  }

  getListWhenCategoryInputEmpty(event: any){
    if(event == ''){
      this.categoryId = null;
      this.list.get();
    }
  }
  
  getListWhenUserInputEmpty(event: any){
    if(event == ''){
        this.list.get();
    }
  }

  getListWithFilters(){
    this.getDocumentListHandler();
  }

  selectedTestItem(item: any){
    this.testId = item.item.id;
    this.list.get();
  }

  getListWhenTestInputEmpty(event: any){
    if(event == ''){
      this.testId = null;
      this.list.get();
    }
  }

  attemptDocumentTest(testId: string, documentId: string){
    this.router.navigate(["test/attempt"], {queryParams: {
      id: testId,
      entity: TestAttemptEntity.Document,
      pointer: documentId
    }});
  } 

  viewDocument(documentId: string, tenantDocumentId: string){
    this.isView = true;
    this.isActiveTab = 0;
    if(this.config.getOne("currentUser").tenantId == null) {
      this.viewDocumentHandler(documentId);
    } else {
      this.viewDocumentHandler(tenantDocumentId);
    }
  }

  viewDocumentPage(documentId: string, tenantDocumentId: string){
    if(this.config.getOne("currentUser").tenantId == null){
      this.router.navigate(['documents/view', documentId])
    } else {
      this.router.navigate(['documents/view', tenantDocumentId])
    }
  }

  viewDocumentHandler(tenantDocumentId: string){
    this.activeDocumentService.get(tenantDocumentId).subscribe((document) => {
      this.selectedDocument = document;
      this.tags.items = document.documentTags;
      this.tags.totalCount = document.documentTags.length;

      if (this.selectedDocument.fileDescriptor != null) {
        this.fileName = this.selectedDocument.fileDescriptor.name;
      }
      this.isMultiSelectModalOpen = false;
      this.buildForm();
      this.isModalOpen = true;
    });
  }

  goToDocumentProductPage(productPath: string){
    window.open(`${this.ecommerceUrl}${productPath}`, "_blank");
  }

  goToProductPage(){
    window.open(`${this.ecommerceUrl}${this.selectedDocument.productUrlPath}`, "_blank");
  }

  getDocumentViewFeature(): boolean {
    return this.config.getFeature("Document.View") == "true";
  }

  getDocumentControlFeature(): boolean {
    return this.config.getFeature("Document.Control") == "true";
  }

  getTestAttemptFeature(): boolean {
    return this.config.getFeature("Test.Attempt") == "true";
  }

  createDocument() {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.selectedDocuments = [];
    this.isMultiSelectModalOpen = false;
    this.tags = { items: [], totalCount: 0} as PagedResultDto<DocumentTagDto>;
    this.isActiveTab = 0;
    this.isView = false;
    this.freeFieldSelected = false;
    this.freeFieldSelected1 = false;
    this.freeFieldSelected2 = false;
    this.freeFieldSelected3 = false;
    this.freeFieldSelected4 = false;
    this.freeFieldSelected5 = false;
    this.freeFieldSelected6 = false;
    this.freeFieldSelected7 = false;
    this.freeFieldSelected8 = false;
    this.freeFieldSelected9 = false;
    this.freeFieldSelected10 = false;
    this.freeFieldSelected11 = false;
    this.freeFieldSelected12 = false;
    this.freeFieldSelected13 = false;
    this.freeFieldSelected14 = false;
    this.selectedDocument = {} as DocumentDto;
    this.buildForm();
    this.isModalOpen = true;
  }

  editDocument(documentId: string, tenandDocumentId: string) {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.selectedDocuments = [];
    this.isMultiSelectModalOpen = false;
    this.isActiveTab = 0;
    this.isView = false;
    this.freeFieldSelected = false;
    this.freeFieldSelected1 = false;
    this.freeFieldSelected2 = false;
    this.freeFieldSelected3 = false;
    this.freeFieldSelected4 = false;
    this.freeFieldSelected5 = false;
    this.freeFieldSelected6 = false;
    this.freeFieldSelected7 = false;
    this.freeFieldSelected8 = false;
    this.freeFieldSelected9 = false;
    this.freeFieldSelected10 = false;
    this.freeFieldSelected11 = false;
    this.freeFieldSelected12 = false;
    this.freeFieldSelected13 = false;
    this.freeFieldSelected14 = false;

    if(this.config.getOne("currentUser").tenantId == null) {
      this.editDocumentHandler(documentId);
    } else {
      this.editDocumentHandler(tenandDocumentId);
    }
  }

  showAvailableUntilField(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      return false;
    }

    var correctRole = false;

    currentUser.roles.forEach(element => {
        if(element == "DSS Specialistas") {
            correctRole = true;
        }
    });

    return correctRole;
  }

  isCurrentTenantUserAdminOrDSSSpecialist(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      return false;
    }

    var correctRole = false;

    currentUser.roles.forEach(element => {
      if(element == "DSS Specialistas" || element == "admin") {
        correctRole = true;
      }
    });

    return correctRole;
  }

  isCurrentUserSuperAdmin(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      if(currentUser.roles.includes("admin")) {
        return true;
      }
    }

    return false;
  }

  isCurrentUserImpersonatedSuperAdmin(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.impersonatorTenantId == null && 
      currentUser.impersonatorTenantName == null && 
      currentUser.impersonatorUserId != null && 
      currentUser.impersonatorUserName != null && 
      currentUser.tenantId != null) {
      return true;
    }

    return false;
  }

  isDocumentForApprovalSelectShown(kind: TicketKind) {
    if (kind == TicketKind.DocumentApproval) {
      this.multiSelectTaskForm.controls["assignedUserId"].setValue(this.responsibleUserForApproval?.selectedUser.id);

      this.selectedUsers.items = [
        {
          userName: this.responsibleUserForApproval?.selectedUser?.userName, 
          id: this.responsibleUserForApproval?.selectedUser?.id
        }
      ];
      this.selectedUsers.totalCount = 1;
      
      this.isTicketDocumentApprovalSelected = true;
      return true;
    }

    this.isTicketDocumentApprovalSelected = false;

    return false;
  }

  isDocumentChecked(data): boolean {
    return this.selectedDocuments.length > 0 && this.selectedDocuments.some(document => document.id == data);
  }

  changeDocumentSelection(data, event) {
    const isSuperAdmin = this.isCurrentUserSuperAdmin();
    const isTenantAdminOrSpecialist = this.isCurrentTenantUserAdminOrDSSSpecialist();

    if (event) {
      if(isSuperAdmin){
        this.toggleSelection(data.id, data.name, data.code);
      }

      if(isTenantAdminOrSpecialist){
        this.toggleSelection(data.tenantDocumentId, null, null);
      }
    } else {
      if(isSuperAdmin){
        this.removeFromSelection(data.id);
      }

      if(isTenantAdminOrSpecialist){
        this.removeFromSelection(data.tenantDocumentId);
      }
    }
  }

  toggleSelection(id, name, code) {
    if (!this.selectedDocuments.some(document => document.id === id)) {
      this.selectedDocuments.push(
        {
          id: id,
          name: name,
          code: code
        } as BaseDocumentDto
      );
    } else {
      this.toaster.error(this.localizationService.instant('::Document:Selection:Already:Selected'));
    }
  }
  
  removeFromSelection(id) {
    const index = this.selectedDocuments.findIndex(document => document.id === id);
    if (index !== -1) {
      this.selectedDocuments.splice(index, 1);
    } else {
      this.toaster.error(this.localizationService.instant('::Document:Selection:Removed:Error'));
    }
  }

  showCreateApprovalTaskActionButton(documentStatus): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      return false;
    }

    if(documentStatus != DocumentStatus.NotApproved)
    {
      return false;
    }

    var correctRole = false;

    currentUser.roles.forEach(element => {
      if(element == "DSS Specialistas" || element == "admin") {
        correctRole = true;
      }
    });

    return correctRole;
  }

  openCreateDocumentMultiselectTaskModal() {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.isModalOpen = false;
    this.isCopyModalOpen = false;
    this.weekFromToday.setDate(this.today.getDate() + 7);
    this.buildMultiselectFrom();
    this.isMultiSelectModalOpen = true;
  }

  openUploadDocumentsModal() {
    this.buildUploadForm();
    this.isUploadModalOpen = true;
  }

  saveMultiSelectTask() {
    if(this.multiSelectTaskForm.invalid) {
      return;
    }

    this.ticketsService.createMultiSelectTasks(this.formMultiSelectData())
      .subscribe({
        next: () => {
          this.resetMultiSelectData();
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Document:Multiselect:Task:Create:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::Document:Multiselect:Task:Create:Error")
          );
        }
      });
  }

  resetMultiSelectData() {
    this.isMultiSelectModalOpen = false;
    this.multiSelectTaskForm.reset();
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.selectedDocuments = [];
  }

  formMultiSelectData() {
    return {
      name: this.multiSelectTaskForm.get('name').value,
      description: this.multiSelectTaskForm.get('description').value,
      status: TicketStatus.New,
      ticketKind: this.multiSelectTaskForm.get('ticketKind').value,
      ticketEducationKind: this.multiSelectTaskForm.get('ticketEducationKind').value,
      assignedUserIdsList: this.selectedUsers.items.map(user => user.id),
      assignedDataIdsList: this.selectedDocuments.map(document => document.id),
      startDateTime: this.datePipe.transform(this.getStartTime(), "yyyy-MM-ddTHH:mm:ss"),
      endDateTime: this.datePipe.transform(this.getEndTime(), "yyyy-MM-ddTHH:mm:ss"),
      ticketEntityKind: TicketEducationKind.Document
    }
  }

  getStartTime(){
    let startDateTime = this.multiSelectTaskForm.get('startDate').value;
    startDateTime.setHours(this.multiSelectTaskForm.get('startTime').value.hour);
    startDateTime.setMinutes(this.multiSelectTaskForm.get('startTime').value.minute);
    return startDateTime
  }

  getEndTime(){
    let endDateTime = this.multiSelectTaskForm.get('endDate').value;
    endDateTime.setHours(this.multiSelectTaskForm.get('endTime').value.hour);
    endDateTime.setMinutes(this.multiSelectTaskForm.get('endTime').value.minute);
    return endDateTime
  }

  selectAllTenantUsers(){
    this.ticketsService.getTenantUserLookup().subscribe((response) => {
      this.selectedUsers.items = response.items;
      this.selectedUsers.totalCount = response.items.length;

      this.checkIfSelectedUsers();
    })
  }

  isMultiSelectDataSelected() {
    return this.selectedDocuments.length > 0;
  }

  selectedNewTaskUsers(selectedUser: any) {
    if (selectedUser) {

      if(this.selectedUsers.totalCount > 0) {
        var isValuePresent = this.selectedUsers.items.find(x => x.userName == selectedUser.userName);
  
        if(isValuePresent){
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:User:Error:00001")
          );
    
          return;
        }
      }

      this.selectedUsers.items.push({userName: selectedUser.userName, id: selectedUser.id});

      this.selectedUsers.items = [...this.selectedUsers.items]
      this.selectedUsers.totalCount++;

      this.checkIfSelectedUsers();
    }
  }

  removeAssignedUser(eventValue: any) {

    this.selectedUsers.items.forEach((element, index) => {
      if(element.id == eventValue.id) {
        this.selectedUsers.items.splice(index, 1);
        this.selectedUsers.totalCount--;
        this.selectedUsers.items = [...this.selectedUsers.items];

        this.checkIfSelectedUsers();
      }
    });

  }

  checkIfSelectedUsers() {
    if(this.selectedUsers.totalCount == 0) {
      this.multiSelectTaskForm.controls["assignedUserId"].setValue(null);
      this.multiSelectTaskForm.controls["assignedUserId"].addValidators(Validators.required);
      this.multiSelectTaskForm.controls["assignedUserId"].updateValueAndValidity();
    } else {
      this.multiSelectTaskForm.controls["assignedUserId"].clearValidators();
      this.multiSelectTaskForm.controls["assignedUserId"].updateValueAndValidity();
    }
  }

  createTaskForDocumentApproval(tenantDocumentId) {
    this.confirmation.warn('::AreYouSureToCreateApprovalTask', '::AreYouSure').subscribe((status) => {
      if(status === Confirmation.Status.confirm){
        this.ticketsService.createTicketForDocumentApprovalByTenantDocumentId(tenantDocumentId).subscribe({
          complete: () => 
            this.toaster.success(
              this.localizationService.instant('::Document:Message:CreateApprovalTask:Success')
            ),
          error: () => (
            this.toaster.error(
              this.localizationService.instant('::Document:Message:CreateApprovalTask:Error')
            )
          )
        });
      }
    });
  }

  editDocumentHandler(id: string) {
    this.isMultiSelectModalOpen = false;
    this.activeDocumentService.get(id).subscribe((document) => {
      this.selectedDocument = document;

      this.tags.items = document.documentTags;
      this.tags.totalCount = document.documentTags.length;
      this.buildForm();

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName.Name)){
        this.freeFieldSelected = true;
        this.addValidityForFreeField(0);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName1.Name)){
        this.freeFieldSelected1 = true;
        this.addValidityForFreeField(1);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName2.Name)){
        this.freeFieldSelected2 = true;
        this.addValidityForFreeField(2);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName3.Name)){
        this.freeFieldSelected3 = true;
        this.addValidityForFreeField(3);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName4.Name)){
        this.freeFieldSelected4 = true;
        this.addValidityForFreeField(4);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName5.Name)){
        this.freeFieldSelected5 = true;
        this.addValidityForFreeField(5);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName6.Name)){
        this.freeFieldSelected6 = true;
        this.addValidityForFreeField(6);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName7.Name)){
        this.freeFieldSelected7 = true;
        this.addValidityForFreeField(7);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName8.Name)){
        this.freeFieldSelected8 = true;
        this.addValidityForFreeField(8);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName9.Name)){
        this.freeFieldSelected9 = true;
        this.addValidityForFreeField(9);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName10.Name)){
        this.freeFieldSelected10 = true;
        this.addValidityForFreeField(10);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName11.Name)){
        this.freeFieldSelected11 = true;
        this.addValidityForFreeField(11);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName12.Name)){
        this.freeFieldSelected12 = true;
        this.addValidityForFreeField(12);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName13.Name)){
        this.freeFieldSelected13 = true;
        this.addValidityForFreeField(13);
      }

      if(document.documentTags.find(x => x.generatorTag == FreeFieldName14.Name)){
        this.freeFieldSelected14 = true;
        this.addValidityForFreeField(14);
      }

      if (this.selectedDocument.fileDescriptor != null) {
        this.fileName = this.selectedDocument.fileDescriptor.name;
      }
      
      this.isModalOpen = true;
    });
  }

  deleteDocument(documentId: string){
    this.confirmation.warn('::AreYouSureToDeleteDocument', '::AreYouSure').subscribe((status) => {
      if(status === Confirmation.Status.confirm){
        this.documentService.delete(documentId).subscribe({
          next: () => this.list.get(),
          complete: () => {
            this.selectedDocuments = [];
            this.toaster.success(
              this.localizationService.instant('::Document:Message:Deleted:Success')
            )            
          },
          error: () => (
            this.toaster.error(
              this.localizationService.instant('::Document:Message:Deleted:Error')
            )
          )
        });
      }
    });
  }

  saveDocument() {
    if(this.form.invalid){
      return;
    }

    if(this.selectedDocument.id){
      this.updateSelectedDocument();
    } else {
      this.createNewDocument();
    }
  }

  saveDocumentCopy(){
    this.isLoading = true;

    if(this.copyForm.invalid){
      return;
    }

    this.documentService.copy(this.copyForm.get('tenantId').value, this.documentId).subscribe({
      next: () => {
        this.isCopyModalOpen = false;
        this.copyForm.reset();
        this.list.get()
      },
      complete: () => {
        this.isLoading = false;
        this.toaster.success(
          this.localizationService.instant('::Document:Message:Copy:Success')
        );
      },
      error: () => {
        this.isLoading = false;
        this.toaster.error(
          this.localizationService.instant('::Document:Message:Copy:Error')
        );
      }
    })
  }

  updateSelectedDocument() {
    this.modalBusy = true;
    var documentFile: File = this.form.controls["fileSource"].value;
    var request: CustomDocumentDto;

    request = {
      name: this.form.controls["name"].value,
      code: this.form.controls["code"].value,
      description: this.form.controls["description"].value,
      categoryId: this.form.get('category').value ? this.form.get('category').value.id : null,
      file: documentFile,
      documentTags: this.tags.items,
      testId: this.form.get('test').value ? this.form.get('test').value.id : null,
      productUrlPath: this.form.get('productUrlPath').value,
      renewalPeriodNumber: this.form.get("renewalPeriodNumber").value,
      renewalPeriod: this.form.get("renewalPeriod").value,
      availableUntil: this.form.get("availableUntil").value ? this.datePipe.transform(this.getAvailableUntilDate(), "yyyy-MM-ddTHH:mm:ss") : null,
      status: this.form.get("status").value,
      freeField: this.form.get('freeField').value,
      freeFieldFillingInstruction: this.form.get('freeFieldFillingInstruction').value,
      freeField1: this.form.get('freeField1').value,
      freeFieldFillingInstruction1: this.form.get('freeFieldFillingInstruction1').value,
      freeField2: this.form.get('freeField2').value,
      freeFieldFillingInstruction2: this.form.get('freeFieldFillingInstruction2').value,
      freeField3: this.form.get('freeField3').value,
      freeFieldFillingInstruction3: this.form.get('freeFieldFillingInstruction3').value,
      freeField4: this.form.get('freeField4').value,
      freeFieldFillingInstruction4: this.form.get('freeFieldFillingInstruction4').value,
      freeField5: this.form.get('freeField5').value,
      freeFieldFillingInstruction5: this.form.get('freeFieldFillingInstruction5').value,
      freeField6: this.form.get('freeField6').value,
      freeFieldFillingInstruction6: this.form.get('freeFieldFillingInstruction6').value,
      freeField7: this.form.get('freeField7').value,
      freeFieldFillingInstruction7: this.form.get('freeFieldFillingInstruction7').value,
      freeField8: this.form.get('freeField8').value,
      freeFieldFillingInstruction8: this.form.get('freeFieldFillingInstruction8').value,
      freeField9: this.form.get('freeField9').value,
      freeFieldFillingInstruction9: this.form.get('freeFieldFillingInstruction9').value,
      freeField10: this.form.get('freeField10').value,
      freeFieldFillingInstruction10: this.form.get('freeFieldFillingInstruction10').value,
      freeField11: this.form.get('freeField11').value,
      freeFieldFillingInstruction11: this.form.get('freeFieldFillingInstruction11').value,
      freeField12: this.form.get('freeField12').value,
      freeFieldFillingInstruction12: this.form.get('freeFieldFillingInstruction12').value,
      freeField13: this.form.get('freeField13').value,
      freeFieldFillingInstruction13: this.form.get('freeFieldFillingInstruction13').value,
      freeField14: this.form.get('freeField14').value,
      freeFieldFillingInstruction14: this.form.get('freeFieldFillingInstruction14').value
    }

    this.customDocumentService
    .update(this.selectedDocument.id, request)
      .subscribe({
        next: () =>{ 
          this.modalBusy = false;
          this.isModalOpen = false;
          this.form.reset();
          this.list.get();
          this.tags = {};
        },
        complete: () => {
          this.modalBusy = false;
          this.toaster.success(
            this.localizationService.instant("::Document:Message:Edited:Success")
          );
        },
        error: () => {
          this.modalBusy = false;
          this.toaster.error(
            this.localizationService.instant("::Document:Message:Edited:Error")
          )
        }
      });
  }

  copyDocument(documentId: string){
    this.documentId = documentId;
    this.buildCopyForm();
    this.isCopyModalOpen = true;
  }

  createNewDocument() {
    this.modalBusy = true;
    var documentFile: File = this.form.controls["fileSource"].value;

    var request: CustomDocumentDto;

    request = {
      name: this.form.controls["name"].value,
      code: this.form.controls["code"].value,
      description: this.form.controls["description"].value,
      categoryId: this.form.get('category').value ? this.form.get('category').value.id : null,
      file: documentFile,
      documentTags: this.tags.items,
      testId: this.form.get('test').value ? this.form.get('test').value.id : null,
      productUrlPath: this.form.get('productUrlPath').value,
      renewalPeriodNumber: this.form.get("renewalPeriodNumber").value,
      renewalPeriod: this.form.get("renewalPeriod").value,
      availableUntil: this.form.get("availableUntil").value ? this.datePipe.transform(this.getAvailableUntilDate(), "yyyy-MM-ddTHH:mm:ss") : null,
      status: this.form.get("status").value,
      freeField: this.form.get('freeField').value,
      freeFieldFillingInstruction: this.form.get('freeFieldFillingInstruction').value,
      freeField1: this.form.get('freeField1').value,
      freeFieldFillingInstruction1: this.form.get('freeFieldFillingInstruction1').value,
      freeField2: this.form.get('freeField2').value,
      freeFieldFillingInstruction2: this.form.get('freeFieldFillingInstruction2').value,
      freeField3: this.form.get('freeField3').value,
      freeFieldFillingInstruction3: this.form.get('freeFieldFillingInstruction3').value,
      freeField4: this.form.get('freeField4').value,
      freeFieldFillingInstruction4: this.form.get('freeFieldFillingInstruction4').value,
      freeField5: this.form.get('freeField5').value,
      freeFieldFillingInstruction5: this.form.get('freeFieldFillingInstruction5').value,
      freeField6: this.form.get('freeField6').value,
      freeFieldFillingInstruction6: this.form.get('freeFieldFillingInstruction6').value,
      freeField7: this.form.get('freeField7').value,
      freeFieldFillingInstruction7: this.form.get('freeFieldFillingInstruction7').value,
      freeField8: this.form.get('freeField8').value,
      freeFieldFillingInstruction8: this.form.get('freeFieldFillingInstruction8').value,
      freeField9: this.form.get('freeField9').value,
      freeFieldFillingInstruction9: this.form.get('freeFieldFillingInstruction9').value,
      freeField10: this.form.get('freeField10').value,
      freeFieldFillingInstruction10: this.form.get('freeFieldFillingInstruction10').value,
      freeField11: this.form.get('freeField11').value,
      freeFieldFillingInstruction11: this.form.get('freeFieldFillingInstruction11').value,
      freeField12: this.form.get('freeField12').value,
      freeFieldFillingInstruction12: this.form.get('freeFieldFillingInstruction12').value,
      freeField13: this.form.get('freeField13').value,
      freeFieldFillingInstruction13: this.form.get('freeFieldFillingInstruction13').value,
      freeField14: this.form.get('freeField14').value,
      freeFieldFillingInstruction14: this.form.get('freeFieldFillingInstruction14').value
    }

    this.customDocumentService.create(request).subscribe({
      next: () => {
        this.modalBusy = false;
        this.list.get();
        this.isModalOpen = false;
        this.form.reset();
        this.tags = {};
      },
      complete: () => {
        this.modalBusy = false;
        this.toaster.success(
          this.localizationService.instant("::Document:Message:Created:Success")
        );
      },
      error: () => {
        this.modalBusy = false;
        this.toaster.error(
          this.localizationService.instant('::Document:Message:Created:Error')
        )
      }
    });

  }

  filterListByName(searchValue: string){
    this.list.filter = searchValue;
    this.list.get();
  }

  onFileChanged(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.patchValue({
          fileSource: file
        });

        if(file.size > this.AVAILABLE_UPLOAD_SIZE) {
          this.form.controls["file"].setErrors({'allowedFileSize': true});
        }
      }
  }

  isShown(tenantId: string){
    if(tenantId == this.currentUserTenantId)
      return true;
    return false;
  }

  isShownForCopy(tenantId: string){
    if(tenantId != null)
      return true;
    return false;
  }

  uploadDocuments(){
    if(this.uploadForm.invalid)
      return;

    const uploadDocumentDto = {
      tenantId: this.uploadForm.get('tenantId').value, 
      documents: this.selectedDocuments
    } as UploadDocumentsDto;

    this.documentService.uploadDocumentsForTenant(uploadDocumentDto).subscribe({
      next: () => {
        this.uploadForm.reset();
      },
      complete: () => {
        this.isUploadModalOpen = false;
        this.selectedDocuments = [];
        this.toaster.success(
          this.localizationService.instant('::Document:Message:Upload:Success')
        );
      },
      error: () => {
        this.isUploadModalOpen = false;
        this.selectedDocuments = [];
        this.toaster.error(
          this.localizationService.instant('::Document:Message:Upload:Error')
        );
      }
    })
  }

  downloadFile(){
    if(this.isTenantExist()){
      const promise = this.documentDownloadService.downloadFileByDocumentId(this.selectedDocument.tenantDocumentId);
      this.documentService.checkForTenantTagsByDocumentId(this.selectedDocument.id).subscribe({ 
        next: () => {
            promise.subscribe((result) => {
              var extension = this.selectedDocument.fileDescriptor.name.split('.').pop();  
              var filename = this.selectedDocument.fileDescriptor.name;
              if(extension == "html"){
                filename = `${this.selectedDocument.fileDescriptor.name}.pdf`;
              }
              downloadBlob(result, filename);
            });
          },
        error: () => {
          this.toaster.error(
            this.localizationService.instant('::Document:Message:Download:Error')
          );
        }
      });
    }
    else{
      this.documentDownloadService.downloadFileByDocumentId(this.selectedDocument.id).subscribe((result) => {
        var filename = this.selectedDocument.fileDescriptor.name;
        downloadBlob(result, filename);
      });
    }
  }
  
  resetFreeFieldControls(fieldNum: number){
    if(fieldNum == 1)
      this.freeFieldSelected1 = false;
    if(fieldNum == 2)
      this.freeFieldSelected2 = false;
    if(fieldNum == 3)
      this.freeFieldSelected3 = false;
    if(fieldNum == 4)
      this.freeFieldSelected4 = false;
    if(fieldNum == 5)
      this.freeFieldSelected5 = false;
    if(fieldNum == 6)
      this.freeFieldSelected6 = false;
    if(fieldNum == 7)
      this.freeFieldSelected7 = false;
    if(fieldNum == 8)
      this.freeFieldSelected8 = false;
    if(fieldNum == 9)
      this.freeFieldSelected9 = false;
    if(fieldNum == 10)
      this.freeFieldSelected10 = false;
    if(fieldNum == 11)
      this.freeFieldSelected11 = false;
    if(fieldNum == 12)
      this.freeFieldSelected12 = false;
    if(fieldNum == 13)
      this.freeFieldSelected13 = false;
    if(fieldNum == 14)
      this.freeFieldSelected14 = false;

    if(fieldNum == 0){
      this.freeFieldSelected = false;
      this.form.controls['freeField'].setValidators(null);
      this.form.controls['freeFieldFillingInstruction'].setValidators(null);  
      this.form.controls['freeField'].markAsPristine();
      this.form.controls['freeFieldFillingInstruction'].markAsPristine();
      this.form.controls['freeField'].setValue("");
      this.form.controls['freeFieldFillingInstruction'].setValue(""); 
      this.form.controls['freeField'].updateValueAndValidity();
      this.form.controls['freeFieldFillingInstruction'].updateValueAndValidity(); 
    } else {
      this.form.controls[`freeField${fieldNum}`].setValidators(null);
      this.form.controls[`freeFieldFillingInstruction${fieldNum}`].setValidators(null);  
      this.form.controls[`freeField${fieldNum}`].markAsPristine();
      this.form.controls[`freeFieldFillingInstruction${fieldNum}`].markAsPristine();
      this.form.controls[`freeField${fieldNum}`].setValue("");
      this.form.controls[`freeFieldFillingInstruction${fieldNum}`].setValue(""); 
      this.form.controls[`freeField${fieldNum}`].updateValueAndValidity();
      this.form.controls[`freeFieldFillingInstruction${fieldNum}`].updateValueAndValidity(); 
    }
  }

  addValidityForFreeField(fieldNum: number){
    if(fieldNum == 0){
      this.form.controls['freeField'].addValidators(Validators.required);
      this.form.controls['freeFieldFillingInstruction'].addValidators(Validators.required);
      this.form.controls['freeField'].updateValueAndValidity();
      this.form.controls['freeFieldFillingInstruction'].updateValueAndValidity();
    } else {
      this.form.controls[`freeField${fieldNum}`].addValidators(Validators.required);
      this.form.controls[`freeFieldFillingInstruction${fieldNum}`].addValidators(Validators.required);
      this.form.controls[`freeField${fieldNum}`].updateValueAndValidity();
      this.form.controls[`freeFieldFillingInstruction${fieldNum}`].updateValueAndValidity();
    }
  }
}