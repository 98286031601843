import { TenantHostCourseDto } from './../proxy/courses/tenant-host-course/models';
import { ConfigStateService, ListService, LocalizationService, PagedResultDto, PermissionService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { BaseCourseDto, CoursesService, UploadCoursesDto, renewalPeriodOptions } from '@proxy/courses';
import { Router } from '@angular/router';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Constants } from '../shared/Constants/Constants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { allowedFileTypes } from 'src/environments/environment';
import { BaseCategoryDto, CategoriesLookupService } from '@proxy/categories';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TenantLookupDto, TenantLookupService } from '@proxy/tenants';
import { TestLookupDto, TestLookupService } from '@proxy/tests';
import { TestAttemptEntity } from '@proxy/test-attempts';
import { TenantCourseService } from '@proxy/tenant-courses';
import { MissingCourseDto } from '@proxy/courses/tenant-course';
import { DatePipe } from '@angular/common';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TicketEducationKind, TicketKind, TicketStatus, TicketsService, ticketKindOptions } from '@proxy/tickets';
import { TenantUserLookupDto, UserLookupDto } from '@proxy/enova-users-lookup';
import { DocumentApprovalDto, DocumentApprovalSettingsService } from '@proxy/documents-approval';
import { DocumentStatus } from '@proxy/documents';
import { EnovaUserLookupService } from '@proxy/enova-user-lookup';

@Component({
  selector: 'app-course',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  providers: [ListService, DatePipe,
    {
      provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    }]
})
export class CoursesComponent implements OnInit {
  courses = { items: [], totalCount: 0} as PagedResultDto<TenantHostCourseDto>;
  missingCourses = { items: [], totalCount: 0 } as PagedResultDto<MissingCourseDto>;
  tenantHasMissingCourses: boolean = false;
  
  isAdmin = false;
  hostUsers: UserLookupDto[] = [];

  selectedCourses: BaseCourseDto[] = [];
  selectedUsers = {
    items: [],
    totalCount: 0
  } as PagedResultDto<TenantUserLookupDto>;

  tenantUsersLookup: TenantUserLookupDto[] = [];

  isGrantedPermissionForSettingProductPageUrl: boolean;
  focusForCategorySearch$ = new Subject<string>();
  clickForCategorySearch$ = new Subject<string>();
  focusForCategoryFilter$ = new Subject<string>();
  clickForCategoryFilter$ = new Subject<string>();
  focusForTestFilter$ = new Subject<string>();
  clickForTestFilter$ = new Subject<string>();
  focusForTestSearch$ = new Subject<string>();
  clickForTestSearch$ = new Subject<string>();
  focusForUserSearch$ = new Subject<string>();
  clickForUserSearch$ = new Subject<string>();
  focusForTicketTenantUser$ = new Subject<string>();
  clickForTicketTenantUser$ = new Subject<string>();
  tenants$: Observable<TenantLookupDto[]>;
  courseId: string;
  allCategories: BaseCategoryDto[] = [];
  allTests: TestLookupDto[] = [];
  availableTests: TestLookupDto[] = [];
  categoryId: string;
  testId: string;
  availableUntilDate: string;
  filterInput = '';
  ecommerceUrl: string = "";
  currentUserTenantId: string;
  isModalOpen = false;
  isTenantExist = false;
  isLoading = false;
  isCopyModalOpen = false;
  DateTimeFormatConst = Constants.DATETIME;
  form: UntypedFormGroup;
  copyForm: UntypedFormGroup;
  uploadForm: UntypedFormGroup;
  multiSelectTaskForm: UntypedFormGroup;
  renewalPeriods = renewalPeriodOptions;
  ticketKinds = ticketKindOptions;

  responsibleUserForApproval: DocumentApprovalDto;
  isTicketDocumentApprovalSelected: boolean = false;
  isMultiSelectModalOpen = false;
  isUploadModalOpen = false;

  today: Date = new Date();
  weekFromToday: Date = new Date();

  get dateFormat() { return Constants.DATETIME }

  filterForm: UntypedFormGroup;

  activeCourseService : TenantCourseService | CoursesService;
  
  formatter = (category: BaseCategoryDto) => category.name;
  tenantFormatter = (tenant: TenantUserLookupDto) => tenant.userName;
  userFormatter = (user: UserLookupDto) => user.userName;

  searchForCategory: OperatorFunction<string, readonly BaseCategoryDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForCategorySearch$, this.clickForCategorySearch$).pipe(
      map(term => (term === '' ? this.allCategories
        : this.allCategories.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchCourseByCategory: OperatorFunction<string, readonly BaseCategoryDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForCategoryFilter$, this.clickForCategoryFilter$).pipe(
      map(term => (term === '' ? this.allCategories
        : this.allCategories.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchForTest: OperatorFunction<string, readonly TestLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTestSearch$, this.clickForTestSearch$).pipe(
      map(term => (term === '' ? this.allTests
        : this.allTests.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchCourseByTest: OperatorFunction<string, readonly TestLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTestFilter$, this.clickForTestFilter$).pipe(
      map(term => (term === '' ? this.availableTests
        : this.availableTests.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchTenantForTicketUsers: OperatorFunction<string, readonly TenantUserLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  
    return merge(debouncedText$, this.focusForTicketTenantUser$, this.clickForTicketTenantUser$).pipe(
      map(term => (term === '' ? this.tenantUsersLookup
        : this.tenantUsersLookup.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  
  searchCourseByUser: OperatorFunction<string, readonly UserLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForUserSearch$, this.clickForUserSearch$).pipe(
        map(term => (term === '' ? this.hostUsers
        : this.hostUsers.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1))
        )
    );
};

  constructor(
    public readonly list: ListService, 
    private categoriesLookupService: CategoriesLookupService,
    private documentApprovalSettingsService: DocumentApprovalSettingsService,
    private tenantLookupService: TenantLookupService,
    private enovaUserLookupService: EnovaUserLookupService,
    private coursesService: CoursesService,
    private testLookupService: TestLookupService,
    private localizationService: LocalizationService,
    private router: Router,
    private tenantCourseService: TenantCourseService,
    private confirmation: ConfirmationService,
    private toaster: ToasterService,
    private config: ConfigStateService,
    private permissionService: PermissionService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private ticketsService: TicketsService,
  ) { 
    this.tenants$ = this.tenantLookupService.getTenantLookup().pipe(map(r => r.items));

    this.isGrantedPermissionForSettingProductPageUrl =
      this.permissionService.getGrantedPolicy('Courses.Courses.AddProductPageUrl');

    this.ecommerceUrl = this.config.getSetting("Enova.DefaultEcommerceUrl");
  }

  ngOnInit(): void {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;

    const canCreate = this.permissionService.getGrantedPolicy('Tickets.Tickets.Create');

    if(canCreate){
      this.ticketsService.getTenantUserLookup().subscribe((response) => {
        this.tenantUsersLookup = response.items;
      });
    }

    this.selectedCourses = [];

    this.buildFormForSearch();
    var currentUser = this.config.getOne("currentUser");
    this.isTenantExist = currentUser.tenantId != null ? true : false;
    this.currentUserTenantId = currentUser.tenantId;

    if(currentUser.tenantId == null){
      if(currentUser.roles.includes("admin")) {
        this.enovaUserLookupService.getUserLookup().subscribe((response) => {
          this.hostUsers = response.items;
        })
        this.isAdmin = true;
      }

      this.activeCourseService = this.coursesService;
      this.getCourseList();
    } else {
      this.activeCourseService = this.tenantCourseService;
      this.getCourseList();

      var canViewCourseSuggestions = this.permissionService.getGrantedPolicy('Courses.Courses.ViewCourseSuggestions');
      if(canViewCourseSuggestions) {
        this.tenantCourseService.getMissingCoursesList().subscribe((response) => {
          this.missingCourses.items = response.items;
          this.missingCourses.totalCount = response.items.length;
          if(response.items.length > 0)
            this.tenantHasMissingCourses = true;
        })
      }
    }

    this.documentApprovalSettingsService.getDocumentApprovalInformation(true)
      .subscribe((response) => {
        this.responsibleUserForApproval = response;
    });
    
    this.categoriesLookupService.getCategoryLookup().subscribe((response) => {
      this.allCategories = response.items;
    })

    this.testLookupService.getTestLookup().subscribe((response) => {
      this.allTests = response.items;
    });

    this.testLookupService.getAvailableTestLookup().subscribe((response) => {
      this.availableTests = response.items;
    });
  }


  getCourseList() {
    const courseStreamCreator = (query) => this.activeCourseService.getList(
      {
        ...query, 
        categoryId: this.filterForm.get("category").value ? this.filterForm.get("category").value.id : null,
        testId: this.filterForm.get("test").value ? this.filterForm.get("test").value.id : null,
        availableUntil: this.filterForm.get("availableUntil").value ? this.datePipe.transform(this.filterForm.get("availableUntil").value, "yyyy-MM-dd") : null,
        creationDateFrom: this.filterForm.get("creationTimeFrom").value ? this.datePipe.transform(this.filterForm.get("creationTimeFrom").value, "yyyy-MM-dd") : null,
        creationDateTo: this.filterForm.get("creationTimeTo").value ? this.datePipe.transform(this.filterForm.get("creationTimeTo").value, "yyyy-MM-dd") : null,
        creatorId: this.filterForm.get('creatorId').value ? this.filterForm.get('creatorId').value.id : null,
      }
    );

    this.list.hookToQuery(courseStreamCreator).subscribe((response) => {
      this.courses.items = response.items;
      this.courses.totalCount = response.totalCount;
    });
  }

  buildFormForSearch() {
    this.filterForm = this.fb.group({
      category: [null],
      test: [null],
      availableUntil: [null],
      creationTimeFrom: [null],
      creationTimeTo: [null],
      creatorId: [null]
    })
  }

  clearFilters(){
    this.buildFormForSearch();
    this.list.get();
  }

  get allowedFileTypesFormattedString() {
    return allowedFileTypes.types.map(e => `.${e}`).join();;
  }

  goToProductPage(productPath: string){
    window.open(`${this.ecommerceUrl}${productPath}`, "_blank");
  }

  getCourseViewFeature(): boolean {
    return this.config.getFeature("Course.View") == "true";
  }

  getCourseControlFeature(): boolean {
    return this.config.getFeature("Course.Control") == "true";
  }

  getTestAttemptFeature(): boolean {
    return this.config.getFeature("Test.Attempt") == "true";
  }

  selectedItem(item: any){
    this.categoryId = item.item.id;
    this.list.get();
  }

  selectedTestItem(item: any){
    this.testId = item.item.id;
    this.list.get();
  }

  selectedUser(user: any){
    this.list.get();
  }

  attemptCourseTest(testId: string, courseId: string) {
    this.router.navigate(["test/attempt"], {queryParams: {
      id: testId,
      entity: TestAttemptEntity.Course,
      pointer: courseId
    }});
  }

  getListWhenTestInputEmpty(event: any){
    if(event == ''){
      this.testId = null;
      this.list.get();
    }
  }

  getListWhenCategoryInputEmpty(event: any){
    if(event == ''){
      this.categoryId = null;
      this.list.get();
    }
  }

  getListWhenUserInputEmpty(event: any){
    if(event == ''){
        this.list.get();
    }
  }

  filterDatatable() {
    this.list.filter = this.filterInput;
    this.list.get();
  }

  createCourse() {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.selectedCourses = [];
    this.isMultiSelectModalOpen = false;
    this.buildForm();
    this.isModalOpen = true;
  }

  openCreateView() {
    this.router.navigate(['courses/create']);
  }

  copyCourse(courseId: string){
    this.courseId = courseId;
    this.buildCopyForm();
    this.isCopyModalOpen = true;
  }

  openCourseView(courseId: string, tenantCourseId: string) {
    var tenantCourse = this.courses.items.find(tenantCourse => tenantCourse.id == courseId);
    if(tenantCourse.classes.length != 0) {
      if(this.config.getOne("currentUser").tenantId == null){
        this.router.navigate(['courses/view', courseId]);
      } else {
        this.router.navigate(['courses/view', tenantCourseId]);
      }
    }
    else
      this.toaster.info("::Course:Message:Classes:Error");
  }

  openEditView(courseId: string, tenantCourseId: string) {
    if(this.config.getOne("currentUser").tenantId == null){
      this.router.navigate(['courses/edit', courseId]);
    } else {
      this.router.navigate(['courses/edit', tenantCourseId]);
    }
  }

  buildForm() {
    this.form = this.fb.group({
      Name: ['', [Validators.required]],
      Code: ['', [Validators.required]],
      Length: ['', [Validators.required, Validators.min(1)]],
      Category: [''],
      Test: [''],
      ShortDescription: [null],
      FullDescription: [null],
      ProductUrlPath: [null],
      RenewalPeriodNumber: [null, [!this.isTenantExist ? Validators.required : Validators.nullValidator, Validators.min(1)]],
      RenewalPeriod: [null, [!this.isTenantExist ? Validators.required : Validators.nullValidator]],
      AvailableUntil: [null]
    });
  }

  buildCopyForm(){
    this.copyForm = this.fb.group({
      tenantId: [null, Validators.required]
    });
  }

  buildUploadForm(){
    this.uploadForm = this.fb.group({
      tenantId: [null, Validators.required]
    });
  }

  formCourseData() {
    return {
      name: this.form.get('Name').value,
      code: this.form.get('Code').value,
      length: this.form.get('Length').value,
      categoryId: this.form.get('Category').value ? this.form.get('Category').value.id : null,
      testId: this.form.get("Test").value ? this.form.get("Test").value.id : null,
      productUrlPath: this.form.get("ProductUrlPath").value,
      shortDescription: this.form.get('ShortDescription').value,
      fullDescription: this.form.get('FullDescription').value,
      renewalPeriodNumber: this.form.get('RenewalPeriodNumber').value,
      renewalPeriod: this.form.get('RenewalPeriod').value,
      classes: [],
      availableUntil: this.form.get('AvailableUntil').value ? this.datePipe.transform(this.getAvailableUntilDate(), "yyyy-MM-ddTHH:mm:ss") : null,
      status: DocumentStatus.NotApproved
    }
  }

  getAvailableUntilDate() {
    let availableUntil = this.form.get('AvailableUntil').value;
    availableUntil.setHours(0);
    availableUntil.setMinutes(0);

    return availableUntil
  }

  isShown(tenantId: string){
    if(tenantId == this.currentUserTenantId)
      return true;
    return false;
  }
  
  isShownForCopy(tenantId: string){
    if(tenantId != null)
      return true;
    return false;
  }

  delete(id: string) {
    this.confirmation.warn('::Course:Message:Delete:Confirmation', 'AbpAccount::AreYouSure').subscribe((status) => {
      if (status === Confirmation.Status.confirm) {
        this.coursesService.delete(id).subscribe(() => {
          this.list.get();
          this.selectedCourses = [];
          this.toaster.success(
            this.localizationService.instant('::Course:Message:Delete:Success')
          );
        });
      }
    });
  }

  saveCourseCopy(){
    this.isLoading = true; 

    if(this.copyForm.invalid){
      return;
    }
    
    this.coursesService.copy(this.copyForm.get('tenantId').value, this.courseId)
      .subscribe({
        next: () => {
          this.isCopyModalOpen = false;
          this.copyForm.reset();
          this.list.get();
        },
        complete: () => {
          this.isLoading = false;
          this.toaster.success(
            this.localizationService.instant('::Course:Message:Copy:Success')
          );
        },
        error: () => {
          this.isLoading = false;
          this.toaster.error(
            this.localizationService.instant('::Course:Message:Copy:Error')
          );
        }
      }
    );
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.coursesService.create(this.formCourseData()).subscribe((value) => {
        this.isModalOpen = false;
        this.form.reset();
        this.list.get();

        if(this.config.getOne("currentUser").tenantId == null){
          this.router.navigate(['courses/edit', value.id]);
        } else {
          this.router.navigate(['courses/edit', value.tenantCourseId]);
        }

      }, (error) => {
        this.toaster.error(
          this.localizationService.instant("::Course:Message:Created:Error")
        );
      }, () => {
          this.toaster.success( 
            this.localizationService.instant("::Course:Message:Created:Success")
          );
      }
    );
  }

  showAvailableUntilField(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      return false;
    }

    var correctRole = false;

    currentUser.roles.forEach(element => {
      if(element == "DSS Specialistas") {
        correctRole = true;
      }
    });

    return correctRole;
  }

  buildMultiselectFrom() {
    this.multiSelectTaskForm = this.fb.group({
      name: [this.localizationService.instant("::Ticket:Default:Name"), Validators.required],
      description: [''],
      assignedUserId: [null, Validators.required],
      taskStatus: [TicketStatus.New, Validators.required],
      ticketKind: [null],
      ticketEducationKind: [null],
      document: [null],
      startDate: [this.today, Validators.required],
      startTime: [
        {
          hour: 12, 
          minute: 0
        }
      ],
      endDate: [this.weekFromToday, Validators.required],
      endTime: [
        {
          hour: 12,
          minute: 0
        }
      ]
    });

    this.multiSelectTaskForm.get('ticketKind').valueChanges.subscribe((kind: TicketKind) => {
      if(kind == TicketKind.Education) {
        this.multiSelectTaskForm.controls["ticketEducationKind"].setValue(TicketEducationKind.Course);
        this.multiSelectTaskForm.controls["ticketEducationKind"].updateValueAndValidity();
      } else {
        this.multiSelectTaskForm.controls["ticketEducationKind"].setValue(null);
        this.multiSelectTaskForm.controls["ticketEducationKind"].updateValueAndValidity();
      }

      if(kind == TicketKind.DocumentApproval) {    
        if(this.responsibleUserForApproval == null) {
          this.multiSelectTaskForm.controls["ticketKind"].setValue(null);
          this.multiSelectTaskForm.controls["ticketKind"].updateValueAndValidity();
  
          this.toaster.warn(
            this.localizationService.instant("::TicketForApproval:Error:00001")
          );
  
          return;
        }
      }
  
      this.isDocumentForApprovalSelectShown(kind);
    });
  }

  isCurrentTenantUserAdminOrDSSSpecialist(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      return false;
    }

    var correctRole = false;

    currentUser.roles.forEach(element => {
      if(element == "DSS Specialistas" || element == "admin") {
        correctRole = true;
      }
    });

    return correctRole;
  }

  isCurrentUserSuperAdmin(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
      if(currentUser.roles.includes("admin")) {
        return true;
      }
    }

    return false;
  }

  isCurrentUserImpersonatedSuperAdmin(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.impersonatorTenantId == null && 
      currentUser.impersonatorTenantName == null && 
      currentUser.impersonatorUserId != null && 
      currentUser.impersonatorUserName != null && 
      currentUser.tenantId != null) {
      return true;
    }

    return false;
  }

  isDocumentForApprovalSelectShown(kind: TicketKind) {
    if (kind == TicketKind.DocumentApproval) {
      this.multiSelectTaskForm.controls["assignedUserId"].setValue(this.responsibleUserForApproval?.selectedUser.id);

      this.selectedUsers.items = [
        {
          userName: this.responsibleUserForApproval?.selectedUser?.userName, 
          id: this.responsibleUserForApproval?.selectedUser?.id
        }
      ];
      this.selectedUsers.totalCount = 1;
      
      this.isTicketDocumentApprovalSelected = true;
      return true;
    }

    this.isTicketDocumentApprovalSelected = false;

    return false;
  }

  isCourseChecked(data): boolean {
    return this.selectedCourses.length > 0 && this.selectedCourses.some(course => course.id === data);
  }

  changeCourseSelection(data, event) {
    const isSuperAdmin = this.isCurrentUserSuperAdmin();
    const isTenantAdminOrSpecialist = this.isCurrentTenantUserAdminOrDSSSpecialist();

    if(event){
      if(isSuperAdmin){
        this.toggleSelection(data.id, data.name, data.code);
      }

      if(isTenantAdminOrSpecialist){
        this.toggleSelection(data.tenantCourseId, null, null);
      }
    } else {
      if(isSuperAdmin){
        this.removeFromSelection(data.id);
      }

      if(isTenantAdminOrSpecialist){
        this.removeFromSelection(data.tenantCourseId);
      }
    }
  }

  toggleSelection(id, name, code) {
    if (!this.selectedCourses.some(doc => doc.id === id)) {
      this.selectedCourses.push(
        {
          id: id,
          name: name,
          code: code
        } as BaseCourseDto
      );
    } else {
      this.toaster.error(this.localizationService.instant('::Course:Selection:Already:Selected'));
    }
  }
  
  removeFromSelection(id) {
    const index = this.selectedCourses.findIndex(course => course.id === id);
    if (index !== -1) {
      this.selectedCourses.splice(index, 1);
    } else {
      this.toaster.error(this.localizationService.instant('::Course:Selection:Removed:Error'));
    }
  }

  openCreateCourseMultiselectTaskModal() {
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.isModalOpen = false;
    this.isCopyModalOpen = false;
    this.weekFromToday.setDate(this.today.getDate() + 7);
    this.buildMultiselectFrom();
    this.isMultiSelectModalOpen = true;

  }

  openUploadCoursesModal() {
    this.buildUploadForm();
    this.isUploadModalOpen = true;
  }

  resetMultiSelectData() {
    this.isMultiSelectModalOpen = false;
    this.multiSelectTaskForm.reset();
    this.selectedUsers.items = [];
    this.selectedUsers.totalCount = 0;
    this.selectedCourses = [];
  }

  formMultiSelectData() {
    return {
      name: this.multiSelectTaskForm.get('name').value,
      description: this.multiSelectTaskForm.get('description').value,
      status: TicketStatus.New,
      ticketKind: this.multiSelectTaskForm.get('ticketKind').value,
      ticketEducationKind: this.multiSelectTaskForm.get('ticketEducationKind').value,
      assignedUserIdsList: this.selectedUsers.items.map(user => user.id),
      assignedDataIdsList: this.selectedCourses.map(course => course.id),
      startDateTime: this.datePipe.transform(this.getStartTime(), "yyyy-MM-ddTHH:mm:ss"),
      endDateTime: this.datePipe.transform(this.getEndTime(), "yyyy-MM-ddTHH:mm:ss"),
      ticketEntityKind: TicketEducationKind.Course
    }
  }

  getStartTime(){
    let startDateTime = this.multiSelectTaskForm.get('startDate').value;
    startDateTime.setHours(this.multiSelectTaskForm.get('startTime').value.hour);
    startDateTime.setMinutes(this.multiSelectTaskForm.get('startTime').value.minute);
    return startDateTime
  }

  getEndTime(){
    let endDateTime = this.multiSelectTaskForm.get('endDate').value;
    endDateTime.setHours(this.multiSelectTaskForm.get('endTime').value.hour);
    endDateTime.setMinutes(this.multiSelectTaskForm.get('endTime').value.minute);
    return endDateTime
  }

  selectAllTenantUsers(){
    this.ticketsService.getTenantUserLookup().subscribe((response) => {
      this.selectedUsers.items = response.items;
      this.selectedUsers.totalCount = response.items.length;

      this.checkIfSelectedUsers();
    })
  }

  isMultiSelectDataSelected() {
    return this.selectedCourses.length > 0;
  }

  saveMultiSelectTask() {
    if(this.multiSelectTaskForm.invalid) {
      return;
    }

    this.ticketsService.createMultiSelectTasks(this.formMultiSelectData())
      .subscribe({
        next: () => {
          this.resetMultiSelectData();
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Course:Multiselect:Task:Create:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::Course:Multiselect:Task:Create:Error")
          );
        }
      });
  }

  selectedNewTaskUsers(selectedUser: any) {
    if (selectedUser) {

      if(this.selectedUsers.totalCount > 0) {
        var isValuePresent = this.selectedUsers.items.find(x => x.userName == selectedUser.userName);
  
        if(isValuePresent){
          this.toaster.error(
            this.localizationService.instant("::Ticket:Message:User:Error:00001")
          );
    
          return;
        }
      }

      this.selectedUsers.items.push({userName: selectedUser.userName, id: selectedUser.id});

      this.selectedUsers.items = [...this.selectedUsers.items]
      this.selectedUsers.totalCount++;

      this.checkIfSelectedUsers();
    }
  }

  uploadCourses(){
    if(this.uploadForm.invalid)
      return true;
    
    const uploadCourseDto = {
      tenantId: this.uploadForm.get('tenantId').value, 
      courses: this.selectedCourses
    } as UploadCoursesDto;

    this.coursesService.uploadCoursesForTenant(uploadCourseDto).subscribe({
      next: () => {
        this.uploadForm.reset();
      },
      complete: () => {
        this.isUploadModalOpen = false;
        this.selectedCourses = [];
        this.toaster.success(
          this.localizationService.instant("::Course:Message:Upload:Success")
        );
      },
      error: () => {
        this.isUploadModalOpen = false;
        this.toaster.error(
          this.localizationService.instant("::Course:Message:Upload:Error")
        );
      }
    })
  }

  removeAssignedUser(eventValue: any) {

    this.selectedUsers.items.forEach((element, index) => {
      if(element.id == eventValue.id) {
        this.selectedUsers.items.splice(index, 1);
        this.selectedUsers.totalCount--;
        this.selectedUsers.items = [...this.selectedUsers.items];

        this.checkIfSelectedUsers();
      }
    });

  }

  checkIfSelectedUsers() {
    if(this.selectedUsers.totalCount == 0) {
      this.multiSelectTaskForm.controls["assignedUserId"].setValue(null);
      this.multiSelectTaskForm.controls["assignedUserId"].addValidators(Validators.required);
      this.multiSelectTaskForm.controls["assignedUserId"].updateValueAndValidity();
    } else {
      this.multiSelectTaskForm.controls["assignedUserId"].clearValidators();
      this.multiSelectTaskForm.controls["assignedUserId"].updateValueAndValidity();
    }
  }
  
}
