import type { GetTenantActionListDto, TenantActionDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantActionsService {
  apiName = 'Default';
  

  get = (id: string) =>
    this.restService.request<any, TenantActionDto>({
      method: 'GET',
      url: `/api/app/users-actions/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetTenantActionListDto) =>
    this.restService.request<any, PagedResultDto<TenantActionDto>>({
      method: 'GET',
      url: '/api/app/users-actions',
      params: { filter: input.filter, entity: input.entity, type: input.type, userName: input.userName, documentId: input.documentId, courseId: input.courseId, position: input.position, nameAndSurname: input.nameAndSurname, dateFrom: input.dateFrom, dateTo: input.dateTo, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getReportData = (input: GetTenantActionListDto) =>
    this.restService.request<any, TenantActionDto[]>({
      method: 'GET',
      url: '/api/app/users-actions/report',
      params: { filter: input.filter, entity: input.entity, type: input.type, userName: input.userName, documentId: input.documentId, courseId: input.courseId, position: input.position, nameAndSurname: input.nameAndSurname, dateFrom: input.dateFrom, dateTo: input.dateTo, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
