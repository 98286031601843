import type { CreateMultiSelectTicketDto, CreateTicketDto, GetTicketListDto, GetTikcetListByDatesDto, TenantDashboardTicketDto, TicketDto, UpdateTicketDto } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TenantUserLookupDto } from '../enova-users-lookup/models';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  apiName = 'Default';
  

  create = (input: CreateTicketDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/tickets',
      body: input,
    },
    { apiName: this.apiName });
  

  createMultiSelectTasks = (input: CreateMultiSelectTicketDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/tickets/multi-select-tasks',
      body: input,
    },
    { apiName: this.apiName });
  

  createTicketForDocumentApprovalByTenantDocumentId = (tenantDocumentId: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/tickets/ticket-for-document-approval/${tenantDocumentId}`,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/tickets/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, TicketDto>({
      method: 'GET',
      url: `/api/app/tickets/${id}`,
    },
    { apiName: this.apiName });
  

  getFilteredList = (input: GetTikcetListByDatesDto) =>
    this.restService.request<any, TenantDashboardTicketDto>({
      method: 'GET',
      url: '/api/app/tickets/filtered-list',
      params: { fromDate: input.fromDate, toDate: input.toDate },
    },
    { apiName: this.apiName });
  

  getList = (input: GetTicketListDto) =>
    this.restService.request<any, PagedResultDto<TicketDto>>({
      method: 'GET',
      url: '/api/app/tickets',
      params: { filter: input.filter, status: input.status, assignedUserId: input.assignedUserId, startDate: input.startDate, endDate: input.endDate, tenantDocumentId: input.tenantDocumentId, tenantCourseId: input.tenantCourseId, ticketKind: input.ticketKind, ticketEducationKind: input.ticketEducationKind, organizationUnitsIds: input.organizationUnitsIds, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getTenantUserLookup = () =>
    this.restService.request<any, ListResultDto<TenantUserLookupDto>>({
      method: 'GET',
      url: '/api/app/tickets/tenant-user-lookup',
    },
    { apiName: this.apiName });
  

  update = (id: string, input: UpdateTicketDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/tickets/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
