import { ProductRenewalExpirationDayDto } from './../proxy/product-renewal-expiration-days/models';
import { ProductRenewalExpirationDaysService } from './../proxy/product-renewal-expiration-days/product-renewal-expiration-days.service';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ListService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
    selector: 'app-product-renewal-expiration-days-tab',
    templateUrl: './product-renewal-expiration-days-tab.component.html',
    styleUrls: ['./product-renewal-expiration-days-tab.component.scss'],
    providers: [ListService]
})
export class ProductRenewalExpirationDaysComponent implements OnInit {
    productRenewalDays: ProductRenewalExpirationDayDto = {} as ProductRenewalExpirationDayDto;
    form: UntypedFormGroup;
    isLoading: boolean = false;

    constructor(
        public readonly list: ListService,
        private productRenewalService: ProductRenewalExpirationDaysService,
        private toaster: ToasterService,
        private localiztionService: LocalizationService,
        private fb: UntypedFormBuilder
    ){}

    ngOnInit(): void {
        this.isLoading = true;
        this.productRenewalDays = {} as ProductRenewalExpirationDayDto;
        this.productRenewalService.get().subscribe((response) => {
            this.productRenewalDays = response;
            this.buildForm();
            this.isLoading = false;
        });
    }

    buildForm() {
        this.form = this.fb.group({
            days: [this.productRenewalDays != null 
                ? this.productRenewalDays.days 
                : null, 
                [Validators.min(0)]]
        });
    }

    setSettings(){
        if(this.form.invalid){
            return;
        }

        this.productRenewalService.update(this.form.value)
            .subscribe({
                next: () => {
                    this.productRenewalService.get().subscribe((response) => {
                        this.productRenewalDays = response;
                        this.buildForm();
                    });
                },
                complete: () => {
                    this.toaster.success(
                        this.localiztionService.instant("::ProductRenewalExpirationDay:Settings:Success")
                    );
                },
                error: () => {
                    this.toaster.error(
                        this.localiztionService.instant("::ProductRenewalExpirationDay:Settings:Error")
                    )
                }
            })
    }

}