import { APP_INITIALIZER, Injector } from '@angular/core';
import { combineLatest } from 'rxjs';
import { RoutesService } from '@abp/ng.core';
import { TESTS_MANAGEMENT_ROUTE_VISIBILITY } from './features.token';
import { ProviderConsts } from 'src/app/shared/Constants/providers.constants';

export const TESTS_MANAGEMENT_VISIBLE_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: testsManagementVisibility,
        deps: [Injector],
        multi: true,
    },
];

export function testsManagementVisibility(injector: Injector) {
    return () => {
        const isTestsManagementFeatureEnable$ = injector.get(TESTS_MANAGEMENT_ROUTE_VISIBILITY);
        const routes = injector.get(RoutesService);
        combineLatest([isTestsManagementFeatureEnable$]).subscribe(
        ([isTestsManagementFeatureEnable]) => {
            routes.patch(ProviderConsts.TEST_ROUTE_PATCH_NAME, {
                invisible: !isTestsManagementFeatureEnable,
            });
        },
        );
    };
}
