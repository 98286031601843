import { RestService } from '@abp/ng.core';
import type { ListResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BaseCourseDto, TenantCourseWithApprovalDataDto } from '../courses/models';

@Injectable({
  providedIn: 'root',
})
export class TenantCourseLookupService {
  apiName = 'Default';
  

  getTenantCourseLookup = () =>
    this.restService.request<any, ListResultDto<BaseCourseDto>>({
      method: 'GET',
      url: '/api/app/tenant-course-lookup/tenant-course-lookup',
    },
    { apiName: this.apiName });
  

  getTenantCourseWithApprovalDataLookup = () =>
    this.restService.request<any, ListResultDto<TenantCourseWithApprovalDataDto>>({
      method: 'GET',
      url: '/api/app/tenant-course-lookup/tenant-course-with-approval-data-lookup',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
