import { IncidentDto, IncidentUpdateDto } from './../proxy/incidents/models';
import { CustomIncidentDto, IncidentService } from './services/incident.service';
import { IncidentDownloadService } from './../proxy/incidents/incident-download.service';
import { ListService, LocalizationService, PagedResultDto, PermissionService, downloadBlob } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FullIncidentDataDto, TenantIncidentsService, incidentStatusOptions, incidentTypeOptions, IncidentStatus } from '@proxy/incidents';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { allowedPictureTypes } from 'src/environments/environment';
import { Constants } from '../shared/Constants/Constants';
import { requiredFileTypes } from '../shared/FileTypeValidation/file.type.validation';
import { allowedFileSize } from '../shared/FileSizeValidation/file.size.validation';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss'],
  providers: [ListService, DatePipe,
    {
      provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
    }
  ]
})
export class IncidentComponent implements OnInit {
  @ViewChild("takeInput", {static: false}) InputVar: ElementRef;
  incidents = { items: [], totalCount: 0 } as PagedResultDto<IncidentDto>;
  selectedIncident: FullIncidentDataDto;
  incidentsStatus: number;
  incidentsType: number;
  statusTypes = incidentStatusOptions;
  incidentTypes = incidentTypeOptions;
  isViewModalVisible: boolean = false;
  canChangeStatus: boolean = false;
  isModalOpen: boolean = false;
  isLoading: boolean = false;
  viewForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  form: UntypedFormGroup;
  DateTimeFormatConst = Constants.DATETIME;

  AVAILABLE_UPLOAD_SIZE: number = 500000000;

  constructor(
    public readonly list: ListService,
    private readonly incidentService: IncidentService,
    private datePipe: DatePipe,
    private confirmation: ConfirmationService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private tenantIncidentService: TenantIncidentsService,
    private permissionService: PermissionService,
    private incidentDownloadService: IncidentDownloadService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    const incidentStreamCreator = (query) => this.tenantIncidentService.getList(
      { 
        ...query, 
        type: this.filterForm.get('type').value,
        status: this.filterForm.get('status').value,
        creationDateFrom: this.filterForm.get("creationTimeFrom").value ? this.datePipe.transform(this.filterForm.get("creationTimeFrom").value, "yyyy-MM-dd") : null,
        creationDateTo: this.filterForm.get("creationTimeTo").value ? this.datePipe.transform(this.filterForm.get("creationTimeTo").value, "yyyy-MM-dd") : null,
      }
    );

    this.canChangeStatus = this.permissionService.getGrantedPolicy('Incidents.Incidents.ChangeIncidentStatus');

    this.list.hookToQuery(incidentStreamCreator).subscribe((response) => {
      this.incidents = response;
    });

    this.buildFormForSearch();
  }

  buildForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      status: [null, Validators.required],
      type: [null, Validators.required],
      solution: [null],
      incidentDate: [new Date(), [Validators.required]],
      incidentTime: [{ hour: 12, minute: 0 }],
      file: [null, [requiredFileTypes(allowedPictureTypes.types)]],
      fileSource: [null, [allowedFileSize(500000000)]]
    });

    if(!this.canChangeStatus){
      this.form.controls['status'].setValue(IncidentStatus.Registered);
    }
  }

  buildFormForSearch(){
    this.filterForm = this.fb.group({
      type: [null],
      status: [null],
      creationTimeFrom: [null],
      creationTimeTo: [null]
    })
  }

  getIncidentDateTime(){
    let incidentDateTime = this.form.get('incidentDate').value;
    incidentDateTime.setHours(this.form.get('incidentTime').value.hour);
    incidentDateTime.setMinutes(this.form.get('incidentTime').value.minute);

    return incidentDateTime;
  }

  filterListByName(searchValue: string){
    this.list.filter = searchValue;
    this.list.get();
  }

  createIncident(){
    this.isModalOpen = true;
    this.buildForm();
  }

  onFileChanged(event: { target: { files: string | any[]; }; }) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
          fileSource: file
      });

      if(file.size > this.AVAILABLE_UPLOAD_SIZE) {
        this.form.controls["File"].setErrors({'allowedFileSize': true});
      }
    }
  }

  showSolutionTextArea(form): boolean {
    return form.get("status").value == IncidentStatus.Resolved;
  }

  selectIncidentStatus(form) {
    var result = form.get("status").value == IncidentStatus.Resolved;
    if(result){
      form.controls["solution"].setValidators([Validators.required]);

    } else {
      form.controls["solution"].setValidators([Validators.nullValidator]);
      form.controls["solution"].setValue(null);
    }

    form.get("solution").updateValueAndValidity();
  }

  saveIncident(){
    if(this.form.invalid)
      return;
    
    this.isLoading = true;

    var incidentFile: File = this.form.controls["fileSource"].value;

    var request: CustomIncidentDto;

    request = {
      title: this.form.get('title').value,
      description: this.form.get('description').value,
      status: this.form.get('status').value,
      type: this.form.get('type').value,
      solution: this.form.get('solution').value,
      incidentDateTime: this.datePipe.transform(this.getIncidentDateTime(), 'yyyy-MM-ddTHH:mm:ss'),
      file: incidentFile,
    }

    this.incidentService.create(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.isModalOpen = false;
        this.form.reset();
        this.list.get();
      },
      complete: () => {
        this.toaster.success(
          this.localizationService.instant("::Incident:Message:Create:Success")
        );
      },
      error: () => {
        this.isLoading = false;
        this.toaster.error(
          this.localizationService.instant("::Incident:Message:Create:Error")
        );
      }
    });
  }

  deleteIncident(incidentId: string){
    this.confirmation.warn('::AreYouSureToDeleteIncident', '::AreYouSure').subscribe((status) => {
      if(status === Confirmation.Status.confirm){
        this.tenantIncidentService.delete(incidentId).subscribe({
          next: () => this.list.get(),
          complete: () => 
            this.toaster.success(
              this.localizationService.instant('::Incident:Message:Delete:Success')
            ),
          error: () => 
            this.toaster.error(
              this.localizationService.instant('::Incident:Message:Delete:Error')
            )
        });
      }
    });
  }

  saveIncidentStatus(){
    if(this.viewForm.invalid){
      return;
    }

    var request: IncidentUpdateDto;

    request = {
      status: this.viewForm.get('status').value,
      solution: this.viewForm.get('solution').value,
    };

    this.tenantIncidentService.changeStatusByIncidentIdAndInput(
      this.selectedIncident.id, request
    ).subscribe({
      next: () => {        
        this.isViewModalVisible = false;
        this.list.get();       
        this.viewForm.reset();
      },
      complete: () => {
        this.toaster.success(
          this.localizationService.instant('::Incident:Message:ChangeStatus:Success')
        );
      },
      error: () => {
        this.toaster.error(
          this.localizationService.instant('::Incident:Message:ChangeStatus:Error')
        );
      }
    })
  }

  getListWithFilters(){
    this.list.get();
  }

  clearFilters(){
    this.buildFormForSearch();
    this.list.get();
  }

  buildViewForm(){
    this.viewForm = this.fb.group({
      title: [this.selectedIncident.title],
      description: [this.selectedIncident.description],
      type: [this.selectedIncident.type],
      status: [this.selectedIncident.status, Validators.required],
      solution: [this.selectedIncident.solution, 
        this.selectedIncident.status == IncidentStatus.Resolved ? [Validators.required] : []],
      incidentDate: [new Date(this.selectedIncident.incidentDateTime)],
      incidentTime: [{          
        hour: new Date(this.selectedIncident.incidentDateTime).getHours(),
        minute: new Date(this.selectedIncident.incidentDateTime).getMinutes()
      }],
      username: [this.selectedIncident.username || null],
      name: [this.selectedIncident.name || null],
      surname: [this.selectedIncident.surname || null],
      email: [this.selectedIncident.email],
      phone: [this.selectedIncident.phone || null]
    });
  }

  viewIncidentData(incidentId: string){
    this.selectedIncident = null;
    this.tenantIncidentService.getIncident(incidentId).subscribe((response) => {
      this.selectedIncident = response;    
      this.isViewModalVisible = true;
      this.buildViewForm();
    });
  }

  downloadFile() {
    this.incidentDownloadService.downloadFileByIncidentId(this.selectedIncident.id).subscribe((result) => {
      downloadBlob(result, this.selectedIncident.fileDescriptor.name);
      this.viewForm.markAsPristine();
    });
  }
}
