import { ListResultDto } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TicketDto } from '@proxy/tickets';

@Component({
  selector: 'app-courses-task-list',
  templateUrl: './courses-task-list.component.html',
  styleUrls: ['./courses-task-list.component.scss']
})
export class CoursesTaskListComponent implements OnInit {
  @Input() tasks: ListResultDto<TicketDto> = {};
  @Output() updatedTask = new EventEmitter<string>();

  constructor(private confirmation: ConfirmationService) { }

  ngOnInit(): void {}

  updateTaskStatus(id: string){
    this.confirmation.warn('::Ticket:StatusUpdate:Confirmation', 'AbpAccount::AreYouSure')
    .subscribe(((status) => {
      if(status === Confirmation.Status.confirm){
        this.updatedTask.emit(id);
      }
    }))
  }

}
