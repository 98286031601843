import { CourseCreateUpdateComponent } from './couses-create-update/courses-create-update.component'
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CourseRoutingModule } from './courses-routing.module';
import { CoursesComponent } from './courses.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { CoursesViewComponent } from './courses-view/courses-view.component';
import { CoursesVideoPlayerComponent } from './courses-view/courses-video-player/courses-video-player.component';
import { CoursesAudioPlayerComponent } from './courses-view/courses-audio-player/courses-audio-player.component';
import { CoursesPhotoViewerComponent } from './courses-view/courses-photo-viewer/courses-photo-viewer.component';
import { CoursesFileViewerComponent } from './courses-view/courses-file-viewer/courses-file-viewer.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { CoursesClassTabsComponent } from './courses-view/courses-class-tabs/courses-class-tabs.component';
import { CoursesClassTextComponent } from './courses-view/courses-class-text/courses-class-text.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { COURSES_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { COURSES_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.provider';
import { CourseBannerComponent } from './course-banner/course-banner.component';
import { CoursesTaskListComponent } from './courses-view/courses-task-list/courses-task-list.component';

@NgModule({
  declarations: [
    CoursesComponent,
    CourseCreateUpdateComponent,
    CoursesViewComponent,
    CoursesVideoPlayerComponent,
    CoursesAudioPlayerComponent,
    CoursesPhotoViewerComponent,
    CoursesFileViewerComponent,
    CoursesClassTabsComponent,
    CoursesClassTextComponent,
    CourseBannerComponent,
    CoursesTaskListComponent,
  ],
  imports: [ 
    NgbModule,
    SharedModule,
    CommonModule,
    CourseRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [
    NgbActiveModal
  ]
})
export class CourseModule {
  static forRoot(): ModuleWithProviders<CourseModule> {
    return {
      ngModule: CourseModule,
      providers: [
        COURSES_MANAGEMENT_FEATURES_PROVIDERS,
        COURSES_MANAGEMENT_VISIBLE_PROVIDERS
      ]      
    }
  }
}
