<div class="card overflow-auto">
    <div class="card-title">
        <h3 class="card-title m-2 mx-3">
            {{ '::CourseTasks' | abpLocalization }}
        </h3>
    </div>
    <ul class="list-group">
        <li 
            class="list-group-item list-group-item-action"
            *ngFor="let task of tasks.items; let i = index"          
        >
            <div class="d-flex">
                <div class="me-auto">{{ i+1 + ". " }}{{ task.name }}</div>
                <button 
                    class="btn btn-primary btn-sm"
                    (click)="updateTaskStatus(task.id)"
                >
                    {{ '::Done' | abpLocalization }}
                </button>
            </div>
        </li>
    </ul>
</div>