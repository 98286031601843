<div class="row">
    <h2>{{ '::Ecommerce' | abpLocalization }}</h2>
    <hr>
    <form 
        class="pt-4"
        *ngIf="!isLoading"
        (ngSubmit)="setEcommerceSettings()"
        [formGroup]="ecommerceProfileForm"
    >
        <div class="form-group mb-3">
            <label class="form-label" for="e-commerce-url">
            {{ '::EcommerceUrl' | abpLocalization }}
            </label>
            <input type="text" id="e-commerce-url" class="form-control" formControlName="ecommerceUrl"/>
        </div>

        <abp-button
            (click)="setEcommerceSettings()"
        >
            <i class="fa fa-save"></i>
            {{ '::Save' | abpLocalization }}
        </abp-button>
    </form>
</div>