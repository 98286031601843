<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title" *ngIf="documentDto">
            {{ documentDto.name }}
        </h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/"><i class="fa fa-home"></i> </a>
            </li>
            <li class="breadcrumb-item">
                <a routerLink="/documents">{{ '::Menu:E-Documents' | abpLocalization }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                {{ "::ViewDocument"  | abpLocalization }}
            </li>
        </ol>
    </div>
</div>

<div class="row" *ngIf="!isLoading">
    <div class="col-lg-9 col-sm-12">
        <div class="card d-flex">
            <div class="card-body">
                <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="main-tab0"
                            type="button"
                            role="tab"
                            (click)="setActiveTab(0)"
                            [class.active]="isActiveTab === 0 ? true : false"
                        >
                            {{ '::MainInformation' | abpLocalization }}
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" *ngIf="!tenantExist">
                        <button
                            class="nav-link"
                            id="main-tab1"
                            type="button"
                            role="tab"
                            (click)="setActiveTab(1)"
                            [class.active]="isActiveTab === 1 ? true : false"
                        >
                            {{ '::Tags' | abpLocalization }}
                        </button>
                    </li>
                </ul>
                <div class="tab-content" [style]="isActiveTab === 0 ? '' : 'display: none;'">
                    <div
                        class="tab-pane fade"
                        id="0"
                        role="tabpanel"
                        aria-labelledby="main-tab0"
                        [class.active]="isActiveTab === 0 ? true : false"
                        [class.show]="isActiveTab === 0 ? true : false"
                    >
                    <form [formGroup]="form" class="col-md-8">
                        <div class="form-group mb-3">
                            <label class="form-label" for="document-name">{{ '::Name' | abpLocalization }}</label>
                            <input type="text" id="document-name" class="form-control" formControlName="name" [readOnly]="true" [abpDisabled]="true"/>
                        </div>

                        <div class="form-group mb-3">
                            <label class="form-label" for="document-code">{{ '::Code' | abpLocalization }}</label>
                            <input type="text" id="document-code" class="form-control" formControlName="code" [readOnly]="true" [abpDisabled]="true"/>
                        </div>
                        
                        <div class="form-group mb-3">
                            <label class="form-label" for="document-description">
                                {{ '::Description' | abpLocalization }}
                            </label>
                            <textarea
                                id="document-description"
                                class="form-control"
                                cols="30"
                                rows="10"
                                formControlName="description"
                                [readOnly]="true" 
                                [abpDisabled]="true"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3">
                            <label 
                                class="form-label" 
                                for="parent-category-name"
                            >
                                {{ '::Category' | abpLocalization }}
                            </label>
                            <input
                                id="typeahead-focus"
                                type="text"
                                class="form-control"
                                formControlName="category"
                                [editable]="false"
                                [ngbTypeahead]="searchForCategory"
                                [resultFormatter]="formatter"
                                [inputFormatter]="formatter"
                                (focus)="focusForCategorySearch$.next($any($event).target.value)"
                                (click)="clickForCategorySearch$.next($any($event).target.value)"
                                #instance="ngbTypeahead"
                                [readOnly]="true" 
                                [abpDisabled]="true"
                            />
                        </div>

                        <div class="form-group mb-3">
                            <label for="test-name" class="form-label">
                                {{ '::Test' | abpLocalization }}
                            </label>
                            <input
                                id="typeahead-focus"
                                type="text"
                                class="form-control"
                                formControlName="test"
                                [editable]="false"
                                [ngbTypeahead]="searchForTest"
                                [resultFormatter]="formatter"
                                [inputFormatter]="formatter"
                                (focus)="focusForTestSearch$.next($any($event).target.value)"
                                (click)="clickForTestSearch$.next($any($event).target.value)"
                                #instance="ngbTypeahead"
                                [readOnly]="true" 
                                [abpDisabled]="true"
                            />
                        </div>

                        <ng-container *ngIf="getTestAttemptFeature()">
                                <div *abpPermission="'Tests.Tests.Attempt'">
                                <div class="form-group mb-3" *ngIf="documentDto.test">
                                    <button type="button" (click)="attemptDocumentTest(documentDto.testId)" class="btn btn-primary w-100">
                                        {{ '::TakeATest' | abpLocalization }}
                                    </button>
                                </div>
                            </div>          
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(0)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(1)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField1' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField1" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction1' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction1"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="isFreeFieldShown(2)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField2' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField2" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction2' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction2"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="isFreeFieldShown(3)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField3' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField3" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction3' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction3"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(4)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField4' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField4" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction4' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction4"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(5)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField5' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField5" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction5' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction5"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(6)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField6' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField6" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction6' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction6"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(7)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField7' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField7" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction7' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction7"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(8)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField8' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField8" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction8' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction8"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(9)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField9' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField9" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction9' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction9"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(10)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField10' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField10" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction10' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction10"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(11)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField11' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField11" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction11' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction11"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="isFreeFieldShown(12)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField12' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField12" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction12' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction12"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(13)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField13' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField13" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction13' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction13"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isFreeFieldShown(14)">
                            <div class="form-group mb-3 mt-3">
                                <label class="form-label" for="document-free-field">{{ '::FreeField14' | abpLocalization }}</label>
                                <input type="text" id="document-free-field" class="form-control" formControlName="freeField14" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                    
                            <div class="form-group mb-3">
                                <label class="form-label" for="document-free-field-filling-instruction">
                                    {{ '::FreeFieldFillingInstruction14' | abpLocalization }}
                                </label>
                                <textarea
                                    id="document-free-field-filling-instruction"
                                    class="form-control"
                                    cols="30"
                                    rows="3"
                                    formControlName="freeFieldFillingInstruction14"
                                    [readOnly]="true" 
                                    [abpDisabled]="true"
                                ></textarea>
                            </div>
                        </ng-container>

                        <div class="form-group mb-3" *ngIf="documentDto.fileDescriptorId">
                            <label class="form-label" for="document-file"> {{ '::FileAttachment' | abpLocalization }} </label>
                            <div class="row">
                                <div class="col">
                                    <button (click)="downloadFile()" type="button" role="button" class="btn btn-primary w-100">
                                        {{ '::DownloadCurrentFileVersion' | abpLocalization }}
                                    </button>
                                </div>
                                <div class="col" *ngIf="isPdfViewerShown()">
                                    <button (click)="showPdfFile()" type="button" role="button" class="btn btn-primary w-100">
                                        {{ '::ViewCurrentDocumentFile' | abpLocalization }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(0)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent"
                                [placeholder]="documentDto.freeFieldFillingInstruction"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(1)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField1 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent1"
                                [placeholder]="documentDto.freeFieldFillingInstruction1"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(2)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField2 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent2"
                                [placeholder]="documentDto.freeFieldFillingInstruction2"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(3)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField3 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent3"
                                [placeholder]="documentDto.freeFieldFillingInstruction3"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(4)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField4 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent4"
                                [placeholder]="documentDto.freeFieldFillingInstruction4"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(5)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField5 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent5"
                                [placeholder]="documentDto.freeFieldFillingInstruction5"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(6)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField6 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent6"
                                [placeholder]="documentDto.freeFieldFillingInstruction6"
                            ></textarea>
                        </div>
                        
                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(7)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField7 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent7"
                                [placeholder]="documentDto.freeFieldFillingInstruction7"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(8)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField8 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent8"
                                [placeholder]="documentDto.freeFieldFillingInstruction8"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(9)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField9 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent9"
                                [placeholder]="documentDto.freeFieldFillingInstruction9"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(10)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField10 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent10"
                                [placeholder]="documentDto.freeFieldFillingInstruction10"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(11)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField11 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent11"
                                [placeholder]="documentDto.freeFieldFillingInstruction11"
                            ></textarea>
                        </div>
                        
                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(12)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField12 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent12"
                                [placeholder]="documentDto.freeFieldFillingInstruction12"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(13)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField13 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent13"
                                [placeholder]="documentDto.freeFieldFillingInstruction13"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="isFreeFieldFillingShown(14)">
                            <label class="form-label" for="document-free-field">{{ documentDto.freeField14 }}</label>
                            <textarea
                                id="document-free-field-filling-instruction"
                                class="form-control"
                                cols="30"
                                rows="3"
                                formControlName="freeFieldContent14"
                                [placeholder]="documentDto.freeFieldFillingInstruction14"
                            ></textarea>
                        </div>

                        <div class="form-group mb-3" *ngIf="this.isGrantedPermissionForSettingProductPageUrl">
                            <label class="form-label" for="document-page-link">{{ '::ProductPageUrl' | abpLocalization }}</label>
                            <div class="input-group mb-3">
                                <span class="input-group-text" *ngIf="ecommerceUrl">{{ ecommerceUrl }}</span>
                                <input type="text" id="document-page-link" class="form-control" formControlName="productUrlPath" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                        </div>

                        <div 
                            class="form-group mb-3"
                            *ngIf="documentDto.productUrlPath && ecommerceUrl"
                        >
                            <label class="form-label" for="document-page-link">{{ '::ProductPageUrl' | abpLocalization }}</label>
                            <button 
                                (click)="goToProductPage()"
                                type="button" 
                                role="button" 
                                class="btn btn-primary w-100"
                            >
                                {{ '::ProductPageUrlButton' | abpLocalization }}
                            </button>
                        </div>

                        <div class="row mb-3" *ngIf="tenantExist">
                            <label class="form-label" for="document-renewal-period">{{ '::DocumentRenewalPeriod' | abpLocalization }}</label>
                            <div class="col">
                                <input type="number" id="document-name" class="form-control" formControlName="renewalPeriodNumber" [readOnly]="true" [abpDisabled]="true"/>
                            </div>
                            <div class="col">
                                <select id="renewalPeriod" class="form-select form-control"   formControlName="renewalPeriod" [readOnly]="true" [abpDisabled]="true">
                                    <option value=""></option>
                                    <option [ngValue]="renewalPeriod.value" *ngFor="let renewalPeriod of renewalPeriods">
                                        {{ '::Enum:Document:RenewalPeriod:' + renewalPeriod.value | abpLocalization }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="form-group mb-3" *ngIf="showAvailableUntilField()">
                            <label class="form-label" for="available-date">{{ '::AvailableUntilDate' | abpLocalization }} </label>
                            <input 
                                id="available-date"
                                #datepickerStart="ngbDatepicker"
                                class="form-control"
                                name="datepickerStart"
                                formControlName="availableUntil"
                                ngbDatepicker
                                (click)="datepickerStart.toggle()"
                                [abpDisabled]="true"
                                [readOnly]="true"
                            >
                        </div>

                        <div class="form-group mb-3" *ngIf="isAnyFreeFieldFillingsAreShown()">
                            <button type="button" class="btn btn-secondary" (click)="navigateToDocuments()">
                                {{ '::Cancel' | abpLocalization }}
                            </button>

                            <button type="button" (click)="saveFreeFieldsData()" class="btn btn-primary mx-2">
                                {{ '::Save' | abpLocalization }}
                            </button>
                        </div>
                    </form>
                    </div> 
                </div>

                <div class="tab-content" [style]="isActiveTab === 1 ? '' : 'display: none;'">
                    <div
                        class="tab-pane fade"
                        id="1"
                        role="tabpanel"
                        aria-labelledby="main-tab1"
                        [class.active]="isActiveTab === 1 ? true : false"
                        [class.show]="isActiveTab === 1 ? true : false"
                    >
                        <app-document-tag-list 
                            [tags]="tags"
                        ></app-document-tag-list>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-sm-12">
        <app-document-task-list 
            *ngIf="isTaskListShown()" 
            [tasks]="tasks"
            (updatedTask)="onUpdatedTask($event)"
        ></app-document-task-list>
    </div>
<div>

<div id="pdf-viewer">
    <app-document-pdf-viewer 
        *ngIf="isPdfShown" 
        [documentId]="tenantHostDocument">
    </app-document-pdf-viewer> 
</div>

