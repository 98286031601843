import { RestService } from '@abp/ng.core';
import type { ListResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TenantUserLookupDto, UserLookupDto } from '../enova-users-lookup/models';

@Injectable({
  providedIn: 'root',
})
export class EnovaUserLookupService {
  apiName = 'Default';
  

  getTenantUserLookup = () =>
    this.restService.request<any, ListResultDto<TenantUserLookupDto>>({
      method: 'GET',
      url: '/api/app/enova-user-lookup/tenant-user-lookup',
    },
    { apiName: this.apiName });
  

  getUserLookup = () =>
    this.restService.request<any, ListResultDto<UserLookupDto>>({
      method: 'GET',
      url: '/api/app/enova-user-lookup/user-lookup',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
