<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:TestResults' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>
</div>

<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="" >
        <abp-advanced-entity-filters-form>
            <form [formGroup]="filterForm">
                <div class="row">

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group mb-3">
                            <label for="test-attempt-user" class="form-label">
                                {{ '::TestAttemptUser' | abpLocalization }}
                            </label>
                            <select id="test-attempt-user" class="form-select form-control" formControlName="testAttemptUser" (ngModelChange)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="user.id" *ngFor="let user of tenantUsers$ | async">{{ user.userName }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                            <div>
                                <label for="test-attempt-document" class="form-label">
                                    {{ '::SearchByDocument' | abpLocalization }}
                                </label>
                            </div>
                            <div class="input-group">
                                <input
                                    class="form-control" 
                                    id="typeahead-focus"
                                    type="search"
                                    class="form-control"
                                    formControlName="documentName"
                                    [editable]="false"
                                    (selectItem)="selectedDocumentItem($event)"
                                    [resultFormatter]="documentFormatter"
                                    [inputFormatter]="documentFormatter"
                                    (input)="getListWhenDocumentInputEmpty($event.target.value)"
                                    [ngbTypeahead]="searchAttemptByDocument"
                                    (focus)="focusForDocumentFilter$.next($any($event).target.value)"
                                    (click)="clickForDocumentFilter$.next($any($event).target.value)"
                                    #instance="ngbTypeahead"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12 my-1">
                        <div class="form-group">
                            <div>
                                <label for="test-attempt-course" class="form-label">
                                    {{ '::SearchByCourse' | abpLocalization }}
                                </label>
                            </div>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    id="typeahead-focus"
                                    type="search"
                                    class="form-control"
                                    formControlName="courseName"
                                    [editable]="false"
                                    (selectItem)="selectedCourseItem($event)"
                                    [resultFormatter]="courseFormatter"
                                    [inputFormatter]="courseFormatter"
                                    (input)="getListWhenCourseInputEmpty($event.target.value)"
                                    [ngbTypeahead]="searchAttemptByCourse"
                                    (focus)="focusForCourseFilter$.next($any($event).target.value)"
                                    (click)="clickForCourseFilter$.next($any($event).target.value)"
                                    #instance="ngbTypeahead"
                                />
                            </div>
                        </div>
                    </div>


                </div>
                    <div class="col-12 col-sm-auto align-self-start mb-3">
                        <div class="row">
                            <div class="col-6 col-sm-auto d-grid">
                                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                                    <span>{{ '::Clear' | abpLocalization }}</span>
                                </button>
                            </div>
                            <div class="col-6 col-sm-auto d-grid">
                                <button type="button" class="btn btn-primary" (click)="list.get()">
                                    <span>{{ '::Refresh' | abpLocalization }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
            </form>
        </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div class="card">
    <div class="card-body">
        <ngx-datatable
            [rows]="testResult.items"
            [count]="testResult.totalCount"
            [list]="list"
            default
        >
            <ngx-datatable-column [name]="'::TestName' | abpLocalization" prop="testName"></ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::TestCode' | abpLocalization" prop="testCode"></ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::Score' | abpLocalization" prop="score"></ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::TestUserName' | abpLocalization" prop="userName"></ngx-datatable-column>

            <ngx-datatable-column 
                [name]="'::StartTestTimeDate' | abpLocalization" 
                prop="startTime"
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.startTime | date: 'medium' }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column 
                [name]="'::EndTestTimeDate' | abpLocalization" 
                prop="endTime"
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.endTime | date: 'medium' }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column 
                [name]="'::CreationDate' | abpLocalization" 
                prop="creationTime"
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.creationTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
            
        </ngx-datatable>
    </div>
</div>