import { Component, Injector } from '@angular/core';
import {
    AccountLayoutComponent,
    Layout,
    LayoutStateService,
} from '@volo/abp.ng.theme.lepton';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    template: `
        <div class="account-brand p-4 text-center mb-1" *ngIf="isAccount; else link">
            <ng-template></ng-template>
        </div>

        <ng-template #link>
            <a [style.background-image]="logoUrl | async" class="navbar-brand" routerLink="/"></a>
        </ng-template>
    `,
})
export class LogoComponent {
    isAccount: boolean;

    logoColor: Observable<Layout.LogoColor>;

    get logoUrl() {
        return this.logoColor.pipe(map(color => `url(/assets/images/logo/logo-${color}.png)`));
    }

    constructor(injector: Injector) {
        const layout = injector.get(LayoutStateService);
        this.isAccount = Boolean(injector.get(AccountLayoutComponent, false));
        this.logoColor = this.isAccount ? layout.getSecondaryLogoColor$() : layout.getPrimaryLogoColor$();
    }
}
