import { PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentTagDto } from '@proxy/documents';

@Component({
  selector: 'app-document-tag-list',
  templateUrl: './document-tag-list.component.html',
  styleUrls: ['./document-tag-list.component.scss']
})
export class DocumentTagListComponent implements OnInit {
  @Input() tags: PagedResultDto<DocumentTagDto> = { items: [], totalCount: 0 };

  constructor() { }

  ngOnInit(): void {}
}
