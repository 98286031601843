import type { CategoryDto, CreateCategoryDto, GetCategoriesListDto, UpdateCategoryDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HostCategoriesService {
  apiName = 'Default';
  

  create = (input: CreateCategoryDto) =>
    this.restService.request<any, CategoryDto>({
      method: 'POST',
      url: '/api/app/host-categories',
      body: input,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/host-categories/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, CategoryDto>({
      method: 'GET',
      url: `/api/app/host-categories/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetCategoriesListDto) =>
    this.restService.request<any, PagedResultDto<CategoryDto>>({
      method: 'GET',
      url: '/api/app/host-categories',
      params: { filter: input.filter, parentCategoryId: input.parentCategoryId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  update = (id: string, input: UpdateCategoryDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/host-categories/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
