import { ConfigStateService, featuresFactory, noop } from '@abp/ng.core';
import { APP_INITIALIZER, inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderConsts } from 'src/app/shared/Constants/providers.constants';

export const TASKS_MANAGEMENT_FEATURES = new InjectionToken<Observable<{ enable: boolean }>>(
    'TASKS_MANAGEMENT_FEATURES',
    {
        providedIn: 'root',
        factory: () => {
        const configState = inject(ConfigStateService);
        const featureKey = ProviderConsts.TASK_FEATURE_KEY;
        const mapFn = (features: Record<string, string>) => ({
            enable: features[featureKey].toLowerCase() !== 'false',
        });
        return featuresFactory(configState, [featureKey], mapFn);
        },
    },
);

export const TASKS_MANAGEMENT_ROUTE_VISIBILITY = new InjectionToken<Observable<boolean>>(
    'TASKS_MANAGEMENT_ROUTE_VISIBILITY',
    {
        providedIn: 'root',
        factory: () => {
        const stream = inject(TASKS_MANAGEMENT_FEATURES);
        return stream.pipe(map(features => features.enable));
        },
    },
);

export const TASKS_MANAGEMENT_FEATURES_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: noop,
        deps: [TASKS_MANAGEMENT_ROUTE_VISIBILITY],
        multi: true,
    },
];
