import { APP_INITIALIZER, Injector } from '@angular/core';
import { combineLatest } from 'rxjs';
import { RoutesService } from '@abp/ng.core';
import { DOCUMENTS_MANAGEMENT_ROUTE_VISIBILITY } from './features.token';
import { ProviderConsts } from 'src/app/shared/Constants/providers.constants';

export const DOCUMENTS_MANAGEMENT_VISIBLE_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: documentsManagementVisibility,
    deps: [Injector],
    multi: true,
  },
];

export function documentsManagementVisibility(injector: Injector) {
  return () => {
    const isDocumentsManagementFeatureEnable$ = injector.get(DOCUMENTS_MANAGEMENT_ROUTE_VISIBILITY);
    const routes = injector.get(RoutesService);
    combineLatest([isDocumentsManagementFeatureEnable$]).subscribe(
      ([isDocumentsManagementFeatureEnable]) => {
        routes.patch(ProviderConsts.DOCUMENT_ROUTE_PATCH_NAME, {
          invisible: !isDocumentsManagementFeatureEnable,
        });
      },
    );
  };
}
