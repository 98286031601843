import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TicketsCalendarComponent } from './calendar/tickets-calendar.component';
import { TicketComponent } from './ticket.component';

const routes: Routes = [
  { path: '', component: TicketComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'calendar', component: TicketsCalendarComponent, canActivate: [AuthGuard, PermissionGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketRoutingModule { }
