import { ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BuildingDto, TenantBuildingsService } from '@proxy/buildings';
import { Constants } from '../shared/Constants/Constants';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
  providers: [ListService]
})
export class BuildingComponent implements OnInit {
  building = { items: [], totalCount: 0} as PagedResultDto<BuildingDto>;
  isModalOpen: boolean = false;
  selectedBuilding: BuildingDto;
  form: UntypedFormGroup;
  DateTimeFormatConst = Constants.DATETIME;

  constructor(
    public readonly list: ListService,
    private buildingService: TenantBuildingsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private confirmation: ConfirmationService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    const buildingStreamCreator = (query) => this.buildingService.getList(query);

    this.list.hookToQuery(buildingStreamCreator).subscribe((response) => {
      this.building = response;
    })
  }

  buildForm(){
    this.form = this.fb.group({
      title: [this.selectedBuilding.title || '', Validators.required],
      code: [this.selectedBuilding.code || '', Validators.required],
      description: [this.selectedBuilding.description || '']
    });
  }

  createBuilding(){
    this.selectedBuilding = {} as BuildingDto;
    this.buildForm();
    this.isModalOpen = true;
  }

  saveBuilding(){
    if(this.form.invalid){
      return;
    }

    if(this.selectedBuilding.id){
      this.updateSelectedBuilding();
    } else {
      this.createNewBuilding();
    }
  }

  editBuilding(buildingId: string){
    this.buildingService.get(buildingId).subscribe((building) => {
      this.selectedBuilding = building;
      this.buildForm();
      this.isModalOpen = true;
    })
  }

  deleteBuilding(buildingId: string){
    this.confirmation.warn('::AreYouSureToDeleteBuilding', '::AreYouSure').subscribe((status) => {
      if(status === Confirmation.Status.confirm){
        this.buildingService.delete(buildingId).subscribe({
          next: () => this.list.get(),
          complete: () => {
            this.toaster.success(
              this.localizationService.instant('::Building:Message:Delete:Success')
            );
          },
          error: () => {
            this.toaster.error(
              this.localizationService.instant('::Building:Message:Delete:Error')
            );
          }
        })
      }
    })
  }

  createNewBuilding(){
    this.buildingService.create(this.form.value).subscribe({
      next: () => {
        this.list.get();
        this.isModalOpen = false;
        this.form.reset();
      },
      complete: () => {
        this.toaster.success(
          this.localizationService.instant("::Building:Message:Create:Success")
        );
      },
      error: () => {
        this.toaster.error(
          this.localizationService.instant("::Building:Message:Create:Error")
        );
      }
    })
  }

  updateSelectedBuilding(){
    this.buildingService.update(this.selectedBuilding.id, this.form.value)
      .subscribe({
        next: () => {
          this.isModalOpen = false;
          this.form.reset();
          this.list.get();
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::Building:Message:Edit:Success")
          );
        },
        error: () =>{
          this.toaster.error(
            this.localizationService.instant("::Building:Message:Edit:Error")
          );
        }
      })
  }
}
