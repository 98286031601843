import { TestUpdateComponent } from './test-update/test-update.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from "@angular/core";
import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { TestsComponent } from './tests.component';

const routes: Routes = [
    { path: '', component: TestsComponent, canActivate: [AuthGuard, PermissionGuard]},
    { path: 'create', component: TestUpdateComponent, canActivate: [AuthGuard, PermissionGuard] },
    { path: 'edit/:id', component: TestUpdateComponent, canActivate: [AuthGuard, PermissionGuard] }
]

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class TestRoutingModule { }