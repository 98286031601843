import { GetTenantActionListDto } from './../../proxy/tenant-actions/models';
import { ToasterService } from '@abp/ng.theme.shared';
import { ListService, LocalizationService } from "@abp/ng.core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TenantActionDto, TenantActionsService } from "@proxy/tenant-actions";
import { Subscription } from "rxjs";
import { Constants } from 'src/app/shared/Constants/Constants';

@Component({
    selector: 'app-tenant-actions-report',
    templateUrl: './tenant-actions-report.component.html',
    styleUrls: ['./tenant-action-report.component.scss'],
    providers: [ListService]
})
export class TenantActionsReportComponent implements OnInit {
    tenantActions:TenantActionDto[] = [];
    isLoading: boolean = false;
    private routeSub: Subscription;

    DateTimeFormatConst = Constants.DATETIME;

    constructor(
        public readonly list: ListService, 
        private route: ActivatedRoute,
        private tenantActionsService: TenantActionsService,
        private toaster: ToasterService,
        private localizationService: LocalizationService,
    )
    {}

    ngOnInit(): void {
        this.routeSub = this.route.queryParams.subscribe( params => {
            this.tenantActions = [];
            var request: GetTenantActionListDto;

            request = {
                type: params['type'] || null,
                entity: params['entity'] || null,
                userName: params['userName'] || null,
                documentId: params['documentId'] || null,
                courseId: params['courseId'] || null,
                position: params['position'] || null,
                nameAndSurname: params['nameAndSurname'] || null,
                dateFrom: params['dateFrom'] || null,
                dateTo: params['dateTo'] || null,
                maxResultCount: null
            };

            this.tenantActionsService.getReportData(request).subscribe({
                next: (responseNext) => {
                    this.tenantActions = responseNext;
                },
                complete: () => {
                },
                error: () => {
                    this.toaster.error(
                        this.localizationService.instant("::TenantActions:Message:Print:Error")
                    );
                }
            });

        });
    }

    printReport() {
        window.print();
    }

}