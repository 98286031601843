<div class="w-100 pt-5">
    <app-spinner [isLoading]="isLoading"></app-spinner>

    <div class="w-100 bg-white position-relative rounded">

        <div class="w-100 text-center" *ngIf="fileStreamSource != null">
            <div class=" d-flex justify-content-center">
                <div class="position-absolute w-100 bottom-0">
                    <div class="video-player-wrapper">
                        <vg-player class="rounded" style="height: 50px;">
                            <vg-controls>
                                <vg-play-pause></vg-play-pause>
                                <vg-playback-button></vg-playback-button>

                                <vg-time-display vgProperty="current" vgFormat="mm:ss">
                                </vg-time-display>

                                <vg-scrub-bar>
                                    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                                    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                                </vg-scrub-bar>

                                <vg-time-display vgProperty="left" vgFormat="mm:ss">
                                </vg-time-display>
                                <vg-time-display vgProperty="total" vgFormat="mm:ss">
                                </vg-time-display>

                                <vg-volume></vg-volume>
                                <vg-mute></vg-mute>

                            </vg-controls>

                            <audio #media [vgMedia]="media" id="myAudio" [src]="fileStreamSource" preload="auto">
                            </audio>
                        </vg-player>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>