import { APP_INITIALIZER, Injector } from '@angular/core';
import { combineLatest } from 'rxjs';
import { RoutesService } from '@abp/ng.core';
import { COURSES_MANAGEMENT_ROUTE_VISIBILITY } from './features.token';
import { ProviderConsts } from 'src/app/shared/Constants/providers.constants';

export const COURSES_MANAGEMENT_VISIBLE_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: coursesManagementVisibility,
        deps: [Injector],
        multi: true,
    },
];

export function coursesManagementVisibility(injector: Injector) {
    return () => {
        const isCoursesManagementFeatureEnable$ = injector.get(COURSES_MANAGEMENT_ROUTE_VISIBILITY);
        const routes = injector.get(RoutesService);
        combineLatest([isCoursesManagementFeatureEnable$]).subscribe(
        ([isCoursesManagementFeatureEnable]) => {
            routes.patch(ProviderConsts.COURSE_ROUTE_PATCH_NAME, {
            invisible: !isCoursesManagementFeatureEnable,
            });
        },
        );
    };
}