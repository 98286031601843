import { FormControl } from "@angular/forms";

export function allowedFileSize(size: number) {
    return function (control: FormControl) {
        const file = control.value;
        if(file) {
            if(file.size > size) {
                return {
                    allowedFileSize: true
                };
            }

            return null;
        }

        return null;
    }
}