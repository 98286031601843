<ngx-extended-pdf-viewer
    *ngIf="blob"
    [src]="blob"
    [useBrowserLocale]="true"
    backgroundColor="#ffffff"
    [height]="'90vh'"
    [useBrowserLocale]="true"
    [handTool]="false"
    [showHandToolButton]="true"
    [showOpenFileButton]="false"
></ngx-extended-pdf-viewer>