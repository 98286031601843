import { Environment } from '@abp/ng.core';

const baseUrl = 'https://savitarna-staging.e-nova.lt';

const oAuthConfig = {
  issuer: 'https://savitarna-staging-api.e-nova.lt/',
  redirectUri: baseUrl,
  clientId: 'Enova_App',
  responseType: 'code',
  scope: 'offline_access Enova',
  requireHttps: true,
};

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'Enova (staging)',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://savitarna-staging-api.e-nova.lt',
      rootNamespace: 'Kirotech.Enova',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  }
} as Environment;

export const fileApis = {
  downloadApi: environment.apis.default.url + '/api/file-management/file-descriptor/download/{fileId}?token={token}',
  downloadClassApi: environment.apis.default.url + '/api/app/course-classes/download?classId={classId}&access_token={accessToken}',
  viewClassFileStreamApi: environment.apis.default.url + '/api/app/course-classes/file-stream?classId={classId}&access_token={accessToken}'
}

export const allowedPictureTypes = {
  types: [
    "jpeg",
    "jpg",
    "png",
    "gif",
    "webp",
    "svg"
  ]
}

export const allowedImportTypes = {
  types: [
    "xls",
    "xlsx"
  ]
}

export const allowedFileTypes = {
  types: [
    'jpeg',
    "jpg",
    "png",
    "gif",
    "pdf",
    "html",
    "xls",
    "xlsx",
    "doc",
    "docx",
    "ppt",
    "pptx",
    'mp3',
    'mp4',
    "mov"
  ]
}

export const tawkTo = {
  widgetId: '1gjjgr0pk',
  chatId: '638f1e14daff0e1306db2788'
}

export const externalLogoutUri = {
  magentoLogoutUri: 'https://staging.e-nova.lt/customer/account/logout?external_logout=1'
}

export const stripePaymentSettings = {
  publishableKey: 'pk_test_51MRcauAcBrQwffFQrINtiFLSKVc7Bfa0rScKc9VMnkzPaN7QeWt8Uv267hoPo4t4ZOW51Oc9ErXejcihJzRRZKMm00Vy7ecNO3'
}