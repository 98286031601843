import type { CreateEmployeeDto, EmployeeDto, GetEmployeeListDto, UpdateEmployeeDto } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { EnovaOrganizationUnitDto } from '../organization-units/models';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  apiName = 'Default';
  

  create = (input: CreateEmployeeDto) =>
    this.restService.request<any, EmployeeDto>({
      method: 'POST',
      url: '/api/app/employees',
      body: input,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/employees/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, EmployeeDto>({
      method: 'GET',
      url: `/api/app/employees/${id}`,
    },
    { apiName: this.apiName });
  

  getDepartmentLookup = () =>
    this.restService.request<any, ListResultDto<EnovaOrganizationUnitDto>>({
      method: 'GET',
      url: '/api/app/employees/department-lookup',
    },
    { apiName: this.apiName });
  

  getLastFiveEmployeesList = (input: GetEmployeeListDto) =>
    this.restService.request<any, PagedResultDto<EmployeeDto>>({
      method: 'GET',
      url: '/api/app/employees/last-five-employees-list',
      params: { filter: input.filter, organizationUnitPositionId: input.organizationUnitPositionId, organizationUnitDepartmentId: input.organizationUnitDepartmentId, isDoingDangerousJobs: input.isDoingDangerousJobs, isGettingSafetyMeasures: input.isGettingSafetyMeasures, isWorkingWithDangerousEquipment: input.isWorkingWithDangerousEquipment, hasHealthChecked: input.hasHealthChecked, renewalPeriodNumber: input.renewalPeriodNumber, hasWorkSafetyCertificate: input.hasWorkSafetyCertificate, hasWorkingRestrictions: input.hasWorkingRestrictions, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getList = (input: GetEmployeeListDto) =>
    this.restService.request<any, PagedResultDto<EmployeeDto>>({
      method: 'GET',
      url: '/api/app/employees',
      params: { filter: input.filter, organizationUnitPositionId: input.organizationUnitPositionId, organizationUnitDepartmentId: input.organizationUnitDepartmentId, isDoingDangerousJobs: input.isDoingDangerousJobs, isGettingSafetyMeasures: input.isGettingSafetyMeasures, isWorkingWithDangerousEquipment: input.isWorkingWithDangerousEquipment, hasHealthChecked: input.hasHealthChecked, renewalPeriodNumber: input.renewalPeriodNumber, hasWorkSafetyCertificate: input.hasWorkSafetyCertificate, hasWorkingRestrictions: input.hasWorkingRestrictions, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getPositionLookup = () =>
    this.restService.request<any, ListResultDto<EnovaOrganizationUnitDto>>({
      method: 'GET',
      url: '/api/app/employees/position-lookup',
    },
    { apiName: this.apiName });
  

  update = (id: string, input: UpdateEmployeeDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/employees/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
