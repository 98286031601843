import { SettingTabsService } from '@abp/ng.setting-management/config';
import { Component, OnInit } from '@angular/core';
import { IncidentAdminTabComponent } from './incident/incident-admin-tab/incident-admin-tab.component';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfigStateService, ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { LogoComponent } from './logo/logo.component';
import { CompanyComponent } from './company-profile-tab/company.component';
import { SonaroCrmTabComponent } from './sonaro-crm-tab/sonaro-crm-tab.component';
import { externalLogoutUri, tawkTo } from 'src/environments/environment';
import { EcommerceTabComponent } from './ecommerce-tab/ecommerce-tab.component';
import { ProductRenewalExpirationDaysComponent } from './Product-Renewal-Expiration-Days-tab/product-renewal-expiration-days-tab.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavItemsService } from '@abp/ng.theme.shared';
import { LinkToShopComponent } from './link-to-shop/link-to-shop.component';
import { DocumentApprovalTabComponent } from './document-approval-tab/document-approval-tab.component';

declare global {
  interface Window {
    usetifulTags: {
      isShown: string;
      userId: string;
    };
  }
}

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <app-tawk-to [id]="tawkToChatId" [widgetId]="tawkToWidgetId"></app-tawk-to>
  `,
  providers: [NgbTimepickerConfig],
})

export class AppComponent implements OnInit {
  tawkToWidgetId = tawkTo.widgetId;
  tawkToChatId = tawkTo.chatId;
  
  constructor(
    private settingTab: SettingTabsService,
    private replace: ReplaceableComponentsService,
    private config: ConfigStateService,
    oAuthService: OAuthService,
    router: Router,
    private navItems: NavItemsService
  ){
    const currentUser = this.config.getOne("currentUser");
    if(currentUser.isAuthenticated && currentUser.tenantId != null && currentUser.roles.includes("admin")){
      window.usetifulTags = {
        isShown: 'true',
        userId: currentUser.id
      };
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.usetiful.com/dist/usetiful.js';
      script.setAttribute('id', 'usetifulScript');
      script.dataset.token = 'cbb53babaf80636125d74018aa5ffe0a';
      document.head.appendChild(script);
    }
    

    oAuthService.events.pipe(filter(e => e.type === 'logout')).subscribe(() => { 
      const currentUrl = router.url.split('?')[0];
      if (currentUrl !== '/logout') {
        window.open(externalLogoutUri.magentoLogoutUri, '_blank');
      }
    });

    this.config.getFeature$("Incident.Control").subscribe((value) => {
      if(value == "true"){
        settingTab.add([
          {
            name: '::Incidents',
            requiredPolicy: 'Incidents.Incidents.AddSetting',
            component: IncidentAdminTabComponent
          }
        ]);
      }
    });

    navItems.addItems([
      {
        id: 'LinkToShop',
        order: 1,
        component: LinkToShopComponent,
      }
    ]);

    settingTab.add([{
      name: '::CompanyProfile',
      requiredPolicy: 'Companies.Companies.AddSettingsForCompany',
      component: CompanyComponent
    }]);

    settingTab.add([{
      name: '::SonaroCRM',
      requiredPolicy: 'Sonaro CRM.SonaroCRM.AddSettingsForSonaroCRM',
      component: SonaroCrmTabComponent
    }]);

    settingTab.add([{
      name: '::Ecommerce',
      requiredPolicy: 'E-commerce.E-commerce.AddSettingsForEcommerce',
      component: EcommerceTabComponent
    }]);

    settingTab.add([{
      name: '::ProductRenewalExpirationDay',
      requiredPolicy:"Tickets.Tickets.RenewalSettings",
      component: ProductRenewalExpirationDaysComponent
    }]);

    settingTab.add([{
      name: '::DocumentAdministration',
      requiredPolicy: 'DocumentApproval.Settings',
      component: DocumentApprovalTabComponent
    }]);
		// this.configureTimePickerComponent(globalTimePickerConfig);
  }

  ngOnInit(): void {
    this.replace.add({
      key: eThemeLeptonComponents.Logo,
      component: LogoComponent
    });
  }

  // configureTimePickerComponent(globalTimePickerConfig: NgbTimepickerConfig) {
  //   globalTimePickerConfig.spinners = false;
  // }
}
