import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ClassDto, CreateClassDto, UpdateClassDto } from '../../classes/models';

@Injectable({
  providedIn: 'root',
})
export class CourseClassesService {
  apiName = 'Default';
  

  create = (input: CreateClassDto, courseId: string) =>
    this.restService.request<any, ClassDto>({
      method: 'POST',
      url: `/api/app/course-classes/${courseId}`,
    },
    { apiName: this.apiName });
  

  update = (input: UpdateClassDto, id: string) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/course-classes/${id}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
