import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ACTIVITY_TYPES_ACTIVITY_TYPE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/activity-types',
        iconClass: 'fas fa-hiking',
        name: '::Menu:ActivityTypes',
        order: 11,
        layout: eLayoutType.application,
        requiredPolicy: 'ActivityTypes.ActivityTypes.View',
      },
    ]);
  };
}
