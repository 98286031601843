<div class="row entry-row">
    <div class="div col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Incidents' | abpLocalization }}</h1>
    </div>
    <div class="col pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>
    <div class="col" *abpPermission="'Incidents.Incidents.Create'">
        <div class="text-right float-end">
            <button
                (click)="createIncident()"
                class="btn btn-sm btn-primary"
                type="button"
            >
                <i class="me-1 fa fa-plus mr-1"></i>
                <span>{{ '::NewIncident' | abpLocalization }}</span>
            </button>
        </div>
    </div>
</div>

<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="" >
        <abp-advanced-entity-filters-form>
            <form [formGroup]="filterForm">
                <div class="row">
                    <div class="col-lg-6 col-sm-12 mb-2">
                        <div class="form-group">
                            <label for="incident-status" class="form-label">
                                {{ '::SearchByStatus' | abpLocalization }}
                            </label>
                            <select id="incident-status" class="form-select form-control" formControlName="status" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [value]="status.value" *ngFor="let status of statusTypes">
                                    {{'::Enum:IncidentStatus:' + status.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 mb-2">
                        <div class="form-group">
                            <label for="incident-status" class="form-label">
                                {{ '::SearchByType' | abpLocalization }}
                            </label>
                            <select id="incident-type" class="form-select form-control" formControlName="type" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [value]="type.value" *ngFor="let type of incidentTypes">
                                    {{'::Enum:IncidentType:' + type.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label for="incident-creation-date-from" class="form-label">
                                {{ '::CreationDateFrom' | abpLocalization }}
                        </label>
                          <div class="input-group">
                            <input 
                              id="creation-date-from"
                              placeholder="{{ '::CreationDateFrom' | abpLocalization }}"
                              #datepickerCreationTimeFrom="ngbDatepicker"
                              class="form-control"
                              name="datepickerCreationTimeFrom"
                              formControlName="creationTimeFrom"
                              ngbDatepicker
                              (click)="datepickerCreationTimeFrom.toggle()"
                              (ngModelChange)="getListWithFilters()"
                            >                                
                          </div>
                        </div>
                      </div>
            
                      <div class="col-lg-6 col-sm-12">
                        <div class="form-group">
                            <label for="incident-creation-date-to" class="form-label">
                                {{ '::CreationDateTo' | abpLocalization }}
                        </label>
                          <div class="input-group">
                            <input 
                              id="creation-date-to"
                              placeholder="{{ '::CreationDateTo' | abpLocalization }}"
                              #datepickerCreationTimeTo="ngbDatepicker"
                              class="form-control"
                              name="datepickerCreationTimeTo"
                              formControlName="creationTimeTo"
                              ngbDatepicker
                              (click)="datepickerCreationTimeTo.toggle()"
                              (ngModelChange)="getListWithFilters()"
                            >                                
                          </div>
                        </div>
                      </div>
                </div>
                <div class="col-12 col-sm-auto align-self-start mt-3">
                    <div class="row">
                        <div class="col-6 col-sm-auto d-grid">
                            <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                                <span>{{ '::Clear' | abpLocalization }}</span>
                            </button>
                        </div>
                        <div class="col-6 col-sm-auto d-grid">
                            <button type="button" class="btn btn-primary" (click)="list.get()">
                                <span>{{ '::Refresh' | abpLocalization }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div class="card">
    <div class="card-body">
        <ngx-datatable [rows]="incidents.items" [count]="incidents.totalCount" [list]="list" default>
            <ngx-datatable-column
                [name]="'::Actions' | abpLocalization"
                [sortable]="false"
                [maxWidth]="150"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                <div ngbDropdown container="body" class="d-inline-block">
                    <button
                        class="btn btn-primary btn-sm dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        ngbDropdownToggle
                    >
                    <i class="fa fa-cog mr-1"></i>
                    {{ '::Actions' | abpLocalization }}
                    </button>
                    <div ngbDropdownMenu>                                
                        <button
                            ngbDropdownItem
                            (click)="viewIncidentData(row.id)"
                            *abpPermission="'Incidents.Incidents.View'"
                        >
                            {{ '::View' | abpLocalization }}
                        </button>
                        <button
                            ngbDropdownItem
                            *abpPermission="'Incidents.Incidents.Delete'"
                            (click)="deleteIncident(row.id)"
                        >
                            {{ '::Delete' | abpLocalization }}
                        </button>
                    </div>
                </div>
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column
                [name]="'::Title' | abpLocalization"
                prop="title"
            ></ngx-datatable-column>
            
            <ngx-datatable-column
                [name]="'::Description' | abpLocalization"
                prop="description"
            ></ngx-datatable-column>
            
            <ngx-datatable-column
                [name]="'::Type' | abpLocalization"
                prop="type"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ '::Enum:IncidentType:' + row.type | abpLocalization}}
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column
                [name]="'::Status' | abpLocalization"
                prop="status"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ '::Enum:IncidentStatus:' + row.status | abpLocalization }}
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column
                [name]="'::IncidentDateTime' | abpLocalization"
                prop="incidentDateTime"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.incidentDateTime | date: 'YYYY-MM-d HH:mm'}}
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-column 
                [name]="'::CreationDate' | abpLocalization" 
                prop="creationTime"
                [maxWidth]="150"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.creationTime | date: DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>



<abp-modal [(visible)]="isModalOpen" [busy]="isLoading">
    <ng-template #abpHeader>
        <h3>
            {{ '::NewIncident' | abpLocalization }}
        </h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="form" (ngSubmit)="saveIncident()">
            <div class="form-group mb-3">
                <label class="form-label" for="incident-title">{{ '::Title' | abpLocalization }}</label><span> *</span>
                <input type="text" id="incident-title" class="form-control" formControlName="title" />
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="incident-description">
                    {{ '::Description' | abpLocalization }}
                </label><span> *</span>
                <textarea
                    id="incident-description"
                    class="form-control"
                    cols="30"
                    rows="7"
                    formControlName="description"
                ></textarea>
            </div>

            <div class="form-group mb-3">
                <label for="incident-type" class="form-label">{{ '::Type' | abpLocalization}}</label><span> *</span>
                <select id="incident-type" class="form-select form-control" formControlName="type">
                    <option value=""></option>
                    <option [ngValue]="type.value" *ngFor="let type of incidentTypes">
                        {{ '::Enum:IncidentType:' + type.value | abpLocalization }}
                    </option>
                </select>
            </div>            

            <div class="form-group mb-3" *abpPermission="'Incidents.Incidents.ChangeIncidentStatus'">
                <label for="incident-status" class="form-label">{{ '::Status' | abpLocalization }}</label><span> *</span>
                <select id="incident-status" class="form-select form-control" formControlName="status" (change)="selectIncidentStatus(form)">
                    <option value=""></option>
                    <option [ngValue]="status.value" *ngFor="let status of statusTypes">
                        {{ '::Enum:IncidentStatus:' + status.value | abpLocalization }}
                    </option>
                </select>
            </div>
            <div [hidden]="!showSolutionTextArea(form)" *abpPermission="'Incidents.Incidents.ChangeIncidentStatus'">
                <div class="card text-white bg-info mb-3">
                    <div class="card-body">
                        <p class="card-text">{{ '::IncidentSolutionPlaceholderText:1' | abpLocalization }}</p>
                        <p class="card-text"> 
                            {{ '::IncidentSolutionPlaceholderText:2' | abpLocalization }}
                            {{ '::IncidentSolutionPlaceholderText:3' | abpLocalization }}
                            {{ '::IncidentSolutionPlaceholderText:4' | abpLocalization }}
                        </p>
                        <p class="card-text">{{ '::IncidentSolutionPlaceholderText:5' | abpLocalization }}</p>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="incident-solution" class="form-label">{{ '::Solution' | abpLocalization }}</label><span> *</span>
                    <textarea  
                        class="form-control"
                        cols="30"
                        rows="7" 
                        id="incident-solution"
                        formControlName="solution">
                    </textarea>
                </div>
            </div>


            <div class="form-group">
                <label for="incident-date">{{ '::Datetime' | abpLocalization }} </label><span> *</span>
                <div class="row">
                    <div class="col-8">
                        <input 
                            id="incident-date"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            formControlName="incidentDate"
                            ngbDatepicker
                            (click)="datepickerStart.toggle()"
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            [abpDisabled]="!this.form.get('incidentDate').value"
                            formControlName="incidentTime"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="incident-file"> {{ '::FileAttachment' | abpLocalization }} </label>

                <div class="spinner-border spinner-border-sm" role="status" *ngIf="isFileLoading">
                    <span class="sr-only">Loading...</span>
                </div>

                <input             
                    #takeInput
                    class="form-control"
                    type="file"
                    formControlName="file"
                    id="incident-file"
                    aria-describedby="incident-file"
                    aria-label="{{ '::IncidentFileName' | abpLocalization }}"
                    (change)="onFileChanged($event)"
                    [abpDisabled]="isLoading"
                />
            </div>
        </form>
    </ng-template>

    <ng-template #abpFooter>
        <button
            type="button"
            class="btn btn-secondary"
            abpClose>
            {{ '::Close' | abpLocalization }}
        </button>

        <abp-button 
            iconClass="fa fa-check" 
            (click)="saveIncident()" 
            [disabled]="form.invalid"
        >
            {{ '::Save' | abpLocalization }}
        </abp-button>
    </ng-template>
</abp-modal>

<abp-modal [(visible)]="isViewModalVisible">
    <ng-template #abpHeader>
        <h3>
            {{ '::IncidentInfo' | abpLocalization }}
        </h3>
    </ng-template>
    <ng-template #abpBody>
        <form [formGroup]="viewForm">
            <div class="form-group mb-3">
                <label class="form-label" for="incident-title">{{ '::Title' | abpLocalization }}</label>
                <input type="text" id="incident-title" class="form-control" formControlName="title" readonly/>
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="incident-description">
                    {{ '::Description' | abpLocalization }}
                </label>
                <textarea
                    id="incident-description"
                    class="form-control"
                    cols="30"
                    rows="8"
                    formControlName="description"
                    readonly
                ></textarea>
            </div>

            <div class="form-group mb-3">
                <label for="incident-type" class="form-label">{{ '::Type' | abpLocalization }}</label>
                <select id="incident-type" class="form-select form-control" formControlName="type" abpDisabled="true" readonly>
                    <option value=""></option>
                    <option [ngValue]="type.value" *ngFor="let type of incidentTypes">
                        {{ '::Enum:IncidentType:' + type.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3" *ngIf="selectedIncident.fileDescriptor">
                <label class="form-label" for="incident-file"> {{ '::FileAttachment' | abpLocalization }} </label>
                <button (click)="downloadFile()" class="btn btn-primary w-100" [disabled]="!selectedIncident.fileDescriptor">
                    {{ '::DownloadIncidentFile' | abpLocalization }}
                </button>
            </div>

            <div class="form-group">
                <label for="task-start-time">{{ '::IncidentDateTime' | abpLocalization }} </label>
                <div class="row">
                    <div class="col-8">
                        <input 
                            id="task-start-time"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            ngbDatepicker
                            formControlName="incidentDate"
                            readonly
                        >
                    </div>
                    <div class="col-4 picker">
                        <ngb-timepicker
                            formControlName="incidentTime"
                            abpDisabled="true"
                        >
                        </ngb-timepicker>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="user-username">{{ '::IncidentCreatorUsername' | abpLocalization }}</label>
                <input type="text" id="user-username" class="form-control" formControlName="username" readonly/>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="user-name">{{ '::IncidentCreatorName' | abpLocalization }}</label>
                <input type="text" id="user-name" class="form-control" formControlName="name" readonly/>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="user-surname">{{ '::IncidentCreatorSurname' | abpLocalization }}</label>
                <input type="text" id="user-surname" class="form-control" formControlName="surname" readonly/>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="user-email">{{ '::IncidentCreatorEmail' | abpLocalization }}</label>
                <input type="text" id="user-email" class="form-control" formControlName="email" readonly/>
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="user-phone">{{ '::IncidentCreatorPhone' | abpLocalization }}</label>
                <input type="text" id="user-phone" class="form-control" formControlName="phone" readonly/>
            </div>

            <div class="form-group mb-3">
                <label for="incident-status" class="form-label">{{ '::Status' | abpLocalization }}</label><span> *</span>
                <select id="incident-status" class="form-select form-control" (change)="selectIncidentStatus(viewForm)" formControlName="status" [abpDisabled]="!canChangeStatus">
                    <option value=""></option>
                    <option [ngValue]="status.value" *ngFor="let status of statusTypes">
                        {{'::Enum:IncidentStatus:' + status.value | abpLocalization }}
                    </option>
                </select>
            </div>

            <div [hidden]="!showSolutionTextArea(viewForm)" *abpPermission="'Incidents.Incidents.ChangeIncidentStatus'">
                <div class="card text-white bg-info mb-3">
                    <div class="card-body">
                        <p class="card-text">{{ '::IncidentSolutionPlaceholderText:1' | abpLocalization }}</p>
                        <p class="card-text"> 
                            {{ '::IncidentSolutionPlaceholderText:2' | abpLocalization }}
                            {{ '::IncidentSolutionPlaceholderText:3' | abpLocalization }}
                            {{ '::IncidentSolutionPlaceholderText:4' | abpLocalization }}
                        </p>
                        <p class="card-text">{{ '::IncidentSolutionPlaceholderText:5' | abpLocalization }}</p>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="incident-solution" class="form-label">{{ '::Solution' | abpLocalization }}</label><span> *</span>
                    <textarea  
                        class="form-control"
                        cols="30"
                        rows="7" 
                        id="incident-solution"
                        formControlName="solution">
                    </textarea>
                </div>
            </div>
        </form>
    </ng-template>
    
    <ng-template 
        *abpPermission="'Incidents.Incidents.ChangeIncidentStatus'"
        #abpFooter>
        <button
            type="button"
            class="btn btn-secondary"
            abpClose>
            {{ '::Close' | abpLocalization }}
        </button>

        <button
            type="button"
            class="btn btn-primary"
            (click)="saveIncidentStatus()"
            [disabled]="viewForm.invalid"
        >
            {{ '::Save' | abpLocalization }}
        </button>
    </ng-template>
    
</abp-modal>