import { TestUpdateComponent } from './test-update/test-update.component';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { TestRoutingModule } from './tests-routing.module';
import { TestsComponent } from './tests.component';
import { TESTS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.providers';
import { TESTS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';

@NgModule({
    declarations: [
        TestsComponent,
        TestUpdateComponent
    ],
    imports: [ 
        NgbModule,
        SharedModule,
        CommonModule,
        TestRoutingModule
    ]
})
export class TestModule { 
    static forRoot(): ModuleWithProviders<TestModule> {
        return {
            ngModule: TestModule,
            providers: [
                TESTS_MANAGEMENT_FEATURES_PROVIDERS,
                TESTS_MANAGEMENT_VISIBLE_PROVIDERS,
            ],
        }
    }
}
