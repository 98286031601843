import { Component, Input, OnInit } from '@angular/core';
import { ClassDto } from '@proxy/classes';

@Component({
  selector: 'app-courses-audio-player',
  templateUrl: './courses-audio-player.component.html',
  styleUrls: ['./courses-audio-player.component.scss']
})
export class CoursesAudioPlayerComponent implements OnInit {
  @Input() class: ClassDto;
  @Input() fileStreamSource: string;

  isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void { }

}
