import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CourseDto } from '../courses/models';
import type { GetTenantCourseListDto, MissingCourseDto } from '../courses/tenant-course/models';
import type { TenantHostCourseDto } from '../courses/tenant-host-course/models';
import type { TicketDto } from '../tickets/models';

@Injectable({
  providedIn: 'root',
})
export class TenantCourseService {
  apiName = 'Default';
  

  get = (id: string) =>
    this.restService.request<any, CourseDto>({
      method: 'GET',
      url: `/api/app/tenant-course/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetTenantCourseListDto) =>
    this.restService.request<any, PagedResultDto<TenantHostCourseDto>>({
      method: 'GET',
      url: '/api/app/tenant-course',
      params: { filter: input.filter, categoryId: input.categoryId, testId: input.testId, availableUntil: input.availableUntil, creationDateFrom: input.creationDateFrom, creationDateTo: input.creationDateTo, documentStatus: input.documentStatus, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getMissingCoursesList = () =>
    this.restService.request<any, ListResultDto<MissingCourseDto>>({
      method: 'GET',
      url: '/api/app/tenant-course/missing-courses-list',
    },
    { apiName: this.apiName });
  

  getTenantCourseTicketsByCourseId = (courseId: string) =>
    this.restService.request<any, ListResultDto<TicketDto>>({
      method: 'GET',
      url: `/api/app/tenant-course/tenant-course-tickets/${courseId}`,
    },
    { apiName: this.apiName });
  

  updateCourseTicketStatusByTicketId = (ticketId: string) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/tenant-course/course-ticket-status/${ticketId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
