<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Buildings' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <div class="col" *abpPermission="'Buildings.Buildings.Create'">
        <div class="text-right float-end">
            <button
                
                class="btn btn-primary"
                type="button"
                (click)="createBuilding()"
            >
                <i class="me-1 fa fa-plus mr-1"></i>
                <span>{{ '::NewBuilding' | abpLocalization }}</span>
            </button>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <div class="data-table-filter">
            <abp-entity-filter [list]="list"></abp-entity-filter>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <ngx-datatable
            [rows]="building.items"
            [count]="building.totalCount"
            [list]="list"
            default
        >
            <ngx-datatable-column
                [name]="'::Actions' | abpLocalization"
                sortable="false"
                [maxWidth]="150"
                *abpPermission="'Buildings.Buildings.Edit || Buildings.Buildings.Delete'"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div ngbDropdown container="body" class="d-inline-block">
                        <button
                            class="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            ngbDropdownToggle
                        >
                            <i class="fa fa-cog mr-1"></i>&nbsp;{{ '::Actions' | abpLocalization }}
                        </button>
                        <div ngbDropdownMenu>
                            <button
                                ngbDropdownItem
                                (click)="editBuilding(row.id)"
                                *abpPermission="'Buildings.Buildings.Edit'"
                            >
                                {{ '::Edit' | abpLocalization }}
                            </button>
                            <button
                                ngbDropdownItem
                                (click)="deleteBuilding(row.id)"
                                *abpPermission="'Buildings.Buildings.Delete'"
                            >
                                {{ '::Delete' | abpLocalization }}
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'::Title' | abpLocalization" prop="title"></ngx-datatable-column>
            
            <ngx-datatable-column [name]="'::Description' | abpLocalization" prop="description"></ngx-datatable-column>
            
            <ngx-datatable-column 
                [name]="'::Code' | abpLocalization" 
                prop="code"
            ></ngx-datatable-column>

            <ngx-datatable-column 
                [name]="'::CreationDate' | abpLocalization" 
                prop="creationTime"
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.creationTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
            
        </ngx-datatable>
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ (selectedBuilding.id ? '::EditBuilding' : '::NewBuilding') | abpLocalization}}</h3>
    </ng-template>
    
    <ng-template #abpBody>
        <form [formGroup]="form" (ngSubmit)="saveBuilding()">
            <div class="form-group mb-3">
                <label for="building-title" class="form-label">
                    {{ '::Title' | abpLocalization }}
                </label><span> *</span>
                <input type="text" id="building-title" class="form-control" formControlName="title">
            </div>
            
            <div class="form-group mb-3">
                <label for="building-code" class="form-label">
                    {{ '::Code' | abpLocalization }}
                </label><span> *</span>
                <input type="text" id="building-code" class="form-control" formControlName="code">
            </div>

            <div class="form-group mb-3">
                <label class="form-label" for="building-description">
                    {{ '::Description' | abpLocalization }}
                </label>
                <textarea
                    id="building-description"
                    class="form-control"
                    cols="30"
                    rows="10"
                    formControlName="description"
                ></textarea>
            </div>
        </form>
    </ng-template>

    <ng-template #abpFooter>
        <button
            type="button"
            class="btn btn-secondary"
            abpClose
        >
            {{ '::Close' | abpLocalization }}
        </button>

        <button
            class="btn btn-primary"
            (click)="saveBuilding()"
            [disabled]="form.invalid"
        >
            {{ '::Save' | abpLocalization }}
        </button>
    </ng-template>
    
</abp-modal>