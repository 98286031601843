import { Component, Input, OnInit } from '@angular/core';
import { ClassDto } from '@proxy/classes';

@Component({
  selector: 'app-courses-video-player',
  templateUrl: './courses-video-player.component.html',
  styleUrls: ['./courses-video-player.component.scss']
})
export class CoursesVideoPlayerComponent implements OnInit {
  @Input() class: ClassDto;
  @Input() fileStreamSource: string;
  isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void { }
}
