import type { ProductRenewalExpirationDayDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductRenewalExpirationDaysService {
  apiName = 'Default';
  

  get = () =>
    this.restService.request<any, ProductRenewalExpirationDayDto>({
      method: 'GET',
      url: '/api/app/product-renewal-expiration-days',
    },
    { apiName: this.apiName });
  

  update = (data: ProductRenewalExpirationDayDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/app/product-renewal-expiration-days',
      body: data,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
