<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Tests' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <ng-container *ngIf="getTestControlFeature()">
        <div class="col" *abpPermission="'Tests.Tests.Create'">
            <div id="AbpContentToolbar" class="text-right float-end">
                <button (click)="createTest()" type="button" class="btn btn-sm btn-primary">
                    <i class="me-1 fa fa-plus"></i> 
                    <span> {{ '::NewTest' | abpLocalization}} </span>
                </button>              
            </div>
        </div>        
    </ng-container>

</div>

<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="">
      <abp-advanced-entity-filters-form *ngIf="isAdmin">
        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col-12 col-sm-4 mb-2">
                <div class="form-group">
                    <label for="typeahead-focus-user" class="form-label">
                        {{ '::SearchByAuthor' | abpLocalization }}
                    </label>
                </div>
                <div class="input-group">
                    <input 
                        class="form-control"
                        id="typeahead-focus-user"
                        type="search"
                        [editable]="false"
                        (selectItem)="selectedUser($event)"
                        [resultFormatter]="formatter"
                        [inputFormatter]="formatter"
                        formControlName="creatorId"
                        (input)="getListWhenUserInputEmpty($event.target.value)"
                        [ngbTypeahead]="searchTestByUser"
                        (focus)="focusForUserSearch$.next($any($event).target.value)"
                        (click)="clickForUserSearch$.next($any($event).target.value)"
                        #instance="ngbTypeahead"
                    />
                </div>
            </div>
          </div>
          <div class="col-12 col-sm-auto align-self-start mt-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ '::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ '::Refresh' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div class="card">
    <div class="card-body">

        <ngx-datatable 
            [rows]="tests.items" 
            [count]="tests.totalCount" 
            [list]="list" 
            default
        >
            <ngx-datatable-column
            [name]="'::Actions' | abpLocalization"
            [maxWidth]="150"
            [sortable]="false"
            >
                <ng-template let-row="row" ngx-datatable-cell-template >
                    <div ngbDropdown container="body" class="d-inline-block">
                        <button
                            class="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            ngbDropdownToggle
                        >
                            <i class="fa fa-cog mr-1"></i>
                            {{ '::Actions' | abpLocalization }}
                        </button>
                        <div ngbDropdownMenu>

                            <ng-container *ngIf="getTestAttemptFeature()">
                                <button ngbDropdownItem
                                    *abpPermission="'Tests.Tests.Attempt'"
                                    (click)="openTestAttemptView(row.id)"
                                >
                                    {{ '::TakeATest' | abpLocalization }}
                                </button>                                
                            </ng-container>

                            <ng-container *ngIf="getTestControlFeature()">
                                <button ngbDropdownItem *abpPermission="'Tests.Tests.Edit'"
                                    (click)="openEditView(row.id)" 
                                >
                                    {{ '::Edit' | abpLocalization }}
                                </button>

                                <button ngbDropdownItem *abpPermission="'Tests.Tests.Delete'"
                                    (click)="deleteTest(row.id)"
                                >
                                    {{ '::Delete' | abpLocalization }}
                                </button>
                            </ng-container>

                        </div>
                    </div>
                </ng-template>

            </ngx-datatable-column>

            <ngx-datatable-column [name]="'::Name' | abpLocalization" prop="name"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::IdentificationNumber' | abpLocalization" prop="identificationNumber"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::Description' | abpLocalization" prop="description"></ngx-datatable-column>
            <ngx-datatable-column *ngIf="isAdmin" [name]="'::Modifier' | abpLocalization" prop="modifier"></ngx-datatable-column>
            <ngx-datatable-column *ngIf="isAdmin" [name]="'::ModificationTime' | abpLocalization" prop="modificationTime"[maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.lastModificationTime | date: DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="isAdmin" [name]="'::Creator' | abpLocalization" prop="creator"></ngx-datatable-column>
            <ngx-datatable-column [name]="'::CreationDate' | abpLocalization" prop="creationTime" [maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.creationTime | date : DateTimeFormatConst }}
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ '::NewTest' | abpLocalization }}</h3>
    </ng-template>

    <ng-template #abpBody> 

        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="form-group mb-3">
                <label class="form-label" for="test-name">{{ '::Name' | abpLocalization }}</label><span> * </span>
                <input type="text" id="test-name" class="form-control" formControlName="Name" autofocus />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="test-IdentificationNumber">{{ '::IdentificationNumber' | abpLocalization }}</label><span> * </span>
                <input type="text" id="test-IdentificationNumber" class="form-control" formControlName="IdentificationNumber" />
            </div>
            <div class="form-group mb-3">
                <label class="form-label" for="test-length">{{ '::input:Label:Description' | abpLocalization }}</label>
                <textarea id="test-Desc" class="form-control" rows="3" formControlName="Description" ></textarea>
            </div>
        </form>

    </ng-template>

    <ng-template #abpFooter>

        <button type="button" class="btn btn-secondary" abpClose>
            {{ '::Cancel' | abpLocalization }}
        </button>
        <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
            <i class="fa fa-check mr-1"></i>
            {{ '::Save' | abpLocalization }}
        </button>

    </ng-template>
</abp-modal>