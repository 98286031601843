import { APP_INITIALIZER, Injector } from '@angular/core';
import { combineLatest } from 'rxjs';
import { RoutesService } from '@abp/ng.core';
import { BUILDINGS_MANAGEMENT_ROUTE_VISIBILITY } from './features.token';
import { ProviderConsts } from 'src/app/shared/Constants/providers.constants';

export const BUILDINGS_MANAGEMENT_VISIBLE_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: buildingsManagementVisibility,
        deps: [Injector],
        multi: true,
    },
];

export function buildingsManagementVisibility(injector: Injector) {
    return () => {
        const isBiuldingsManagementFeatureEnable$ = injector.get(BUILDINGS_MANAGEMENT_ROUTE_VISIBILITY);
        const routes = injector.get(RoutesService);
        combineLatest([isBiuldingsManagementFeatureEnable$]).subscribe(
        ([isBiuldingsManagementFeatureEnable]) => {
            routes.patch(ProviderConsts.BUILDING_ROUTE_PATCH_NAME, {
            invisible: !isBiuldingsManagementFeatureEnable,
            });
        },
        );
    };
}