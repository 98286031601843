import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeImportErrorDto, EmployeeImportResponseDto } from './../proxy/employees/models';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { EmployeesService } from './../proxy/employees/employees.service';
import { ListService, PagedResultDto, LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from "@angular/core";
import { EmployeeDto, renewalPeriodOptions } from '@proxy/employees';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnovaOrganizationUnitDto } from '@proxy/organization-units';
import { requiredFileTypes } from '../shared/FileTypeValidation/file.type.validation';
import { allowedImportTypes } from 'src/environments/environment';
import { CustomEmployeeImportDto, EmployeeImportService } from './services/employee.service';
import { allowedFileSize } from '../shared/FileSizeValidation/file.size.validation';

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss'],
    providers: [ListService,
        {
            provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
        }
    ]
})
export class EmployeesComponent implements OnInit{
    employees = { items: [], totalCount: 0} as PagedResultDto<EmployeeDto>;
    importErrors = { items: [], totalCount: 0 } as PagedResultDto<EmployeeImportErrorDto>;
    importSuccessResponse: EmployeeImportResponseDto;
    filterInput = '';
    organizationUnitPositions$: Observable<EnovaOrganizationUnitDto[]>
    organizationUnitDepartments$: Observable<EnovaOrganizationUnitDto[]>
    selectedEmployee: EmployeeDto;
    isEmployeeEdit = false;
    isModalOpen = false;  
    isModalImportOpen = false;  
    filterForm: UntypedFormGroup;
    form: UntypedFormGroup;
    importForm: UntypedFormGroup;

    modalBusy = false;

    AVAILABLE_UPLOAD_SIZE: number = 500000000;

    renewalPeriods = renewalPeriodOptions;

    constructor(
        public readonly list: ListService,
        public readonly importErrorsList: ListService,
        private employeesService: EmployeesService,
        private employeesImportService: EmployeeImportService,
        private confirmation: ConfirmationService,
        private fb: UntypedFormBuilder,
        private toaster: ToasterService,
        private localizationService: LocalizationService
    ){
        this.organizationUnitPositions$ = this.employeesService.getPositionLookup().pipe(map(r => r.items));
        this.organizationUnitDepartments$ = this.employeesService.getDepartmentLookup().pipe(map(r => r.items));
    }

    ngOnInit(): void {
        const employeeStreamCreator = (query) => this.employeesService.getList(
            {
                ...query,
                organizationUnitPositionId: this.filterForm.get('OrganizationUnitPositionId').value,
                organizationUnitDepartmentId: this.filterForm.get('OrganizationUnitDepartmentId').value,
                isDoingDangerousJobs: this.filterForm.get('IsDoingDangerousJobs').value,
                isGettingSafetyMeasures: this.filterForm.get('IsGettingSafetyMeasures').value,
                isWorkingWithDangerousEquipment: this.filterForm.get('IsWorkingWithDangerousEquipment').value,
                hasHealthChecked: this.filterForm.get('HasHealthChecked').value,
                hasWorkSafetyCertificate: this.filterForm.get('HasWorkSafetyCertificate').value,
                hasWorkingRestrictions: this.filterForm.get('HasWorkingRestrictions').value
            }
        );

        this.list.hookToQuery(employeeStreamCreator).subscribe((response) => {
            this.employees.items = response.items;
            this.employees.totalCount = response.totalCount;
        });

        this.buildFormForSearch();
    }

    getListWithFilters(){
        this.list.get();
    }

    buildFormForSearch(){
        this.filterForm = this.fb.group({
            OrganizationUnitPositionId: [null],
            OrganizationUnitDepartmentId: [null],
            IsDoingDangerousJobs: [null],
            IsGettingSafetyMeasures: [null],
            IsWorkingWithDangerousEquipment: [null],
            HasHealthChecked: [null],
            HasWorkSafetyCertificate: [null],
            HasWorkingRestrictions: [null]
        });
    }

    clearFilters(){
        this.buildFormForSearch();
        this.list.get();
    }

    createEmployee() {
        this.buildForm();
        this.isEmployeeEdit = false;
        this.isModalOpen = true;
    }

    createImportEmployees() {
        this.buildImportForm();
        this.importErrors.items = [];
        this.importErrors.totalCount = 0;
        this.isModalImportOpen = true;
        this.importSuccessResponse = undefined;
    }

    onImportModalClose() {
        this.buildImportForm();
        this.importErrors.items = [];
        this.importErrors.totalCount = 0;
        this.importSuccessResponse = undefined;
        this.isModalImportOpen = false;
    }

    buildForm() {
        this.form = this.fb.group({
            UserName: ['', [Validators.required]],
            Name: ['', [Validators.required]],
            Surname: ['', [Validators.required]],
            IdentificationCode: [''],
            Email: ['', [Validators.required, Validators.email]],
            OrganizationUnitPositionId: [null],
            OrganizationUnitDepartmentId: [null],
            Password: ['', this.isEmployeeEdit ? null :
            [
                Validators.required, 
                Validators.pattern(/^(?=.*([A-Z]){1,})(?=.*[!@#$&*+-]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{6,}$/)
            ]],
            IsDoingDangerousJobs: [false],
            IsGettingSafetyMeasures: [false],
            DateOfEmployment: [null],
            DateOfUnemployment: [null],
            IsWorkingWithDangerousEquipment: [false],
            HasHealthChecked: [false],
            HealthCheckedDate: [null],
            RenewalPeriodNumber: [null, [Validators.min(1)]], //need to check if number then renewal period must be
            RenewalPeriod: [null],
            HasWorkSafetyCertificate: [false],
            HasWorkingRestrictions: [false],
        });
    }

    get allowedFileTypesFormattedString() {
        return allowedImportTypes.types.map(e => `.${e}`).join();
    }

    buildImportForm() {
        this.importForm = this.fb.group({
            file: [null, [Validators.required, requiredFileTypes(allowedImportTypes.types)]],
            fileSource: [null, [allowedFileSize(500000000)]],
        });
    }

    downloadExample(){
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.download = 'EmployeeImportExample.xlsx';
        link.href = '/assets/EmployeeImportExample/EmployeeImportExample.xlsx';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    save() {
        if (this.form.invalid) {
            return;
        }
        this.isEmployeeEdit ? 
            this.editSelectedEmployee() 
            : this.createNewEmployee();
    }

    selectRenewalPeriod(form){
        var renewalPeriodNumber = form.get("RenewalPeriodNumber").value;
        var renewalPeriod = form.get("RenewalPeriod").value;

        if(renewalPeriodNumber ==  null && (renewalPeriod == "" || renewalPeriod == null)) {
            form.controls["RenewalPeriodNumber"].setValidators([Validators.min(1), Validators.nullValidator]);
            form.controls["RenewalPeriod"].setValidators([Validators.nullValidator]);
        } 
        else 
        {
            form.controls["RenewalPeriodNumber"].setValidators([Validators.min(1), Validators.required]);
            form.controls["RenewalPeriod"].setValidators([Validators.required]);
        }

        form.get("RenewalPeriodNumber").updateValueAndValidity();
        form.get("RenewalPeriod").updateValueAndValidity();
    }

    getFormDate() {
        if(!this.form.controls["HasHealthChecked"].value) {
            this.form.controls["HealthCheckedDate"].setValue(null);
            this.form.controls["RenewalPeriodNumber"].setValue(null);
            this.form.controls['RenewalPeriod'].setValue(null);
        }

        if(this.form.controls['RenewalPeriod'].value == ""){
            this.form.controls['RenewalPeriod'].setValue(null);
        }

        return this.form.value;
    }

    import() {
        if (this.importForm.invalid) {
            return;
        }
        this.modalBusy = true;

        var documentFile: File = this.importForm.controls["fileSource"].value;
        var request: CustomEmployeeImportDto;

        request = {
            file: documentFile
        }

        this.employeesImportService.import(request).subscribe({
            next: (response) => {
                if(response.importErrors.length > 0) {
                    this.importErrors.items = response.importErrors;
                    this.importErrors.totalCount = response.importErrors.length;
    
                    this.toaster.error(
                        this.localizationService.instant("::Employee:Import:Message:Error")
                    );
                } else {
                    this.importSuccessResponse = response;
                    if(response?.importedEmployees > 0){
                        this.toaster.success(
                            this.localizationService.instant("::Employee:Import:Message:Successs")
                        );
                    }
                }

                this.modalBusy = false;
            },
            error: () => {
                this.modalBusy = false;
            }
        });
    }

    onImportModalDisappear(){
        this.list.get();
    }

    onFileChanged(event) {
        if (event.target.files.length > 0) {
            this.importErrors = {};
            this.importSuccessResponse = undefined;
            const file = event.target.files[0];
            this.importForm.patchValue({
                fileSource: file
            });

            if(file.size > this.AVAILABLE_UPLOAD_SIZE) {
                this.importForm.controls["file"].setErrors({'allowedFileSize': true});
            }
        }
    }

    createNewEmployee() {
        if(this.form.get('OrganizationUnitPositionId').value == ""){
            this.form.controls['OrganizationUnitPositionId'].setValue(null);
        }

        if(this.form.get('OrganizationUnitDepartmentId').value == ""){
            this.form.controls['OrganizationUnitDepartmentId'].setValue(null);
        }

        this.employeesService.create(this.getFormDate()).subscribe((value) => {
            this.isModalOpen = false;
            this.isEmployeeEdit = false;
            this.isModalImportOpen = false;
            this.form.reset();
            this.list.get();
            }, (error) => {
                this.toaster.error(
                    this.localizationService.instant("::Employee:Message:Created:Error")
                );
            }, () => {
                this.toaster.success( 
                    this.localizationService.instant("::Employee:Message:Created:Success")
                );
            }
        );
    }

    hasCheckedHealth(): boolean {
        return this.form.get('HasHealthChecked').value;
    }

    editSelectedEmployee() {
        if(this.form.get('OrganizationUnitPositionId').value == ""){
            this.form.controls['OrganizationUnitPositionId'].setValue(null);
        }

        if(this.form.get('OrganizationUnitDepartmentId').value == ""){
            this.form.controls['OrganizationUnitDepartmentId'].setValue(null);
        }

        this.employeesService.update(this.selectedEmployee.id, this.getFormDate()).subscribe((value) => {
            this.isModalOpen = false;
            this.isEmployeeEdit = false;
            this.isModalImportOpen = false;
            this.form.reset();
            this.list.get();
        }, (error) => {
            this.toaster.error(
                this.localizationService.instant("::Employee:Message:Edit:Error")
            );
        }, () => {
            this.toaster.success( 
                this.localizationService.instant("::Employee:Message:Edit:Success")
            );
        });
    }

    selectEmployeeEdit(data) {
        this.isEmployeeEdit = true;
        this.buildForm();
        this.form.reset();
        this.isModalOpen = true;
        this.isModalImportOpen = false;

        this.selectedEmployee = data;
        this.form.controls["UserName"].setValue(this.selectedEmployee.identityUser.userName);
        this.form.controls["Name"].setValue(this.selectedEmployee.identityUser.name);
        this.form.controls["Surname"].setValue(this.selectedEmployee.identityUser.surname);
        this.form.controls["IdentificationCode"].setValue(this.selectedEmployee.identificationCode);
        this.form.controls['OrganizationUnitPositionId'].setValue(this.selectedEmployee.organizationUnitPositionId);
        this.form.controls['OrganizationUnitDepartmentId'].setValue(this.selectedEmployee.organizationUnitDepartmentId);
        this.form.controls["Email"].setValue(this.selectedEmployee.identityUser.email);

        this.form.controls["IsDoingDangerousJobs"].setValue(this.selectedEmployee.isDoingDangerousJobs == null 
            ? false : this.selectedEmployee.isDoingDangerousJobs);

        this.form.controls["IsGettingSafetyMeasures"].setValue(this.selectedEmployee.isGettingSafetyMeasures == null 
            ? false : this.selectedEmployee.isGettingSafetyMeasures);
        
        this.form.controls["DateOfEmployment"].setValue(
            this.selectedEmployee.dateOfEmployment != null ?
            new Date(this.selectedEmployee.dateOfEmployment) :
            null
        );
        this.form.controls["DateOfUnemployment"].setValue(
            this.selectedEmployee.dateOfUnemployment != null ?
            new Date(this.selectedEmployee.dateOfUnemployment) :
            null
        );
        
        this.form.controls['IsWorkingWithDangerousEquipment'].setValue(this.selectedEmployee.isWorkingWithDangerousEquipment == null 
            ? false : this.selectedEmployee.isWorkingWithDangerousEquipment)
        this.form.controls["HasHealthChecked"].setValue(this.selectedEmployee.hasHealthChecked == null 
            ? false : this.selectedEmployee.hasHealthChecked);
        
        this.form.controls["HealthCheckedDate"].setValue(
            this.selectedEmployee.healthCheckedDate != null ?
            new Date(this.selectedEmployee.healthCheckedDate) :
            null
        );
        
        this.form.controls["RenewalPeriodNumber"].setValue(this.selectedEmployee.renewalPeriodNumber);
        this.form.controls['RenewalPeriod'].setValue(this.selectedEmployee.renewalPeriod)
        this.form.controls["HasWorkSafetyCertificate"].setValue(this.selectedEmployee.hasWorkSafetyCertificate == null 
            ? false : this.selectedEmployee.hasWorkSafetyCertificate);
        this.form.controls["HasWorkingRestrictions"].setValue(this.selectedEmployee.hasWorkingRestrictions == null 
            ? false : this.selectedEmployee.hasWorkingRestrictions);

        this.selectRenewalPeriod(this.form);
    }

    selectEmployeeDelete(data) {
        this.confirmation.warn('::AreYouSureToDeleteEmployee', '::AreYouSure').subscribe((status) => {
            if(status === Confirmation.Status.confirm){
                this.employeesService.delete(data.id).subscribe({
                next: () => this.list.get(),
                complete: () => 
                    this.toaster.success(
                        this.localizationService.instant('::Employee:Message:Deleted:Success')
                    ),
                error: () => (
                    this.toaster.error(
                        this.localizationService.instant('::Employee:Message:Deleted:Error')
                    )
                )
                });
            }
        });
    }
}