import { AuditedEntityDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { DocumentTagDto, RenewalPeriod } from '@proxy/documents';

@Injectable({
    providedIn: 'root'
})
export class CustomDocumentService {
    apiName = 'Default';

    constructor(private restService: RestService) {}
    
    create = (request: CustomDocumentDto) => this.restService
        .request<any, any>(
            
            {
            method: 'POST',
            url: `/api/app/documents`,
            body: this.generateCreateFormData(request),
            },
            { apiName: this.apiName}
        );

    update = (id: string, request: CustomDocumentDto) => this.restService
        .request<any, any>(
            {
                method: 'PUT',
                url: `/api/app/documents/${id}`,
                body: this.generateUpdateFormData(request),
            },
            { apiName: this.apiName }
        );

    private generateCreateFormData(request: CustomDocumentDto) {
        const formData = new FormData();

        formData.append('File', request.file, request.file.name);
        formData.append('Name', request.name);
        formData.append('Code', request.code);       
        formData.append("RenewalPeriod", request.renewalPeriod);

        formData.append('Status', request.status);
        
        formData.append('FreeField', request.freeField);
        formData.append('FreeFieldFillingInstruction', request.freeFieldFillingInstruction);
        
        formData.append('FreeField1', request.freeField1);
        formData.append('FreeFieldFillingInstruction1', request.freeFieldFillingInstruction1);

        formData.append('FreeField2', request.freeField2);
        formData.append('FreeFieldFillingInstruction2', request.freeFieldFillingInstruction2);

        formData.append('FreeField3', request.freeField3);
        formData.append('FreeFieldFillingInstruction3', request.freeFieldFillingInstruction3);
        
        formData.append('FreeField4', request.freeField4);
        formData.append('FreeFieldFillingInstruction4', request.freeFieldFillingInstruction4);


        formData.append('FreeField5', request.freeField5);
        formData.append('FreeFieldFillingInstruction5', request.freeFieldFillingInstruction5);
        
        formData.append('FreeField6', request.freeField6);
        formData.append('FreeFieldFillingInstruction6', request.freeFieldFillingInstruction6);

        formData.append('FreeField7', request.freeField7);
        formData.append('FreeFieldFillingInstruction7', request.freeFieldFillingInstruction7);

        formData.append('FreeField8', request.freeField8);
        formData.append('FreeFieldFillingInstruction8', request.freeFieldFillingInstruction8);
        
        formData.append('FreeField9', request.freeField9);
        formData.append('FreeFieldFillingInstruction9', request.freeFieldFillingInstruction9);

        formData.append('FreeField10', request.freeField10);
        formData.append('FreeFieldFillingInstruction10', request.freeFieldFillingInstruction10);
        
        formData.append('FreeField11', request.freeField11);
        formData.append('FreeFieldFillingInstruction11', request.freeFieldFillingInstruction11);

        formData.append('FreeField12', request.freeField12);
        formData.append('FreeFieldFillingInstruction12', request.freeFieldFillingInstruction12);

        formData.append('FreeField13', request.freeField13);
        formData.append('FreeFieldFillingInstruction13', request.freeFieldFillingInstruction13);
        
        formData.append('FreeField14', request.freeField14);
        formData.append('FreeFieldFillingInstruction14', request.freeFieldFillingInstruction14);
        
        if(request.renewalPeriodNumber != null)[
            formData.append("RenewalPeriodNumber", request.renewalPeriodNumber)
        ]

        if (request.description != null) {
            formData.append('Description', request.description);            
        }

        if(request.categoryId != null){
            formData.append('CategoryId', request.categoryId);
        }

        if(request.testId != null){
            formData.append('TestId', request.testId);
        }

        if(request.productUrlPath != null){
            formData.append("ProductUrlPath", request.productUrlPath);
        }

        for (var i = 0; i < request.documentTags.length; i++) {
            formData.append(`DocumentTagIds[${i}]`, request.documentTags[i].generatorTagId);
        }   

        if (request.availableUntil != null) {
            formData.append('AvailableUntil', request.availableUntil);
        }

        return formData;
    }

    private generateUpdateFormData(request: CustomDocumentDto) {
        const formData = new FormData();
        if(request.file != null) {
            formData.append('File', request.file, request.file.name);
        }

        formData.append('Name', request.name);
        formData.append('Code', request.code);
        formData.append("RenewalPeriod", request.renewalPeriod);

        formData.append('Status', request.status);

        formData.append('FreeField', request.freeField);
        formData.append('FreeFieldFillingInstruction', request.freeFieldFillingInstruction);

        formData.append('FreeField1', request.freeField1);
        formData.append('FreeFieldFillingInstruction1', request.freeFieldFillingInstruction1);

        formData.append('FreeField2', request.freeField2);
        formData.append('FreeFieldFillingInstruction2', request.freeFieldFillingInstruction2);

        formData.append('FreeField3', request.freeField3);
        formData.append('FreeFieldFillingInstruction3', request.freeFieldFillingInstruction3);
        
        formData.append('FreeField4', request.freeField4);
        formData.append('FreeFieldFillingInstruction4', request.freeFieldFillingInstruction4);

        formData.append('FreeField5', request.freeField5);
        formData.append('FreeFieldFillingInstruction5', request.freeFieldFillingInstruction5);

        formData.append('FreeField6', request.freeField6);
        formData.append('FreeFieldFillingInstruction6', request.freeFieldFillingInstruction6);

        formData.append('FreeField7', request.freeField7);
        formData.append('FreeFieldFillingInstruction7', request.freeFieldFillingInstruction7);

        formData.append('FreeField8', request.freeField8);
        formData.append('FreeFieldFillingInstruction8', request.freeFieldFillingInstruction8);
        
        formData.append('FreeField9', request.freeField9);
        formData.append('FreeFieldFillingInstruction9', request.freeFieldFillingInstruction9);

        formData.append('FreeField10', request.freeField10);
        formData.append('FreeFieldFillingInstruction10', request.freeFieldFillingInstruction10);

        formData.append('FreeField11', request.freeField11);
        formData.append('FreeFieldFillingInstruction11', request.freeFieldFillingInstruction11);

        formData.append('FreeField12', request.freeField12);
        formData.append('FreeFieldFillingInstruction12', request.freeFieldFillingInstruction12);

        formData.append('FreeField13', request.freeField13);
        formData.append('FreeFieldFillingInstruction13', request.freeFieldFillingInstruction13);
        
        formData.append('FreeField14', request.freeField14);
        formData.append('FreeFieldFillingInstruction14', request.freeFieldFillingInstruction14);

        if(request.renewalPeriodNumber != null)[
            formData.append("RenewalPeriodNumber", request.renewalPeriodNumber)
        ]

        if (request.description != null) {
            formData.append('Description', request.description);            
        }

        if(request.categoryId != null) {
            formData.append('CategoryId', request.categoryId);
        }

        if(request.testId != null){
            formData.append('TestId', request.testId);
        }
        
        if(request.productUrlPath != null){
            formData.append("ProductUrlPath", request.productUrlPath);
        }

        for (var i = 0; i < request.documentTags.length; i++) {
            formData.append(`DocumentTagIds[${i}]`, request.documentTags[i].generatorTagId);
        }     

        if (request.availableUntil != null) {
            formData.append('AvailableUntil', request.availableUntil);
        }

        return formData;
    }
}

export interface CustomDocumentDto extends AuditedEntityDto<string>{
    name: string;
    code: string;
    description?: string;
    categoryId?: string;
    file: File;
    testId: string;
    documentTags: DocumentTagDto[];
    productUrlPath: string;
    renewalPeriodNumber: string;
    renewalPeriod: string;
    availableUntil: string;
    status: string;
    freeField: string;
    freeFieldFillingInstruction: string;
    freeField1: string;
    freeFieldFillingInstruction1: string;
    freeField2: string;
    freeFieldFillingInstruction2: string;
    freeField3: string;
    freeFieldFillingInstruction3: string;
    freeField4: string;
    freeFieldFillingInstruction4: string;
    freeField5: string;
    freeFieldFillingInstruction5: string;
    freeField6: string;
    freeFieldFillingInstruction6: string;
    freeField7: string;
    freeFieldFillingInstruction7: string;
    freeField8: string;
    freeFieldFillingInstruction8: string;
    freeField9: string;
    freeFieldFillingInstruction9: string;
    freeField10: string;
    freeFieldFillingInstruction10: string;
    freeField11: string;
    freeFieldFillingInstruction11: string;
    freeField12: string;
    freeFieldFillingInstruction12: string;
    freeField13: string;
    freeFieldFillingInstruction13: string;
    freeField14: string;
    freeFieldFillingInstruction14: string;
}

