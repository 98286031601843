import { DevicesComponent } from './devices.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from "@angular/core";
import { AuthGuard, PermissionGuard } from '@abp/ng.core';

const routes: Routes = [
    {path: '', component: DevicesComponent, canActivate: [AuthGuard, PermissionGuard]}
]

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class DevicesRoutingModule {}