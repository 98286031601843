import { ClassService, CustomClassDto } from './../services/class.service';
import { ClassFileDownloadService } from './../../proxy/classes/class-file-download.service';
import { CourseClassesService, CourseDto, CoursesService, renewalPeriodOptions } from '@proxy/courses';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService, PagedResultDto, ListService, downloadBlob, ConfigStateService, PermissionService } from '@abp/ng.core';
import { Subscription, Observable, OperatorFunction, Subject, merge } from 'rxjs';
import { ClassDto } from '@proxy/classes';
import { allowedFileTypes } from 'src/environments/environment';
import { BaseCategoryDto, CategoriesLookupService } from '@proxy/categories';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { requiredFileTypes } from 'src/app/shared/FileTypeValidation/file.type.validation';
import { TestLookupDto, TestLookupService } from '@proxy/tests';
import { TenantCourseService } from '@proxy/tenant-courses';
import { DatePipe } from '@angular/common';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { allowedFileSize } from 'src/app/shared/FileSizeValidation/file.size.validation';
import { DocumentStatus } from '@proxy/documents';

@Component({
    selector: 'app-course-create',
    templateUrl: './courses-create-update.component.html',
    styleUrls: ['./courses-create-update.component.scss'],
    providers: [ListService, DatePipe,
        {
            provide: NgbDateAdapter, useClass: NgbDateNativeAdapter
        }]
})
export class CourseCreateUpdateComponent implements OnInit, OnDestroy {
    @Input() requiredFileType:string;
    isGrantedPermissionForSettingProductPageUrl: boolean;
    focusForCategorySearch$ = new Subject<string>();
    clickForCategorySearch$ = new Subject<string>();
    focusForTestSearch$ = new Subject<string>();
    clickForTestSearch$ = new Subject<string>();
    allCategories: BaseCategoryDto[] = [];
    allTests: TestLookupDto[] = [];
    private routeSub: Subscription;
    private routeSubTenant: Subscription;
    form: UntypedFormGroup;
    classForm: UntypedFormGroup;
    selectedClass: ClassDto;
    ecommerceUrl: string;
    classes = { items: [], totalCount: 0 } as PagedResultDto<ClassDto>;
    renewalPeriods = renewalPeriodOptions;
    selectedCourse = {} as CourseDto;
    oldEditingClassItem: ClassDto;
    isTenantExist = false;
    isActiveTab: number = 0;
    isClassToEdit = false;
    isModalOpen = false;
    newClassFileSelected = false;
    courseId = null;
    deleteFileOnEdit = false;

    AVAILABLE_UPLOAD_SIZE: number = 500000000;

    modalBusy = false;

    activeCourseService : TenantCourseService | CoursesService;

    formatter = (category: BaseCategoryDto) => category.name;

    searchForCategory: OperatorFunction<string, readonly BaseCategoryDto[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    
        return merge(debouncedText$, this.focusForCategorySearch$, this.clickForCategorySearch$).pipe(
            map(term => (term === '' ? this.allCategories
                : this.allCategories.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
            )
        );
    };

    searchForTest: OperatorFunction<string, readonly TestLookupDto[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    
        return merge(debouncedText$, this.focusForTestSearch$, this.clickForTestSearch$).pipe(
            map(term => (term === '' ? this.allTests
                : this.allTests.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
            )
        );
    };

    constructor(
        private fb: UntypedFormBuilder,
        private categoriesLookupService: CategoriesLookupService,
        private coursesService: CoursesService,
        private tenantCourseService: TenantCourseService,
        private courseClassesService: CourseClassesService,
        private testLookupService: TestLookupService,
        private router: Router,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        private localizationService: LocalizationService,
        private confirmation: ConfirmationService,
        private classFileDownloadService: ClassFileDownloadService,
        private config: ConfigStateService,
        private permissionService: PermissionService,
        public readonly list: ListService,
        private readonly classService: ClassService,
        private datePipe: DatePipe,
    ) {
        this.isGrantedPermissionForSettingProductPageUrl =
            this.permissionService.getGrantedPolicy('Courses.Courses.AddProductPageUrl');

        this.ecommerceUrl = this.config.getSetting("Enova.DefaultEcommerceUrl");
    }

    ngOnInit(): void {
        this.selectedCourse.classes = [];
        this.isTenantExist = this.config.getOne("currentUser").tenantId != null ? true : false;

        this.categoriesLookupService.getCategoryLookup().subscribe((response) => {
            this.allCategories = response.items;
        });

        this.testLookupService.getTestLookup().subscribe((response) => {
            this.allTests = response.items;
        });

        this.buildForm();

        this.routeSub = this.route.params.subscribe((params) => {
            if(params['id']) {
                this.courseId = params['id'];
            }
        });

        if(this.config.getOne("currentUser").tenantId == null){
            this.activeCourseService = this.coursesService;
            this.prepareEditCourse(this.courseId);
        } else {
            this.activeCourseService = this.tenantCourseService;
            this.prepareEditCourse(this.courseId);
        }

    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
        if (this.routeSubTenant) {
            this.routeSubTenant.unsubscribe();
        }
    }

    onDisappear(e: any) {
        if(this.deleteFileOnEdit){
            this.list.get();
        }
    }

    get allowedFileTypesFormattedString() {
        return allowedFileTypes.types.map(e => `.${e}`).join();;
    }

    prepareEditCourse(id: string) {
        this.newClassFileSelected = false;

        this.activeCourseService.get(id).subscribe((course) => {
            this.selectedCourse = course;
            this.buildForm();
        });

        const courseStreamCreator = (query) => 
            this.courseClassesService.getList(query, this.selectedCourse.id);

        this.list.hookToQuery(courseStreamCreator).subscribe((response) => {
            this.classes.items = response.items;
            this.classes.totalCount = response.totalCount;
            this.isModalOpen = false;
            this.isClassToEdit = false;
        });

        this.buildClassForm();
    }

    createClass() {
        this.isModalOpen = true;
        this.isClassToEdit = false;
        this.selectedClass = null;
        this.classForm.reset();
    }

    buildClassForm() {
        this.classForm = this.fb.group({
            Name: ['', [Validators.required]],
            Length: ['', [Validators.required, Validators.min(1)]],
            SortOrder: [''],
            ShortDescription: ['', Validators.required],
            File: ['', [requiredFileTypes(allowedFileTypes.types)]],
            FileSource: ['', [allowedFileSize(500000000)]]
        });
    }

    onFileChanged(event) {
        this.newClassFileSelected = true;

        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.classForm.patchValue({
                FileSource: file
            });

            if(file.size > this.AVAILABLE_UPLOAD_SIZE) {
                this.classForm.controls["File"].setErrors({'allowedFileSize': true});
            }
        }
    }

    downloadFile() {
        this.classFileDownloadService.downloadFileByClassId(this.selectedClass.id).subscribe((result) => {
            downloadBlob(result, this.selectedClass.fileDescriptor.name);
        });
    }

    deleteFile() {
        this.confirmation.warn('::File:Message:Delete:Confirmation', 'AbpAccount::AreYouSure')
        .subscribe((status) => {
            if (status === Confirmation.Status.confirm) {
                this.deleteFileOnEdit = true;
                const request = this.courseClassesService.hardDeleteFile(this.selectedClass.id);
                request.subscribe(null, () => {
                    this.toaster.error(
                        this.localizationService.instant("::File:Message:Delete:Error")
                    );
                }, () => {
                    this.selectedClass.fileDescriptor = null;
                    this.selectedClass.fileDescriptorId = null;
                    this.toaster.success( 
                        this.localizationService.instant("::File:Message:Delete:Success"));
                });
            }
        });
    }

    buildForm() {
        this.form = this.fb.group({
            Name: [this.selectedCourse.name || '', [Validators.required]],
            Code: [this.selectedCourse.code || '', [Validators.required]],
            Length: [this.selectedCourse.length || '', [Validators.required, Validators.min(1)]],
            Category: [this.selectedCourse.category || ''],
            Test: [this.selectedCourse.test || ''],
            ShortDescription: [this.selectedCourse.shortDescription || null],
            FullDescription: [this.selectedCourse.fullDescription || null],
            ProductUrlPath: [this.selectedCourse.productUrlPath || null],
            RenewalPeriodNumber: [this.selectedCourse.renewalPeriodNumber || null, 
                [!this.isTenantExist ? Validators.required : Validators.nullValidator, Validators.min(1)]],
            RenewalPeriod: [this.selectedCourse.renewalPeriod || null, 
                [!this.isTenantExist ? Validators.required : Validators.nullValidator]],
            AvailableUntil: [this.selectedCourse.availableUntil != null ? new Date(this.selectedCourse.availableUntil) : null]
        });
    }

    formCourseData(){
        return {
            name: this.form.get('Name').value,
            code: this.form.get("Code").value,
            length: this.form.get('Length').value,
            categoryId: this.form.get('Category').value ? this.form.get('Category').value.id : null,
            testId: this.form.get("Test").value ? this.form.get("Test").value.id : null,
            shortDescription: this.form.get('ShortDescription').value,
            fullDescription: this.form.get('FullDescription').value,
            productUrlPath: this.form.get('ProductUrlPath').value,
            renewalPeriodNumber: this.form.get('RenewalPeriodNumber').value,
            renewalPeriod: this.form.get('RenewalPeriod').value,
            classes: this.classes.items,
            availableUntil: this.form.get('AvailableUntil').value ? this.datePipe.transform(this.getAvailableUntilDate(), "yyyy-MM-ddTHH:mm:ss") : null,
            status: DocumentStatus.NotApproved
        }
    }

    getAvailableUntilDate() {
        let availableUntil = this.form.get('AvailableUntil').value;
        availableUntil.setHours(0);
        availableUntil.setMinutes(0);
    
        return availableUntil
    }

    saveCourse() {
        if (this.form.invalid) {
            return;
        }
        
        const request = this.coursesService.update(this.selectedCourse.id, this.formCourseData()); 

        request.subscribe(null, () => {
            this.toaster.error(
                this.localizationService.instant("::Course:Message:Edited:Error")
            );
        }, () => {
            this.toaster.success( 
                this.localizationService.instant("::Course:Message:Edited:Success"));
            this.navigateToCourses();
        });
    }

    navigateToCourses() {
        this.form.reset();
        this.classForm.reset();
        this.router.navigate(['courses']);
    }

    setActiveTab(tabNumber: number){
        this.isActiveTab = tabNumber;
    }

    removeClass(data) {
        this.confirmation.warn('::Class:Message:Delete:Confirmation', 'AbpAccount::AreYouSure')
        .subscribe((status) => {
            if (status === Confirmation.Status.confirm) {
                
                const request = this.courseClassesService.delete(data.id); 

                request.subscribe(null, () => {
                    this.toaster.error(
                        this.localizationService.instant("::Class:Message:Delete:Error")
                    );
                }, () => {
                    this.list.get();
                    this.toaster.success( 
                        this.localizationService.instant("::Class:Message:Delete:Success"));
                });
            }
        });
    }

    //Add class to list
    addClass() {
        if (this.classForm.invalid) {
            return;
        }

        this.modalBusy = true;

        var classFile: File = this.classForm.controls["FileSource"].value;

        var request: CustomClassDto;
        
        request = {
            name: this.classForm.controls["Name"].value,
            length: this.classForm.controls["Length"].value,
            sortOrder: this.classForm.controls["SortOrder"].value,
            shortDescription: this.classForm.controls["ShortDescription"].value,
            file: classFile,
            courseId: this.selectedCourse.id
        }

        this.classService.create(request, this.selectedCourse.id)
        .subscribe(
            () => {
            },
            () => {
                this.modalBusy = false;
                this.toaster.error(
                    this.localizationService.instant("::Class:Message:Create:Error")
                );
            }, 
            () => {
                this.modalBusy = false;
                this.isModalOpen = false;
                this.classForm.reset();
                this.list.get();
                this.toaster.success( 
                    this.localizationService.instant("::Class:Message:Create:Success"));
            }
        );            
    }

    //select and load class to edit data
    selectClassToEdit(data) {
        this.deleteFileOnEdit = false;
        this.isClassToEdit = true;
        this.isModalOpen = true;
        this.classForm.reset();

        this.courseClassesService.get(data.id).subscribe((result) => {
            this.selectedClass = result;
            
            result.fileDescriptorId != null ? 
                this.newClassFileSelected = false 
                : this.newClassFileSelected = true;
        });

        //select class 
        this.classForm.controls["Name"].setValue(data.name);
        this.classForm.controls["Length"].setValue(data.length);
        this.classForm.controls["SortOrder"].setValue(data.sortOrder);
        this.classForm.controls["ShortDescription"].setValue(data.shortDescription);

        //save value which will be changed
        this.classes.items.forEach(classItem => {
            if (classItem.name == data.name) {
                this.oldEditingClassItem = classItem;
            }
        });
    }

    //Update selected class and load new data to list
    updateSelectedClass() {
        if (this.classForm.invalid) {
            return;
        }

        this.modalBusy = true;

        var classFile: File = this.classForm.controls["FileSource"].value;

        var request: CustomClassDto;
        
        request = {
            name: this.classForm.controls["Name"].value,
            length: this.classForm.controls["Length"].value,
            sortOrder: this.classForm.controls["SortOrder"].value,
            shortDescription: this.classForm.controls["ShortDescription"].value,
            file: classFile,
            courseId: this.courseId
        }

        this.classService.update(this.oldEditingClassItem.id, request).subscribe(
            () => {
            }, 
            () => {
                this.modalBusy = false;
                this.toaster.error(
                    this.localizationService.instant("::Class:Message:Edited:Error")
                );
            }, 
            () => {
                this.modalBusy = false;
                this.classForm.reset();
                this.list.get();
                this.isModalOpen = false;
                this.isClassToEdit = false;
                this.toaster.success( 
                    this.localizationService.instant("::Class:Message:Edited:Success"));
            }
        );
    }

    showAvailableUntilField(): boolean {
        const currentUser = this.config.getOne("currentUser");
    
        if(currentUser.tenantId == null){
            return false;
        }
    
        var correctRole = false;
    
        currentUser.roles.forEach(element => {
            if(element == "DSS Specialistas") {
                correctRole = true;
            }
        });
    
        return correctRole;
    }
}
