<ng-container *ngIf="getIncidentControlFeature()">
    <div class="row">
        <h2>{{ '::IncidentsAdmin' | abpLocalization }}</h2> 
        <hr>
        <form 
            *ngIf="!isLoading"
            [formGroup]="adminForm" 
            (ngSubmit)="setAdminValueToTenantSettings()"
        >
            <div class="row m-1">
                <div class="mb-3">
                    <label class="form-label">
                        {{ '::IncidentsAdmin' | abpLocalization }}
                    </label>
                    <select class="form-select form-control" formControlName="adminEmail">
                        <option value=""></option>
                        <option [ngValue]="admin.email" *ngFor="let admin of incidentTenantAdmins">
                            {{ admin.email }}
                        </option>
                    </select>
                </div>        
                <abp-button
                    (click)="setAdminValueToTenantSettings()"
                >
                    <i class="fa fa-save"></i>
                    {{ '::Save' | abpLocalization }}
                </abp-button>
            </div>
        </form>
    </div>    
</ng-container>



