import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TicketsCalendarComponent } from './ticket/calendar/tickets-calendar.component';
import { LogoutComponent } from './logout/logout.component';
import { RedirectToEcommerceLogoutComponent } from './redirect-to-ecommerce-logout/redirect-to-ecommerce-logout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'files-management',
    loadChildren: () =>
      import('@volo/abp.ng.file-management').then(m => m.FileManagementModule.forLazy()),
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses/courses.module').then(m => m.CourseModule),
  },
  {
    path: 'documents',
    loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
  },
  { path: 'tasks', loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule) },
  { path: 'tests', loadChildren: () => import('./tests/tests.module').then(m => m.TestModule) },
  { path: 'calendar', component: TicketsCalendarComponent },
  {
    path: 'incidents',
    loadChildren: () => import('./incident/incident.module').then(m => m.IncidentModule),
  },
  {
    path: 'categories',
    loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: 'questions',
    loadChildren: () => import('./question/question.module').then(m => m.QuestionModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'buildings',
    loadChildren: () => import('./building/building.module').then(m => m.BuildingModule),
  },
  {
    path: 'devices',
    loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule),
  },
  {
    path: 'test-results',
    loadChildren: () => import('./test-results/test-results.module').then(m => m.TestResultsModule),
  },
  {
    path: 'users-actions',
    loadChildren: () =>
      import('./tenantActions/tenant-actions.module').then(m => m.TenantActionsModule),
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./test-attempt/test-attempts.module').then(m => m.TestAttemptModule),
  },
  {
    path: 'activity-types',
    loadChildren: () =>
      import('./activity-types/activity-type/activity-type.module').then(m => m.ActivityTypeModule),
  },
  {
    path: 'work-safety-areas',
    loadChildren: () =>
      import('./work-safety-areas/work-safety-area/work-safety-area.module').then(
        m => m.WorkSafetyAreaModule
      ),
  },
  {
    path: 'questionnaires',
    loadChildren: () =>
      import('./questionnaires/questionnaire/questionnaire.module').then(
        m => m.QuestionnaireModule
      ),
  },
  {
    path: 'questionnaire-question-answers',
    loadChildren: () =>
      import(
        './questionnaire-question-answers/questionnaire-question-answer/questionnaire-question-answer.module'
      ).then(m => m.QuestionnaireQuestionAnswerModule),
  },
  {
    path: 'questionnaire',
    loadChildren: () =>
      import('./tenant-questionnaire/tenant-questionnaire.module').then(
        m => m.TenantQuestionnaireModule
      ),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then(
        m => m.BillingModule
      ),
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'redirect-to-ecommerce-logout',
    component: RedirectToEcommerceLogoutComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
