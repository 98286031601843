import { PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { MissingCourseDto } from '@proxy/courses/tenant-course';

@Component({
  selector: 'app-course-banner',
  templateUrl: './course-banner.component.html',
  styleUrls: ['./course-banner.component.scss']
})
export class CourseBannerComponent implements OnInit {
  @Input() courses: PagedResultDto<MissingCourseDto>;
  @Input() ecommerceUrl: string;
  isModalOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  openCoursesOfferModal(){
    this.isModalOpen = true;
  }

  viewCoursePage(urlPath: string){
    window.open(`${this.ecommerceUrl}${urlPath}`, "_blank");
  }
}
