import { EmployeeImportResponseDto } from './../../proxy/employees/models';
import { AuditedEntityDto, RestService } from "@abp/ng.core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EmployeeImportService {
    apiName = 'Default';

    constructor(private restService: RestService) {}

    import = (request: CustomEmployeeImportDto) => this.restService
        .request<any, EmployeeImportResponseDto>(
            {
                method: 'POST',
                url: `/api/app/employees/import`,
                body: this.generateImportFormData(request)
            },
            { apiName: this.apiName}
        );
    
        private generateImportFormData(request: CustomEmployeeImportDto) {
            const formData = new FormData();
            formData.append('File', request.file, request.file.name);
            return formData;
        }
}

export interface CustomEmployeeImportDto extends AuditedEntityDto<string>{
    file : File
}