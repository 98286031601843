import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EcommerceDto, EcommerceSettingsService } from '@proxy/ecommerce';

@Component({
  selector: 'app-ecommerce-tab',
  templateUrl: './ecommerce-tab.component.html',
  styleUrls: ['./ecommerce-tab.component.scss']
})
export class EcommerceTabComponent implements OnInit {
  ecommerceProfile: EcommerceDto = {} as EcommerceDto;
  ecommerceProfileForm: UntypedFormGroup;
  isLoading: boolean = false;

  constructor(
    private ecommerceSettingsService: EcommerceSettingsService,
    private toaster: ToasterService,
    private localiztionService: LocalizationService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.ecommerceProfile = {} as EcommerceDto;
    this.ecommerceSettingsService.getEcommerceInformation().subscribe((response) => {
      this.ecommerceProfile = response;
      this.buildForm();
      this.isLoading = false;
    });
  }

  buildForm(){
    this.ecommerceProfileForm = this.fb.group({
      ecommerceUrl: [this.ecommerceProfile.ecommerceUrl || ""]
    });
  }

  setEcommerceSettings(){
    this.ecommerceSettingsService.setEcommerceInformation(this.ecommerceProfileForm.value)
      .subscribe({
        next: () => {
          this.ecommerceSettingsService.getEcommerceInformation().subscribe((response) => {
            this.ecommerceProfile = response;
            this.buildForm();
          })
        },
        complete: () => {
          this.toaster.success(
            this.localiztionService.instant("::EcommerceProfile:Settings:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localiztionService.instant("::EcommerceProfile:Settings:Error")
          )
        }
      })
  }

}
