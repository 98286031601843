import { TestQuestionAnswerDto } from './../../proxy/test-questions/test-question-answers/models';
import { TestQuestionService } from './../../proxy/tests/test-questions/test-question.service';
import { TestQuestionDto } from './../../proxy/test-questions/models';
import { TestDto } from './../../proxy/tests/models';
import { ConfigStateService, ListService, LocalizationService, PagedResultDto } from "@abp/ng.core";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TestService } from '@proxy/tests';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';

@Component({
    selector: 'app-test-update',
    templateUrl: './test-update.component.html',
    styleUrls: ['./test-update.component.scss'],
    providers: [ListService]
})
export class TestUpdateComponent implements OnInit, OnDestroy {
    questions = { items: [], totalCount: 0 } as PagedResultDto<TestQuestionDto>;
    questionAnswers = { items: [], totalCount: 0} as PagedResultDto<TestQuestionAnswerDto>;
    private routeSub: Subscription;
    form: UntypedFormGroup;
    questionForm: UntypedFormGroup;
    selectedTest = {} as TestDto;
    selectedQuestion = {} as TestQuestionDto;

    isActiveTab: number = 0;
    isModalOpen = false;
    isQuestionToEdit = false;
    selectedAnswer = false;
    selectedCorrectAnswer = false;
    testId = "";
    
    AnswerText = null;

    constructor(
        private fb: UntypedFormBuilder,
        private localizationService: LocalizationService,
        private questionService: TestQuestionService,
        private router: Router,
        private route: ActivatedRoute,
        private toaster: ToasterService,
        private confirmation: ConfirmationService,
        private testAppService: TestService,
        public readonly list: ListService,
        private config: ConfigStateService,
        public readonly questionList: ListService,

    ) {}

    ngOnInit(): void {
        this.buildForm();
        this.routeSub = this.route.params.subscribe( params => {
            if (params['id']) {
                this.prepareEditTest(params['id']);
                this.testId = params['id'];
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    createQuestion() {
        this.questionAnswers.items = [];
        this.questionAnswers.totalCount = 0;

        this.selectedCorrectAnswer = false;
        this.isModalOpen = true;
        this.isQuestionToEdit = false;
        this.selectedQuestion = null;
        this.questionForm.reset();
    }

    prepareEditTest(id: string) {
        this.testAppService.get(id).subscribe((test) => {
            this.selectedTest = test;
            this.buildForm();
        });

        const questionStreamCreator = (query) => this.questionService.getList(query, id);

        this.list.hookToQuery(questionStreamCreator).subscribe((response) => {
            this.questions.items = response.items;
            this.questions.totalCount = response.totalCount;

            this.isModalOpen = false;
            this.isQuestionToEdit = false;
        });

        this.buildQuestionForm();
    }

    buildForm() {
        this.form = this.fb.group({
            Name: [this.selectedTest.name || '', [Validators.required]],
            identificationNumber: [this.selectedTest.identificationNumber || '', [Validators.required]],
            Description: [this.selectedTest.description || '', []],
        });
    }

    buildQuestionForm() {
        this.questionForm = this.fb.group({
            QuestionText: ['', [Validators.required]],
            Answers: [false, []],
            TestId: [this.testId || '', []]
        });
    }

    updateTest() {
        if (this.form.invalid) {
            return;
        }

        const request = this.testAppService.update(this.selectedTest.id, this.form.value);
        
        request.subscribe(null, () => {
            this.toaster.error(
                this.localizationService.instant("::Test:Message:Edited:Error")
            );
        }, () => {
            this.toaster.success( 
                this.localizationService.instant("::Test:Message:Edited:Success"));
            this.navigateToTests();
        });
    }

    navigateToTests() {
        this.form.reset();
        this.router.navigate(['tests']);
    }

    setActiveTab(tabNumber: number){
        this.isActiveTab = tabNumber;
    }

    addQuestion() {
        if (this.questionForm.invalid) {
            return;
        }

        if (this.selectedCorrectAnswer == false) {
            this.toaster.error(
                this.localizationService.instant("::Answer:Missing:Error:01")
            );
            return;
        }

        this.questionForm.controls["TestId"].setValue(this.testId);
        this.questionForm.controls["Answers"].setValue(this.questionAnswers.items);

        const request = this.questionService.create(this.questionForm.value, this.testId);

        request.subscribe(null, () => {
            this.toaster.error(
                this.localizationService.instant("::Question:Message:Create:Error")
            );
        }, () => {
            this.isModalOpen = false;
            this.questionForm.reset();
            this.list.get();
            this.toaster.success(
                this.localizationService.instant("::Question:Message:Create:Success")
            );
        });
    }

    addAnswer() {
        if(this.AnswerText == null) {
            this.toaster.warn(
                this.localizationService.instant("::Answer:Add:Warning:0001")
            );
            return;
        }

        var douplicateAnswer = this.questionAnswers.items.find(item => 
            item.answerText == this.AnswerText
        );

        if (douplicateAnswer) {
            this.toaster.warn(
                this.localizationService.instant("::Answer:Add:Warning:0002")
            );
            return;
        }

        var newAnswer = {} as TestQuestionAnswerDto;
        newAnswer.answerText = this.AnswerText;
        this.AnswerText = null;
        newAnswer.isCorrect = false;
        this.questionAnswers.items.push(newAnswer);
        this.questionAnswers.items = [...this.questionAnswers.items];
        this.questionAnswers.totalCount++;
    }

    selectQuestionToEdit(data) {
        this.selectedCorrectAnswer = false;
        this.isQuestionToEdit = true;
        this.isModalOpen = true;
        this.questionForm.reset();

        this.questionService.get(data.id).subscribe((result) => {
            this.selectedQuestion = result;
            this.questionForm.controls["QuestionText"].setValue(result.questionText);

            this.questionAnswers.items = result.answers;
            this.questionAnswers.totalCount = result.answers.length;

            this.questionAnswers.items.forEach(item => {
                if(item.isCorrect) {
                    this.selectedCorrectAnswer = true;
                }
            });

        });
    }

    updateSelectedQuestion() {
        if (this.questionForm.invalid) {
            return true;
        }

        if (this.selectedCorrectAnswer == false || this.questionAnswers.totalCount < 1) {
            this.toaster.warn(
                this.localizationService.instant("::Answer:Missing:Error:01")
            );
            return;
        }

        this.questionForm.controls["TestId"].setValue(this.testId);
        this.questionForm.controls["Answers"].setValue(this.questionAnswers.items);

        const request = this.questionService.update(this.selectedQuestion.id, this.questionForm.value); 

        request.subscribe(null, () => {
            this.toaster.error(
                this.localizationService.instant("::Question:Message:Edited:Error")
            );
        }, () => {
            this.questionForm.reset();
            this.list.get();
            this.isModalOpen = false;
            this.isQuestionToEdit = false;
            this.toaster.success( 
                this.localizationService.instant("::Question:Message:Edited:Success"));
        });
    }

    removeQuestion(data) {
        this.confirmation.warn('::Question:Message:Delete:Confirmation', 'AbpAccount::AreYouSure')
        .subscribe((status) => {
            if (status === Confirmation.Status.confirm) {
                const request = this.questionService.delete(data.id); 

                request.subscribe(null, () => {
                    this.toaster.error(
                        this.localizationService.instant("::Question:Message:Delete:Error")
                    );
                }, () => {
                    this.list.get();
                    this.toaster.success( 
                        this.localizationService.instant("::Question:Message:Delete:Success"));
                });
            }
        });
    }

    setAnswer(ans: boolean) {
        this.questionForm.controls["Answer"].setValue(ans);
        this.selectedAnswer = ans;
    }

    removeAnswer(answer) {
        this.questionAnswers.items.forEach((element, index) => {
            if (element.answerText === answer.answerText) {
                //if i delete answer which is correct i want to reset that correct one is not selected
                if(element.isCorrect) this.selectedCorrectAnswer = false;
                this.questionAnswers.items.splice(index, 1);
                this.questionAnswers.totalCount--;
                this.questionAnswers.items = [...this.questionAnswers.items];
            }
        });
    }

    changeCorrectAnswer(event, data) {
        this.selectedCorrectAnswer = event.target.checked;
        this.questionAnswers.items.forEach(element => {
            if(element.answerText == data) {
                element.isCorrect = event.target.checked;
            }
        });
    }
    
}