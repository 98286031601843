import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentDownloadService {
  apiName = 'Default';
  

  downloadFileByDocumentId = (documentId: string) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/documents/download',
      params: { documentId },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
