import { ConfigStateService, downloadBlob, ListResultDto, LocalizationService, PagedResultDto, PermissionService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseCategoryDto, CategoriesLookupService } from '@proxy/categories';
import { DocumentDownloadService, DocumentDto, DocumentsService, DocumentTagDto, renewalPeriodOptions } from '@proxy/documents';
import { TenantDocumentService } from '@proxy/tenant-documents';
import { TestAttemptEntity } from '@proxy/test-attempts';
import { TestLookupDto, TestLookupService } from '@proxy/tests';
import { TicketDto } from '@proxy/tickets';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { FreeFieldName, FreeFieldName1, FreeFieldName10, FreeFieldName11, FreeFieldName12, FreeFieldName13, FreeFieldName14, FreeFieldName2, FreeFieldName3, FreeFieldName4, FreeFieldName5, FreeFieldName6, FreeFieldName7, FreeFieldName8, FreeFieldName9 } from 'src/app/shared/Constants/Constants';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { UserRoleConsts } from 'src/app/shared/Constants/user-roles.constants';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class DocumentViewComponent implements OnInit {
  documentDto: DocumentDto;
  ecommerceUrl: string;
  tasks: ListResultDto<TicketDto> = { items: [] } as ListResultDto<TicketDto>;
  tags = { items: [], totalCount: 0 } as PagedResultDto<DocumentTagDto>;
  activeDocumentService: TenantDocumentService | DocumentsService;
  isGrantedPermissionForSettingProductPageUrl: boolean;
  isActiveTab: number = 0;
  tenantExist: boolean = false;
  allCategories: BaseCategoryDto[] = [];
  allTests: TestLookupDto[] = [];
  focusForCategorySearch$ = new Subject<string>();
  clickForCategorySearch$ = new Subject<string>();
  focusForTestSearch$ = new Subject<string>();
  clickForTestSearch$ = new Subject<string>();
  isLoading: boolean = false;
  form: UntypedFormGroup;
  renewalPeriods = renewalPeriodOptions;
  isTicketsLoading = false;
  isPdfShown: boolean = false;
  freeFieldContent: string;
  tenantHostDocument: string;

  formatter = (category: BaseCategoryDto) => category.name;

  searchForCategory: OperatorFunction<string, readonly BaseCategoryDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForCategorySearch$, this.clickForCategorySearch$).pipe(
      map(term => (term === '' ? this.allCategories
        : this.allCategories.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  searchForTest: OperatorFunction<string, readonly TestLookupDto[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

    return merge(debouncedText$, this.focusForTestSearch$, this.clickForTestSearch$).pipe(
      map(term => (term === '' ? this.allTests
        : this.allTests.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1))
      )
    );
  };

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentsService,
    private tenantDocumentService: TenantDocumentService,
    private router: Router,
    private config: ConfigStateService,
    private toaster: ToasterService,
    private permissionService: PermissionService,
    private localizationService: LocalizationService,
    private categoryLookupService: CategoriesLookupService,
    private testLookupService: TestLookupService,
    private documentDownloadService: DocumentDownloadService,
    private scroller: ViewportScroller,
    private fb: UntypedFormBuilder
  ) { 
    this.isGrantedPermissionForSettingProductPageUrl =
        this.permissionService.getGrantedPolicy('E-Document.Documents.AddProductPageUrl');

    this.ecommerceUrl = this.config.getSetting("Enova.DefaultEcommerceUrl");
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe((params) => {
      this.tenantHostDocument = params['id'];
      if(this.config.getOne("currentUser").tenantId == null){
        this.activeDocumentService = this.documentService;
        this.getDocumentData(this.tenantHostDocument);
      }else {
        this.activeDocumentService = this.tenantDocumentService;
        this.getDocumentData(this.tenantHostDocument);
        this.getDocumentTasks();
        this.tenantExist = true;
      }
    })
    

    this.categoryLookupService.getCategoryLookup().subscribe((response) => {
      this.allCategories = response.items;
    });

    this.testLookupService.getTestLookup().subscribe((response) => {
      this.allTests = response.items;
    });
  }

  buildViewForm(){
    this.form = this.fb.group({
      name: [this.documentDto.name || ''],
      code: [this.documentDto.code || ''],
      description: [this.documentDto.description || ''],
      category: [this.documentDto.category || ''],
      test: [this.documentDto.test || ''],
      productUrlPath: [this.documentDto.productUrlPath || ''],
      renewalPeriodNumber: [this.documentDto.renewalPeriodNumber || ''],
      renewalPeriod: [this.documentDto.renewalPeriod || ''],
      
      freeField: [this.documentDto.freeField || null],
      freeFieldFillingInstruction: [this.documentDto.freeFieldFillingInstruction || null],
      freeFieldContent: [this.documentDto.freeFieldContent || null],

      freeField1: [this.documentDto.freeField1|| null],
      freeFieldFillingInstruction1: [this.documentDto.freeFieldFillingInstruction1 ||null],
      freeFieldContent1: [this.documentDto.freeFieldContent1 || null],

      freeField2: [this.documentDto.freeField2 || null],
      freeFieldFillingInstruction2: [this.documentDto.freeFieldFillingInstruction2 || null],
      freeFieldContent2: [this.documentDto.freeFieldContent2 || null],

      freeField3: [this.documentDto.freeField3 || null],
      freeFieldFillingInstruction3: [this.documentDto.freeFieldFillingInstruction3 || null],
      freeFieldContent3: [this.documentDto.freeFieldContent3 || null],

      freeField4: [this.documentDto.freeField4 || null],
      freeFieldFillingInstruction4: [this.documentDto.freeFieldFillingInstruction4 || null],
      freeFieldContent4: [this.documentDto.freeFieldContent4|| null],

      freeField5: [this.documentDto.freeField5 || null],
      freeFieldFillingInstruction5: [this.documentDto.freeFieldFillingInstruction5 || null],
      freeFieldContent5: [this.documentDto.freeFieldContent5 || null],

      freeField6: [this.documentDto.freeField6 || null],
      freeFieldFillingInstruction6: [this.documentDto.freeFieldFillingInstruction6 ||null],
      freeFieldContent6: [this.documentDto.freeFieldContent6 || null],

      freeField7: [this.documentDto.freeField7 || null],
      freeFieldFillingInstruction7: [this.documentDto.freeFieldFillingInstruction7 || null],
      freeFieldContent7: [this.documentDto.freeFieldContent7 || null],

      freeField8: [this.documentDto.freeField8 || null],
      freeFieldFillingInstruction8: [this.documentDto.freeFieldFillingInstruction8 || null],
      freeFieldContent8: [this.documentDto.freeFieldContent8 || null],

      freeField9: [this.documentDto.freeField9 || null],
      freeFieldFillingInstruction9: [this.documentDto.freeFieldFillingInstruction9 || null],
      freeFieldContent9: [this.documentDto.freeFieldContent9 || null],

      freeField10: [this.documentDto.freeField10 || null],
      freeFieldFillingInstruction10: [this.documentDto.freeFieldFillingInstruction10 || null],
      freeFieldContent10: [this.documentDto.freeFieldContent10 || null],

      freeField11: [this.documentDto.freeField11 || null],
      freeFieldFillingInstruction11: [this.documentDto.freeFieldFillingInstruction11 ||null],
      freeFieldContent11: [this.documentDto.freeFieldContent11 || null],

      freeField12: [this.documentDto.freeField12 || null],
      freeFieldFillingInstruction12: [this.documentDto.freeFieldFillingInstruction12 || null],
      freeFieldContent12: [this.documentDto.freeFieldContent12 || null],

      freeField13: [this.documentDto.freeField13 || null],
      freeFieldFillingInstruction13: [this.documentDto.freeFieldFillingInstruction13 || null],
      freeFieldContent13: [this.documentDto.freeFieldContent13 || null],

      freeField14: [this.documentDto.freeField14 || null],
      freeFieldFillingInstruction14: [this.documentDto.freeFieldFillingInstruction14 || null],
      freeFieldContent14: [this.documentDto.freeFieldContent14 || null],

      availableUntil: [this.documentDto.availableUntil != null ? new Date(this.documentDto.availableUntil) : null]
    });
  }

  getDocumentData(id: string){
    this.activeDocumentService.get(id).subscribe(response => {
      this.documentDto = response;
      this.tags.items = response.documentTags;
      this.tags.totalCount = response.documentTags.length;

      if(this.documentDto.freeField != null){
        this.documentDto.freeField = this.documentDto.freeField.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }
      
      if(this.documentDto.freeField1 != null){
        this.documentDto.freeField1 = this.documentDto.freeField1.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField2 != null){
        this.documentDto.freeField2 = this.documentDto.freeField2.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField3 != null){
        this.documentDto.freeField3 = this.documentDto.freeField3.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField4 != null){
        this.documentDto.freeField4 = this.documentDto.freeField4.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField5 != null){
        this.documentDto.freeField5 = this.documentDto.freeField5.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField6 != null){
        this.documentDto.freeField6 = this.documentDto.freeField6.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField7 != null){
        this.documentDto.freeField7 = this.documentDto.freeField7.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField8 != null){
        this.documentDto.freeField8 = this.documentDto.freeField8.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField9 != null){
        this.documentDto.freeField9 = this.documentDto.freeField9.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField10 != null){
        this.documentDto.freeField10 = this.documentDto.freeField10.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField11 != null){
        this.documentDto.freeField11 = this.documentDto.freeField11.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField12 != null){
        this.documentDto.freeField12 = this.documentDto.freeField12.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField13 != null){
        this.documentDto.freeField13 = this.documentDto.freeField13.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      if(this.documentDto.freeField14 != null){
        this.documentDto.freeField14 = this.documentDto.freeField14.replace(/(\[\[|\]\]|\[|\]|,)/g, '');
      }

      this.buildViewForm();
      this.isLoading = false;
    })
  }

  getDocumentTasks(){
    this.isTicketsLoading = true;
    this.route.params.pipe(
      mergeMap((params) => 
        this.tenantDocumentService.getTenantDocumentTicketsByDocumentId(params['id'])
      )).subscribe((response) => {
        this.tasks = response;
        this.isTicketsLoading = false;
        this.isTaskListShown();
      })
  }

  isTaskListShown(){
    if(!this.isTicketsLoading && this.tasks.items.length != 0)
      return true;
    return false;
  }

  isRoleCorrect(): boolean{
    const currentUser = this.config.getOne("currentUser");
    if(currentUser.tenantId == null)
      return false;

    var correctRole = false;
    currentUser.roles.forEach(element => {
      if(element == UserRoleConsts.DSS_SPECIALIST_ROLE){
        correctRole = true;
      }
    });

    return correctRole;
  }

  showAvailableUntilField(): boolean {
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
        return false;
    }

    var correctRole = false;

    currentUser.roles.forEach(element => {
      if(element == UserRoleConsts.DSS_SPECIALIST_ROLE) {
        correctRole = true;
      }
    });

    return correctRole;
  }

  onUpdatedTask(taskId: string){
    this.tenantDocumentService.updateDocumentTicketStatusByTicketId(taskId).subscribe({
      complete: () => {
        this.tasks.items.forEach((element, index)=> {
          if(element.id == taskId){
            this.tasks.items.splice(index, 1);
          }
        });
        this.toaster.success(
          this.localizationService.instant("::Document:Ticket:UpdateStatus:Success")
        );
      },
      error: () =>{
        this.toaster.error(
          this.localizationService.instant("::Document:Ticket:UpdateStatus:Error:00001")
        );
      }
    })
  }

  isPdfViewerShown(){
    const currentUser = this.config.getOne("currentUser");

    if(currentUser.tenantId == null){
        return false;
    }
    var correctRole = false;

    currentUser.roles.forEach(element => {
        if(element == "DSS Specialistas" || element == "Darbuotojas" || element == "admin") {
            correctRole = true;
        }
    });

    return correctRole;
  }

  setActiveTab(tabNumber: number){
    this.isActiveTab = tabNumber;
    if(tabNumber){
      this.isPdfShown = false;
    }
  }

  getTestAttemptFeature(): boolean {
    return this.config.getFeature("Test.Attempt") == "true";
  }

  attemptDocumentTest(testId: string){
    this.router.navigate(["test/attempt"], {queryParams: {
      id: testId,
      entity: TestAttemptEntity.Document,
      pointer: this.tenantHostDocument
    }});
  } 

  goToProductPage(){
    window.open(`${this.ecommerceUrl}${this.documentDto.productUrlPath}`, "_blank");
  }

  showPdfFile(){  
    this.documentService.checkIfDocumentFilePDFOrHTMLByDocumentId(this.documentDto.id).subscribe({
      next: (response) => {
        if(response){
          this.isPdfShown = true;
          this.scroller.scrollToAnchor("pdf-viewer");
        } 
      },
      error: () => {
        this.toaster.error(
          this.localizationService.instant('::Document:View:Error:00003')
        );
      }
    })  
  }

  isAnyFreeFieldFillingsAreShown(): boolean {
    return (this.isFreeFieldFillingShown(0) 
    || this.isFreeFieldFillingShown(1) 
    || this.isFreeFieldFillingShown(2) 
    || this.isFreeFieldFillingShown(3) 
    || this.isFreeFieldFillingShown(4)
    || this.isFreeFieldFillingShown(5) 
    || this.isFreeFieldFillingShown(6) 
    || this.isFreeFieldFillingShown(7) 
    || this.isFreeFieldFillingShown(8)
    || this.isFreeFieldFillingShown(9) 
    || this.isFreeFieldFillingShown(10) 
    || this.isFreeFieldFillingShown(11) 
    || this.isFreeFieldFillingShown(12)
    || this.isFreeFieldFillingShown(13) 
    || this.isFreeFieldFillingShown(14) 
    );
  }

  saveFreeFieldsData() {
    if(this.tenantExist) {
      this.documentService.checkForTenantTagsByDocumentId(this.documentDto.id).subscribe({ 
        next: () => {
          if(this.isAnyFreeFieldFillingsAreShown()){
            this.UpdateDocumentFreeFields();
          }
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant('::Document:Message:FreeFields:Update:Error')
          )
        }
      });
    }
  }

  private isFreeFieldContentChanged(): boolean {
    return (this.documentDto.freeFieldContent != this.form.get('freeFieldContent').value 
    || this.documentDto.freeFieldContent1 != this.form.get('freeFieldContent1').value
    || this.documentDto.freeFieldContent2 != this.form.get('freeFieldContent2').value
    || this.documentDto.freeFieldContent3 != this.form.get('freeFieldContent3').value
    || this.documentDto.freeFieldContent4 != this.form.get('freeFieldContent4').value
    || this.documentDto.freeFieldContent5 != this.form.get('freeFieldContent5').value
    || this.documentDto.freeFieldContent6 != this.form.get('freeFieldContent6').value
    || this.documentDto.freeFieldContent7 != this.form.get('freeFieldContent7').value
    || this.documentDto.freeFieldContent8 != this.form.get('freeFieldContent8').value
    || this.documentDto.freeFieldContent9 != this.form.get('freeFieldContent9').value
    || this.documentDto.freeFieldContent10 != this.form.get('freeFieldContent10').value
    || this.documentDto.freeFieldContent11 != this.form.get('freeFieldContent11').value
    || this.documentDto.freeFieldContent12 != this.form.get('freeFieldContent12').value
    || this.documentDto.freeFieldContent13 != this.form.get('freeFieldContent13').value
    || this.documentDto.freeFieldContent14 != this.form.get('freeFieldContent14').value
    );
  }

  private setFreeFieldContentDataFromForm() {
    this.documentDto.freeFieldContent = this.form.get('freeFieldContent').value; 
    this.documentDto.freeFieldContent1 = this.form.get('freeFieldContent1').value;
    this.documentDto.freeFieldContent2 = this.form.get('freeFieldContent2').value;
    this.documentDto.freeFieldContent3 = this.form.get('freeFieldContent3').value;
    this.documentDto.freeFieldContent4 = this.form.get('freeFieldContent4').value;
    this.documentDto.freeFieldContent5 = this.form.get('freeFieldContent5').value; 
    this.documentDto.freeFieldContent6 = this.form.get('freeFieldContent6').value;
    this.documentDto.freeFieldContent7 = this.form.get('freeFieldContent7').value;
    this.documentDto.freeFieldContent8 = this.form.get('freeFieldContent8').value;
    this.documentDto.freeFieldContent9 = this.form.get('freeFieldContent9').value;
    this.documentDto.freeFieldContent10 = this.form.get('freeFieldContent10').value; 
    this.documentDto.freeFieldContent11 = this.form.get('freeFieldContent11').value;
    this.documentDto.freeFieldContent12 = this.form.get('freeFieldContent12').value;
    this.documentDto.freeFieldContent13 = this.form.get('freeFieldContent13').value;
    this.documentDto.freeFieldContent14 = this.form.get('freeFieldContent14').value;
    this.documentDto.freeFieldContent = this.form.get('freeFieldContent').value;
  }

  UpdateDocumentFreeFields() {
    if(this.isFreeFieldContentChanged()){
        this.isPdfShown = false;
        this.tenantDocumentService
        .updateDocumentFreeFieldContentByIdAndFreeFieldContentAndFreeFieldContent1AndFreeFieldContent2AndFreeFieldContent3AndFreeFieldContent4AndFreeFieldContent5AndFreeFieldContent6AndFreeFieldContent7AndFreeFieldContent8AndFreeFieldContent9AndFreeFieldContent10AndFreeFieldContent11AndFreeFieldContent12AndFreeFieldContent13AndFreeFieldContent14
          (
            this.tenantHostDocument,
            this.form.get('freeFieldContent').value,
            this.form.get('freeFieldContent1').value,
            this.form.get('freeFieldContent2').value,
            this.form.get('freeFieldContent3').value,
            this.form.get('freeFieldContent4').value,
            this.form.get('freeFieldContent5').value,
            this.form.get('freeFieldContent6').value,
            this.form.get('freeFieldContent7').value,
            this.form.get('freeFieldContent8').value,
            this.form.get('freeFieldContent9').value,
            this.form.get('freeFieldContent10').value,
            this.form.get('freeFieldContent11').value,
            this.form.get('freeFieldContent12').value,
            this.form.get('freeFieldContent13').value,
            this.form.get('freeFieldContent14').value
          ).subscribe({
            next: () => {
              this.setFreeFieldContentDataFromForm();

              this.toaster.success(
                this.localizationService.instant("::Document:Message:FreeFieldUpdate:Success")
              );

            }, 
            error: () => {
              this.toaster.error(
                this.localizationService.instant("::Document:Message:FreeFieldUpdate:Error")
              );
            }
          })
    } else {
      this.toaster.success(
        this.localizationService.instant("::Document:Message:FreeFieldUpdate:Success")
      );
    }
  }

  downloadFile() {
    const promise = this.documentDownloadService.downloadFileByDocumentId(this.tenantHostDocument);
    if(this.tenantExist) {
      this.documentService.checkForTenantTagsByDocumentId(this.documentDto.id).subscribe({ 
        next: () => {
          this.subscribeToDownloadPromise(promise); 
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant('::Document:Message:Download:Error')
          )
        }
      });
    }
    else{
      promise.subscribe((result) => {
        var filename = this.documentDto.fileDescriptor.name;
        downloadBlob(result, filename);
      });
    }
  }
  navigateToDocuments() {
    this.router.navigate(['documents']);
  }

  subscribeToDownloadPromise(promise: Observable<Blob>){
    promise.subscribe((result) => {
      var extension = this.documentDto.fileDescriptor.name.split('.').pop();  

      var filename = this.documentDto.fileDescriptor.name;
      if(extension == "html"){
        filename = `${this.documentDto.fileDescriptor.name}.pdf`;
      }
      downloadBlob(result, filename);
    })  
  }

  isFreeFieldFillingShown(fieldNum: number) {
    const field = fieldNum != 0 ? this.documentDto[`freeField${fieldNum}`] : this.documentDto.freeField;
    const instruction = fieldNum != 0 ? this.documentDto[`freeFieldFillingInstruction${fieldNum}`] : this.documentDto.freeFieldFillingInstruction;
    var tagName: string = "";
    if(fieldNum == 0)
      tagName = FreeFieldName.Name;
    if(fieldNum == 1)
      tagName = FreeFieldName1.Name;
    if(fieldNum == 2)
      tagName = FreeFieldName2.Name;
    if(fieldNum == 3)
      tagName = FreeFieldName3.Name;
    if(fieldNum == 4)
      tagName = FreeFieldName4.Name;
    if(fieldNum == 5)
      tagName = FreeFieldName5.Name;
    if(fieldNum == 6)
      tagName = FreeFieldName6.Name;
    if(fieldNum == 7)
      tagName = FreeFieldName7.Name;
    if(fieldNum == 8)
      tagName = FreeFieldName8.Name;
    if(fieldNum == 9)
      tagName = FreeFieldName9.Name;
    if(fieldNum == 10)
      tagName = FreeFieldName10.Name;
    if(fieldNum == 11)
      tagName = FreeFieldName11.Name;
    if(fieldNum == 12)
      tagName = FreeFieldName12.Name;
    if(fieldNum == 13)
      tagName = FreeFieldName13.Name;
    if(fieldNum == 14)
      tagName = FreeFieldName14.Name;
    
    const tag = this.documentDto.documentTags.find(x => x.generatorTag == tagName);
  
    return field && instruction && tag && this.isRoleCorrect();
  }

  isFreeFieldShown(fieldNum) {
    const field = fieldNum != 0 ? this.documentDto[`freeField${fieldNum}`] : this.documentDto.freeField;
    const instruction = fieldNum != 0 ? this.documentDto[`freeFieldFillingInstruction${fieldNum}`] : this.documentDto.freeFieldFillingInstruction;
    const tenantId = this.config.getOne("currentUser").tenantId;
    
    return field && instruction && tenantId == null;
  }
}
