<app-spinner [isLoading]="isLoading"></app-spinner>
<div class="video-player-wrapper">
  <vg-player class="rounded">

    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-controls [vgAutohide]="true">
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>
      <vg-time-display vgProperty="current" vgFormat="hh:mm:ss"> </vg-time-display>
      <vg-scrub-bar [vgSlider]="true">
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>
      <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display>
      <vg-mute></vg-mute>
      <vg-volume></vg-volume>
      <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <video
      #media
      [vgMedia]="$any(media)"
      id="singleVideo"
      [src]="fileStreamSource"
      preload="auto"
      crossorigin
    ></video>
  </vg-player>
</div>


