import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SonaroCRMDto, SonaroCRMSettingsService } from '@proxy/sonaro-crm';

@Component({
  selector: 'app-sonaro-crm-tab',
  templateUrl: './sonaro-crm-tab.component.html',
  styleUrls: ['./sonaro-crm-tab.component.scss']
})
export class SonaroCrmTabComponent implements OnInit {
  sonaroCRMProfile: SonaroCRMDto = {} as SonaroCRMDto;
  sonaroCRMProfileForm: UntypedFormGroup;
  isLoading: boolean = false;

  constructor(
    private sonaroCRMSettingsService: SonaroCRMSettingsService,
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.sonaroCRMProfile = {} as SonaroCRMDto;
    this.sonaroCRMSettingsService.getSonaroCRMInformation().subscribe((response) => {
      this.sonaroCRMProfile = response;
      this.buildForm();
      this.isLoading = false;
    })
  }

  buildForm(){
    this.sonaroCRMProfileForm = this.fb.group({
      clientID: [this.sonaroCRMProfile.clientID || ""],
      clientSecret: [this.sonaroCRMProfile.clientSecret || ""],
      grantType: [this.sonaroCRMProfile.grantType || ""],
      authApiUrl: [this.sonaroCRMProfile.authApiUrl || ""],
      apiUrl: [this.sonaroCRMProfile.apiUrl || ""],
      isEnabled: [this.sonaroCRMProfile.isEnabled]
    });
  }

  setSonaroCRMSettings(){
    this.sonaroCRMSettingsService.setSonaroCRMInformation(this.sonaroCRMProfileForm.value)
      .subscribe({
        next: () => {
          this.sonaroCRMSettingsService.getSonaroCRMInformation().subscribe((response) => {
            this.sonaroCRMProfile = response;
            this.buildForm();
          })
        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::SonaroCRMProfile:Settings:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::SonaroCRMProfile:Settings::Error")
          );
        }
      })
  }
}
