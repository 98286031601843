<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:TenantActions' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <ng-container>
        <div class="col">
            <div class="text-right float-end">
                <button
                    (click)="printActions()"
                    class="btn btn-sm btn-primary"
                    type="button"
                    id="action-report-button"
                >
                <i class="me-1 fa fa-book"></i>
                    <span>{{ '::Report' | abpLocalization }}</span>
                </button>
            </div>
        </div>  
    </ng-container>

</div>

<div class="data-tables-filter text-start">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="" >
        <abp-advanced-entity-filters-form>
            <form [formGroup]="filterForm">
                <div class="row">

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                            <label for="tenant-action-user" class="form-label">
                                {{ '::SearchByUser' | abpLocalization }}
                            </label>
                            <select id="tenant-action-user-select" class="form-select form-control" formControlName="userName" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [value]="user.userName" *ngFor="let user of tenantUsers">
                                    {{user.userName}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <label for="employee-position" class="form-label">
                            {{ '::SearchByNameAndSurname' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <input type="text" id="tenant-action-user" class="form-control" formControlName="nameAndSurname" (input)="getListWithFilters()"/>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <label for="employee-position" class="form-label">
                            {{ '::SearchByPosition' | abpLocalization }}
                        </label>
                        <div class="form-group">
                            <select id="employee-position" class="form-select form-control" formControlName="position" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [ngValue]="position.displayName" *ngFor="let position of organizationUnitPositions$ | async">
                                    {{ position.displayName }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12 my-1">
                        <div class="form-group">
                            <div>
                                <label for="tenant-action-document" class="form-label">
                                    {{ '::SearchByDocument' | abpLocalization }}
                                </label>
                            </div>
                            <div class="input-group">
                                <input
                                    class="form-control" 
                                    id="typeahead-focus"
                                    type="search"
                                    class="form-control"
                                    formControlName="documentName"
                                    [editable]="false"
                                    (selectItem)="selectedDocumentItem($event)"
                                    [resultFormatter]="documentFormatter"
                                    [inputFormatter]="documentFormatter"
                                    (input)="getListWhenDocumentInputEmpty($event.target.value)"
                                    [ngbTypeahead]="searchActionByDocument"
                                    (focus)="focusForDocumentFilter$.next($any($event).target.value)"
                                    (click)="clickForDocumentFilter$.next($any($event).target.value)"
                                    #instance="ngbTypeahead"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12 my-1">
                        <div class="form-group">
                            <div>
                                <label for="tenant-action-course" class="form-label">
                                    {{ '::SearchByCourse' | abpLocalization }}
                                </label>
                            </div>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    id="typeahead-focus"
                                    type="search"
                                    class="form-control"
                                    formControlName="courseName"
                                    [editable]="false"
                                    (selectItem)="selectedCourseItem($event)"
                                    [resultFormatter]="courseFormatter"
                                    [inputFormatter]="courseFormatter"
                                    (input)="getListWhenCourseInputEmpty($event.target.value)"
                                    [ngbTypeahead]="searchActionByCourse"
                                    (focus)="focusForCourseFilter$.next($any($event).target.value)"
                                    (click)="clickForCourseFilter$.next($any($event).target.value)"
                                    #instance="ngbTypeahead"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                            <label for="tenant-action-entity" class="form-label">
                                {{ '::SearchByEntity' | abpLocalization }}
                            </label>
                            <select id="tenant-action-entity-select" class="form-select form-control" formControlName="entity" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [value]="status.value" *ngFor="let status of actionEntityOptions">
                                    {{'::Enum:TenantActionEntityOptions:' + status.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                            <label for="tenant-action-action-select" class="form-label">
                                {{ '::SearchByActions' | abpLocalization }}
                            </label>
                            <select id="tenant-action-action-select" class="form-select form-control" formControlName="type" (change)="getListWithFilters()">
                                <option value=""></option>
                                <option [value]="type.value" *ngFor="let type of actionTypeOptions">
                                    {{'::Enum:TenantActionTypeOptions:' + type.value | abpLocalization }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                            <label for="date-from-picker" class="form-label">
                                {{ '::FromDate' | abpLocalization }}
                            </label>
                            <input
                                #datepickerStart="ngbDatepicker"
                                class="form-control"
                                name="date-from-picker"
                                formControlName="dateFrom"
                                ngbDatepicker
                                (ngModelChange)="getListWithFilters()"
                                (click)="datepickerStart.toggle()"
                            />
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="form-group">
                            <label for="date-to-picker" class="form-label">
                                {{ '::ToDate' | abpLocalization }}
                            </label>
                            <input
                                #datepickerEnd="ngbDatepicker"
                                class="form-control"
                                name="date-to-picker"
                                formControlName="dateTo"
                                ngbDatepicker
                                (ngModelChange)="getListWithFilters()"
                                (click)="datepickerEnd.toggle()"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-sm-auto align-self-start mt-3">
                        <div class="row">
                            <div class="col-6 col-sm-auto d-grid">
                                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                                    <span>{{ '::Clear' | abpLocalization }}</span>
                                </button>
                            </div>
                            
                            <div class="col-6 col-sm-auto d-grid">
                                <button type="button" class="btn btn-primary" (click)="list.get()">
                                    <span>{{ '::Refresh' | abpLocalization }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
</div>

<div class="card">
    <div class="card-body">
        <ngx-datatable
            [rows]="tenantActions.items" 
            [count]="tenantActions.totalCount" 
            [list]="list" 
            default
        >
        <ngx-datatable-column [name]="'::UserName' | abpLocalization" prop="userName">
        </ngx-datatable-column> 

        <ngx-datatable-column [name]="'::FirstName' | abpLocalization" prop="firstName">
        </ngx-datatable-column> 

        <ngx-datatable-column [name]="'::Surname' | abpLocalization" prop="surname">
        </ngx-datatable-column> 

        <ngx-datatable-column [name]="'::Position' | abpLocalization" prop="position">
        </ngx-datatable-column> 

        <ngx-datatable-column [name]="'::EntityName' | abpLocalization" prop="entityName">
        </ngx-datatable-column> 


        <ngx-datatable-column
            [name]="'::Entity' | abpLocalization"
            prop="actionEntity"
        >
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ '::Enum:ActionEntity:' + row.actionEntity | abpLocalization}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
            [name]="'::Action' | abpLocalization"
            prop="actionType"
        >
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ '::Enum:ActionType:' + row.actionType | abpLocalization}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'::TestResult' | abpLocalization" prop="testResult">
        </ngx-datatable-column> 

        <ngx-datatable-column 
            [name]="'::CompletionDate' | abpLocalization" 
            prop="creationTime"
            [maxWidth]="200"
        >
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.creationTime | date : DateTimeFormatConst }}
            </ng-template>
        </ngx-datatable-column>  

        </ngx-datatable>        
    </div>
</div>

