<div class="row entry-row">
    <ng-container>
        <div class="col">
            <div class="text-right float-end">
                <button
                    (click)="printReport()"
                    class="btn btn-sm btn-primary"
                    type="button"
                    id="report-print-button"
                >
                <i class="me-1 fa fa-print"></i>
                    <span>{{ '::PrintReport' | abpLocalization }}</span>
                </button>
            </div>
        </div>  
    </ng-container>
</div>

<div class="card">
    <table class="table" id="tenantActionsReportTable">
        <thead>
            <tr>
                <th scope="col-sm-1">{{ '::UserName' | abpLocalization }}</th>
                <th scope="col">{{ '::FirstName' | abpLocalization }}</th>
                <th scope="col-sm-2">{{ '::Surname' | abpLocalization }}</th>
                <th scope="col-sm-3">{{ '::Position' | abpLocalization }}</th>
                <th scope="col-sm-1">{{ '::EntityName' | abpLocalization }}</th>
                <th scope="col">{{ '::Entity' | abpLocalization }}</th>
                <th scope="col-sm-2">{{ '::Action' | abpLocalization }}</th>
                <th scope="col-sm-3">{{ '::TestResult' | abpLocalization }}</th>
                <th scope="col-sm-1">{{ '::CompletionDate' | abpLocalization }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let action of tenantActions">
                <tr>
                    <td class="col">{{ action.userName }}</td>
                    <td class="col">{{ action.firstName }}</td>
                    <td class="col">{{ action.surname }}</td>
                    <td class="col">{{ action.position }}</td>
                    <td class="col">{{ action.entityName }}</td>
                    <td class="col">{{ '::Enum:ActionEntity:' + action.actionEntity | abpLocalization}}</td>
                    <td class="col">{{ '::Enum:ActionType:' + action.actionType | abpLocalization}}</td>
                    <td class="col">{{ action.testResult }}</td>
                    <td class="col">{{ action.creationTime | date : DateTimeFormatConst }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
