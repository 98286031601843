<div class="row entry-row">
  <div class="col-12 col-sm-auto">
    <h1 class="content-header-title">{{ selectedTest.name }}</h1>
  </div>

  <div class="col-lg-auto pl-lg-0">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home"></i> </a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/tests">{{ '::Menu:Tests' | abpLocalization }}</a>
      </li>
      <li class="breadcrumb-item">
        {{ '::AttemptTest' | abpLocalization }}
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ selectedTest.name }}
      </li>
    </ol>
  </div>

  <div class="col">
    <div id="AbpContentToolbar" class="row justify-content-end mx-0">
      <div class="col-auto"></div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6" >
    <div *ngIf="questions.totalCount > 0; else falseBlock">
      <div class="card" *ngFor="let item of questions.items; let i = index">
        <h3 class="card-header p-3">
          <span class="badge bg-primary">{{ i + 1 }}</span
          >&nbsp;{{ item.questionText }}
        </h3>
        <div class="card-body mx-0 px-0 py-0 my-0">
          <div class="list-group">
            <div
              *ngFor="let answer of item.answers; let j = index"
              class="list-group-item p-3"
              [ngClass]="checkIfAnswerWasSelected(answer, item) ? 'active' : null"
              (click)="selectAnswer(answer, item)"
            >
              <input
                class="form-check-input me-2"
                type="radio"
                name="flexRadioDefault"
                name="{{ item.id }}"
                [checked]="checkIfAnswerWasSelected(answer, item)"
                
              />
              <label class="form-check-label text-break" for="{{ answer.id }}">
                {{ answer.answerText }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #falseBlock>
      <div class="card">
        <h3 class="card-body p-3">
          {{ '::TestAttemptNoQuestions' | abpLocalization }}
        </h3>
      </div>
    </ng-template>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="card">
      <div class="card-footer px-2">
        <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">
          {{ '::Cancel' | abpLocalization }}
        </button>
        <button type="button" class="btn btn-primary mx-2" (click)="save()" [disabled]="answeredQuestions.totalCount > 0 ? false : true">
          {{ '::Save' | abpLocalization }}
        </button>
        {{ getAnswerCount() }} / {{ questions.totalCount }}
      </div>
    </div>
  </div>
</div>
