import type { DocumentApprovalDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentApprovalSettingsService {
  apiName = 'Default';
  

  getDocumentApprovalInformation = (isForSettings: boolean) =>
    this.restService.request<any, DocumentApprovalDto>({
      method: 'GET',
      url: '/api/app/document-approval-settings/document-approval-information',
      params: { isForSettings },
    },
    { apiName: this.apiName });
  

  setDocumentApprovalInformation = (input: DocumentApprovalDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/document-approval-settings/set-document-approval-information',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
