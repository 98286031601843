<div class="row entry-row">
  <div class="col-12 col-sm-auto">
    <h1 class="content-header-title">{{ '::EditCourse' | abpLocalization }}</h1>
  </div>

  <div class="col-lg-auto pl-lg-0">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/"><i class="fa fa-home"></i> </a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/courses">{{ '::Menu:Course' | abpLocalization }}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ '::EditCourse' | abpLocalization }}
      </li>
    </ol>
  </div>

  <div class="col">
    <div id="AbpContentToolbar" class="row justify-content-end mx-0">
      <div class="col-auto"></div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="main-tab0"
          type="button"
          role="tab"
          (click)="setActiveTab(0)"
          [class.active]="isActiveTab === 0 ? true : false"
        >
          {{ '::MainInformation' | abpLocalization }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="main-tab1"
          type="button"
          role="tab"
          (click)="setActiveTab(1)"
          [class.active]="isActiveTab === 1 ? true : false"
        >
          {{ '::Classes' | abpLocalization }}
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent0" [style]="isActiveTab === 0 ? '' : 'display: none;'">
      <div
        class="tab-pane fade"
        id="0"
        role="tabpanel"
        aria-labelledby="main-tab0"
        [class.active]="isActiveTab === 0 ? true : false"
        [class.show]="isActiveTab === 0 ? true : false"
      >
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <form [formGroup]="form" (ngSubmit)="saveCourse()">
              <div class="form-group mb-3">
                <label class="form-label" for="course-name">{{ '::Name' | abpLocalization }}</label
                ><span> * </span>
                <input
                  type="text"
                  id="course-name"
                  class="form-control"
                  formControlName="Name"
                  autofocus
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="course-code">{{ '::Code' | abpLocalization }}</label
                ><span> * </span>
                <input
                  type="text"
                  id="course-code"
                  class="form-control"
                  formControlName="Code"
                  autofocus
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="course-length">{{
                  '::input:Label:LengthMin' | abpLocalization
                }}</label><span> * </span>
                <input 
                  type="number" 
                  min="0" 
                  id="course-length" 
                  class="form-control" 
                  formControlName="Length" />
              </div>
              <div class="form-group mb-3">
                <label 
                    class="form-label" 
                    for="parent-category-name"
                >
                    {{ '::Category' | abpLocalization }}
                </label>
                <input
                    id="typeahead-focus"
                    type="text"
                    class="form-control"
                    formControlName="Category"
                    [editable]="false"
                    [ngbTypeahead]="searchForCategory"
                    [resultFormatter]="formatter"
                    [inputFormatter]="formatter"
                    (focus)="focusForCategorySearch$.next($any($event).target.value)"
                    (click)="clickForCategorySearch$.next($any($event).target.value)"
                    #instance="ngbTypeahead"
                />
              </div>
              <div class="form-group mb-3">
                <label for="test-name" class="form-label">
                    {{ '::Test' | abpLocalization }}
                </label>
                <input
                      id="typeahead-focus"
                      type="text"
                      class="form-control"
                      formControlName="Test"
                      [editable]="false"
                      [ngbTypeahead]="searchForTest"
                      [resultFormatter]="formatter"
                      [inputFormatter]="formatter"
                      (focus)="focusForTestSearch$.next($any($event).target.value)"
                      (click)="clickForTestSearch$.next($any($event).target.value)"
                      #instance="ngbTypeahead"
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="course-shortDesc">{{
                  '::input:Label:ShortDescription' | abpLocalization
                }}</label>
                <textarea
                  id="course-shortDesc"
                  class="form-control"
                  rows="2"
                  formControlName="ShortDescription"
                >
                </textarea>
              </div>

              <div class="form-group mb-3">
                <label class="form-label" for="course-fullDesc">{{
                  '::input:Label:FullDescription' | abpLocalization
                }}</label>
                <textarea
                  id="course-fullDesc"
                  class="form-control"
                  rows="5"
                  formControlName="FullDescription"
                ></textarea>
              </div>

              <div class="form-group mb-3" *ngIf="this.isGrantedPermissionForSettingProductPageUrl">
                <label class="form-label" for="course-page-link">{{ '::ProductPageUrl' | abpLocalization }}</label>
                <div class="input-group mb-3">
                    <span class="input-group-text" *ngIf="ecommerceUrl">{{ ecommerceUrl }}</span>
                    <input type="text" id="course-page-link" class="form-control" formControlName="ProductUrlPath"/>
                </div>
              </div>

              <div class="row mb-3" *ngIf="!isTenantExist">
                <label class="form-label" for="course-renewal-period">{{ '::CourseRenewalPeriod' | abpLocalization }}<span> *</span></label>
                <div class="col">
                    <input type="number" id="course-name" class="form-control" formControlName="RenewalPeriodNumber"/>
                </div>
                <div class="col">
                    <select id="renewalPeriod" class="form-select form-control" formControlName="RenewalPeriod">
                        <option value=""></option>
                        <option [ngValue]="renewalPeriod.value" *ngFor="let renewalPeriod of renewalPeriods">
                            {{ '::Enum:Course:RenewalPeriod:' + renewalPeriod.value | abpLocalization }}
                        </option>
                    </select>
                </div>
              </div>

              <div class="form-group mb-3" *ngIf="showAvailableUntilField()">
                <label for="available-date">{{ '::AvailableUntilDate' | abpLocalization }} </label>
                <div class="row">
                    <div>
                        <input 
                            id="available-date"
                            #datepickerStart="ngbDatepicker"
                            class="form-control mt-2"
                            name="datepickerStart"
                            formControlName="AvailableUntil"
                            ngbDatepicker
                            (click)="datepickerStart.toggle()"
                        >
                    </div>
                </div>
              </div>

            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <button type="button" class="btn btn-secondary mx-2" (click)="navigateToCourses()">
              {{ '::Cancel' | abpLocalization }}
            </button>
            <button class="btn btn-primary" (click)="saveCourse()" [disabled]="form.invalid">
              <i class="fa fa-check mr-1"></i>
              {{ '::Save' | abpLocalization }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content" id="myTabContent1" [style]="isActiveTab === 1 ? '' : 'display: none;'">
      <div
        class="tab-pane fade"
        id="1"
        role="tabpanel"
        aria-labelledby="main-tab1"
        [class.active]="isActiveTab === 1 ? true : false"
        [class.show]="isActiveTab === 1 ? true : false"
      >
        <div class="row">
          <div class="col-8 col-sm-auto">
            <h3 class="content-header-title">{{ '::ClassList' | abpLocalization }}</h3>
          </div>

          <div class="col">
            <div class="row justify-content-end mx-0">
              <div class="col-auto">
                <button
                  (click)="createClass()"
                  type="button"
                  ng-reflect-ng-class="btn btn-sm btn-primary"
                  class="btn btn-sm btn-primary ng-star-inserted"
                  id="new-class"
                >
                  <i class="me-1 fa fa-plus" ng-reflect-ng-class="fa fa-plus"></i>
                  <span> {{ '::NewClassButton' | abpLocalization }} </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <ngx-datatable [rows]="classes.items" [count]="classes.totalCount" [list]="list" default>
          <ngx-datatable-column
            [name]="'::Actions' | abpLocalization"
            [maxWidth]="150"
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div ngbDropdown container="body" class="d-inline-block">
                <button
                  class="btn btn-primary btn-sm dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  ngbDropdownToggle
                >
                  <i class="fa fa-cog mr-1"></i>
                  {{ '::Actions' | abpLocalization }}
                </button>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem (click)="selectClassToEdit(row)">
                    {{ '::Edit' | abpLocalization }}
                  </button>

                  <button ngbDropdownItem (click)="removeClass(row)">
                    {{ '::Delete' | abpLocalization }}
                  </button>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [maxWidth]="100"
            [name]="'::SortOrder' | abpLocalization"
            prop="sortOrder"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [name]="'::Name' | abpLocalization"
            prop="name"
          ></ngx-datatable-column>
          <ngx-datatable-column 
            [name]="'::FileName' | abpLocalization" 
            prop="fileDescriptor.name" 
            [sortable]="false">
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<abp-modal [(visible)]="isModalOpen" [busy]="modalBusy" (disappear)="onDisappear($event)">
  <ng-template #abpHeader>
    <h3>{{ (isClassToEdit ? '::EditClass' : '::CreateNewClass') | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="classForm">
      <div class="form-group mb-3">
        <label class="form-label" for="course-name">{{ '::Name' | abpLocalization }}</label
        ><span> * </span>
        <input
          type="text"
          id="course-name"
          class="form-control"
          formControlName="Name"
          autofocus
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3">
            <div>
              <label class="form-label" for="sort-order">{{
                '::SortOrder' | abpLocalization
              }}</label>
              <input
                type="number"
                min="0"
                id="sort-order"
                class="form-control"
                formControlName="SortOrder"
              />
            </div>          
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mb-3">
            <div>
              <label class="form-label" for="course-length">
                {{'::ClassLengthMin' | abpLocalization }}
              </label><span> * </span>
              <input
                type="number"
                min="0"
                id="course-length"
                class="form-control"
                formControlName="Length"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="form-label" for="course-fullDesc">
          {{ '::ShortDescription' | abpLocalization }}
        </label>
        <span> * </span>
        <textarea
          id="course-fullDesc"
          class="form-control"
          rows="5"
          formControlName="ShortDescription"
        ></textarea>
      </div>
      <div class="form-group mb-3">
        <label class="form-label" for="FileAttachment">{{
          '::FileAttachment' | abpLocalization
        }}</label>

        <div class="input-group">
          <input
            class="form-control"
            type="file"
            formControlName="File"
            id="AttachNewFile"
            aria-describedby="AttachNewFile"
            aria-label="AttachNewFile"
            (change)="onFileChanged($event)"
            [accept]="allowedFileTypesFormattedString"
            [abpDisabled]="modalBusy"
          />

          <div
            ngbDropdown
            placement="top-right"
            *ngIf="(selectedClass != null) && (selectedClass.fileDescriptor != null)"
          >
            <button
              class="btn btn-outline-secondary dropdown-toggle"
              type="button"
              aria-expanded="false"
              data-bs-toggle="dropdown"              
              *ngIf="selectedClass.fileDescriptorId"
              ngbDropdownToggle
              style="border-radius: 0px 5px 5px 0px; padding: 6px 18px"
            >
              {{ '::Actions' | abpLocalization }}
            </button>

            <div ngbDropdownMenu>
              <button class="btn" ngbDropdownItem (click)="downloadFile()" type="button">
                <i class="fa fa-download"></i> {{ '::Download' | abpLocalization }}
              </button>
              <button class="btn" ngbDropdownItem (click)="deleteFile()" type="button">
                <i class="fa fa-remove"></i> {{ '::Delete' | abpLocalization }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>

    <button 
      type="button" 
      class="btn btn-secondary mx-2" 
      abpClose>
      {{ '::Cancel' | abpLocalization }}
    </button>

    <abp-button 
      iconClass="fa fa-check" 
      (click)="isClassToEdit == true ? updateSelectedClass() : addClass()" 
      [disabled]="classForm.invalid"
    >
      {{
        (isClassToEdit ? '::Edit' : '::Save') | abpLocalization 
      }}
    </abp-button>

  </ng-template>
</abp-modal>
