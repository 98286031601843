import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentComponent } from './document.component';
import { DOCUMENTS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { DOCUMENTS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.provider';
import { DocumentBannerComponent } from './document-banner/document-banner.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { DocumentTaskListComponent } from './document-view/document-task-list/document-task-list.component';
import { DocumentTagListComponent } from './document-view/document-tag-list/document-tag-list.component';
import { DocumentPdfViewerComponent } from './document-view/document-pdf-viewer/document-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    DocumentComponent,
    DocumentBannerComponent,
    DocumentViewComponent,
    DocumentTaskListComponent,
    DocumentTagListComponent,
    DocumentPdfViewerComponent
  ],
  imports: [
    NgbModule,
    SharedModule,
    DocumentRoutingModule,
    NgxExtendedPdfViewerModule
  ]
})
export class DocumentModule { 
  static forRoot(): ModuleWithProviders<DocumentModule> {
    return {
      ngModule: DocumentModule,
      providers: [
        DOCUMENTS_MANAGEMENT_FEATURES_PROVIDERS,
        DOCUMENTS_MANAGEMENT_VISIBLE_PROVIDERS,
      ],
    }
  }
}
