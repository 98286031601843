import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CompanyProfileDto, CompanyProfileService } from '@proxy/company';
import {regionOptions, personKindOptions, PersonKind} from '@proxy/enova-account';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  companyProfile: CompanyProfileDto = {} as CompanyProfileDto;
  companyProfileForm: UntypedFormGroup;
  isLoading: boolean = false;
  regionValues = regionOptions;
  personKindValues = personKindOptions

  constructor(
    private toaster: ToasterService,
    private localizationService: LocalizationService,
    private companyProfileService: CompanyProfileService,
    private fb: UntypedFormBuilder,
    private config: ConfigStateService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.companyProfile = {} as CompanyProfileDto;
    this.companyProfileService.getCompanyProfile().subscribe((response) => {
      this.companyProfile = response;
      this.buildForm();
      this.isLoading = false;
    });

  }

  buildForm(){
    var tenantName = this.config.getOne("currentTenant").name;

    this.companyProfileForm = this.fb.group({
      companyName: [this.companyProfile.companyName || "", [Validators.maxLength(700)]],
      desicionMakerResponsibilities: [this.companyProfile.desicionMakerResponsibilities || ""],
      nameSurname: [this.companyProfile.nameSurname || ''],
      city: [this.companyProfile.city || ''],
      companyCode: [this.companyProfile.companyCode || '', 
        this.companyProfile.personKind && this.companyProfile.personKind == PersonKind.Company 
        ? [Validators.minLength(9), Validators.maxLength(9)]
        : null
      ],
      manager: [this.companyProfile.manager || ''],
      registrationAddress: [this.companyProfile.registrationAddress || ''],
      officeAddress: [this.companyProfile.officeAddress || ''],
      companyActivities: [this.companyProfile.companyActivities || ''],
      postCode: [this.companyProfile.postCode || ''],
      companyVatCode: [this.companyProfile.companyVatCode || '', [Validators.maxLength(16)]],
      region: [this.companyProfile.region || null],
      personKind: [this.companyProfile.personKind || null],
      tenantName: [tenantName || "Host"],
    });

    this.companyProfileForm.get('personKind').valueChanges.subscribe((kind: PersonKind) => {
      if(kind === PersonKind.Company) {
        this.companyProfileForm.clearValidators();
        this.companyProfileForm.controls["companyCode"].setValidators(
          [Validators.required, Validators.minLength(9), Validators.maxLength(9)]
        );
      } else {
        this.companyProfileForm.controls["companyCode"].setValidators(null);
      }

      this.companyProfileForm.controls["companyCode"].updateValueAndValidity();
    });
  }

  setTenantCompanySettings(){
    this.companyProfileService.setCompanyInformationForTenant(this.companyProfileForm.value)
      .subscribe({
        next: () => {

        },
        complete: () => {
          this.toaster.success(
            this.localizationService.instant("::CompanyProfile:SetProfile:Success")
          );
        },
        error: () => {
          this.toaster.error(
            this.localizationService.instant("::CompanyProfile:SetProfile:Error")
          );
        }
      })
  }
}
