import { DevicesComponent } from './devices.component';
import { ModuleWithProviders, NgModule } from "@angular/core";
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { DevicesRoutingModule } from './devices-routing.module';
import { DEVICES_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { DEVICES_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.provider';

@NgModule({
    declarations: [
        DevicesComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        DevicesRoutingModule
    ]
})
export class DevicesModule {
    static forRoot(): ModuleWithProviders<DevicesModule> {
        return {
        ngModule: DevicesModule,
        providers: [
            DEVICES_MANAGEMENT_FEATURES_PROVIDERS,
            DEVICES_MANAGEMENT_VISIBLE_PROVIDERS,
        ],
        }
    }
}