import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuildingRoutingModule } from './building-routing.module';
import { BuildingComponent } from './building.component';
import { SharedModule } from '../shared/shared.module';
import { BUILDINGS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { BUILDINGS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.provider';


@NgModule({
  declarations: [
    BuildingComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    BuildingRoutingModule
  ]
})
export class BuildingModule { 
  static forRoot(): ModuleWithProviders<BuildingModule> {
    return {
      ngModule: BuildingModule,
      providers: [
        BUILDINGS_MANAGEMENT_FEATURES_PROVIDERS,
        BUILDINGS_MANAGEMENT_VISIBLE_PROVIDERS
      ]      
    }
  }
}
