import { Component, OnInit } from '@angular/core';
import { externalLogoutUri } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-to-ecommerce-logout',
  templateUrl: './redirect-to-ecommerce-logout.component.html',
  styleUrls: ['./redirect-to-ecommerce-logout.component.scss']
})
export class RedirectToEcommerceLogoutComponent implements OnInit {

  constructor() { 
    window.open(externalLogoutUri.magentoLogoutUri, '_self');
  }

  ngOnInit(): void {
  }

}
