import { PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { MissingDocumentDto } from '@proxy/documents/tenant-documents';

@Component({
  selector: 'app-document-banner',
  templateUrl: './document-banner.component.html',
  styleUrls: ['./document-banner.component.scss']
})
export class DocumentBannerComponent implements OnInit {
  @Input() documents: PagedResultDto<MissingDocumentDto>;
  @Input() ecommerceUrl: string;
  isModalOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  openDocumentsOfferModal(){
    this.isModalOpen = true;
  }

  viewDocumentPage(urlPath: string){
    window.open(`${this.ecommerceUrl}${urlPath}`, "_blank");
  }
}
