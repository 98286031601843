import type { EcommerceDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EcommerceSettingsService {
  apiName = 'Default';
  

  getEcommerceInformation = () =>
    this.restService.request<any, EcommerceDto>({
      method: 'GET',
      url: '/api/app/ecommerce-settings/ecommerce-information',
    },
    { apiName: this.apiName });
  

  setEcommerceInformation = (input: EcommerceDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/ecommerce-settings/set-ecommerce-information',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
