import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuardService } from '../shared/CanDeactivateGuard/canDeactivate-guard.service';
import { TestAttemptComponent } from '../test-attempt/test-attempt.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { DocumentComponent } from './document.component';

const routes: Routes = [
  { path: '', component: DocumentComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'view/:id', component: DocumentViewComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: 'tests/attempt/:id', component: TestAttemptComponent, canActivate: [AuthGuard, PermissionGuard], canDeactivate: [CanDeactivateGuardService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule { }
