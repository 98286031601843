import { mapEnumToOptions } from '@abp/ng.core';

export enum TenantActionEntity {
  Class = 0,
  Document = 1,
  Course = 2,
  Task = 3,
  Test = 4,
}

export const tenantActionEntityOptions = mapEnumToOptions(TenantActionEntity);
