import { EcommerceService } from '../proxy/ecommerce/ecommerce.service';
import { LocalizationService } from '@abp/ng.core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-link-to-shop-button',
    templateUrl: `./link-to-shop.component.html`,
    styleUrls: ['./link-to-shop.component.scss']
})
export class LinkToShopComponent {
    ecommerceUrl: string = "";
    buttonText: string = "";

    constructor(
        private ecommerceService: EcommerceService,
        private localizationService: LocalizationService,
    ){
        this.ecommerceService.getEcommerceDefaultLink().subscribe((response) => {
            this.ecommerceUrl = response;
        });

        this.buttonText = this.localizationService.instant('::EShop');
    }

    openShopPage() {
        window.open(this.ecommerceUrl, '_self');
    }

}
