import { TenantActionsModule } from './tenantActions/tenant-actions.module';
import { TestResultsModule } from './test-results/test-results.module';
import { DevicesModule } from './devices/devices.module';
import { IncidentModule } from './incident/incident.module';
import { BuildingModule } from './building/building.module';
import { TicketModule } from './ticket/ticket.module';
import { EmployeeModule } from './employees/employee.module';
import { TestModule } from './tests/tests.module';
import { CourseModule } from './courses/courses.module';
import { LogoComponent } from './logo/logo.component';
import { CoreModule } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { DEFAULT_VALIDATION_BLUEPRINTS, ThemeSharedModule, UserMenuService } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { VALIDATION_BLUEPRINTS } from '@ngx-validate/core';
import { CompanyComponent } from './company-profile-tab/company.component';
import { DocumentModule } from './document/document.module';
import { SonaroCrmTabComponent } from './sonaro-crm-tab/sonaro-crm-tab.component';
import { SharedModule } from './shared/shared.module';
import { EcommerceTabComponent } from './ecommerce-tab/ecommerce-tab.component';
import { ACTIVITY_TYPES_ACTIVITY_TYPE_ROUTE_PROVIDER } from './activity-types/activity-type/providers/activity-type-route.provider';
import { WORK_SAFETY_AREAS_WORK_SAFETY_AREA_ROUTE_PROVIDER } from './work-safety-areas/work-safety-area/providers/work-safety-area-route.provider';
import { QUESTIONNAIRES_QUESTIONNAIRE_ROUTE_PROVIDER } from './questionnaires/questionnaire/providers/questionnaire-route.provider';
import { QUESTIONNAIRE_QUESTION_ANSWERS_QUESTIONNAIRE_QUESTION_ANSWER_ROUTE_PROVIDER } from './questionnaire-question-answers/questionnaire-question-answer/providers/questionnaire-question-answer-route.provider';
import { ProductRenewalExpirationDaysComponent } from './Product-Renewal-Expiration-Days-tab/product-renewal-expiration-days-tab.component';
import { LogoutComponent } from './logout/logout.component';
import { RedirectToEcommerceLogoutComponent } from './redirect-to-ecommerce-logout/redirect-to-ecommerce-logout.component';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { MyUserMenuService } from './user-menu/my-user-menu.service';
import { DocumentApprovalTabComponent } from './document-approval-tab/document-approval-tab.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LogoComponent,
    CompanyComponent,
    SonaroCrmTabComponent,
    EcommerceTabComponent,
    ProductRenewalExpirationDaysComponent,
    LogoutComponent,
    RedirectToEcommerceLogoutComponent,
    DocumentApprovalTabComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FullCalendarModule,
    NgbModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      }
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    GdprConfigModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    DocumentModule.forRoot(),
    CourseModule.forRoot(),
    TestModule.forRoot(),
    EmployeeModule.forRoot(),
    TicketModule.forRoot(),
    BuildingModule.forRoot(),
    IncidentModule.forRoot(),
    DevicesModule.forRoot(),
    TestResultsModule.forRoot(),
    TenantActionsModule.forRoot(),
    SharedModule,
    AbpOAuthModule.forRoot()
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: UserMenuService,
      useClass: MyUserMenuService
    },
    {
      provide: VALIDATION_BLUEPRINTS,
      useValue: {
        ...DEFAULT_VALIDATION_BLUEPRINTS,
        requiredFileTypes: '::FileTypeNotAllowed',
        allowedFileSize: '::FileToLargeAllowedSizeIs'
      },
    },
    ACTIVITY_TYPES_ACTIVITY_TYPE_ROUTE_PROVIDER,
    WORK_SAFETY_AREAS_WORK_SAFETY_AREA_ROUTE_PROVIDER,
    QUESTIONNAIRES_QUESTIONNAIRE_ROUTE_PROVIDER,
    QUESTIONNAIRE_QUESTION_ANSWERS_QUESTIONNAIRE_QUESTION_ANSWER_ROUTE_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
