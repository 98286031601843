import { EmployeesComponent } from './employee.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';

const routes: Routes = [
    { path: '', component: EmployeesComponent, canActivate: [AuthGuard, PermissionGuard]}
]

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class EmployeeRoutingModule {}