import { downloadBlob } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { ClassDto, ClassFileDownloadService } from '@proxy/classes';

@Component({
  selector: 'app-courses-file-viewer',
  templateUrl: './courses-file-viewer.component.html',
  styleUrls: ['./courses-file-viewer.component.scss']
})
export class CoursesFileViewerComponent implements OnInit{
  @Input() class: ClassDto;

  constructor(private classFileDownloadService: ClassFileDownloadService,) { }

  ngOnInit(): void { }

  downloadFile() {
    this.classFileDownloadService.downloadFileByClassId(this.class.id).subscribe((result) => {
      downloadBlob(result, this.class.fileDescriptor.name);
    });
}

}
