export abstract class ProviderConsts {
    static readonly DOCUMENT_FEATURE_KEY: string = 'Document.View';
    static readonly DOCUMENT_ROUTE_PATCH_NAME: string = '::Menu:E-Documents';

    static readonly COURSE_FEATURE_KEY: string = 'Course.View';
    static readonly COURSE_ROUTE_PATCH_NAME: string = '::Menu:Course';

    static readonly TEST_FEATURE_KEY: string = 'Test.Control';
    static readonly TEST_ROUTE_PATCH_NAME: string = '::Menu:Tests';

    static readonly TEST_RESULT_FEATURE_KEY: string = 'Test.TestResultReport';
    static readonly TEST_RESULT_ROUTE_PATCH_NAME: string = '::Menu:TestResults';

    static readonly EMPLOYEE_FEATURE_KEY: string = 'Employee.Control';
    static readonly EMPLOYEE_ROUTE_PATCH_NAME: string = '::Menu:Employees';

    static readonly TASK_FEATURE_KEY: string = 'Task.Control';
    static readonly TASK_ROUTE_PATCH_NAME: string = '::Menu:Tasks';

    static readonly BUILDING_FEATURE_KEY: string = 'Building.Control';
    static readonly BUILDING_ROUTE_PATCH_NAME: string = '::Menu:Buildings';

    static readonly INCIDENT_FEATURE_KEY: string = 'Incident.Control';
    static readonly INCIDENT_ROUTE_PATCH_NAME: string = '::Menu:Incidents';

    static readonly DEVICE_FEATURE_KEY: string = 'Device.Control';
    static readonly DEVICE_ROUTE_PATCH_NAME: string = '::Menu:Devices';

    
    static readonly USER_ACTION_FEATURE_KEY: string = 'User.UserActions';
    static readonly USER_ACTION_ROUTE_PATCH_NAME: string = '::Menu:TenantActions';
}