import { TestAttemptEntity } from './../proxy/test-attempts/test-attempt-entity.enum';
import { TestAttemptDto } from '../proxy/test-attempts/models';
import { TestAttemptResultDto } from '../proxy/test-attempt-results/models';
import { TestAttemptService as TestAttemptService } from '../proxy/test-attempts/test-attempt.service';
import { TestQuestionService } from '../proxy/tests/test-questions/test-question.service';
import { TestAttemptQuestionDto } from '../proxy/test-questions/models';
import { LocalizationService, PagedResultDto } from "@abp/ng.core";
import { Confirmation, ConfirmationService, ToasterService } from "@abp/ng.theme.shared";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TestDto, TestService } from "@proxy/tests";
import { Observable, of, Subscription } from "rxjs";
import { Constants } from 'src/app/shared/Constants/Constants';
import { Location, DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-test-attempt',
    templateUrl: './test-attempt.component.html',
    styleUrls: ['./test-attempt.component.scss']
})
export class TestAttemptComponent implements OnInit {
    questions = {items: [], totalCount: 0 } as PagedResultDto<TestAttemptQuestionDto>;
    answeredQuestions = {items: [], totalCount: 0 } as PagedResultDto<TestAttemptResultDto>;
    testAttempt = {} as TestAttemptDto;
    form: UntypedFormGroup;
    attemptedEntity: TestAttemptEntity;
    pointerToEntity: string;

    private routeSub: Subscription;
    selectedTest = {} as TestDto;

    //flag to check if user is changing route without save or cancel
    isFinished = false;

    constructor(
        private route: ActivatedRoute,
        private testAppService: TestService,
        private questionService: TestQuestionService,
        private testAttemptsService: TestAttemptService,
        private location: Location,
        private confirmation: ConfirmationService,
        private localizationService: LocalizationService,
        private toaster: ToasterService,
        private datePipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.routeSub = this.route.queryParams.subscribe( params => {
            if (params['id']) {
                this.answeredQuestions.items = [];
                this.answeredQuestions.totalCount = 0;

                this.attemptedEntity = params['entity'] || null;
                this.pointerToEntity = params['pointer'] || null;

                this.prepareAttemptTest(params['id']);
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    get dateFormat() { return Constants.DATE }

    getDateTimeString(): string {
        return this.datePipe.transform(new Date(), "yyyy-MM-dd, h:mm:ss");
    }

    getAnswerCount(): number {
        var count: number = 0;
        this.answeredQuestions.items.forEach(item => {
            if(item.testQuestionAnswerId != null) count++;
        });
        
        return count;
    }

    prepareAttemptTest(id: string) {
        this.testAppService.get(id, true).subscribe((test) => {
            this.selectedTest = test;

            this,this.questionService.getTestAttemptQuestions(test.id).subscribe((response) => {
                this.questions.items = response;
                this.questions.totalCount = response.length;

                response.forEach(element => {
                    var attemptData = {} as TestAttemptResultDto;
                    attemptData.testQuestionId = element.id;

                    this.answeredQuestions.items.push(attemptData);
                    this.answeredQuestions.totalCount++;
                });

                if (response.length == 0)
                {
                    this.isFinished = true;
                }
            });

            this.createTestAttempt(test);
        });
    }

    createTestAttempt(test) {
        this.testAttempt = {} as TestAttemptDto;
        this.testAttempt.testId = test.id;
        this.testAttempt.testName = test.name;
        this.testAttempt.testCode = test.identificationNumber;
        this.testAttempt.testAnswers = null;
        this.testAttempt.startTimeString = this.getDateTimeString();
    }

    selectAnswer(answer, question) {
        this.answeredQuestions.items.forEach((item) => {
            if(item.testQuestionId == question.id) {
                item.testQuestionAnswerId = answer.id;
            } 
        });
    }

    checkIfAnswerWasSelected(answer, question): boolean {
        return !!this.answeredQuestions.items.filter(item => item.testQuestionId == question.id 
            && item.testQuestionAnswerId == answer.id)[0];
    }

    save() {
        var incompleteTest = false;

        incompleteTest = !!this.answeredQuestions.items.find(x => x.testQuestionAnswerId == null);
        this.testAttempt.testAnswers = this.answeredQuestions.items;
        this.testAttempt.endTimeString = this.getDateTimeString();
        this.testAttempt.attemptedEntity = this.attemptedEntity;
        this.testAttempt.pointerToEntity = this.pointerToEntity;

        this.confirmation.warn(
            (incompleteTest ? '::Test:Attempt:Complete:Warning:0001' : '::Test:Attempt:Complete:Warning:0002'),
            'AbpAccount::AreYouSure')
                .subscribe((status) => {
                    if(status === Confirmation.Status.confirm) {
                        this.saveTestAttempt();
                    }
                });
    }

    saveTestAttempt() {
        this.testAttemptsService.create(this.testAttempt).subscribe((result) => {
            this.isFinished = true;
            this.toaster.success(
                this.localizationService.instant("::Test:Attempt:Toast:Message:00002")
            );
            this.location.back();
        });
    }

    cancel() {
        if(this.answeredQuestions.totalCount == 0){
            this.isFinished = true;
            this.location.back();
            return;
        }
    
        this.confirmation.warn('::Test:Attempt:Cancel:Warning:0001', 'AbpAccount::AreYouSure')
            .subscribe((status) => {
                if(status === Confirmation.Status.confirm) {
                    this.isFinished = true;
                    this.location.back();
                }
            });
    }

    canExit(): Observable<boolean> {
        if (!this.isFinished) {
            return this.confirmation.warn('::Test:Attempt:Navigation:Warning:0001', 'AbpAccount::AreYouSure').pipe(map((status) => {
                return status === Confirmation.Status.confirm;
            }));
        }
        else {
            return of(true);
        }
    }
}
