import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(authService: OAuthService, route: ActivatedRoute) {
    route.queryParams.subscribe(params => {
      const { post_logout_redirect_uri } = params;
      authService.logOut({post_logout_redirect_uri});   
    });
  }
}
