import { DeviceDto } from './../proxy/devices/models';
import { DevicesService } from './../proxy/devices/devices.service';
import { ConfigStateService, ListService, LocalizationService, PagedResultDto } from "@abp/ng.core";
import { Confirmation, ConfirmationService, ToasterService } from "@abp/ng.theme.shared";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Constants } from '../shared/Constants/Constants';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
    providers: [ListService]
})
export class DevicesComponent implements OnInit{
    devices = { items: [], totalCount: 0} as PagedResultDto<DeviceDto>;
    selectedDevice: DeviceDto;
    isModalOpen = false;
    form: UntypedFormGroup;
    DateTimeFormatConst = Constants.DATETIME;

    constructor(
        public readonly list: ListService,
        private confirmation: ConfirmationService,
        private fb: UntypedFormBuilder,
        private toaster: ToasterService,
        private config: ConfigStateService,
        private localizationService: LocalizationService,
        private devicesService: DevicesService
    ){}

    ngOnInit(): void{
        const courseStreamCreator = (query) => this.devicesService.getList(query);
        this.list.hookToQuery(courseStreamCreator).subscribe((response) => {
            this.devices.items = response.items;
            this.devices.totalCount = response.totalCount;
        });
    }

    getDeviceControlFeature(): boolean {
        return this.config.getFeature("Device.Control") == "true";
    }

    createDevice() {
        this.selectedDevice = {} as DeviceDto;
        this.buildForm();
        this.isModalOpen = true;
    }

    editDevice(deviceId: string) {
        this.selectedDevice = this.devices.items.find(x => x.id == deviceId);
        this.buildForm();
        this.isModalOpen = true;
    }

    buildForm() {
        this.form = this.fb.group({
            Name: [this.selectedDevice.name || '', [Validators.required]],
            Code: [this.selectedDevice.code || '', [Validators.required]],
            Description: [this.selectedDevice.description || '', []]
        });
    }

    formDeviceData(){
        return {
            name: this.form.get('Name').value,
            code: this.form.get('Code').value,
            description: this.form.get('Description').value
        }
    }

    save() {
        if (this.form.invalid) {
            return;
        }

        if (this.selectedDevice.id) {
            this.updateDevice();
        } else {
            this.createNewDevice();
        }
    }

    createNewDevice() {
        this.devicesService.create(this.formDeviceData()).subscribe((value) => {
            this.isModalOpen = false;
            this.form.reset();
            this.list.get();
        }, (error) => {
            this.toaster.error(
                this.localizationService.instant("::Device:Message:Created:Error")
            );
        }, () => {
            this.toaster.success( 
                this.localizationService.instant("::Device:Message:Created:Success")
            );
        });
    }

    updateDevice() {
        this.devicesService.update(this.selectedDevice.id, this.formDeviceData()).subscribe((value) => {
            this.isModalOpen = false;
            this.form.reset();
            this.list.get();
        }, (error) => {
            this.toaster.error(
                this.localizationService.instant("::Device:Message:Edit:Error")
            );
        }, () => {
            this.toaster.success( 
                this.localizationService.instant("::Device:Message:Edit:Success")
            );
        });
    }

    delete(id: string) {
        this.confirmation.warn('::Device:Message:Delete:Confirmation', 'AbpAccount::AreYouSure').subscribe((status) => {
            if (status === Confirmation.Status.confirm) {
                this.devicesService.delete(id).subscribe((value) => {
                    this.list.get();
                }, (error) => {
                    this.toaster.error(
                        this.localizationService.instant("::Device:Message:Delete:Error")
                    );
                }, () => {
                    this.toaster.success( 
                        this.localizationService.instant("::Device:Message:Delete:Success")
                    );
                });
            }
        });
    }
}