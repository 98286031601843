import { AuditedEntityDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { IncidentStatus, IncidentType } from '@proxy/incidents';

@Injectable({
    providedIn: 'root'
})
export class IncidentService {
    apiName = 'Default';

    constructor(private restService: RestService) {}

    create = (request: CustomIncidentDto) => this.restService
        .request<any, any>(
            {
            method: 'POST',
            url: `/api/app/tenant-incidents`,
            body: this.generateFormData(request),
            },
            { apiName: this.apiName }
        );

    private generateFormData(request: CustomIncidentDto) {
        const formData = new FormData();
        if(request.file != null){
            formData.append('File', request.file, request.file.name);
        }
        
        formData.append('Title', request.title);
        formData.append('Description', request.description);
        formData.append('Status', request.status.toString());

        if(request.solution != null){
            formData.append('Solution', request.solution);  
        }

        formData.append('Type', request.type.toString());
        formData.append('IncidentDateTime', request.incidentDateTime.toString());
        return formData;
    }

}

export interface CustomIncidentDto extends AuditedEntityDto<string> {
    title: string;
    description: string;
    status: IncidentStatus;
    type: IncidentType;
    solution?: string;
    incidentDateTime: string;
    file?: File;
}
