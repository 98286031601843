import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TenantUserLookupDto } from '../enova-users-lookup/models';
import type { LookupDto, LookupRequestDto } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class EnovaOrganizationUnitsService {
  apiName = 'Default';
  

  getOrganizationUnitLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/organization-units/organization-unit-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getUsers = (organizationUnitIds: string[]) =>
    this.restService.request<any, ListResultDto<TenantUserLookupDto>>({
      method: 'GET',
      url: '/api/app/organization-units/users-by-id',
      params: { organizationUnitIds },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
