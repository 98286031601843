<div class="row entry-row">
    <div class="col-12 col-sm-auto">
        <h1 class="content-header-title">{{ '::Menu:Devices' | abpLocalization }}</h1>
    </div>

    <div class="col-lg-auto pl-lg-0">
        <abp-breadcrumb></abp-breadcrumb>
    </div>

    <div class="col">
        <div class="text-right float-end">
            <button  
                type="button"
                ng-reflect-ng-class="btn btn-sm btn-primary" 
                class="btn btn-sm btn-primary" 
                *abpPermission="'Devices.Devices.Create'"
                (click)="createDevice()"
            >
                <i class="me-1 fa fa-plus"></i> 
                <span> {{ '::NewDeviceButton' | abpLocalization}} </span>
            </button>                    
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        
        <div class="data-table-filter">
            <abp-entity-filter [list]="list">               
            </abp-entity-filter>
        </div>
        
    </div>
</div>

<div class="card">
    <div class="card-body">

        <ngx-datatable 
            [rows]="devices.items" 
            [count]="devices.totalCount" 
            [list]="list" 
            default
        >

        <ngx-datatable-column
            [name]="'::Actions' | abpLocalization"
            [maxWidth]="150"
            [sortable]="false"
            *abpPermission="'Devices.Devices.Edit || Devices.Devices.Delete'"
            >
                <ng-template let-row="row" ngx-datatable-cell-template >
                    <div ngbDropdown container="body" class="d-inline-block">
                        <button
                            class="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            ngbDropdownToggle
                        >
                            <i class="fa fa-cog mr-1"></i>&nbsp;{{ '::Actions' | abpLocalization }}
                        </button>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem 
                                *abpPermission="'Devices.Devices.Edit'"
                                (click)="editDevice(row.id)"
                            >
                                {{ '::Edit' | abpLocalization }}
                            </button>
                            
                            <button ngbDropdownItem 
                                *abpPermission="'Devices.Devices.Delete'"
                                (click)="delete(row.id)"
                            >
                                {{ '::Delete' | abpLocalization }}
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="'::Name' | abpLocalization" prop="name"></ngx-datatable-column>

            <ngx-datatable-column [name]="'::Description' | abpLocalization" prop="description"></ngx-datatable-column>

            <ngx-datatable-column [name]="'::Code' | abpLocalization" prop="code"></ngx-datatable-column>
        
            <ngx-datatable-column
                [name]="'::CreationDate' | abpLocalization"
                prop="creationTime"
                [maxWidth]="200"
            >
                <ng-template let-row="row" ngx-datatable-cell-template="">
                    {{ row.creationTime | date : DateTimeFormatConst }} 
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{
                (selectedDevice.id ? '::EditDevice' : '::CreateNewDevice') | abpLocalization 
            }}</h3>
    </ng-template>

    <ng-template #abpBody>
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="form-group mb-3">
                <label class="form-label" for="course-name">{{ '::Name' | abpLocalization }}</label><span> * </span>
                <input type="text" id="course-name" class="form-control" formControlName="Name" autofocus />
            </div>
    
            <div class="form-group mb-3">
                <label class="form-label" for="course-code">{{ '::Code' | abpLocalization }}</label><span> * </span>
                <input type="text" id="course-code" class="form-control" formControlName="Code" />
            </div>
    
            <div class="form-group mb-3">
                <label class="form-label" for="course-desc">{{ '::input:Label:Description' | abpLocalization }}</label>
                <textarea  id="course-desc" class="form-control" rows="3" formControlName="Description" >
                </textarea>
            </div>
        </form>

    </ng-template>

    <ng-template #abpFooter>
        <button type="button" class="btn btn-secondary" abpClose>
            {{ '::Cancel' | abpLocalization }}
        </button>
        <button class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
            <i class="fa fa-check mr-1"></i>
            {{ '::Save' | abpLocalization }}
        </button>
    </ng-template>

</abp-modal>