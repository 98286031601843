import { mapEnumToOptions } from '@abp/ng.core';

export enum Region {
  AlytausApskritis = 1,
  KaunoApskritis = 2,
  KlaipedosApskritis = 3,
  MarijampolesApskritis = 4,
  PanevezioApskritis = 5,
  SiauliuApskritis = 6,
  TauragesApskritis = 7,
  TelsiuApskirtis = 8,
  UtenosApskritis = 9,
  VilniausApskritis = 10,
}

export const regionOptions = mapEnumToOptions(Region);
