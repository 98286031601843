import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { TenantActionsComponent } from './tenant-actions.component';
import { TenantActionsReportComponent } from './tenant-actions-report/tenant-actions-report.component';


const routes: Routes = [
    { path: '', component: TenantActionsComponent, canActivate: [AuthGuard, PermissionGuard]},
    { path: 'users-actions/report', component: TenantActionsReportComponent, canActivate: [AuthGuard, PermissionGuard]}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class TenantActionRoutingModule { }
