import { TenantActionsReportComponent } from './tenant-actions-report/tenant-actions-report.component';
import { TenantActionsComponent } from './tenant-actions.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantActionRoutingModule } from './tenant-actions-routing.module';
import { USER_ACTIONS_MANAGEMENT_FEATURES_PROVIDERS } from './providers/features.token';
import { USER_ACTIONS_MANAGEMENT_VISIBLE_PROVIDERS } from './providers/visible.providers';

@NgModule({
    declarations: [
        TenantActionsComponent,
        TenantActionsReportComponent
    ],
    imports: [ 
        NgbModule,
        SharedModule,
        CommonModule,
        TenantActionRoutingModule
    ],
    providers: [
        NgbActiveModal
    ]
})
export class TenantActionsModule { 
    static forRoot(): ModuleWithProviders<TenantActionsModule> {
        return {
            ngModule: TenantActionsModule,
            providers: [
                USER_ACTIONS_MANAGEMENT_FEATURES_PROVIDERS,
                USER_ACTIONS_MANAGEMENT_VISIBLE_PROVIDERS,
            ],
        }
    }
}
