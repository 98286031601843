import type { TestLookupDto } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TestLookupService {
  apiName = 'Default';
  

  getAvailableTestLookup = () =>
    this.restService.request<any, ListResultDto<TestLookupDto>>({
      method: 'GET',
      url: '/api/app/test-lookup/available-test-lookup',
    },
    { apiName: this.apiName });
  

  getTestLookup = () =>
    this.restService.request<any, ListResultDto<TestLookupDto>>({
      method: 'GET',
      url: '/api/app/test-lookup/test-lookup',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
