import type { GeneratorTagDto } from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeneratorTagLookupService {
  apiName = 'Default';
  

  getGeneratorTagLookup = () =>
    this.restService.request<any, ListResultDto<GeneratorTagDto>>({
      method: 'GET',
      url: '/api/app/generator-tag-lookup/generator-tag-lookup',
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
