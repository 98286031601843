<div class="row">
    <h2>{{ '::CompanyProfile' | abpLocalization }}</h2>
    <hr>
    <form 
        class="pt-4"
        *ngIf="!isLoading"
        (ngSubmit)="setTenantCompanySettings()"
        [formGroup]="companyProfileForm"
    >
        <div class="form-group mb-3">
            <label class="form-label" for="tenant-name">
                {{ '::CompanyTab:Tenant' | abpLocalization }}
            </label>
            <input type="text" id="tenant-name" class="form-control" formControlName="tenantName" [abpDisabled]="true"/>
        </div>

        <div class="form-group mb-3">
            <label for="person-kind">{{'::PersonKind' | abpLocalization}}</label>
            <select class="form-control" id="person-kind" formControlName="personKind">
                <option [ngValue]="null">{{'::SelectPersonKind' | abpLocalization}}</option>
                <option [ngValue]="personKindValue.value" *ngFor="let personKindValue of personKindValues"> {{ '::Enum:PersonKind:' + personKindValue.value | abpLocalization }}</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-name">
            {{ '::CompanyName' | abpLocalization }}
            </label>
            <input type="text" id="company-name" class="form-control" formControlName="companyName"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="decision-maker-responsibilites">
                {{ '::DecisionMakerResponsibilities' | abpLocalization }}
            </label>
            <textarea
                id="decision-maker-responsibilities"
                class="form-control"
                cols="30"
                rows="2"
                formControlName="desicionMakerResponsibilities"
            ></textarea>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="name-surname">
                {{ '::NameSurname' | abpLocalization }}
            </label>
            <input type="text" id="name-surname" class="form-control" formControlName="nameSurname"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-city">
                {{ '::CompanyCity' | abpLocalization }}
            </label>
            <input type="text" id="company-city" class="form-control" formControlName="city"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-code">
                {{ '::CompanyCode' | abpLocalization }}
            </label>
            <input type="text" id="company-code" class="form-control" formControlName="companyCode"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-vat-code">
                {{ '::CompanyVatCode' | abpLocalization }}
            </label>
            <input type="text" id="company-vat-code" class="form-control" formControlName="companyVatCode"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-manager">
                {{ '::CompanyManager' | abpLocalization }}
            </label>
            <input type="text" id="company-manager" class="form-control" formControlName="manager"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-registration-address">
                {{ '::CompanyRegistrationAddress' | abpLocalization }}
            </label>
            <input type="text" id="company-registration-address" class="form-control" formControlName="registrationAddress"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="post-code">
                {{ '::PostCode' | abpLocalization }}
            </label>
            <input type="text" id="post-code" class="form-control" formControlName="postCode"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-office-address">
                {{ '::CompanyOfficeAddress' | abpLocalization }}
            </label>
            <input type="text" id="company-office-address" class="form-control" formControlName="officeAddress"/>
        </div>

        <div class="form-group mb-3">
            <label class="form-label" for="company-activities">
                {{ '::CompanyActivities' | abpLocalization }}
            </label>
            <textarea
                id="company-activities"
                class="form-control"
                cols="30"
                rows="2"
                formControlName="companyActivities"
            ></textarea>
        </div>

        <div class="form-group mb-3">
            <label for="region">{{'::Region' | abpLocalization}}</label>
            <select class="form-control" id="region" formControlName="region">
              <option [ngValue]="null">{{'::SelectRegion' | abpLocalization}}</option>
              <option [ngValue]="regionValue.value" *ngFor="let regionValue of regionValues"> {{ '::Enum:Region:' + regionValue.value | abpLocalization }}</option>
            </select>
          </div>

        <abp-button
            (click)="setTenantCompanySettings()"
        >
            <i class="fa fa-save"></i>
            {{ '::Save' | abpLocalization }}
        </abp-button>
    </form>
</div>