import { Component, Input } from '@angular/core';
import { CourseDto } from '@proxy/courses';

@Component({
  selector: 'app-courses-class-tabs',
  templateUrl: './courses-class-tabs.component.html',
  styleUrls: ['./courses-class-tabs.component.scss']
})
export class CoursesClassTabsComponent {
  @Input() course: CourseDto;
  @Input() courseMinutes: number;
  @Input() courseHours: number;
  active = 1;

  constructor() { }
}
