<div class="row">
    <div class="col-12">
        <div class="alert alert-info" role="alert">
            <div class="container">
                <h3 class="text-center">{{ '::CourseBannerText' | abpLocalization }}</h3>
                <hr>
                <div class="d-flex justify-content-center">
                    <h4>{{ '::CourseBannerSuggestionText' | abpLocalization }}</h4>
                    <button class="btn btn-primary btn-sm mx-3" (click)="openCoursesOfferModal()">
                        {{ '::CourseBannerButton' | abpLocalization }}
                    </button>
                </div>
            </div>
        </div>  
    </div>
</div>

<abp-modal [(visible)]="isModalOpen">
    <ng-template #abpHeader>
        <h3>{{ '::MissingCourses' | abpLocalization }}</h3>
    </ng-template>
    
    <ng-template #abpBody>
        <ngx-datatable
            [rows]="courses.items"
            [totalCount]="courses.totalCount"
            [limit]="10"
            default
        >
            <ngx-datatable-column
                [name]="'::Course' | abpLocalization"
                prop="name"
            ></ngx-datatable-column>
            <ngx-datatable-column
                *ngIf="ecommerceUrl"
                [sortable]="false"
            >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button
                        *ngIf="row.productUrlPath"
                        class="btn btn-primary btn-sm"
                        type="button"
                        (click)="viewCoursePage(row.productUrlPath)"
                    >
                        {{ '::CourseBannerModalButton' | abpLocalization }}
                    </button>
                </ng-template>
                
            </ngx-datatable-column>
        </ngx-datatable>
        
    </ng-template>

    <ng-template #abpFooter>
        <button
            type="button"
            class="btn btn-secondary"
            abpClose
        >
            {{ '::Close' | abpLocalization }}
        </button>
    </ng-template>
</abp-modal>