import type { CreateTestDto, GetTestListDto, TestDto, UpdateTestDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TestService {
  apiName = 'Default';
  

  create = (input: CreateTestDto) =>
    this.restService.request<any, TestDto>({
      method: 'POST',
      url: '/api/app/test',
      body: input,
    },
    { apiName: this.apiName });
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/test/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string, isAttempt?: boolean) =>
    this.restService.request<any, TestDto>({
      method: 'GET',
      url: `/api/app/test/${id}`,
      params: { isAttempt },
    },
    { apiName: this.apiName });
  

  getList = (input: GetTestListDto) =>
    this.restService.request<any, PagedResultDto<TestDto>>({
      method: 'GET',
      url: '/api/app/test',
      params: { filter: input.filter, creatorId: input.creatorId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  update = (id: string, input: UpdateTestDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/test/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
