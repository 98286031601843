import { RoutesService, eLayoutType } from '@abp/ng.core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true }
];

function configureRoutes(routes: RoutesService) {

  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: '/dashboard',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-chart-line',
        order: 2,
        layout: eLayoutType.application,
        requiredPolicy: 'Enova.Dashboard.Host || Enova.Dashboard.Tenant'
      },
      {
        name: '::Menu:Tasks',
        order: 3,
        iconClass: 'fa fa-tasks',
        requiredPolicy: 'Tickets.Tickets.View',
        layout: eLayoutType.application,
      },
      {
        path: '/tasks',
        order: 1,
        name: '::Menu:AllTasks',
        parentName: '::Menu:Tasks',
        requiredPolicy: 'Tickets.Tickets.View',
        layout: eLayoutType.application,
      },
      {
        path: '/calendar',
        order: 2,
        name: '::Menu:Calendar',
        parentName: '::Menu:Tasks',
        requiredPolicy: 'Tickets.Tickets.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:Course',
        path: '/courses',
        iconClass: 'fas fa-desktop',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: "Courses.Courses.View",
      },
      {
        path: '/documents',
        name: '::Menu:E-Documents',
        iconClass: 'fa fa-file',
        order: 5,
        requiredPolicy: 'E-Document.Documents.View',
        layout: eLayoutType.application
      },
      {
        path: '/categories',
        name: '::Menu:Categories',
        iconClass: 'fa fa-list-alt',
        order: 6,
        layout: eLayoutType.application,
        requiredPolicy: 'Categories.Categories.View'
      },
      {
        path: '/employees',
        name: '::Menu:Employees',
        iconClass: 'fa fa-briefcase',
        order: 7,
        requiredPolicy: 'Employees.Employees.View',
        layout: eLayoutType.application
      },
      {
        path: '/incidents',
        name: '::Menu:Incidents',
        iconClass: 'fa fa-exclamation-triangle',
        order: 6,
        requiredPolicy: 'Incidents.Incidents.View',
        layout: eLayoutType.application,
      },
      {
        name: '::Menu:Tests',
        path: '/tests',
        iconClass: 'fas fa-book',
        order: 7,
        requiredPolicy: 'Tests.Tests.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:Orders',
        path: '/orders',
        iconClass: 'fas fa-list',
        order: 8,
        requiredPolicy: 'Order.Orders.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:Buildings',
        path: '/buildings',
        iconClass: 'fa fa-building',
        order: 8,
        requiredPolicy: 'Buildings.Buildings.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:Devices',
        path: '/devices',
        iconClass: 'fas fa-hdd',
        order: 8,
        requiredPolicy: 'Devices.Devices.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:Questions',
        path: '/questions',
        iconClass: 'fa fa-question-circle',
        order: 8,
        requiredPolicy: 'Questions.Question.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:TenantActions',
        path: '/users-actions',
        iconClass: 'fa fa-history',
        order: 9,
        requiredPolicy: 'TenantActions.TenantActions',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:TestResults',
        path: '/test-results',
        iconClass: 'fa fa-check',
        order: 10,
        requiredPolicy: 'Test-Attempts.TestAttempts.View',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:TenantQuestionnaire',
        path: '/questionnaire',
        iconClass: 'fa fa-file',
        order: 11,
        requiredPolicy: 'TenantQuestionnaire.Questionnaire',
        layout: eLayoutType.application
      },
      {
        name: '::Menu:TenantBilling',
        path: '/billing',
        iconClass: 'fa fa-file',
        parentName: eThemeSharedRouteNames.Administration,
        requiredPolicy: 'Billing.Billing',
        order: 4,
        layout: eLayoutType.application
      }
    ]);
  };
}
