<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs rounded">
    <li [ngbNavItem]="1">
        <a ngbNavLink class="rounded-top">
        {{ '::Course:Overview' | abpLocalization }}
        </a>
        <ng-template ngbNavContent>
            <div class="row">
                <div class="col-12">
                <h3>{{ '::Course:About' | abpLocalization }}</h3>
                <p>
                    {{ course.shortDescription }}
                </p>
            </div>
            </div>
            <hr class="border-top border-secondary">
            <div class="row mt-3">
                <div class="col-4">
                    {{ '::Category' | abpLocalization }}
                </div>
                <div class="col-8">
                    <p>
                        {{ course.category ? course.category.name : null }}
                    </p>
                </div>
            </div>
            <hr class="border-top border-secondary">
            <div class="row mt-3">
                <div class="col-4">
                    {{ '::CourseNumbers' | abpLocalization }}
                </div>
                <div class="col-8">
                    <p>
                        {{ '::CourseLength' | abpLocalization }}
                        {{ courseHours }} h 
                        {{ courseMinutes }} min
                    </p>
                </div>
            </div>
            <hr class="border-top border-secondary">
            <div class="row mt-3">
                <div class="col-4">
                    {{ '::Course:Description' | abpLocalization }}
                </div>
                <div class="col-8">
                    <p>
                        {{ course.fullDescription }}
                    </p>
                </div>
            </div>
            <ng-container *ngIf="course.renewalPeriod">
                <hr class="border-top border-secondary">
                <div class="row mt-3">
                    <div class="col-4">
                        {{ '::CourseRenewalPeriod' | abpLocalization }}
                    </div>
                    <div class="col-8">
                        <p>
                            {{  course.renewalPeriodNumber  }}
                            {{ (course.renewalPeriod == 1 ? '::Days' : course.renewalPeriod == 2 ? '::Months' : '::Years') | abpLocalization }}
                        </p>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2 rounded"></div>