import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ClassDto, GetClassListDto } from '../classes/models';

@Injectable({
  providedIn: 'root',
})
export class CourseClassesService {
  apiName = 'Default';
  

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/course-classes/${id}`,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, ClassDto>({
      method: 'GET',
      url: `/api/app/course-classes/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetClassListDto, courseId: string) =>
    this.restService.request<any, PagedResultDto<ClassDto>>({
      method: 'GET',
      url: '/api/app/course-classes',
      params: { filter: input.filter, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount, courseId },
    },
    { apiName: this.apiName });
  

  getView = (classId: string, tenantCourseId: string) =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/app/course-classes/view',
      params: { classId, tenantCourseId },
    },
    { apiName: this.apiName });
  

  hardDeleteFile = (id: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/course-classes/${id}/hard-delete-file`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
