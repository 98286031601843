import { Component, Input, OnInit } from '@angular/core';
import { DocumentDownloadService } from '@proxy/documents';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-document-pdf-viewer',
  templateUrl: './document-pdf-viewer.component.html',
  styleUrls: ['./document-pdf-viewer.component.scss']
})
export class DocumentPdfViewerComponent implements OnInit {
  @Input() documentId: string;
  blob: Blob = null;

  constructor(
    private documentDownloadService: DocumentDownloadService) {
      pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    }

  ngOnInit(): void {
    this.documentDownloadService.downloadFileByDocumentId(this.documentId).subscribe((response) => {
      this.blob = response;
    })
  }

}
