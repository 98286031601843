import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DocumentDto } from '../documents/models';
import type { GetTenantDocumentListDto, MissingDocumentDto } from '../documents/tenant-documents/models';
import type { TenantHostDocumentDto } from '../documents/tenant-host-document/models';
import type { TicketDto } from '../tickets/models';

@Injectable({
  providedIn: 'root',
})
export class TenantDocumentService {
  apiName = 'Default';
  

  get = (id: string) =>
    this.restService.request<any, DocumentDto>({
      method: 'GET',
      url: `/api/app/tenant-document/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetTenantDocumentListDto) =>
    this.restService.request<any, PagedResultDto<TenantHostDocumentDto>>({
      method: 'GET',
      url: '/api/app/tenant-document',
      params: { filter: input.filter, categoryId: input.categoryId, testId: input.testId, availableUntil: input.availableUntil, creationDateFrom: input.creationDateFrom, creationDateTo: input.creationDateTo, documentStatus: input.documentStatus, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });
  

  getMissingDocumentsList = () =>
    this.restService.request<any, ListResultDto<MissingDocumentDto>>({
      method: 'GET',
      url: '/api/app/tenant-document/missing-documents-list',
    },
    { apiName: this.apiName });
  

  getTenantDocumentTicketsByDocumentId = (documentId: string) =>
    this.restService.request<any, ListResultDto<TicketDto>>({
      method: 'GET',
      url: `/api/app/tenant-document/tenant-document-tickets/${documentId}`,
    },
    { apiName: this.apiName });
  

  updateDocumentFreeFieldContentByIdAndFreeFieldContentAndFreeFieldContent1AndFreeFieldContent2AndFreeFieldContent3AndFreeFieldContent4AndFreeFieldContent5AndFreeFieldContent6AndFreeFieldContent7AndFreeFieldContent8AndFreeFieldContent9AndFreeFieldContent10AndFreeFieldContent11AndFreeFieldContent12AndFreeFieldContent13AndFreeFieldContent14 = (id: string, freeFieldContent: string, freeFieldContent1: string, freeFieldContent2: string, freeFieldContent3: string, freeFieldContent4: string, freeFieldContent5: string, freeFieldContent6: string, freeFieldContent7: string, freeFieldContent8: string, freeFieldContent9: string, freeFieldContent10: string, freeFieldContent11: string, freeFieldContent12: string, freeFieldContent13: string, freeFieldContent14: string) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/tenant-document/${id}/document-free-field-content`,
      params: { freeFieldContent, freeFieldContent1, freeFieldContent2, freeFieldContent3, freeFieldContent4, freeFieldContent5, freeFieldContent6, freeFieldContent7, freeFieldContent8, freeFieldContent9, freeFieldContent10, freeFieldContent11, freeFieldContent12, freeFieldContent13, freeFieldContent14 },
    },
    { apiName: this.apiName });
  

  updateDocumentTicketStatusByTicketId = (ticketId: string) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/tenant-document/document-ticket-status/${ticketId}`,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
