import type { GetTestAttemptListDto, TestAttemptDto, TestUserAttemtResultDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TestAttemptService {
  apiName = 'Default';
  

  create = (input: TestAttemptDto) =>
    this.restService.request<any, TestAttemptDto>({
      method: 'POST',
      url: '/api/app/test-attempt',
      body: input,
    },
    { apiName: this.apiName });
  

  get = (id: string) =>
    this.restService.request<any, TestAttemptDto>({
      method: 'GET',
      url: `/api/app/test-attempt/${id}`,
    },
    { apiName: this.apiName });
  

  getList = (input: GetTestAttemptListDto) =>
    this.restService.request<any, PagedResultDto<TestUserAttemtResultDto>>({
      method: 'GET',
      url: '/api/app/test-attempt',
      params: { filter: input.filter, testAttemptUserId: input.testAttemptUserId, documentId: input.documentId, courseId: input.courseId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
