import { AuditedEntityDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ClassService {
    apiName = 'Default';

    constructor(private restService: RestService) {}

    create = (request: CustomClassDto, courseId: string) => this.restService
        .request<any, any>(
            {
            method: 'POST',
            url: `/api/app/course-classes/${courseId}`,
            body: this.generateFormData(request),
            },
            { apiName: this.apiName }
        );

        update = (id: string, request: CustomClassDto) => this.restService
        .request<any, any>(
            {
                method: 'PUT',
                url: `/api/app/course-classes/${id}`,
                body: this.generateFormData(request),
            },
            { apiName: this.apiName }
        );

    private generateFormData(request: CustomClassDto) {
        const formData = new FormData();
        if(request.file != null) {
            formData.append('File', request.file, request.file.name);
        }
        formData.append('Name', request.name);
        formData.append('Length', request.length.toString());
        formData.append('ShortDescription', request.shortDescription);
        if(request.sortOrder != null) {
            formData.append('SortOrder', request.sortOrder.toString());      
        }

        return formData;
    }
    
}

export interface CustomClassDto extends AuditedEntityDto<string> {
    name: string;
    length?: number;
    shortDescription?: string;
    courseId?: string;
    file?: File;
    sortOrder?: number;
}
